import React from "react";
import styled from "styled-components";
import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';

const BottomNavigation: React.FC<BottomNavigationProps> = ({ children, sidemenuIsOpen, isFullscreen }) => {
    return (
        <Container sidemenuIsOpen={sidemenuIsOpen && !isFullscreen}>
            {children}
        </Container>
    )
}


export interface BottomNavigationProps {
    children: React.ReactNode;
    sidemenuIsOpen: boolean
    isFullscreen?: boolean
}

const mapStateToProps = (state: ReduxStore) => ({
    sidemenuIsOpen: state.navbar.sidebarOpen
});

const mapDispatchToProps = { };
const ConnectedBottomNavigation = connect(
    mapStateToProps,
    mapDispatchToProps
)(BottomNavigation);

export default ConnectedBottomNavigation;


const Container = styled.div<{sidemenuIsOpen: boolean}>`
    padding: 15px 20px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .12);
    background-color: #fff;
    width: 100%;
    height: 70px;
    box-sizing: border-box;
    z-index: 100;

    position: fixed;
    bottom: 0;
    max-width: ${props => props.sidemenuIsOpen ? 'calc(100% - 280px)' : 'calc(100vw - 56px)'};
`
