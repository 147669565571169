import React from 'react';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';
import { LevelCardProps, SummaryCardProps } from './ResultSummary.types';

export const TalentPathImage = styled.img`
    width: 100px;
    height: 100px;
    background-color: #fff;
    border-radius: 50%;
`

export const ResultSummaryContainer = styled.section`
    position: relative;
`;

export const SummaryCard: React.FC<SummaryCardProps> = ({
    title,
    text,
}) => {
    return (
        <SummaryCardContainer>
            <SummaryCardTitle>{title}</SummaryCardTitle>
            <SummaryCardText>{text}</SummaryCardText>
        </SummaryCardContainer>
    );
};

export const SummaryCardContainer = styled.div`
    margin: 1em;
    overflow: hidden;
`;

export const SummaryCardTitle = styled.h5`
    margin-top: 1em;
    margin-bottom: 0.5em;
    font-weight: bold;
`;

export const SummaryCardText = styled.p`
    margin: 0;
    color: #333333;
    font-size: 1em;
    font-weight: 300;
    line-height: 25px;
    position: relative;
`;

export const LevelCardContainer = styled.div<{ color: string }>`
    margin-bottom: 1.5rem;
    padding: 1em;
    height: 100px;
    align-items: center;
    display: flex;
    background-color: ${props => props.color};
`;

export const LevelCardNumber = styled.div`
    padding-left: 4px;
    padding-right: 8px;
    display: inline-block;
    font-size: 2.5rem;
    font-weight: bold;
`;

export const LevelCard: React.FC<LevelCardProps> = ({
    text,
    color,
}) => {
    return (
        <LevelCardContainer color={color}>
            <LevelCardNumber>{text}</LevelCardNumber>
        </LevelCardContainer>
    );
};

export const AsideHeader = styled.div<{ bgColor: string }>`
    padding: 30px 20px;
    background-color: ${props => props.bgColor};
    display: grid;
    grid-template-columns: 1fr 247px 1fr 100px;
    gap: 10px;
    align-items: center;
    box-sizing: border-box;
`;

export const AsideResults = styled.div`
    padding: 30px 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;
    box-sizing: border-box;
    background-color: #eff4f9;
`;
