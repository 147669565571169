import React from 'react';
import styled from 'styled-components';
import theme from 'utils/theme';

interface ToggleProps {
    state: boolean
}

const Container = styled.div<{ state: boolean }>`
    height: 28px;
    width: 52px;
    background-color: ${props => props.state ? '#33b9a3' : '#bfd3e7'};
    border-radius: 32px;
    position: relative;
    min-width: 52px;
`;

const Notch = styled.div<{ state: boolean }>`
    height: 24px;
    width: 24px;
    border-radius: 32px;
    background-color: #fff;
    position: absolute;
    top: 2px;
    left: ${props => props.state ? 26 : 2}px;
    z-index: 1;
`;

const Toggle: React.FC<ToggleProps> = ({ state }) => (
    <Container state={state}>
        <Notch state={state} />
    </Container>
)

export default Toggle
