import { ButtonStates } from "./IconButton.types";
export type Colors = 'primary' | 'secondary' | 'success' | 'warning' | 'warningSticky' | 'danger';
export const ButtonRecords: Record<Colors, ButtonStates> = {
    primary: {
        default: {
            background: 'transparent',
            text: '#262738',
        },
        hover: {
            background: '#606273',
            text: '#fff',
        },
        focus: {
            background: '#262738',
            text: '#fff',
        }
    },
    secondary: {
        default: {
            background: 'transparent',
            text: '#262738',
        },
        hover: {
            background: '#606273',
            text: '#fff',
        },
        focus: {
            background: '#262738',
            text: '#fff',
        }
    },
    success: {
        default: {
            background: 'transparent',
            text: '#262738',
        },
        hover: {
            background: '#7ABABF',
            text: '#fff',
        },
        focus: {
            background: '#649CA0',
            text: '#fff',
        }
    },
    warning: {
        default: {
            background: 'transparent',
            text: '#262738',
        },
        hover: {
            background: '#66caba',
            text: '#fff',
        },
        focus: {
            background: '#99dcd1',
            text: '#fff',
        }
    },
    warningSticky: {
        default: {
            background: '#66caba',
            text: '#262738',
        },
        hover: {
            background: '#99dcd1',
            text: '#fff',
        },
        focus: {
            background: '#99dcd1',
            text: '#fff',
        }
    },
    danger: {
        default: {
            background: 'transparent',
            text: '#262738',
        },
        hover: {
            background: '#FA6E6E',
            text: '#fff',
        },
        focus: {
            background: '#E94343',
            text: '#fff',
        }
    },
};
