import React, { useEffect } from 'react';
import getLanguageObject from 'helpers/language';
import {
    ManageOrganisationUsersContainer,
    RenderUsers,
} from './ManageOrganisationUsers.components';
import ManageOrganisationUsersProps from './ManageOrganisationUsers.types';

const ManageOrganisationUsers: React.FC<ManageOrganisationUsersProps> = ({
    users,
    deleteUser,
    updateOrganisationRequest,
    currentLanguage,
    permissions
}) => {
    useEffect(() => {
        updateOrganisationRequest();
    }, [updateOrganisationRequest]);

    const lang = getLanguageObject(currentLanguage);

    return (
        <ManageOrganisationUsersContainer>
            <RenderUsers deleteUser={deleteUser} lang={lang} users={users} permissions={permissions} />
        </ManageOrganisationUsersContainer>
    );
};

export default ManageOrganisationUsers;
