import * as Sentry from "@sentry/react";
import { SENTRY_DSN } from 'config';
import React from 'react';
import MainRouter from 'routes/Router';
Sentry.init({ 
    dsn: SENTRY_DSN,
    tracesSampleRate: 1.0,
});

const Styr = () => <MainRouter />;

export default Styr;
