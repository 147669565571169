import { useEffect, useReducer } from "react";
import { localDataReducer } from "./JobMatrixResults.reducer";
import { initialLocalJobMatrixData } from "./JobMatrixResults.types";
import initialJobMatrixData, { LOCAL_STORAGE_KEY } from "./initialJobMatrixData";

export const useJobMatrixHelper = () => {
    const [localData, localDataDispatch] = useReducer(localDataReducer, initialLocalJobMatrixData);

    // Load the last local data from the local storage.
    useEffect(() => {
        const localString = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (localString) {
            const locallyStoredData = JSON.parse(localString);
            localDataDispatch({
                type: "init",
                payload: locallyStoredData
            })
        } else {
            localDataDispatch({
                type: "init",
                payload: initialJobMatrixData
            })
        }
    }, [])

    const enlargeWidthColumn = (uuid: string) => {
        localDataDispatch({
            type: "enlargeWidthColumn",
            payload: {
                uuid
            }
        })
    }

    const shrinkWidthColumn = (uuid: string) => {
        localDataDispatch({
            type: "shrinkWidthColumn",
            payload: {
                uuid
            }
        })
    }


    const changeStickyColumn = (uuid: string) => {
        localDataDispatch({
            type: "toggleStickyColumn",
            payload: {
                uuid
            }
        })
    }

    const changeVisibilityRow = (level: string) => {
        localDataDispatch({
            type: "toggleVisibilityRow",
            payload: {
                uuid: level
            }
        })
    }

    const changeVisibilityColumn = (uuid: string, newValue?: boolean) => {
        localDataDispatch({
            type: "toggleVisibilityColumn",
            payload: {
                uuid,
                newValue
            }
        })
    }

    const changeVisibilityTalentpath = (uuid: string, newValue?: boolean) => {
        localDataDispatch({
            type: "toggleVisibilityTalentpath",
            payload: {
                uuid,
                newValue
            }
        })
    }

    const changeFilterTag = (tag: string) => {
        localDataDispatch({
            type: "toggleTags",
            payload: {
                tag
            }
        })
    }

    const controllerHandlers = {
        changeStickyColumn,
        changeVisibilityColumn,
        changeVisibilityTalentpath,
        changeVisibilityRow,
        enlargeWidthColumn,
        shrinkWidthColumn,
        changeFilterTag
    }

    return {
        controllerHandlers,
        localData
    }
}
