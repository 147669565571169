import useOnClickOutside from 'helpers/hooks/useOnClick';
import getLanguageObject from 'helpers/language';
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';
import { IconContainer } from '../Button/Button.components';
import { H2 } from '../Typography/Typography';
import {
    AreYouSurePopupContainer, Button, ButtonContainer,
    ButtonDelete,
    Clickable, InnerPopup,
    PopupHeader
} from './AreYouSurePopup.components';
import AreYouSurePopupProps from './AreYouSurePopup.types';
import XmarkIcon from 'components/Icons/xmark';

export const AreYouSurePopup: React.FC<AreYouSurePopupProps> = ({
    action,
    item,
    title,
    currentLanguage,
    onClick,
    children,
    InnerContent
}) => {
    const [visible, setVisible] = useState(false);
    const lang = getLanguageObject(currentLanguage);
    const text = title || lang.confirmation(action.toLowerCase(), item.toLowerCase());
    const ref = useRef(null);
    useOnClickOutside(ref, () => close());
    const close = () => {
        setVisible(false);
    };
    const open = () => {
        setVisible(true);
    };

    const onClickAndClose = () => {
        onClick();
        close();
    }

    return (
        <>
            {visible ? (
                <>
                    <div onClick={open}>
                        {InnerContent && <InnerContent />}
                    </div>
                    <AreYouSurePopupContainer>
                        <InnerPopup ref={ref}>
                            <PopupHeader>
                                <H2>{text}</H2>
                            </PopupHeader>
                            {children}
                            <ButtonContainer>
                                <ButtonDelete onClick={close}>
                                    {lang.cancel}
                                    <IconContainer>
                                        <XmarkIcon />
                                    </IconContainer>
                                </ButtonDelete>
                                <Button onClick={onClickAndClose}>{action}</Button>
                            </ButtonContainer>
                        </InnerPopup>
                    </AreYouSurePopupContainer>
                </>
            ) : (
                <Clickable onClick={open}>
                    {InnerContent && <InnerContent />}
                </Clickable>
            )}
        </>
    );
};

const mapStateToProps = (state: ReduxStore) => ({
    currentLanguage: state.language.currentLanguage,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(AreYouSurePopup);
