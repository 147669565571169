import LoginFormSL from 'containers/LoginForm/LoginFormSL.connector';
import MainLayoutNoSidebar from 'layouts/MainLayoutNoSidebar/MainLayoutNoSidebar';
import React from 'react';
import LoginSection from 'sections/LoginSection/LoginSection.connector';
import LoginSharedLinkProps from './Login.types';

import { useParams } from 'react-router-dom';
import { LoginContainer } from './Login.components';

const Login: React.FC<LoginSharedLinkProps> = () => {
    const { id } = useParams<{ id: string }>();

    return (
        <MainLayoutNoSidebar>
            <LoginContainer>
                <LoginSection>
                    <LoginFormSL token={id} />
                </LoginSection>
            </LoginContainer>
        </MainLayoutNoSidebar>
    );
};

export default Login