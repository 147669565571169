import { connect } from 'react-redux';
import LoginSection from './LoginSection';

const mapStateToProps = () => ({});
const mapDispatchToProps = {};
const ConnectedLoginSection = connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginSection);

export default ConnectedLoginSection;
