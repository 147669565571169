import React from 'react';
import { AllocatorButtonProps } from './AllocatorButton.types';
import { Container } from './AllocatorButton.styling';

const AllocatorButton: React.FC<AllocatorButtonProps> = ({
    selectable,
    selected,
    onClick,
    children,
}) => {
    return (
        <Container
            selectable={selectable === undefined ? true : selectable}
            selected={selected}
            onClick={onClick}
        >
            {children}
        </Container>
    );
};

export default AllocatorButton;
