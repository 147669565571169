import styled from "styled-components";
import theme from "utils/theme";

export const Matrix = styled.div<{ isPrinting?: boolean }>`
    background: ${props => props.isPrinting ? 'rgb(255, 255, 255, .8)' : 'transparent'};
    height: fit-content;
    padding: ${props => !props.isPrinting ? "0 20px" : "20px"};
    cursor: grab;
    box-sizing: border-box;
    ${
        props => !props.isPrinting && `
            overflow: auto;
            margin-top: 20px;
        `
    }
`

export const PrintingContainer = styled.div<{ isPrinting?: boolean }>`
    ${props => props.isPrinting && `
        padding: 2em;
        background-color: ${theme.colors.lightBlue};
        box-shadow: 0 0 10px 10px rgba(0,0,0, .12);`}
`

export const PrintingInnerContainer = styled.div<{ isPrinting?: boolean}>`
    ${props => props.isPrinting && `
        padding: 2em;
        background-color: ${theme.jobMatrix.backgroundColor};
    `}

`

export const MatrixHeader = styled.div`
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 8px 8px 0 0;
    overflow: hidden;
`;
