import { levelToColor } from 'helpers/colors';
import React from 'react';
import styled from 'styled-components';

interface LevelLabelProps {
    level: string;
}

const LevelLabel: React.FC<LevelLabelProps> = ({
    level
}) => {
    return (
        <Container color={levelToColor(level.toLowerCase())}>
            <span>STYR  {level.toLocaleUpperCase()}</span>
        </Container>   
    )
}

export default LevelLabel;  

const Container = styled.div<{color?: string}>`
    padding: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.color};
    border-radius: 4px;
    font-weight: bold;
    width: 100%;
    box-sizing: border-box;
`