import styled from "styled-components";

export const AdminAllocatorValidatedProfilesSingleContainer = styled.div`
    margin-top: 50px;
`

export const ValidatedProfileEditContainer = styled.div`
    margin-top: 30px;
    padding: 30px;
    background: white;
    overflow: auto;
    max-height: calc(100vh - 280px);
    padding-left: 4em;
`

export const StyledSelect = styled.select`
    max-width: fit-content;
    border: 2px solid #3b4b63;
    border-radius: 4px;
    height: 2.5rem;
    padding-left: 0.5rem;
    outline: none;
    background-color: white;
    font-size: 14px;
    box-sizing: border-box;
    height: 46px !important;
    margin-bottom: 5px;
    width: 100%;
    -webkit-appearance: none;
    &active, &:focus {
        border: 2px solid #66caba;
        outline: none;
    }
`
