import styled from "styled-components";
import theme from "utils/theme";

export const Container = styled.div`
    position: relative;
    margin-left: -25px;
`;

export const ItemMenu = styled.div<{ visible?: boolean; }>`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    width: 44px;
`;

export const Menu = styled.div<{ visible?: boolean; itemsCount?: number; }>`
    padding: 10px;
    width: auto;
    height: auto;
    border-radius: 4px;

    display: ${props => props.visible ? 'grid' : 'none'};
    grid-template-columns: repeat(${props => props.itemsCount}, 16px);
    grid-gap: 1em;
    align-items: center;
    justify-content: center;
    
    position: absolute;
    top: 36px;
    left: 10px;
    z-index: 4;

    text-align: center;

    background-color: #fff;
    box-shadow: ${theme.shadows.green};

    &:before{
        content: '';
        display: ${props => props.visible ? 'block' : 'none'};
        position: absolute;
        top: -8px;
        left: 4px;
        border-radius: 8px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 8px 10px 8px;
        border-color: transparent transparent #fff transparent;
    }
`;