import React from "react";
import styled from "styled-components";
import { H1 } from "components/Typography/Typography";
import { useNavigate } from "helpers/useNavigation";
import Routes from "routes/Routes.types";
import ArrowRightIcon from "components/Icons/arrowRight";

const BreadCrumbs: React.FC<BreadcrumbProps> = ({ paths, title, }) => {
  const { navigate } = useNavigate();

  const handleNavigate = (canNavigate: boolean, path: Routes, id?: string) => {
    if (canNavigate) {
      return;
    }
    if (id) {
      navigate(path + id);
    } else {
      navigate(path);
    }
  };

  return (
    <Container>
      <H1>{title}</H1>
      {paths && <Content>
        {paths.map((x: BreadCrumbPath, index: number) => {
          const canNavigate = paths.length - 1 === index;
          return (
            <Item key={x.title} onClick={() => handleNavigate(canNavigate, x.path, x.id)} isFirstItems={!canNavigate}>
              <span>{x.title}</span>
              {!canNavigate && <ArrowRightIcon color="#000" />}
            </Item>
          );
        })}
      </Content>}
    </Container>
  );
};

export default BreadCrumbs;

const Container = styled.div`
    margin: 0 auto;
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;

    h1 {
        margin: 0;
    }
`

const Content = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
`

const Item = styled.div<{ isFirstItems?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    ${props => props.isFirstItems && `
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }`}
`

interface BreadcrumbProps {
  title: string;
  paths?: BreadCrumbPath[];
}

interface BreadCrumbPath {
  title: string;
  path: Routes;
  id?: string;
}
