import { createGlobalStyle } from 'styled-components';

import globalFonts from './fonts/fonts';

const GlobalStyle = createGlobalStyle`
  ${globalFonts}

  div, p {
    font-family: 'Avenir';
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }

  button {
    font-family: 'Avenir';
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }

  h1, h2, h3, h4, h5 {
    font-family: 'Avenir';
    font-weight: bold;
    line-height: 1.5;
  }

  h1 {
    font-size: 32px;
    line-height: 28px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 18px;
  }

  body {
      background-color: #eff4f9;
      margin: 0;
      padding: 0;
  }

  p.ql-align-center {
    text-align: center;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  p.ql-align-right {
    text-align: right;
    width: 100%;
  }

  p.ql-align-left {
    text-align: left;
    width: 100%;
  }

  .ql-editor{
    padding: 10px !important;
    cursor: text;
    white-space: pre-wrap
  }

  .rmsc > .dropdown-container {
    text-indent: 0 !important;
  }

  .rmsc > .dropdown-container .dropdown-heading{
    padding-left: 0;
  }
`;

export default GlobalStyle;
