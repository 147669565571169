import React, { useEffect, useState } from 'react';
import MainLayout from 'layouts/MainLayout/MainLayout';
import ResultsFilters from 'sections/ResultsFilters/ResultsFilters';
import ResultsTable, { SelectedItemsProps } from 'components/ResultsTable/ResultsTable';
import ResultsProps, { BaseFilterValues, FilterValues } from './Results.types';
import styled from 'styled-components';
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs';
import Routes from 'routes/Routes.types';
import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';
import getLanguageObject from 'helpers/language';
import useLocalStorageState from 'use-local-storage-state';
import { Result } from 'types/Result';
import { compareResults } from "../../helpers/CompareFunctions";
import { resultsGetResults } from 'store/results/results.actions';
import { useMsal } from '@azure/msal-react';

const ResultsPage: React.FC<ResultsProps> = ({ currentLanguage, results, resultsGetResults, currentOrganisation, role }) => {
    const lang = getLanguageObject(currentLanguage);
    const [filterValues, setFilterValues] = useState<FilterValues>(
        BaseFilterValues
    );
    const { accounts } = useMsal();
    const [selectedItems, setSelectedItems] = useState<SelectedItemsProps[]>([]);

    //TODO: Implement this on the backend and remove this filter
    if (role && role === ("Employee View" || "Guest"))
        results = results.filter(x => x.status === 'active' )

    const filteredResults = results.filter(result => {
        return (
            (result.status?.toLowerCase() === filterValues.status.toLowerCase() || filterValues.status === 'Show All') && // Filter on status filter
            (result.level.toLowerCase() === filterValues.level.toLowerCase() || filterValues.level === 'Show All') && // Filter on level filter
            (result.organisationUnitId === filterValues.organisationUnit || filterValues.organisationUnit === 'Show All') && // Filter on organisationUnit filter
            (result.validatedProfile.talentPath.key === filterValues.talentPath || filterValues.talentPath === 'Show All') && // Filter on talent path filter
            (result.unitId === filterValues.unit || filterValues.unit === 'Show All') && // Filter on unit filter
            (result.name?.toLowerCase().includes(filterValues.name?.toLowerCase() || "") || filterValues.name?.replace(/\s/g, '') === '') // Filter on name filter

        );
    });

    useEffect(() => {
        resultsGetResults()
    }, [
        resultsGetResults,
        currentOrganisation?.id,
    ]);


    const onSelectItem = (id: string, status: string) => {
        const exists = selectedItems?.find((x) => x.id === id)?.id;
        if (exists) return setSelectedItems(selectedItems.filter(x => x.id !== id));
        return setSelectedItems([...selectedItems, { id, status }]);
    }

    const onSelectAll = () => {
        const getAllIds = filteredResults.map((x) => {
            return {
                id: x.id,
                status: x.status
            }
        })
        if (selectedItems.length === 0) return setSelectedItems(getAllIds)

        return setSelectedItems([])
    }


    const [state, setState] = useLocalStorageState<{
        sorting: keyof Result;
        sortingOrder: boolean;
    }>("resultsSortOrder" + currentOrganisation?.id, { sorting: 'createdOn', sortingOrder: false });

    const changeSorting = (item: keyof Result) => {
        if (state.sorting === item) {
            setState({
                ...state,
                sortingOrder: !state.sortingOrder,
            });
        } else {
            setState({
                ...state,
                sorting: item,
                sortingOrder: false,
            });
        }
    };

    const sortedResults = filteredResults.sort((a, b) => {
        return compareResults(a, b, state.sorting);
    });

    if (state.sortingOrder) {
        sortedResults.reverse();
    }

    return (
        <MainLayout>
            <Container>
                <Header>
                    <BreadCrumbs title={lang.searchJobsnRoles} paths={
                        [
                            { title: lang.dashboard, path: Routes.DashboardRoute },
                            { title: lang.searchJobsnRoles, path: Routes.ResultsRoute }]
                    } />
                </Header>
                <ResultsTable results={sortedResults} state={state} onSelectAll={onSelectAll} onSelectItem={onSelectItem} changeSorting={changeSorting} selectedItems={selectedItems} />
                <ResultsFilters
                    results={sortedResults}
                    setFilterValues={setFilterValues}
                    filterValues={filterValues}
                    setSelectedItems={setSelectedItems}
                    selectedItems={selectedItems}
                />
            </Container>
        </MainLayout>
    );
};

const mapStateToProps = (state: ReduxStore) => ({ currentLanguage: state.language.currentLanguage, results: state.results.results, currentOrganisation: state.user.currentOrganisation, role: state.user.role });
const mapDispatchToProps = { resultsGetResults };
const Results = connect(mapStateToProps, mapDispatchToProps)(ResultsPage);

export default Results;


export const Container = styled.div`
    display: grid;
    grid-template-rows: 120px 1fr 80px;
    height: 100%;

    &:-webkit-scrollbar {
        display: none;
    }
`

export const Header = styled.div`
    margin: 0 auto 20px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    box-sizing: border-box;
`
