import MatrixBoxWithJobs from 'containers/MatrixBoxWithJobs/MatrixBoxWithJobs';
import React, { useEffect, useState } from 'react';
import { LocalJobMatrixData } from 'sections/JobMatrix/JobMatrixResults/JobMatrixResults.types';
import { getStringFromCurrentLanguage } from 'store/language/language.actions';
import { Language } from 'types/MultiLingualString';
import { Result } from 'types/Result';
import theme from 'utils/theme';
import { OrganisationUnitHeaderUnitContainer, OrganisationUnitHeaderMatrixBox, HeaderEditable, InnerMatrixHeaderBox } from './MatrixContent.components';
import { ConnectedHeaderEditableUnit } from './MatrixContent.connector';
import { ControllerHandlers } from './MatrixContent.types';
import { getAvailableLevels } from './matrixUtil';
import { OrganisationUnit, Unit } from 'types/Organisation';

interface OrganisationUnitDataColumnProps {
    currentLanguage: Language;
    organisationUnit: OrganisationUnit;
    results: Result[];
    localData: LocalJobMatrixData;
    controllerHandlers: ControllerHandlers;
    editMode?: boolean
    levels: string[];
}

interface UnitDataColumn {
    unit: Unit;
    results: Result[];
    localData: LocalJobMatrixData;
    levels: string[];
}


const OrganisationUnitHeaderUnits: React.FC<OrganisationUnitDataColumnProps> = ({ organisationUnit, localData, controllerHandlers, editMode, currentLanguage }) => (<OrganisationUnitHeaderUnitContainer units={organisationUnit.units} localData={localData}>

    {
        organisationUnit.units.map(unit => {
            const localItem = localData.columns.find(localDataitem => localDataitem.uuid === unit.id)
            if (localItem && !localItem.shown) {
                return null;
            }
            return (
                <ConnectedHeaderEditableUnit key={unit.id} organisationUnit={organisationUnit} controllerHandlers={controllerHandlers} unit={unit} editMode={editMode} language={currentLanguage} />
            )
        })
    }
</OrganisationUnitHeaderUnitContainer>
)

interface HeaderEditableUnitProps {
    cLanguage: Language;
    language?: Language;
    organisationUnit: OrganisationUnit;
    controllerHandlers: ControllerHandlers;
    editMode?: boolean
    unit: Unit
    updateUnit: (name: string, unit: string, organisationUnit?: string) => void;
}

export const HeaderEditableUnit: React.FC<HeaderEditableUnitProps> = ({ cLanguage, language, controllerHandlers, editMode, updateUnit, unit, organisationUnit }) => {
    let currentLanguage = language || cLanguage;

    const [newName, setNewName] = useState<string>(getStringFromCurrentLanguage(unit.name, currentLanguage));
    const onChange = (value: string, key?: string,) => {
        setNewName(value.replace(/(<([^>]+)>)/ig, '').replace(/&amp;/g, '&'))
    }

    const onSubmit = () => {
        return updateUnit(newName, unit.id, organisationUnit?.id);
    }

    useEffect(() => {
        if(!newName) {
            return
        }
        if(newName === getStringFromCurrentLanguage(unit.name, currentLanguage)) {
            return
        }
        const debounce = setTimeout(() => onSubmit(), 500);
        return () => clearTimeout(debounce);
    }, [newName]);

    return (<HeaderEditable
        key={unit.id}
        title={newName || getStringFromCurrentLanguage(unit.name, currentLanguage)}
        uuid={unit.id}
        controllerHandlers={controllerHandlers}
        color={theme.colors.darkGrey}
        editMode={editMode}
        onChange={onChange}
    />)
}

const RenderUnits: React.FC<OrganisationUnitDataColumnProps> = ({ organisationUnit, results, localData, levels }) => (<>{organisationUnit.units.map(unit => {
    const data = results.filter(x => x.unitId === unit.id);
    const localItem = localData.columns.find(localDataitem => localDataitem.uuid === unit.id)
    if (localItem && !localItem.shown) {
        return null;
    }

    return <UnitColumn levels={levels} key={unit.id} localData={localData} unit={unit} results={data} />
})}</>)

export const OrganisationUnitDataColumn: React.FC<OrganisationUnitDataColumnProps> = ({ localData, currentLanguage, organisationUnit, results, controllerHandlers, editMode, levels }) => {
    const colSpans = organisationUnit.units.filter(d => {
        const localItem = localData.columns.find(localDataitem => localDataitem.uuid === d.id)
        if (localItem && !localItem.shown) {
            return false;
        }
        return true;
    }).length;

    return (
        <>
            <OrganisationUnitHeaderMatrixBox colSpan={colSpans}>
                <InnerMatrixHeaderBox color={theme.colors.darkGrey} textCenter>{getStringFromCurrentLanguage(organisationUnit.name, currentLanguage)}</InnerMatrixHeaderBox>
                <OrganisationUnitHeaderUnits levels={levels} organisationUnit={organisationUnit} localData={localData} results={results} controllerHandlers={controllerHandlers} editMode={editMode} currentLanguage={currentLanguage} />
            </OrganisationUnitHeaderMatrixBox>
            <RenderUnits levels={levels} organisationUnit={organisationUnit} localData={localData} results={results} currentLanguage={currentLanguage} controllerHandlers={controllerHandlers} />
        </>
    )
}

const UnitColumn: React.FC<UnitDataColumn> = ({ localData, results, levels }) => {
    const availableLevels = getAvailableLevels(levels, localData);

    return (
        <>
            {availableLevels.map(level => {
                const jobs = results.filter(x => x.level.toLowerCase() === level.toLowerCase());
                return (
                    <MatrixBoxWithJobs key={level + jobs} level={level} jobs={jobs} />
                )
            })}
        </>
    )
}
