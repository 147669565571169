import React, { useEffect, useState } from 'react';
import MainLayout from 'layouts/MainLayout/MainLayout';
import { H2, H3 } from 'components/Typography/Typography';
import ManageOrganisationValuationProps from './ManageOrganisationValuation.types';
import { ActivateHeader, Main } from './ManageOrganisationValuation.components';
import getLanguageObject from 'helpers/language';
import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';
import { getLevels } from 'utils/levels';
import InputField from 'containers/InputField/InputField';
import Button from 'components/Button/Button';
import { updateOrganisationValuation } from 'store/user/user.actions';
import ListItemWithToggle from 'containers/ListItemWithToggle/ListItemWithToggle';
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs';
import styled from 'styled-components';
import Routes from 'routes/Routes.types';
import { Container, Header } from 'layouts/MainLayout/MainLayout.components';

const OptionsGrid = styled.div`
    display: grid;
    grid-template-rows: repeat(4, 1fr);
    grid-auto-flow: column;
    grid-gap: 0 1em;
    margin-top: 40px;
    margin-bottom: 20px;
    width: 100%;
    max-width: 700px;

    @media screen and (max-width: 1350px) {
        grid-template-rows: repeat(6, 1fr);
    }

    @media screen and (max-width: 950px) {
        grid-template-rows: repeat(9, 1fr);
    }
`

const OptionsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
`;

const OptionBox = styled.div`
    display: grid;
    grid-template-columns: 10px 100px;
    grid-gap: 50px;
`

const OrganisationValuation: React.FC<ManageOrganisationValuationProps> = ({ currentOrganisation, currentLanguage, currentOrganisationId, currentValuation, currentValEnabled, updateOrganisationValuation }) => {
    const lang = getLanguageObject(currentLanguage);
    const [levelmap, setLevelmap] = useState(currentValuation);
    const [enableValuation, setEnableValuation] = useState(currentValEnabled);

    const updateLevelmap = (key: string, value: string) => {
        setLevelmap({
            ...levelmap,
            [key]: value
        })
    }

    const submit = () => {
        updateOrganisationValuation(currentOrganisationId, levelmap, enableValuation)
    };

    useEffect(() => {
        if(!currentOrganisation?.alternativeTitle) return;
        setLevelmap({
            ...levelmap,
            title: currentOrganisation?.alternativeTitle
        })
    }, [currentOrganisation?.alternativeTitle])

    return (
        <MainLayout>
            <Container>
                <Header>
                    <BreadCrumbs title={lang.manageValuation} paths={
                        [{ title: lang.dashboard, path: Routes.DashboardRoute },
                        { title: lang.manageValuation, path: Routes.ValuationRoute }]
                    } />
                </Header>
                <Main style={{borderRadius: 0, padding: 40, width: "100%"}}>
                    <ActivateHeader>
                        <div style={{
                            whiteSpace: "pre-wrap"
                        }}>
                            {lang.manageValuationDescription}
                        </div>
                        <div>
                            <ListItemWithToggle state={enableValuation} label={''} onChange={setEnableValuation}/>
                        </div>
                        <div>
                            <H3>{lang.title}</H3>
                            <InputField
                                type="text"
                                value={levelmap.title || ""}
                                onChange={event => updateLevelmap("title", event.target.value)}
                                errorMessage={null}
                            />
                        </div>
                    </ActivateHeader>
                    <OptionsContainer>
                        <OptionsGrid>
                            {getLevels([]).map((level) => (
                                <OptionBox>
                                    <H3>{level.toUpperCase()}</H3>
                                    <InputField
                                        type="text"
                                        value={levelmap[level]}
                                        onChange={event => updateLevelmap(level, event.target.value)}
                                        errorMessage={null}
                                    />
                                </OptionBox>
                            ))}
                        </OptionsGrid>
                        <Button text={lang.save} onClick={submit} priority="tertiary" />
                    </OptionsContainer>
                </Main>
            </Container>
        </MainLayout>
    );
};

const mapStateToProps = (state: ReduxStore) => ({
    currentLanguage: state.language.currentLanguage,
    currentOrganisation: state.user.currentOrganisation,
    currentOrganisationId: state.user.currentOrganisation!.id,
    currentValuation: state.user.currentOrganisation!.alternativeLevels || Object.fromEntries(getLevels([]).map(item => [item, ""])),
    currentValEnabled: state.user.currentOrganisation!.enabledStyrLevelSubs
});

const mapDispatchToProps = {
    updateOrganisationValuation: updateOrganisationValuation
};

const ManageOrganisationValuation = connect(mapStateToProps, mapDispatchToProps)(OrganisationValuation);

export default ManageOrganisationValuation;
