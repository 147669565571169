import styled from 'styled-components';
import theme from 'utils/theme';
const borderSize = '10px';

export const Table = styled.table`
    user-select: none;
    margin-top: 1em;
    margin-left: -10px;
    margin-right: -10px;
    border: 0 solid transparent;
    border-radius: 1em;
    border-collapse: collapse;
`;

export const TH = styled.th<{
    center?: boolean;
    color?: string;
}>`
    padding: 5px;
    border: ${borderSize} solid #eff4f9;

    height: 58px;
    text-align: ${props => props.center ? 'center' : 'left'};
    background-color: ${props => props.color ? props.color : 'transparent'};
    color: #fff;

    position: relative;

    &:not(:first-child) {
    &:before{
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;

        width: 42px;
        height: 42px;

        z-index: 0;

        background-color: #fff;
    }
}
`;

export const Talentpath = styled.div`
    padding: 10px;
    background-color: #3b4b63;
    color: #fff;

    display: grid;
    grid-template-columns: 50px 1fr;
    grid-gap: 12px;
    align-items: center;
`;

export const TD = styled.td<{
    width?: number;
    center?: boolean;
    color?: string;
    show?: boolean;
    bold?: boolean;
    currentLevel?: boolean;
    levelColor?: string;
}>`
    padding: 1em;
    border: ${borderSize} solid #eff4f9;
    text-align: ${props => props.center ? 'center' : 'left'};
    ${props => props.width ? `min-width: ${props.width}px;` : null}
    position: relative;

    ${props => props.currentLevel ? `
    background-color: ${theme.colorCodePicker(props.levelColor, 0.3)};
    ` : `background-color: ${props.color ? props.color : '#fff'};
    `}
    font-weight: ${props => props.bold ? 'bold' : 'normal'};
    vertical-align: top;
    ul{
        padding: 0 0 0 20px;
    }
`;

export const VerticleText = styled.div`
    text-align: center;
    white-space: nowrap;
    transform: rotate(-90deg);
`;

export const VerticleTextCenter = styled.div`
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    top: 50%;
`

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const LevelEmblem = styled.div<{ color: string; }>`
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;

    transform: translate(-50%, -50%);
    text-align: center;

    width: 40px;
    height: 40px;
    line-height: 42px;
    font-size: 18px;
    color: ${props => props.color};
    font-weight: bold;
`;

export const Icon = styled.div`
    width: 42px;
    height: 42px;
    background-color: #fff;
    border-radius: 50px;
    position: relative;
`

export const ImageIcon = styled.img`
    margin-left: -4px;
    margin-top: -2px;
    width: 50px;
    height: 50px;
`;

export const StyrCompetenceContainer = styled.div`
    vertical-align: top;
`;
export const StyrCompetenceBlock = styled.div`
    padding: 10px;
`;
