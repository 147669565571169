import useOnClickOutside from "helpers/hooks/useOnClick";
import React, { useRef, useState } from 'react';
import { Container, ItemMenu, Menu } from "./ItemActions.styling";
import EllipsisMenuIcon from "components/Icons/ellipsisMenu";

const ItemActions: React.FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const itemsCount = React.Children.count(children);

    const [optionsMenu, setOptionsMenu] = useState(false);

    const ref = useRef(null);
    useOnClickOutside(ref, () => close());
    const close = () => {
        setOptionsMenu(false);
    };

    return <Container ref={ref}>
        <div onClick={() => setOptionsMenu(!optionsMenu)}>
            <ItemMenu visible={optionsMenu}>
                <EllipsisMenuIcon />
            </ItemMenu>
        </div>
        <Menu visible={optionsMenu} itemsCount={itemsCount}>
            {children}
        </Menu>
    </Container>
}

export default ItemActions;