import { LocalJobMatrixData, RemoteJobMatrixData } from "sections/JobMatrix/JobMatrixResults/JobMatrixResults.types";
import LanguageState from "store/language/language.types";
import { Permissions, LevelsOptionsArray } from "store/user/user.types";
import { Language } from "types/MultiLingualString";
import { MatrixLevelNotesObject, Organisation } from "types/Organisation";

export enum MatrixViews {
    jobmatrix = 'job matrix',
    talentmatrix = 'talent matrix'
}

export interface ControllerHandlers {
    changeVisibilityColumn: (uuid: string, newValue?: boolean) => void;
    changeVisibilityTalentpath: (uuid: string, newValue?: boolean) => void;
    changeVisibilityRow: (level: string) => void;
    changeStickyColumn: (uuid: string) => void;
    enlargeWidthColumn: (uuid: string) => void;
    shrinkWidthColumn: (uuid: string) => void;
}

export interface RenderOrangeColumnsProps {
    currentLanguage: Language;
    matrixView: MatrixViews;
    isSidemenuOpen?: boolean;
    fullScreen?: boolean;
    printing?: boolean;
    levels: string[];
    localData: LocalJobMatrixData;
    controllerHandlers: ControllerHandlers;
    remoteData: RemoteJobMatrixData;
    editMode?: boolean
}

export default interface MatrixHeaderProps extends ColumnSetPropsNoLang {
    cLanguage: Language;
    permissions: Permissions;
    language?: Language;
    currentOrganisation?: Organisation;
    controllerHandlers: ControllerHandlers;
    matrixView: MatrixViews;
    isSidemenuOpen?: boolean;
    fullScreen?: boolean;
    levels: string[];
}

export interface ColumnProps {
    localData: LocalJobMatrixData;
    controllerHandlers: ControllerHandlers;
    uuid: string;
    levels: string[];
}

export interface StyrLevelColumnProps extends ColumnProps {
    currentLanguage: Language;
}

export interface StyrDataColumnProps extends ColumnProps {
    controllerHandlers: ControllerHandlers;
    data: LevelsOptionsArray;
    index: "problem_solving_focus" | "education" | "problem_solving_capability" | "planning_horizon" | "salary";
    title: string;
    currentLanguage: Language;
    textCenter?: boolean;
}

export interface ColumnSetProps extends ColumnSetPropsNoLang {
    permissions?: Permissions;
    currentLanguage: Language;
    levels: string[];
    currentOrganisation?: Organisation
}

export interface ColumnSetPropsNoLang {
    localData: LocalJobMatrixData;
    controllerHandlers: ControllerHandlers;
    remoteData: RemoteJobMatrixData;
    editMode?: boolean;
    printing?: boolean;
}

export interface ResultColumnProps extends ColumnProps {
    remoteData: RemoteJobMatrixData;
    currentLanguage: Language;
}

export interface ExtraColumnProps extends ColumnProps {
    languageState?: LanguageState;
    data?: MatrixLevelNotesObject;
    editMode?: boolean
    suffix?: string;
    currentOrganisation?: Organisation
    currentLanguage: Language;
    updateLevelNotes: (notes: MatrixLevelNotesObject, suffix?: string) => void;
    levels: string[];
}

export interface RowHandlerProps {
    localData: LocalJobMatrixData;
    controllerHandlers: ControllerHandlers;
    height?: number;
    levels: string[];
    printing?: boolean;
}

export interface MatrixColumnHeaderProps extends MatrixColumnHeaderControllerProps {
    title: string;
    stickyState?: boolean;
    cssSticky?: string;
    color?: string;
    innerHTML?: boolean;
}

export interface MatrixColumnHeaderWithEditorProps extends MatrixColumnHeaderProps {
    title: string;
    stickyState?: boolean;
    cssSticky?: string;
    color?: string;
    innerHTML?: boolean;
    editMode?: boolean
    onChange: (e: string, level?: string) => void;
    onSubmit?: () => void;
    canChangeWidth?: boolean;
}



export interface MatrixColumnHeaderControllerProps {
    canChangeWidth?: boolean;
    canStick?: boolean;
    isTalentPath?: boolean;
    uuid: string;
    controllerHandlers: ControllerHandlers;
    stickyState?: boolean;
}
