import styled from 'styled-components';
import theme from 'utils/theme';

export const AreYouSurePopupContainer = styled.div`
    position: fixed;
    width: 100%;
    max-width: 100% !important;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 1 !important;
`;

export const InnerPopup = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    height: fit-content;
    width: 500px;
    overflow: hidden;
    padding: 1rem;
    border-radius: 8px;
    color: ${theme.colors.dark};
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    background-color: white;
    opacity: 1 !important;
`;

export const PopupHeader = styled.div`
    display: flex;
    justify-content: left;
    margin-bottom: 20px;
`;

export const ButtonContainer = styled.div`
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
`;

export const Button = styled.div`
    background-color: ${theme.colors.green};
    color: ${theme.colors.light};
    box-shadow: ${theme.boxShadow};
    padding: 0.5em 1.5em;
    border-radius: 8px;
    font-size: 1em;
    font-weight: bold;
    line-height: 34px;
    letter-spacing: 0.2px;
    transition: opacity 1s ease-out;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
    min-width: 160px;
    outline: 0;
    margin: 0;
    border: 0;
    height: 32px;
`;

export const ButtonDelete = styled(Button)`
    background-color: ${theme.colors.red};
    color: ${theme.colors.light};
`;

export const Clickable = styled.div`
    cursor: pointer;
`;