import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';
import { updatesGetUpdates } from 'store/updates/updates.actions';
import LatestUpdatesTable from './LatestUpdatesTable';

const mapStateToProps = (state: ReduxStore) => ({
    currentLanguage: state.language.currentLanguage,
    updates: state.updates.updates,
    // TODO: canEdit based on permissions
    canEdit: state.user.permissions.canDuplicateOrganisation
});
const mapDispatchToProps = { updatesGetUpdates };
const ConnectedLatestUpdatesTable = connect(
    mapStateToProps,
    mapDispatchToProps
)(LatestUpdatesTable);

export default ConnectedLatestUpdatesTable;
