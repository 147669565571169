import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';
import Header from './Header';

const mapStateToProps = (state: ReduxStore) => ({
    organisations: state.user.organisations,
});

const mapDispatchToProps = {};
const ConnectedHeader = connect(mapStateToProps, mapDispatchToProps)(Header);

export default ConnectedHeader;
