import React from "react";
import { connect } from "react-redux";
import { navbarToggle } from "store/navbar/navbar.actions";
import ReduxStore from "store/store.type";
import styled from "styled-components";
import ArrowLeftIcon from "components/Icons/arrowLeft";
import ArrowRightIcon from "components/Icons/arrowRight";

interface CollapseNavbarButtonProps {
    sidebarOpen?: boolean
    navbarToggle: () => void;
}

const CollapseNavbarButton: React.FC<CollapseNavbarButtonProps> = ({ sidebarOpen, navbarToggle }) => {
    return (
        <CollapseNavbarButtonContainer onClick={() => navbarToggle()}>
            {sidebarOpen ? (
                <ArrowLeftIcon />
            ) : (
                <ArrowRightIcon color="#fff" />
            )}
        </CollapseNavbarButtonContainer>
    )
}
const mapCollapseNavbarButtonStateToProps = (state: ReduxStore) => ({
    sidebarOpen: state.navbar.sidebarOpen,
});

const mapCollapseNavbarButtonDispatchToProps = { navbarToggle };
export const ConnectedCollapseNavbarButton = connect(
    mapCollapseNavbarButtonStateToProps,
    mapCollapseNavbarButtonDispatchToProps
)(CollapseNavbarButton);


export const CollapseNavbarButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px
`