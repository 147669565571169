import { CountryFlag } from 'components/CountryFlag/CountryFlag';
import React from 'react';
import { connect } from 'react-redux';
import { setCurrentLanguage } from 'store/language/language.actions';
import { languages } from 'helpers/language';
import {
    Container,
    ImageContainer
} from './LanguageDropdown.components';
import LanguageDropdownProps from './LanguageDropdown.types';

const LanguageDropdown: React.FC<LanguageDropdownProps> = ({
    setCurrentLanguage,
}) => (
    <Container>
        {languages.map(lang => (
            <ImageContainer key={lang}
                onClick={() => {
                    setCurrentLanguage(lang);
                }}>
                <CountryFlag language={lang} />
            </ImageContainer>
        ))}
    </Container>
);

const mapStateToProps = () => ({});
const mapDispatchToProps = { setCurrentLanguage };

export default connect(mapStateToProps, mapDispatchToProps)(LanguageDropdown);
