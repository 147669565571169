import { MatrixViews } from "containers/MatrixContent/MatrixContent.types";
import { jobMatrixDefaultColumnWidth } from "sections/JobMatrix/JobMatrixResults/initialJobMatrixData";
import { LocalJobMatrixData, RemoteJobMatrixData } from "sections/JobMatrix/JobMatrixResults/JobMatrixResults.types";
import { DifferentiatingFactors } from 'types/Organisation';
import { talentPaths } from "utils/talentPaths";

export const getGridRowLayoutFromData = (levels: string[], localData: LocalJobMatrixData) => {
    const result = levels.map(level => {
        const locallyFoundItem = localData.rows.find(x => x.uuid === level);
        return {
            shown: locallyFoundItem ? locallyFoundItem.shown : true,
            height: "max-content"
        }
    }).filter(x => x.shown).map(x => x.height).join(" ");

    return "140px " + result;
}

export const getGridColumnLayoutFromData = (initialJobMatrixData: LocalJobMatrixData, currentMatrixView: MatrixViews, localData: LocalJobMatrixData, remoteData: RemoteJobMatrixData, differentiatingFactors: DifferentiatingFactors) => {
    const styrColumns = initialJobMatrixData.columns.map(column => {
        const locallyFound = localData.columns.find(localDataItem => localDataItem.uuid === column.uuid);
        if (column.uuid === "styrGroup") {
            return {
                width: 100,
                shown: locallyFound ? locallyFound.shown : true
            }
        }

        if (column.uuid === "educationLevel") {
            return {
                width: initialJobMatrixData.columns.find(columnName => columnName.uuid === column.uuid)?.width,
                shown: locallyFound?.shown && differentiatingFactors.educationLevel
            }
        }

        if (column.uuid === "planningHorizon") {
            return {
                width: initialJobMatrixData.columns.find(columnName => columnName.uuid === column.uuid)?.width,
                shown: locallyFound?.shown && differentiatingFactors.planningHorizon
            }
        }

        return locallyFound || {
            width: initialJobMatrixData.columns.find(columnName => columnName.uuid === column.uuid)?.width,
            shown: true
        }
    }).filter(x => x.shown).map(x => "max(100px, " + (x.width ? x.width : jobMatrixDefaultColumnWidth) + "px)").join(" "); // [100,200,300] -> "100px, 200px, 300px"

    const remoteUnitUUIDs = remoteData.organisationUnits.flatMap(x => x.units).map(x => x.id);
    const styrJobMatrixColumns = remoteUnitUUIDs.map(id => {
        const locallyFoundItem = localData.columns.find(localDataItem => localDataItem.uuid === id);
        const result = locallyFoundItem || {
            width: jobMatrixDefaultColumnWidth,
            shown: true
        };
        return result
    })

    const styrTalentpathColumns = talentPaths.map(talentpath => {
        const locallyFoundItem = localData.talentpath.find(localDataItem => localDataItem.uuid === talentpath);
        const result = locallyFoundItem || {
            width: jobMatrixDefaultColumnWidth,
            shown: true
        };
        return result
    })

    const selectedMatrixView = currentMatrixView === MatrixViews.jobmatrix ? styrJobMatrixColumns : styrTalentpathColumns;
    const lengthSelectedOrangeColumns = selectedMatrixView.filter(x => x.shown === true).map(x => x).length;
    const orangeColumnsSelected = lengthSelectedOrangeColumns !== 0 ? "px" : "";
    const unitWidthsString = selectedMatrixView.filter(x => x.shown).map(x => x.width || jobMatrixDefaultColumnWidth).join("px ") + orangeColumnsSelected; // [100, 200,300] -> "100px, 200px, 300"

    return "50px " + styrColumns + " " + unitWidthsString; // 100px, 200px, 300 -> 100px, 200px, 300px
}

export const getFullWidthFromData = (initialData: LocalJobMatrixData, currentMatrixView: MatrixViews, localData: LocalJobMatrixData, remoteData: RemoteJobMatrixData, differentiatingFactors: DifferentiatingFactors) => {
    const columns = getGridColumnLayoutFromData(initialData, currentMatrixView, localData, remoteData, differentiatingFactors)
    const array = columns.split("px ");
    const sum = array.reduce((previous, currentItem) => {
        const intVal = parseInt(currentItem)
        if (isNaN(intVal)) {
            return previous
        }
        return previous + intVal
    }, 0)
    return sum;
}

export const getUnitColumnLayoutFromData = (localData: LocalJobMatrixData, remoteData: any[]) => {
    const jobColumns = remoteData.map(unit => {
        const locallyFound = localData.columns.find(localDataItem => localDataItem.uuid === unit.id)
        return locallyFound || {
            width: jobMatrixDefaultColumnWidth,
            shown: true
        }
    })

    const unitsWithWidth = jobColumns.filter(units => units.shown).map(unit => unit.width ? unit.width : jobMatrixDefaultColumnWidth).join("px ") + "px";
    return unitsWithWidth;
}
