import AreYouSurePopup from 'components/AreYouSurePopup/AreYouSurePopup';
import Button from 'components/Button/Button';
import { IconButton } from 'components/Button/IconButton/IconButton';
import { FullArrowDownIcon } from 'components/Icons/arrowDown';
import { FullArrowUpIcon } from 'components/Icons/arrowUp';
import EditIcon from 'components/Icons/edit';
import MinusIcon from 'components/Icons/minus';
import PlusIcon from 'components/Icons/plus';
import TrashIcon from 'components/Icons/trash';
import NumberTag from "components/NumberTag/NumberTag";
import { Overlay } from 'components/Popup/Popup';
import { H2, TextField, TextWarning } from 'components/Typography/Typography';
import { SelectField } from 'containers/InputField/InputField';
import { InputFieldErrorMessages, Label } from 'containers/InputField/InputField.components';
import useOnClickOutside from 'helpers/hooks/useOnClick';
import getLanguageObject from 'helpers/language';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom';
import { LoadValidatedProfiles, loadValidatedProfiles } from 'store/allocator/allocator.actions';
import { deleteJobfamily, DeleteJobfamily, updateJobfamily, UpdateJobfamily } from 'store/families/jobfamily.actions';
import { getStringFromCurrentLanguage } from 'store/language/language.actions';
import { resultsGetResults, ResultsPostResultManual, resultsPostResultManual } from "store/results/results.actions";
import ReduxStore from "store/store.type";
import { UpdateFamType, updateOrganisationRequest } from 'store/user/user.actions';
import { Organisation } from 'types/Organisation';
import styled from "styled-components";
import { Language } from 'types/MultiLingualString';
import { Family, ValidatedProfile, Result } from "types/Result";
import { getLevelIndex } from 'utils/levels';
import { talentPathNames, transformTalentPathKey } from 'utils/talentPaths';
import theme from "utils/theme";

export const Card = styled.div<{ width?: number; padding?: number }>`
    padding: ${props => props.padding ? `${props.padding}px` : "unset"};
    background-color: white;
    border-radius: 8px 8px 0 0;
    box-shadow: ${theme.boxShadow};
    overflow: hidden;
    width: ${props => props.width ? `${props.width}px` : "unset"};
    margin-bottom: 12px;
    max-height: calc(90vh - 180px);
`;

export const Container = styled.div`
    display: grid;
    grid-template-rows: 120px 1fr 80px;
    height: 100%;

    &:-webkit-scrollbar {
        display: none;
    }
`

export const FilterForm = styled.div`
    display:  flex;
    gap: 10px;
    align-items: center;
`;

export const Row = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1em;
`

export const Columns = styled.div`
    padding: 0 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
`

export const ListHeader = styled.div`
    padding: 1em;
    font-weight: bold;
`

export const JobAsListItemContainer = styled.div<{ nrColumns?: number }>`
    padding-bottom: 12px;
    display: grid;
    grid-template-columns: ${props => props.nrColumns === 4 ? "24px 72px 1fr 48px" : "72px 1fr 48px"};
    grid-gap: 10px;
    align-items: center;
    background-color: #fff;
    border-bottom: 2px solid ${theme.colors.green};
    position: relative;
`

export const ActionButton = styled.div`
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8px;
    cursor: pointer;
`
export const ArrowBox = styled.div`
    max-height: 48px;
    max-width: 12px;
    position: relative;
`
export const Arrow = styled.div`
    max-height: 20px;
    max-width: 12px;
`

export const Icon = styled.div`
    width: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: move;
`;

type ActionType = "add" | "remove"

export const JobAsListItem: React.FC<{ id: string; results: Result[]; arrows: UpdateFamType[]; action: (action: UpdateFamType) => void; actionType: ActionType; currentLanguage: Language; currentOrganisation?: Organisation }> = ({ id, arrows, currentLanguage, results, action, currentOrganisation }) => {
    const findJobByID = results.find((job: Result) => job.id === id)
    if (!findJobByID) return null;

    const hasArrowCol = arrows.includes("up") || arrows.includes("down")

    const alternativeLevel = currentOrganisation?.enabledStyrLevelSubs && currentOrganisation.alternativeLevels[findJobByID.level]

    return (
        <JobAsListItemContainer nrColumns={hasArrowCol ? 4 : 3}>
            {hasArrowCol && <ArrowBox>
                <IconButton color={"success"} IconSVG={FullArrowUpIcon} onClick={() => action("up")} disabled={!arrows.includes("up")} />
                <IconButton color={"success"} IconSVG={FullArrowDownIcon} onClick={() => action("down")} disabled={!arrows.includes("down")} />
            </ArrowBox>}
            <NumberTag level={findJobByID.level} alternativeLevel={alternativeLevel || undefined} />
            <div>
                <strong>{findJobByID.name}</strong> <br />
                <span>{getStringFromCurrentLanguage(findJobByID.validatedProfile.talentPath.names, currentLanguage)} | {getStringFromCurrentLanguage(findJobByID.organisationUnit, currentLanguage)} | {getStringFromCurrentLanguage(findJobByID.unit, currentLanguage)}</span>
            </div>
            {arrows.includes("add") && <IconButton IconSVG={PlusIcon} color={"success"} onClick={() => action("add")} />}
            {arrows.includes("remove") && <IconButton IconSVG={MinusIcon} color={"danger"} onClick={() => action("remove")} />}
        </JobAsListItemContainer>)
}

const mapStateToProps = (state: ReduxStore) => (
    {
        currentLanguage: state.language.currentLanguage,
        currentOrganisation: state.user.currentOrganisation,
        results: state.results.results,
    }
)

const mapDispatchToProps = { resultsGetResults };

const ConnectedJobAsListItem = connect(
    mapStateToProps,
    mapDispatchToProps
)(JobAsListItem);

export default ConnectedJobAsListItem


export const ListOfJobs = styled.div<{ items?: number }>`
    padding: 1em;
    display: grid;
    grid-template-rows: repeat(${props => props.items ? props.items : 0}, 62px);
    grid-gap: 10px;
`

const ListWithJobs = styled.div`
    max-height: calc(90vh - 300px);
    overflow-y: auto;
    box-sizing: border-box;
`

export const ResultsList: React.FC<{ results?: Result[]; action: (id: string, action: UpdateFamType) => void; actionType: ActionType; currentLanguage: Language; canEdit: boolean }> = ({ results, action, actionType, canEdit }) => {

    if (!results) return null;

    const resultWithArrows: [Result, UpdateFamType[]][] = results.sort((a, b) => getLevelIndex(b.level) - getLevelIndex(a.level)).map((cur, index) => {
        if(!canEdit) {
            return [cur, []]
        }

        const arrows: UpdateFamType[] = [actionType]
        if (actionType === "remove" && index > 0 && results[index - 1].level === cur.level) arrows.push("up")
        if (actionType === "remove" && index < results.length - 1 && results[index + 1].level === cur.level) arrows.push("down")

        return [cur, arrows]
    })

    return (
        <>
            <ListWithJobs>
                <ListOfJobs items={results?.length}>
                    {resultWithArrows.map((tuple) => {
                        const res = tuple[0]
                        const arrows = tuple[1]
                        return <ConnectedJobAsListItem arrows={arrows} key={res.id} id={res.id} action={(famAction: UpdateFamType) => action(res.id, famAction)} actionType={actionType} />
                    })}
                </ListOfJobs>
            </ListWithJobs >
            {canEdit && actionType != "remove" && <ConnectedCreateNewJob />}
        </>
    )
}

interface EditFamily {
    item: Family,
    currentLanguage: Language,
    currentOrganisation?: Organisation,
    updateJobfamily: UpdateJobfamily
    deleteJobfamily: DeleteJobfamily
}

const ChangeName: React.FC<EditFamily> = ({ item, currentLanguage, currentOrganisation, updateJobfamily }) => {
    const [value, setValue] = useState<string>(item.name);
    const lang = getLanguageObject(currentLanguage)
    const changeName = (organisation: string, family: string, name: string, results: Result[] | null) => {
        updateJobfamily(organisation, family, name, results?.map(x => x.id) || []);
        updateOrganisationRequest();
    }

    if (!currentOrganisation) return null

    return (
        <AreYouSurePopup
            onClick={() => {
                changeName(currentOrganisation.id, item.id, value, item.jobs);
            }}
            action={lang.editPlural}
            item={item.name}
            InnerContent={() => <IconButton IconSVG={EditIcon} color="success" onClick={() => { }} />}
        >
            <TextField type="text" placeholder={lang.newName.replace(/<[^>]*>?/gm, '').replace("&amp;", "&")} value={value.replace(/<[^>]*>?/gm, '').replace("&amp;", "&")} onChange={(e) => setValue(e.target.value)} fullWidth />
        </AreYouSurePopup>
    )
}

const mapEditStateToProps = (state: ReduxStore) => (
    {
        currentLanguage: state.language.currentLanguage,
        currentOrganisation: state.user.currentOrganisation
    }
)

const mapEditDispatchToProps = { updateJobfamily, updateOrganisationRequest, deleteJobfamily };

export const ConnectedChangeName = connect(
    mapEditStateToProps,
    mapEditDispatchToProps
)(ChangeName);

const DeleteItem: React.FC<EditFamily> = ({ item, currentLanguage, deleteJobfamily }) => {
    const lang = getLanguageObject(currentLanguage)
    const history = useHistory();

    return (<AreYouSurePopup
        onClick={() => deleteJobfamily(item.id, history)}
        action={lang.deletePlural}
        InnerContent={() => <IconButton IconSVG={TrashIcon} color={'danger'} onClick={() => { }} />}
        item={item.name}
    ><TextWarning>{lang.questionDeleteShareLink}</TextWarning></AreYouSurePopup>
    )
}

export const ConnectedDeleteItem = connect(
    mapEditStateToProps,
    mapEditDispatchToProps
)(DeleteItem);


export const Header = styled.div`
    margin: 0 auto 20px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    box-sizing: border-box;
`

export const ManageButtons = styled.div`
display: grid;
grid-template-columns: auto 44px 44px;
align-items: center;
gap: 1em;
`

export const FooterBar = styled.div`
    margin-top: auto;
    padding: .5em 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 -3px 4px 0 rgba(167, 178, 195, 0.32);
    background-color: #fff;
`

const initialNewJob = {
    name: "",
    organisationUnit: "",
    unit: "",
    talentPathKey: "",
    validatedProfileId: ""
}


export const CreateNewJob: React.FC<{ currentLanguage: Language; currentOrganisation?: Organisation; resultsPostResultManual: ResultsPostResultManual; validatedProfiles?: ValidatedProfile[]; loadValidatedProfiles: LoadValidatedProfiles }> = ({ currentLanguage, currentOrganisation, resultsPostResultManual, validatedProfiles, loadValidatedProfiles }) => {
    const lang = getLanguageObject(currentLanguage)
    const [newJob, setNewJob] = useState(initialNewJob)

    const [hasError, setHasError] = useState<boolean>(false)
    const [visible, setVisible] = useState(false);
    const ref = useRef(null);

    useOnClickOutside(ref, () => handlePopup());

    useEffect(() => {
        loadValidatedProfiles();
    }, [])

    const fieldChecks = newJob.name === "" ||
        newJob.organisationUnit === "" ||
        newJob.unit === "" ||
        newJob.talentPathKey === "" ||
        newJob.validatedProfileId === "";

    if (!currentOrganisation?.id) return null


    const units = currentOrganisation?.organisationUnits.find(ent => ent.id === newJob.organisationUnit)?.units || currentOrganisation?.organisationUnits[0]?.units || [];
    const filteredValidatedProfiles = validatedProfiles?.filter((x) => x.talentPath.key === newJob.talentPathKey) || [];

    const handlePopup = () => {
        return setVisible(!visible);
    };

    const onChange = (key: string, value: string) => {
        setNewJob({
            ...newJob,
            [key]: value,
        })
    }

    const onSave = () => {
        if (fieldChecks) return setHasError(true)

        resultsPostResultManual(newJob)
        setHasError(false)
        setNewJob(initialNewJob)
        return setVisible(false);
    }

    const MaybeRenderError = () => {
        if (hasError) return <InputFieldErrorMessages text={lang.pleaseFillInAllField} />

        return null;
    }

    return (
        <>
            {visible && <Overlay >
                <Card width={300} padding={15}>
                    <div ref={ref}>
                        <H2>{lang.createNewJob}</H2>
                        <div style={{ marginBottom: 12 }}>
                            <Label htmlFor="name">{lang.name}</Label>
                            <TextInput
                                autoFocus
                                type="text"
                                name="name"
                                value={newJob.name}
                                onChange={e => {
                                    onChange('name', e.target.value);
                                }}
                            />
                        </div>
                        <div>
                            <SelectField
                                label={lang.organisationUnit}
                                value={newJob.organisationUnit}
                                onChange={e => {
                                    onChange('organisationUnit', e.target.value);
                                }}
                                options={
                                    <>
                                        <option>{lang.selectOrganisationUnit}</option>
                                        {currentOrganisation.organisationUnits.map((e, index) => (
                                            <option key={`${e.id} + ${index}`} value={e.id}>{getStringFromCurrentLanguage(e.name, currentLanguage)}</option>
                                        ))}
                                    </>
                                }
                            />
                        </div>
                        <div>
                            <SelectField
                                label={lang.unit}
                                onChange={e => {
                                    onChange('unit', e.target.value);
                                }}
                                options={
                                    <>
                                        <option>{lang.selectUnit}</option>
                                        {units.map((e, index) => (
                                            <option key={`${e.id} + ${index}`} value={e.id}>{getStringFromCurrentLanguage(e.name, currentLanguage)}</option>
                                        ))}
                                    </>
                                }
                                value={newJob.unit}
                                disabled={!newJob.organisationUnit}
                            />
                        </div>
                        <div>
                            <SelectField
                                label={lang.talentpath}
                                onChange={e => {
                                    onChange('talentPathKey', e.target.value);
                                }}
                                options={
                                    <>
                                        <option>{lang.selectTalentPath}</option>
                                        {talentPathNames.sort((a, b) => a.value < b.value ? -1 : 1).map((e, index) => (
                                            <option key={`${e.key} + ${index}`} value={e.key}>{transformTalentPathKey(e.key, currentLanguage)}</option>
                                        ))}
                                    </>
                                }
                                value={newJob.talentPathKey}
                                disabled={!newJob.unit}
                            />
                        </div>
                        <div>
                            <SelectField
                                label={lang.level}
                                onChange={e => {
                                    onChange('validatedProfileId', e.target.value);
                                }}
                                options={
                                    <>
                                        <option value={0}>{lang.selectLevel}</option>
                                        {filteredValidatedProfiles.sort((a, b) => getLevelIndex(a.styrLevel.level.toLowerCase()) - getLevelIndex(b.styrLevel.level.toLowerCase())).map((e) => (
                                            <option key={e.id} value={e.id}>{e.styrLevel.level}</option>
                                        ))}
                                    </>

                                }
                                value={newJob.validatedProfileId}
                                disabled={!newJob.talentPathKey}
                            />
                        </div>
                        <MaybeRenderError />
                        <Button text={lang.save} onClick={onSave} priority="tertiary" />
                    </div>
                </Card>
            </Overlay>
            }
            <FooterBar>
                <Button text={lang.addNewJob} onClick={handlePopup} priority="primary" />
            </FooterBar>
        </>
    )
}

const mapCreateNewJobStateToProps = (state: ReduxStore) => (
    {
        currentLanguage: state.language.currentLanguage,
        currentOrganisation: state.user.currentOrganisation,
        validatedProfiles: state.allocator.validatedProfiles
    }
)

const mapCreateNewJobDispatchToProps = { resultsPostResultManual, loadValidatedProfiles };

export const ConnectedCreateNewJob = connect(
    mapCreateNewJobStateToProps,
    mapCreateNewJobDispatchToProps
)(CreateNewJob);


export const JobListHead = styled.div`
    padding: 1em;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 10px;
    align-items: center;
`

export const TextInput = styled.input<{ disabled?: boolean }>`
    margin-bottom: 2px;
    border: 2px solid #3b4b63;
    border-radius: 4px;
    font-size: 18px;
    height: 42px;
    width: 100%;
    background-color: ${props => props.disabled ? '#eee' : 'transparent'};
    color: #333333;
    display: block;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    min-height: 48px;
    text-indent: 8px;
    box-sizing: border-box;
    &active, &:focus {
        border: 2px solid #66caba;
        outline: none;
    }
`;
