import React, { useState } from 'react';
import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';
import { Organisation } from 'types/Organisation';
import useZoomMatrix from 'utils/zoom';

interface EditJobProps {
    id: string | null
    popup: boolean
}

export interface JobMatrixCellWithJobsList {
    level: string;
    jobIDs: string[];
    handleEditJob: (id: string, state: boolean) => void;
}

interface MatrixDataContextType {
    editJob: EditJobProps
    setEditJob: (editJob: EditJobProps) => void
    currentCellData: JobMatrixCellWithJobsList | null
    setCurrentCellData: (currentCellData: JobMatrixCellWithJobsList | null) => void
    zoomTable: number
    editMode: boolean
    setEditMode: (editMode: boolean) => void
    handleZoom: (zoom: number) => void
}

export const MatrixDataContext = React.createContext<MatrixDataContextType>({
    editJob: { id: null, popup: false },
    setEditJob: () => { },
    currentCellData: null,
    setCurrentCellData: () => { },
    zoomTable: 100,
    handleZoom: () => { },
    editMode: false,
    setEditMode: () => { },
});


const MatrixContextProvider: React.FC<{ currentOrganisation?: Organisation, children: React.ReactNode; }> = ({ children, currentOrganisation }) => {
    const [currentCellData, setCurrentCellData] = useState<JobMatrixCellWithJobsList | null>(null);
    const [editMode, setEditMode] = useState(false);
    const [editJob, setEditJob] = useState<EditJobProps>({
        id: null,
        popup: false
    })
    const { zoomTable, handleZoom } = useZoomMatrix(currentOrganisation?.id)

    return (
        <MatrixDataContext.Provider value={{ editMode, setEditMode, editJob, setEditJob, currentCellData, setCurrentCellData, zoomTable, handleZoom }}>
            {children}
        </MatrixDataContext.Provider>
    );
};


const mapStateToProps = (state: ReduxStore) => ({
    currentOrganisation: state.user.currentOrganisation,

});
const mapDispatchToProps = {};
const ConnectedMatrixContextProvider = connect(mapStateToProps, mapDispatchToProps)(MatrixContextProvider);

export default ConnectedMatrixContextProvider;
