import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';
import ComparingLevelsTable from './ComparingLevelsTable';

const mapStateToProps = (state: ReduxStore) => ({
    currentLanguage: state.language.currentLanguage
});
const mapDispatchToProps = {};
const ConnectedComparingLevelsTable = connect(mapStateToProps, mapDispatchToProps)(ComparingLevelsTable);

export default ConnectedComparingLevelsTable;
