import MainLayout from 'layouts/MainLayout/MainLayout';
import React from 'react';
import JobMatrixProps from './Matrix.types';
import ConnectedJobMatrixResults from 'sections/JobMatrix/JobMatrixResults/JobMatrixResults.connector';
import ConnectedMatrixContextProvider from 'containers/MatrixContextProvider/MatrixContextProvider';
import { MatrixViews } from 'containers/MatrixContent/MatrixContent.types';
import { Container } from 'layouts/MainLayout/MainLayout.components';
import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';

const JobMatrixScreen: React.FC<JobMatrixProps> = () => {
    return (
        <MainLayout>
            <Container>
                <ConnectedMatrixContextProvider>
                    <ConnectedJobMatrixResults matrixView={MatrixViews.jobmatrix} />
                </ConnectedMatrixContextProvider>
            </Container>
        </MainLayout>
    )
};


const mapMatrixViewTabsStateToProps = (state: ReduxStore) => ({
    currentLanguage: state.language.currentLanguage,
});
const mapMatrixViewTabsDispatchToProps = {  };
const JobMatrix = connect(
    mapMatrixViewTabsStateToProps, 
    mapMatrixViewTabsDispatchToProps
)(JobMatrixScreen);

export default JobMatrix;
