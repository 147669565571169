import {
    StyleSheet
} from '@react-pdf/renderer';

export const styles = StyleSheet.create({
    /* Fonts */
    h1: { marginBottom: 4, fontFamily: 'Avenir', fontSize: 20 },
    h2: { marginBottom: 4, fontFamily: 'Avenir', fontSize: 17 },
    h3: { marginBottom: 4, fontFamily: 'Avenir', fontSize: 13 },
    strong: { marginBottom: 4, fontFamily: 'Avenir', fontSize: 11, fontWeight: 800 },
    em: { marginBottom: 4, fontFamily: 'Avenir Italic', fontSize: 11 },
    underline: { marginBottom: 4, fontFamily: 'Avenir Book', textDecoration: 'underline', fontSize: 11 },
    p: { marginBottom: 4, fontFamily: 'Avenir Book', fontSize: 11, marginBlockStart: 0, marginBlockEnd: 0 },
    span: { fontFamily: 'Avenir Book', fontSize: 11 },
    ul: { marginLeft: 15, display: 'flex', flexDirection: 'column' },
    li: { paddingBottom: 3, fontSize: 11 },
    liIndent1: { paddingBottom: 3, fontSize: 11, marginLeft: 25 },
    titles: {
        marginBottom: 4,
        fontFamily: 'Avenir'
    },
    answers: {
        fontFamily: 'Avenir Book',
    },
    /* Page */
    page: {
        backgroundColor: '#FFFFFF',
        fontSize: 11,
        flexDirection: 'column',
    },
    pageNotes: {
        paddingTop: 30,
        paddingBottom: 30,
        backgroundColor: '#FFFFFF',
        fontSize: 11,
        flexDirection: 'column',
    },
    main: { flexDirection: 'column' },
    styrCompetencies: {},
    /* Aside Section */
    aside: { backgroundColor: '#eff4f9', width: '100%', height: 177 },
    asideHeaderTitle: { width: 172, alignContent: 'center' },
    asideHeaderJob: { width: 172, alignContent: 'center' },
    asideHeadePath: { width: 100, alignContent: 'center' },
    asideHeadeStatus: { width: 120, alignContent: 'center' },
    asideHeadeCreatedOn: { width: 100, alignContent: 'center' },
    asideCreateDetailItem: { fontSize: 12, fontFamily: 'Avenir Book' },
    styrLevel: {
        fontSize: 32,
        fontWeight: 800,
    },
    asideProfile: {
        padding: 16,
        flex: "1 1 auto",
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },
    asideProfileItem: {
        paddingTop: 8,
        width: 172,
    },
    asideFileDetails: {
        height: 100,
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 16,
        textAlign: 'left',
        justifyContent: 'flex-start',
    },
    asideFileDetailItem: {
        marginBottom: 8,
        fontFamily: 'Avenir Book',
    },
    talentPathIcon: {
        marginTop: -12,
        width: 56,
        height: 56,
        backgroundColor: '#fff',
        borderRadius: 50,
        marginLeft: 'auto',
        marginRight: 20,
        position: 'relative'
    },
    talentPathSVG: {
        width: 65,
        height: 65,
        position: 'absolute',
        top: -4,
        left: -4.2,
    },
    /* Results Section */
    results: {
        paddingTop: 16,
        flex: "1 1 auto",
        flexDirection: 'row',
        width: '100%',
        height: 580,
    },
    resultsList: { padding: 16, flex: 1 },
    resultItem: { marginBottom: 24, minHeight: 30 },

    /* Notes Section */
    notes: {
        paddingLeft: 16,
        paddingRight: 16,
        textAlign: 'left',
        justifyContent: 'flex-start',
        width: '100%',
    },
    footer: {
        marginTop: 'auto',
        paddingBottom: 16,
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'flex-end',
        width: '100%',
    },
    disclaimer: {
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 16,
        justifyContent: 'flex-start',
        paddingTop: 3,
        marginLeft: 16,
        textAlign: 'center',
        width: 420,
        fontSize: 9,
    },
    disclaimerText: {
        fontFamily: 'Avenir Book',
        fontSize: 10,
    },
    companyLogo: {
        paddingLeft: 16,
        paddingRight: 16,
    },
    companyLogoImage: {
        height: 32,
        width: 'auto',
    },

    // styr competencies section
    headerText: {
        fontWeight: 800,
        fontSize: 11,
        fontFamily: 'Avenir',
        padding: '16px 16px 0',
    },

    resultsListCompetencies: {
        padding: '0px 16px', flex: 1
    },

    asideSmall: {
        backgroundColor: 'white',
        width: '100%',
        height: 60,
    },
});
