import React from 'react';
import { UnderlinedTitle } from 'components/Typography/Typography';

import {
    Form,
    LoginFormContainer,
    Row
} from './LoginForm.components';
import LoginFormProps from './LoginForm.types';
import { MicrosoftLogin } from './MicrosoftLogin';
import { InputFieldErrorMessages } from 'containers/InputField/InputField.components';

const LoginForm: React.FC<LoginFormProps> = ({
    userAddError,
    userLoginMicrosoft,
    currentLanguage,
    errors
}) => {
    return (
        <>
            <LoginFormContainer>
                <Form>
                    <UnderlinedTitle>Styr dock</UnderlinedTitle>
                        {errors.map((error, key) => (
                            <InputFieldErrorMessages key={key} text={error} />
                        ))}
                    <Row>
                        <MicrosoftLogin currentLanguage={currentLanguage} userLoginMicrosoft={userLoginMicrosoft} userAddError={(e: string) => {
                            userAddError(e);
                        }} />
                    </Row>
                </Form>
            </LoginFormContainer>
        </>
    );
};

export default LoginForm;
