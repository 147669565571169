import { connect } from 'react-redux';
import { setActiveNavbarItem } from 'store/navbar/navbar.actions';
import ReduxStore from 'store/store.type';
import { userLogout } from 'store/user/user.actions';
import Sidebar from './Sidebar';
import { updatesGetUpdates } from 'store/updates/updates.actions';

const mapStateToProps = (state: ReduxStore) => ({
    sidebarOpen: state.navbar.sidebarOpen,
    options: state.user.currentOrganisation?.options,
    permissions: state.user.permissions,
    currentLanguage: state.language.currentLanguage,
    isShareLink: state.user.isShareLink,
    shareLinkSettings: state.user.share_link_settings,
    updates: state.updates.updates,
});

const mapDispatchToProps = { setActiveNavbarItem, userLogout, updatesGetUpdates };

const ConnectedSidebar = connect(mapStateToProps, mapDispatchToProps)(Sidebar);

export default ConnectedSidebar;
