import getLanguageObject from "helpers/language";
import { Language } from "types/MultiLingualString";

//TODO: do this in backend.
export const talentPaths = [
    "talentpathAdministrative",
    "talentpathOperationalTechnical",
    "talentpathSupportCoordination",
    "talentpathCommercial",
    "talentpathExpert",
    "talentpathProcessManagement",
    "talentpathAdvisory",
    "talentpathProjectManagement",
    "talentpathManagement",
]

//TODO: do this in backend.
export const talentPathNames = [
    {
        key: "talentpathAdministrative",
        value: "Administrative"
    },
    {
        key: "talentpathOperationalTechnical",
        value: "Operational / Technical"
    },
    {
        key: "talentpathSupportCoordination",
        value: "Support / Coordination"
    },
    {
        key: "talentpathCommercial",
        value: "Commercial"
    },
    {
        key: "talentpathExpert",
        value: "Expert"
    },
    {
        key: "talentpathProcessManagement",
        value: "Process Management"
    },
    {
        key: "talentpathProjectManagement",
        value: "Project Management"
    },
    {
        key: "talentpathAdvisory",
        value: "Advisory"
    },
    {
        key: "talentpathManagement",
        value: "Management"
    },
]

//TODO: do this in backend.
export const transformTalentPathKey = (original: string | null | undefined, currentLanguage: Language) => {
    if (!original) {
        return original
    }

    const lang = getLanguageObject(currentLanguage);
    //@ts-ignore
    return lang[original] || capitalize(original.replace("-", " "));
}

export const capitalize = (text: string) => {
    return text.replace(/(^\w|\s\w)/g, m => m.toUpperCase());
}
