import styled from 'styled-components';

export const Container = styled.div`
    padding: 5px 10px 5px 10px;
    display: inline-flex;
    position: relative;
    cursor: pointer;
    background-color: #FFFFFF;
    height: 40px;
    border-radius: 5px;
    box-sizing: border-box;
    align-items: center;
`;

export const Image = styled.img`
    display: inline;
    vertical-align: middle;
    width: 18px;
    height: 12px;
    position: absolute;
    top: 15px;
`;

export const Icon = styled.div`
    color: #fff;
    position: absolute;
    top: 10px;
    right: 0;
`;

export const LangButton = styled.div`
    width: 40px;
    height: 40px;
    position: relative;

    display: flex;
    align-items: center;
`;
