import AreYouSurePopup from "components/AreYouSurePopup/AreYouSurePopup";
import Button from "components/Button/Button";
import { TextWarning } from "components/Typography/Typography";
import InputField from "containers/InputField/InputField";
import { InputFieldErrorMessages, TextField } from "containers/InputField/InputField.components";
import ItemActions from "containers/ItemActions/ItemActions";
import { onKeyDown } from "helpers/form";
import getLanguageObject, { LangObject } from "helpers/language";
import React, { useEffect, useState } from "react";
import { getStringFromCurrentLanguage } from "store/language/language.actions";
import { Language } from "types/MultiLingualString";
import { ConnectedChangeOrganisationUnitForm } from "./ManageOrganisationUnits.connector";
import { UnitContainer, OrganisationUnitContainer, OrganisationUnitH3, Row } from "./ManageOrganisationUnits.styling";
import { NewUnitFormProps, NewOrganisationUnitFormProps, SortableOrganisationUnitProps } from "./ManageOrganisationUnits.types";
import EditIcon from "components/Icons/edit";
import TrashIcon from "components/Icons/trash";
import { OrganisationUnit, Unit } from "types/Organisation";

export const UnitElement : React.FC<any> = ({ value, currentLanguage, updateUnit, deleteUnit, organisationUnit }: { 
    value: Unit,
    currentLanguage: Language,
    updateUnit: (name: string, unit: string, organisationUnit?: string) => void,
    deleteUnit: (unit: string) => void,
    organisationUnit: OrganisationUnit
}) => {
    const unit = value;

    return (
        <UnitContainer key={(unit.id)}>
            <div>{getStringFromCurrentLanguage(unit.name, currentLanguage)?.replace(/(<([^>]+)>)/ig, '')}</div>
            <ItemActions>
                <ChangeName item={unit} currentLanguage={currentLanguage} updateUnit={updateUnit} organisationUnit={organisationUnit} />
                <DeleteItem item={unit} currentLanguage={currentLanguage} deleteUnit={deleteUnit} />
            </ItemActions>
        </UnitContainer>
    )
}

export const OrganisationUnitForm: React.FC<NewOrganisationUnitFormProps> = ({ currentOrganisation, addOrganisationUnit, currentLanguage, getRemoteLanguages }) => {
    const [organisationUnitName, setOrganisationUnitName] = useState('');
    const [errorOrganisationUnit, setErrorOrganisationUnit] = useState(false);
    const [errorEmpty, setErrorEmpty] = useState(false);

    const lang = getLanguageObject(currentLanguage);

    useEffect(() => {
        getRemoteLanguages()
    }, [])

    const addOrganisationUnitButton = () => {
        const organisationUnitExist = currentOrganisation?.organisationUnits.find(
            x => x.name[currentLanguage] === organisationUnitName
        );

        if (!organisationUnitName) return setErrorEmpty(true);

        if (organisationUnitExist) {
            return (
                setErrorOrganisationUnit(true)
            )
        }

        setErrorOrganisationUnit(false);
        setOrganisationUnitName('');
        addOrganisationUnit(organisationUnitName);
    };

    const MaybeRenderError = () => {
        if (errorOrganisationUnit) return <InputFieldErrorMessages text={lang.unitAlreadyExists} />
        if (errorEmpty) return <InputFieldErrorMessages text={lang.unitEmpty} />

        return null;
    }

    return (
        <Row>
            <InputField
                type="text"
                value={organisationUnitName}
                onChange={event => setOrganisationUnitName(event.target.value)}
                errorMessage={<MaybeRenderError />}
                onKeyDown={event => onKeyDown(event, addOrganisationUnitButton)}
                fullWidth
            />
            <Button text={lang.add} onClick={addOrganisationUnitButton} />
        </Row>
    )
}

export const UnitForm: React.FC<NewUnitFormProps> = ({ selectedOrganisationUnit, addUnit, currentLanguage }) => {
    const [unitName, setUnitName] = useState('');
    const [errorEmptyUnit, setErrorEmptyUnit] = useState(false);
    const [errorExistUnit, setErrorExistUnit] = useState(false);
    const [unitOrganisationUnit, setUnitOrganisationUnit] = useState('');

    useEffect(() => {
        setUnitOrganisationUnit(selectedOrganisationUnit.id || '');
    }, [selectedOrganisationUnit]);

    const lang = getLanguageObject(currentLanguage);
    const addUnitButton = () => {
        const nameExists = selectedOrganisationUnit?.units.find(y => y.name[currentLanguage] === unitName);

        if (!unitName) return setErrorEmptyUnit(true);
        if (nameExists) {
            return setErrorExistUnit(true);
        }

        setErrorEmptyUnit(false);
        setErrorExistUnit(false);
        setUnitName('');
        addUnit(unitName, unitOrganisationUnit);
    };

    const MaybeRenderError = () => {
        if (errorEmptyUnit) return <InputFieldErrorMessages text={lang.pleaseEnterUnit} />

        if (errorExistUnit) return <InputFieldErrorMessages text={lang.nameAlreadyExists} />

        return null;
    }

    return (
        <Row>
            <InputField
                type="text"
                value={unitName}
                onChange={event => setUnitName(event.target.value)}
                errorMessage={<MaybeRenderError />}
                onKeyDown={event => onKeyDown(event, addUnitButton)}
                fullWidth
            />
            <Button text={lang.add} onClick={addUnitButton} />
        </Row>
    )
}

export const ChangeOrganisationUnitForm: React.FC<{
    organisationUnit: OrganisationUnit;
    currentLanguage: Language;
    lang: LangObject;
    updateOrganisationUnit: (name: string, organisationUnit: string) => void;
}> = ({ organisationUnit, currentLanguage, lang, updateOrganisationUnit }) => {
    const [organisationUnitUpdatedName, setUpdatedOrganisationUnitName] = useState<string>(getStringFromCurrentLanguage(organisationUnit.name, currentLanguage));
    return (<AreYouSurePopup
        onClick={() => {
            updateOrganisationUnit(organisationUnitUpdatedName, organisationUnit.id);
            setUpdatedOrganisationUnitName("");
        }}
        action={lang.editPlural}
        item={getStringFromCurrentLanguage(organisationUnit.name, currentLanguage)}
        InnerContent={() => <EditIcon />}
    >
        <TextField type="text" placeholder={lang.newName} value={organisationUnitUpdatedName} onChange={(e) => setUpdatedOrganisationUnitName(e.target.value)} />
    </AreYouSurePopup>
    )
}

export const OrganisationUnitElement: React.FC<SortableOrganisationUnitProps> = ({ selected, organisationUnit, deleteOrganisationUnit, lang, currentLanguage }) => {

    const DeleteItem = () => (
        <AreYouSurePopup
            onClick={() => deleteOrganisationUnit(organisationUnit.id)}
            action={lang.deletePlural}
            item={getStringFromCurrentLanguage(organisationUnit.name, currentLanguage)?.replace(/(<([^>]+)>)/ig, '')}
            InnerContent={() => <TrashIcon />}
        >
            <TextWarning>{lang.deleteWarningOrganisationUnit}</TextWarning>
        </AreYouSurePopup>
    )

    return (
        <OrganisationUnitContainer selected={selected}>
            <OrganisationUnitH3>{getStringFromCurrentLanguage(organisationUnit.name, currentLanguage)}</OrganisationUnitH3>
            <ItemActions>
                <ConnectedChangeOrganisationUnitForm organisationUnit={organisationUnit} lang={lang} />
                <DeleteItem />
            </ItemActions>
        </OrganisationUnitContainer>
    )
}

const ChangeName: React.FC<{ item: Unit, currentLanguage: Language, updateUnit: (name: string, unit: string, organisationUnit?: string) => void, organisationUnit: OrganisationUnit}> = ({ item, currentLanguage, updateUnit, organisationUnit }) => {
    const [value, setValue] = useState<string>(getStringFromCurrentLanguage(item.name, currentLanguage));
    const lang = getLanguageObject(currentLanguage);
    return (
        <AreYouSurePopup
            onClick={() => {
                updateUnit(value, item.id, organisationUnit.id);
                setValue("");
            }}
            action={lang.editPlural}
            item={value?.replace(/(<([^>]+)>)/ig, '')}
            InnerContent={() => <EditIcon />}>
            <TextField type="text" placeholder={lang.newName} value={value?.replace(/(<([^>]+)>)/ig, '')} onChange={(e) => setValue(e.target.value)} />
        </AreYouSurePopup>
    )
}

const DeleteItem: React.FC<{ item: Unit, currentLanguage: Language, deleteUnit: (item: string) => void }> = ({ item, currentLanguage, deleteUnit }) => {
    const lang = getLanguageObject(currentLanguage);
    return (<AreYouSurePopup
        onClick={() => deleteUnit(item.id)}
        action={lang.deletePlural}
        InnerContent={() => <TrashIcon />}
        item={getStringFromCurrentLanguage(item.name, currentLanguage)}
    ><TextWarning>{lang.deleteWarningUnit}</TextWarning></AreYouSurePopup>
    )
}