import styled from 'styled-components';

export const ResultPageContainer = styled.div`
    margin: 20px auto;
    max-width: 970px;
    height: fit-content;
`;

export const PageDetails = styled.div`
    margin: auto;
    padding: 0px 50px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    max-width: 970px;
    box-sizing: border-box;
`;
