import React from 'react';
import styled from 'styled-components';
import theme from 'utils/theme';

export const Container = styled.div`
    margin-bottom: 12px;
`;

export const ErrorMessagesContainer = styled.div`
    margin-top: 4px;
    padding: 4px 8px;
    margin-bottom: 8px;
    background-color: ${theme.colors.red};
    color: #fff;
    font-size: 12px;
    width: calc(100% - 16px);
    border-radius: 5px;
`;

export const SuccesMessagesContainer = styled(ErrorMessagesContainer)`
    background-color: ${theme.colors.green};
`;


export const InputFieldErrorMessages: React.FC<{ text: string }> = ({ text }) => {
    return <ErrorMessagesContainer>{text}</ErrorMessagesContainer>
}

export const SuccesMessages: React.FC<{ text: string }> = ({ text }) => {
    return <SuccesMessagesContainer>{text}</SuccesMessagesContainer>
}

export const Label = styled.label`
    font-size: 14px;
    font-weight: 900;
    line-height: 22px;
`;

export const TextField = styled.input<{ valid?: boolean; }>`
    width: 100%;

    padding: 4px 8px;
    margin: 0;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 1.4;
    border: 2px solid ${props => props.valid ? "#3b4b63" : theme.colors.red};
    border-radius: 5px;
    color: #333333;
    display: block;
    font-weight: 500;
    min-height: 46px;
    text-indent: 8px;
    &active, &:focus {
        border: 2px solid #66caba;
        outline: none;
    }
`;

export const SearchField = styled(TextField)`
    border-width: 0px;
    background-color: #F5F5F5;
    text-indent: 32px;

    &::placeholder{
        color: rgba(0,0,0, .3);
    }
`;

export const Icon = styled.div`
    content: '';
    position: absolute;
    top: 12px;
    left: 14px;
    z-index: 2;
    width: 24px;
    height: 24px;
    color: #ACACAC;
`;


export const Fieldset = styled.fieldset<{ width?: number; fullWidth?: boolean; }>`
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    position: relative;
    border: 0;
    width: ${props => props.width ? `${props.width}px` : 'unset'};
    max-width: ${props => props.fullWidth ? 'unset' : '300px'};

    .multi-select{
        width: ${props => props.fullWidth ? '100%' : 'auto'};
        .dropdown-container{
            width: 100%;
            padding: 4px 8px;
            margin: 0;
            box-sizing: border-box;
            font-size: 14px;
            line-height: 1.4;
            border: 2px solid #3b4b63;
            border-radius: 5px;
            color: #333333;
            display: block;
            font-weight: 500;
            min-height: 46px;
            text-indent: 8px;
        }
    }
`;
export const InputFieldContainer = styled.div`
`;

export const SelectWrapper = styled.div<{ disabled?: boolean }>`
    position: relative;
    width: 100%;

    opacity: ${props => props.disabled ? .24 : 1};
    &::after{
        content: "";
        position: absolute;
        top: 20px;
        right: 16px;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;

        border-top: 6px solid #000;
    }
`;

export const Select = styled.select`
    border: 2px solid #3b4b63;
    border-radius: 5px;
    height: 2.5rem;
    padding-left: 0.5rem;
    outline: none;
    background-color: white;
    font-size: 14px;
    box-sizing: border-box;
    height: 46px !important;
    margin-bottom: 5px;
    width: 100%;

    // is needed to hide the default dropdown arrow
    -webkit-appearance: none;
    &active, &:focus {
        border: 2px solid #66caba;
        outline: none;
    }
`;


export const FieldLabel: React.FC<{ label?: string }> = ({ label }) => {
    if (!label) return null;

    return <Label>{label}</Label>
}
