import { MatrixDataContext } from "containers/MatrixContextProvider/MatrixContextProvider";
import useOnClickOutside from "helpers/hooks/useOnClick";
import getLanguageObject from "helpers/language";
import React, { useContext, useRef, useState } from "react";
import { connect } from "react-redux";
import Routes from "routes/Routes.types";
import ReduxStore from "store/store.type";
import { Permissions, ShareLinkSettings } from "store/user/user.types";
import { Language } from "types/MultiLingualString";
import { Result } from "types/Result";
import { Container, EditOption, JobWithView, Option, Options, Title } from "./MatrixBoxWithJobs.components";

interface RenderJobItemProps {
    permissions: Permissions;
    data: Result;
    familyID?: string | null;
    language?: Language;
    currentLanguage: Language;
    isSharedLink?: boolean;
    shareLinkSettings?: ShareLinkSettings;
}

const RenderJobItem: React.FC<RenderJobItemProps> = ({ permissions, data, familyID, language, currentLanguage, isSharedLink, shareLinkSettings }) => {
    const { setEditJob } = useContext(MatrixDataContext)
    let langUrl: Language;
    language ? langUrl = language : langUrl = currentLanguage;
    const ref = useRef(null);
    useOnClickOutside(ref, () => setViewOptions(false));
    const lang = getLanguageObject(currentLanguage)

    const [viewOptions, setViewOptions] = useState(false);

    const canEditJobs = permissions.canUpdateStatusResult

    const onClickJob = () => {
        setEditJob({ id: data.id, popup: false })
        setViewOptions((oldState) => !oldState)
    }

    const canViewValidatedProfile = !isSharedLink || isSharedLink && shareLinkSettings?.show_styr_profile

    return (
        <Container >
            <JobWithView selected={viewOptions}>
                <Title onClick={onClickJob}>{data.name}</Title>
            </JobWithView>
            <Options active={viewOptions} ref={ref}>
                {
                    canViewValidatedProfile && <Option
                        key={"viewValidatedProfile"+(data.id)}
                        to={`${Routes.ResultRoute + data.id}/${langUrl}`}>
                        {lang.viewValidatedProfile}
                    </Option>
                }
                {familyID && <Option
                    key={"viewJobFamily"+(data.id)}
                    to={`${Routes.JobFamilyView + familyID}/${langUrl}`}>
                    {lang.viewJobFamily}
                </Option>}
                {
                    canEditJobs && (
                        <EditOption onClick={() => setEditJob({ id: data.id, popup: true })}>
                            {lang.editJob}
                        </EditOption>
                    )
                }
            </Options>
        </Container>
    )
}



const mapStateToProps = (state: ReduxStore) => ({
    currentLanguage: state.language.currentLanguage,
    jobFamilies: state.user.currentOrganisation?.jobFamilies,
    permissions: state.user.permissions,
    isSharedLink: state.user.isShareLink,
    shareLinkSettings: state.user.share_link_settings,
});
const mapDispatchToProps = {};
const ConnectedRenderJobItem = connect(mapStateToProps, mapDispatchToProps)(RenderJobItem);

export default ConnectedRenderJobItem;
