import React from 'react';
import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import AdminAllocatorValidatedProfilesList from 'sections/Admin/AdminAllocatorValidatedProfilesList/AdminAllocatorValidatedProfilesList.connected';

const AdminAllocatorValidatedProfiles: React.FC = () => {
    return (
        <AdminLayout>
            <AdminAllocatorValidatedProfilesList />
        </AdminLayout>
    );
};

export default AdminAllocatorValidatedProfiles;