import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';
import { setCurrentOrganisation, updateOrganisationRequest, updateOrganisationsRequest } from 'store/user/user.actions';
import OrganisationDropdownMenu from './OrganisationDropdownMenu';

const mapStateToProps = (state: ReduxStore) => ({
    sidebarOpen: state.navbar.sidebarOpen,
    organisations: state.user.organisations,
    currentLanguage: state.language.currentLanguage,
    currentOrganisation: state.user.currentOrganisation,
});

const mapDispatchToProps = { setCurrentOrganisation, updateOrganisationRequest, updateOrganisationsRequest };
const ConnectedOrganisationDropdownMenu = connect(
    mapStateToProps,
    mapDispatchToProps
)(OrganisationDropdownMenu);

export default ConnectedOrganisationDropdownMenu;
