import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';
import { uploadOrganisationImage } from 'store/user/user.actions';
import ManageOrganisationImage from './ManageOrganisationImage';

const mapStateToProps = (state: ReduxStore) => ({
    currentLanguage: state.language.currentLanguage,
    currentOrganisation: state.user.currentOrganisation
});

const mapDispatchToProps = { uploadOrganisationImage };
const ConnectedManageOrganisationImage = connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageOrganisationImage);

export default ConnectedManageOrganisationImage;
