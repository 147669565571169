import styled from 'styled-components';

export const Container = styled.div`
margin-bottom: 0px;
color: #333333;
font-size: 14px;
font-weight: 900;
line-height: 22px;
position: relative;

display: flex;
flex-direction: row;
align-items: center;

background-color: #EFEDEE;
border-radius: 8px;
`;

export const FormDropdown = styled.div`
    all: unset;
    padding: 0 10px;
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 40px;
    border: 0;

    cursor: pointer;
`;

export const Label = styled.div<{lastOfType?: boolean}>`
    padding: 10px 0;
    display: grid;
    align-items: center;
    font-weight: bold;
    width: 100%;
    text-transform: capitalize;
    cursor: pointer;
`;

export const Option = styled.label<{lastOfType?: boolean}>`
    padding: 10px 0;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 5px;
    font-weight: bold;
    width: 100%;
    text-transform: capitalize;
    cursor: pointer;

    border-bottom: ${props => props.lastOfType ? "0" : "1px"} solid #E5E4E5;
`;

export const DropdownSelect = styled.div<{ openInverted?: boolean; customWidth?: number }>`
    padding: 8px;
    max-height: 400px;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #E5E4E5;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    ${props => props.openInverted ? `bottom: 62px;` : `top: 48px`}
    width: ${props => props.customWidth ? props.customWidth : 220}px;
`;

export const Icon = styled.div`
    color: #000;
`;


export const TableIcon = styled.div`
    padding: 0 8px 0 8px;
    color: #000;
`;
