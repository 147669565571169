import { jobMatrixDefaultColumnWidth, LOCAL_STORAGE_KEY } from "./initialJobMatrixData";
import { LocalColumnData, LocalFilterTagData, LocalJobMatrixData, LocalJobMatrixDataActions, LocalRowData, LocalTalentpathData } from "./JobMatrixResults.types";

export const localDataReducer = (state: LocalJobMatrixData, action: LocalJobMatrixDataActions) => {
    let result: LocalJobMatrixData = state;
    switch (action.type) {
        case "init":
            result = action.payload;
            break;
        case "toggleStickyColumn":
            const currentColumnToStick = state.columns.find(x => x.uuid === action.payload.uuid);
            const stickyColumnFallback: LocalColumnData = {
                index: action.payload.index,
                uuid: action.payload.uuid,
                shown: true,
                sticky: false
            }

            const stickyColumnWithFallback = currentColumnToStick || stickyColumnFallback;

            result = {
                ...state,
                columns: [
                    ...state.columns.filter(x => x.uuid !== action.payload.uuid),
                    {
                        ...stickyColumnWithFallback,
                        sticky: !stickyColumnWithFallback.sticky
                    }
                ]
            }
            break;
        case "toggleVisibilityColumn":
            const currentColumn = state.columns.find(x => x.uuid === action.payload.uuid);

            const fallbackColumn: LocalColumnData = {
                index: action.payload.index,
                uuid: action.payload.uuid,
                // newValue is used for when a complete organisationUnit list is disabled. All columns in the organisationUnit become false.
                shown: action.payload.newValue === undefined ? true : action.payload.newValue,
                sticky: false
            }

            const columnWithFallback = currentColumn || fallbackColumn;

            result = {
                ...state,
                columns: [
                    ...state.columns.filter(x => x.uuid !== action.payload.uuid),
                    {
                        ...columnWithFallback,
                        // newValue is used for when a complete organisationUnit list is disabled. All columns in the organisationUnit become false.
                        shown: action.payload.newValue === undefined ? !columnWithFallback.shown : action.payload.newValue
                    }
                ]
            }
            break;

        case "toggleTags":
            const currentTag = state?.tags?.find(x => x.uuid === action.payload.tag);

            const fallbackTag: LocalFilterTagData = {
                uuid: action.payload.tag,
                shown: true
            }

            const tagWithFallback = currentTag || fallbackTag;

            result = {
                ...state,
                tags: [
                    ...state.tags.filter(x => x.uuid !== action.payload.tag),
                    {
                        ...tagWithFallback,
                        shown: !tagWithFallback.shown
                    }
                ]
            }
            break;

        case "toggleVisibilityTalentpath":
            const currentTalentpath = state.talentpath.find(x => x.uuid === action.payload.uuid);

            const fallbackTalentpath: LocalTalentpathData = {
                uuid: action.payload.uuid,
                shown: action.payload.newValue === undefined ? true : action.payload.newValue,
            }

            const talentpathWithFallback = currentTalentpath || fallbackTalentpath;

            result = {
                ...state,
                talentpath: [
                    ...state.talentpath.filter(x => x.uuid !== action.payload.uuid),
                    {
                        ...talentpathWithFallback,
                        shown: action.payload.newValue === undefined ? !talentpathWithFallback.shown : action.payload.newValue
                    }
                ]
            }

            break;
        case "toggleVisibilityRow":
            const currentRow = state.rows.find(x => x.uuid === action.payload.uuid);

            const fallbackRow: LocalRowData = {
                uuid: action.payload.uuid,
                shown: true
            }

            const rowWithFallback = currentRow || fallbackRow;

            result = {
                ...state,
                rows: [
                    ...state.rows.filter(x => x.uuid !== action.payload.uuid),
                    {
                        ...rowWithFallback,
                        shown: !rowWithFallback.shown
                    }
                ]
            }
            break;
        case "enlargeWidthColumn":
            const currentColumn2 = state.columns.find(x => x.uuid === action.payload.uuid);
            const enlargeFallback: LocalColumnData = {
                index: action.payload.index,
                uuid: action.payload.uuid,
                width: 250,
                shown: true,
                sticky: false
            }

            const enlargeColumnWithFallback = currentColumn2 || enlargeFallback;

            result = {
                ...state,
                columns: [
                    ...state.columns.filter(x => x.uuid !== action.payload.uuid),
                    {
                        ...enlargeColumnWithFallback,
                        width: (enlargeColumnWithFallback.width || jobMatrixDefaultColumnWidth) + 80,
                    }
                ]
            }
            break;
        case "shrinkWidthColumn":
            const currentColumn3 = state.columns.find(x => x.uuid === action.payload.uuid);
            const shrinkColumnFallback: LocalColumnData = {
                index: action.payload.index,
                uuid: action.payload.uuid,
                width: 180,
                shown: true,
                sticky: false
            }

            const shrinkColumnWithFallback = currentColumn3 || shrinkColumnFallback;

            result = {
                ...state,
                columns: [
                    ...state.columns.filter(x => x.uuid !== action.payload.uuid),
                    {
                        ...shrinkColumnWithFallback,
                        width: (shrinkColumnWithFallback.width || jobMatrixDefaultColumnWidth) - 80 >= 110 ? (shrinkColumnWithFallback.width || jobMatrixDefaultColumnWidth) - 80 : 110,
                    }
                ]
            }

    }

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(result));
    return result;
}
