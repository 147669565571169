import { MatrixViews } from 'containers/MatrixContent/MatrixContent.types';
import getLanguageObject from 'helpers/language';
import React from 'react';
import { Language } from 'types/MultiLingualString';
import { AwaitIsPrintingContainer, AwaitIsPrintingIcon, ButtonScale, ColumnSettingsContainer, IconContainer, MatrixViewTabsContainer, Tab, TabsContainer } from './JobMatrixResults.styles';
import { ColumnSettingsProps } from './JobMatrixResults.types';
import MinusIcon from 'components/Icons/minus';
import PlusIcon from 'components/Icons/plus';
import EyeIcon from 'components/Icons/eye';
import ThumbTackIcon, { ThumbTackLightIcon } from 'components/Icons/thumbtack';

export const ColumnSettings: React.FC<ColumnSettingsProps> = ({
    onClickToggleVisibility,
    vertical,
    stickyState,
    onClickToggleSticky,
    onClickShrink,
    onClickEnlarge,
}) => {

    const RenderResizing = () => {
        return (<>
            {onClickShrink && <ButtonScale onClick={onClickShrink}>
                <IconContainer>
                    <MinusIcon />
                </IconContainer></ButtonScale>}
            {onClickEnlarge && <ButtonScale onClick={onClickEnlarge}>
                <IconContainer>
                    <PlusIcon />
                </IconContainer>
            </ButtonScale>}
        </>
        )
    }


    const RenderVisbility = () => (
        <ButtonScale onClick={onClickToggleVisibility}>
            <IconContainer>
                <EyeIcon />
            </IconContainer>
        </ButtonScale>
    )


    const RenderStickyColumn = () => {
        return (
            <ButtonScale onClick={onClickToggleSticky}>
                <IconContainer>
                    {stickyState ? <ThumbTackIcon color="white" /> : <ThumbTackLightIcon color='white' />}
                </IconContainer>
            </ButtonScale>)
    }

    return (
        <ColumnSettingsContainer vertical={vertical}>
            <RenderVisbility />
            <RenderResizing />
            {onClickToggleSticky && <RenderStickyColumn />}
        </ColumnSettingsContainer>
    )

}

export const MatrixViewTabs: React.FC<{ currentView: MatrixViews; changeView: (tab: MatrixViews) => void; currentLanguage: Language }> = ({ currentLanguage, currentView, changeView }) => {
    const lang = getLanguageObject(currentLanguage);

    return (
        <MatrixViewTabsContainer>
            <TabsContainer>
                <Tab onClick={() => changeView(MatrixViews.jobmatrix)} active={currentView === MatrixViews.jobmatrix}> {lang.jobMatrix}</Tab>
                <Tab onClick={() => changeView(MatrixViews.talentmatrix)} active={currentView === MatrixViews.talentmatrix}> {lang.talentMatrix}</Tab>
            </TabsContainer>
        </MatrixViewTabsContainer >
    )
}

export const AwaitIsPrinting = () => (<AwaitIsPrintingContainer><AwaitIsPrintingIcon>Printing</AwaitIsPrintingIcon></AwaitIsPrintingContainer>)
