import { CountryFlag } from "components/CountryFlag/CountryFlag";
import React from "react";
import styled from "styled-components";
import { Language } from "types/MultiLingualString";
import { languages } from "helpers/language";

export const LanguageTabsContainer = styled.div<{ positionTop?: number; fullWidth?: boolean }>`
    position: absolute;
    top: ${props => props.positionTop ? props.positionTop : "0"}px;
    left: 0;
    height: 44px;
    width: ${props => props.fullWidth ? '100%' : "200"}px;
    `;

export const LanguageTabs = styled.ul<{ fullWidth?: boolean }>`
       margin: 0;
       padding: 0;
       display: flex;
       flex-direction: row;
       align-items: center;

       ${props => props.fullWidth ? `
        li:first-child {
            border-top-left-radius: 0px;
        }
        li:last-child {
            border-top-right-radius: 0px;
        }` : ` li:first-child {
            border-top-left-radius: 8px;
        }
        li:last-child {
            border-top-right-radius: 8px;
        }`}
`;

export const LanguageTabButton = styled.li<{ active?: boolean }>`
    height: 44px;
    width: 50px;

    background-color: ${props => props.active ? '#fff' : '#eee'};

    list-style-type: none;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

interface TranslationTabsProps {
    fullWidth?: boolean
    positionTop?: number;
    setCurrentLanguage: (lang: Language) => void;
    currentLanguage: Language;
}

const TranslationTabs: React.FC<TranslationTabsProps> = (
    { positionTop, currentLanguage, setCurrentLanguage, fullWidth }
) => {

    return (<LanguageTabsContainer positionTop={positionTop} fullWidth={fullWidth}>
        <LanguageTabs>
            {languages.map(lang => (
                <LanguageTabButton key={lang}
                    onClick={() => {
                        setCurrentLanguage(lang);
                    }} active={currentLanguage === lang}><CountryFlag language={lang} /></LanguageTabButton>
            ))}
        </LanguageTabs>
    </LanguageTabsContainer>)
}

export default TranslationTabs;