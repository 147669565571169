import { getUnitColumnLayoutFromData, getFullWidthFromData, getGridColumnLayoutFromData, getGridRowLayoutFromData } from 'helpers/jobmatrix';
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import { ColumnSettings } from 'sections/JobMatrix/JobMatrixResults/JobMatrixResults.components';
import { LocalJobMatrixData, RemoteJobMatrixData } from 'sections/JobMatrix/JobMatrixResults/JobMatrixResults.types';
import styled from 'styled-components';
import theme from 'utils/theme';
import { MatrixColumnHeaderControllerProps, MatrixColumnHeaderProps, MatrixColumnHeaderWithEditorProps, MatrixViews } from './MatrixContent.types';
import { DifferentiatingFactors } from 'types/Organisation';

export const ZoomContainer = styled.div<{ containerSize?: string; }>`
    ${props => props.containerSize};
`;

export const MatrixContentContainer = styled.div<{ levels: string[], currentMatrixView: MatrixViews; initialData: LocalJobMatrixData; localData: LocalJobMatrixData; remoteData: RemoteJobMatrixData; zoom: number; differentiatingFactors: DifferentiatingFactors }>`
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: ${props => getGridColumnLayoutFromData(props.initialData, props.currentMatrixView, props.localData, props.remoteData, props.differentiatingFactors)};
    grid-template-rows: ${props => getGridRowLayoutFromData(props.levels, props.localData)};
    grid-gap: 10px;
    zoom: ${props => props.zoom}%;
`;

export const matrixBoxStyle = `
    position: relative;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
`

export const MatrixBox = styled.div<{ cssSticky?: string; focus?: boolean }>`
    ${matrixBoxStyle}
    ${props => props.cssSticky}
    background: ${props => props.focus ? '#afc8e1' : 'rgb(255, 255, 255)'};
    color: ${props => props.focus ? theme.colors.light : theme.colors.dark};
`;

export const InnerMatrixHeaderBox = styled.div<{ withEditor?: boolean; color?: string; textCenter?: boolean; removePadding?: boolean }>`
display: ${props => props.withEditor ? 'block' : 'flex'};
justify-content:  ${props => props.textCenter ? 'center' : "flex-start"};
    align-items: center;
    text-align:  ${props => props.textCenter ? 'center' : "left"};
    white-space: normal;
    background-color: ${props => props.color ? props.color : "transparent"};
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    & p {
        margin-block-start: 0;
        margin-block-end: 0;
    }
`;

export const InnerMatrixBox = styled(InnerMatrixHeaderBox)`
    padding: ${props => props.removePadding ? '12px' : '20px 12px 12px 20px'};
    width: 100%;
    height: 100%;
    ${props => props.removePadding ? `display: flex; justify-content: center;` : ''};
`

export const InformationHeaderMatrixBox = styled.div<{ color?: string; cssSticky?: string; withEditor?: boolean }>`
    ${matrixBoxStyle}
    height: 100%;
    background-color: ${props => props.color ? props.color : "#3b4b63"};

    &:hover, &:focus, &:active {
        background-color: #6c788a !important;
    }

    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 2;
    box-shadow: rgb(242, 243, 247) -10px -10px 0px, rgb(242, 243, 247) 10px -10px 0px, rgb(242, 243, 247) 10px 10px 0px, rgb(242, 243, 247) -10px 10px 0px;
    ${props => props.cssSticky};
`

export const OrganisationUnitHeaderMatrixBox = styled.div<{ colSpan?: number }>`
    ${matrixBoxStyle};
    display: ${props => props.colSpan === 0 ? 'none' : 'grid'};
    grid-column: span ${props => props.colSpan};
    grid-template-rows: ${props => props.colSpan ? '1fr minmax(64px, auto)' : 'auto'};
    grid-gap: 10px;
    color: white;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #eff4f9;
`

export const OrganisationUnitHeaderUnitContainer = styled.div<{ units: any; localData: LocalJobMatrixData }>`
    display: grid;
    justify-content: center;
    align-items: center;
    gap: 10px;
    grid-template-columns: ${props => getUnitColumnLayoutFromData(props.localData, props.units)};
    overflow: hidden;
    box-shadow: rgb(242,243,247) -10px -10px 0px,rgb(242,243,247) 10px -10px 0px,rgb(242,243,247) 10px 10px 0px,rgb(242,243,247) -10px 10px 0px
`

export const UnitHeader = styled.div`
    padding: 10px;
    text-align: center;
    word-break: all;

    position: relative;
`

export const StyrGroupMatrixBox = styled.div<{ rowSpan: number; color: string; sticky?: string; isSticky?: boolean }>`
    ${matrixBoxStyle}
    ${props => props.sticky}
    padding-top: 8px;
    grid-row: span ${props => props.rowSpan};
    background: ${props => props.color};

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    box-sizing: border-box;
`

export const StyrGroupInnerMatrixBox = styled.div<{ rowSpan?: number }>`
    transform: rotate(-180deg);
    writing-mode: vertical-rl;
    text-align: center;
    text-orientation: mixed;

    font-weight: bold;
    font-size: ${props => props.rowSpan === 1 ? "12px" : "20px"};
`;

export const RowHandlerBox = styled.div<{ sticky?: boolean, printing?: boolean }>`
    background-color: ${props => props.color ? props.color : "#3b4b63"};

    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 6;
    ${props => props.printing ? "" : "box-shadow: rgb(242, 243, 247) -10px -10px 0px, rgb(242, 243, 247) 10px -10px 0px, rgb(242, 243, 247) 10px 10px 0px, rgb(242, 243, 247) -10px 10px 0px"};
`;

export const ControllerBox = styled.div`
    padding: 20px;
`;

export const RenderControllers: React.FC<MatrixColumnHeaderControllerProps> = ({ canChangeWidth, canStick, isTalentPath, controllerHandlers, uuid, stickyState }) => {
    if (canChangeWidth && canStick) {
        return (
            <ColumnSettings
                onClickEnlarge={() => controllerHandlers.enlargeWidthColumn(uuid)}
                onClickShrink={() => controllerHandlers.shrinkWidthColumn(uuid)}
                onClickToggleVisibility={() => controllerHandlers.changeVisibilityColumn(uuid)}
                onClickToggleSticky={() => controllerHandlers.changeStickyColumn(uuid)}
                stickyState={stickyState}
            />
        )
    }

    if (canChangeWidth) {
        return (
            <ColumnSettings
                onClickEnlarge={() => controllerHandlers.enlargeWidthColumn(uuid)}
                onClickShrink={() => controllerHandlers.shrinkWidthColumn(uuid)}
                onClickToggleVisibility={() => controllerHandlers.changeVisibilityColumn(uuid)}
            />
        )
    }

    if (canStick) return (
        <ColumnSettings
            onClickToggleVisibility={() => controllerHandlers.changeVisibilityColumn(uuid)}
            onClickToggleSticky={() => controllerHandlers.changeStickyColumn(uuid)}
            stickyState={stickyState}
        />
    )

    return (
        <ColumnSettings
            onClickToggleVisibility={() => isTalentPath ? controllerHandlers.changeVisibilityTalentpath(uuid) : controllerHandlers.changeVisibilityColumn(uuid)}
        />
    )
}

const RenderTitle: React.FC<{ innerHTML?: boolean; title: string }> = ({ innerHTML, title }) => {
    if (innerHTML) return (<InnerMatrixHeaderBox textCenter dangerouslySetInnerHTML={{ __html: title }} />)
    return (<InnerMatrixHeaderBox textCenter>{title}</InnerMatrixHeaderBox>)
}

export const Header: React.FC<MatrixColumnHeaderProps> = ({ title, canStick, uuid, controllerHandlers, innerHTML, color, stickyState, cssSticky, isTalentPath }) => {
    const [showController, setShowController] = useState(false);

    return (<InformationHeaderMatrixBox
        cssSticky={cssSticky}
        color={color}
        onMouseEnter={() => setShowController(true)}
        onMouseLeave={() => setShowController(false)}>
        {showController ?
            <ControllerBox>
                <RenderControllers isTalentPath={isTalentPath} stickyState={stickyState} canStick={canStick} uuid={uuid} controllerHandlers={controllerHandlers} />
            </ControllerBox> :
            <RenderTitle innerHTML={innerHTML} title={title} />
        }
    </InformationHeaderMatrixBox>)
}


export const HeaderEditable: React.FC<MatrixColumnHeaderWithEditorProps> = ({ title, canStick, onChange, uuid, controllerHandlers, color, stickyState, cssSticky, editMode, canChangeWidth }) => {
    const [showController, setShowController] = useState(false);

    const handleUserInput = (value: string, source: String) => {
        if (source !== "user") {
            return
        }
        onChange(value, 'title');
    };

    const cssStyling = {
        width: '100%',
        marginBottom: 0,
        backgroundColor: '#fff',
        color: '#000',
        padding: '0px !important',
        height: 66,
        overflow: 'hidden'
    }

    if (editMode) return (
        <InformationHeaderMatrixBox color={color} cssSticky={cssSticky} >
            <InnerMatrixBox withEditor removePadding>
                <ReactQuill
                    theme="snow"
                    preserveWhitespace
                    value={title}
                    onChange={
                        (content, _, source) => {
                            handleUserInput(content, source);
                        }
                    }
                    style={cssStyling}
                    modules={
                        { toolbar: false }
                    }
                />
            </InnerMatrixBox>
        </InformationHeaderMatrixBox>
    )

    return (<InformationHeaderMatrixBox
        cssSticky={cssSticky}
        color={color}
        onMouseEnter={() => setShowController(true)}
        onMouseLeave={() => setShowController(false)}>
        {showController ?
            <ControllerBox>
                <RenderControllers canChangeWidth={canChangeWidth} canStick={canStick} stickyState={stickyState} uuid={uuid} controllerHandlers={controllerHandlers} />
            </ControllerBox> :
            <RenderTitle innerHTML title={title} />
        }
    </InformationHeaderMatrixBox>)
}
