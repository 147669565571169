import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';
import {
    changeRole,
    deleteUser,
    updateOrganisationRequest
} from 'store/user/user.actions';
import ManageOrganisationUsers from './ManageOrganisationUsers';

const mapStateToProps = (state: ReduxStore) => ({
    users: state.user.currentOrganisation?.users,
    currentLanguage: state.language.currentLanguage,
    permissions: state.user.permissions
});
const mapDispatchToProps = { deleteUser, updateOrganisationRequest, changeRole };
const ConnectedManageOrganisationUsersList = connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageOrganisationUsers);

export default ConnectedManageOrganisationUsersList;
