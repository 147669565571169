import styled from 'styled-components';
import theme from 'utils/theme';

export const Container = styled.div`
    border-radius: 8px;
    box-shadow: ${theme.boxShadow};
    padding: 10px;
    position: absolute;
    top: 45px;
    right: 0px;
    background-color: #fff;
    z-index: 9999;
`;


export const ImageContainer = styled.a`
    display: block;
    text-decoration: none;
    padding: 5px 10px;
    line-height: 1;
`;
