import styled from "styled-components";
import theme from "utils/theme";

export const Container = styled.div`
    margin-bottom: 10px;
    display: grid;
    grid-gap: 1em;
    background-color: white;
    border-radius: 8px;
    box-shadow: ${theme.boxShadow};
    padding: 15px 20px;
`;
