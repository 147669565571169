import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';
import { AdminAllocatorValidatedProfilesSingle } from './AdminAllocatorValidatedProfilesSingle';
import { deleteValidatedProfile, loadLevels, loadTalentpaths, loadValidatedProfiles, updateValidatedProfile } from 'store/allocator/allocator.actions';
import { getRemoteLanguages } from 'store/language/language.actions';

const mapStateToProps = (state: ReduxStore) => {
    return {
        validatedProfiles: state.allocator.validatedProfiles,
        currentLanguage: state.language.currentLanguage,
        levels: state.allocator.levels,
        talentPaths: state.allocator.talentPaths,
    };
};
const mapDispatchToProps = { loadValidatedProfiles, updateValidatedProfile, getRemoteLanguages, deleteValidatedProfile, loadLevels, loadTalentpaths };

const ConnectedAdminAllocatorValidatedProfilesSingle = connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminAllocatorValidatedProfilesSingle);

export default ConnectedAdminAllocatorValidatedProfilesSingle;
