import styled from 'styled-components';
import theme from 'utils/theme';

export const LoginSectionContainer = styled.div`
    border-radius: 10px;
    box-shadow: ${theme.boxShadow};
    display: grid;
    grid-template-columns: 480px 1fr;
    font-family: 'NunitoSans';
`;

export const LoginFormContainer = styled.div`
    background: white;
    padding: 30px;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 480px;
`;

export const LoginImage = styled.div`
    height: 560px;
    display: grid;
    align-items: end;
    justify-content: end;

    img{
        padding: 10px;
    }
`;

export const LanguageSwitch = styled.div`
    margin-left: auto;
    padding: 2px 8px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
`