import { ResultsPostResult } from "store/results/results.actions";
import Answer from "types/Answer";
import QuestionNode from "types/QuestionNode";
import { History } from 'history';

export const next = (
    questions: QuestionNode[],
    selectedAnswers: Answer[],
    nextQuestion: (question: QuestionNode) => void,
    history: History,
    resultsPostResult: ResultsPostResult
) => () => {
    if (selectedAnswers.length > 0) {
        const bestAnswer = selectedAnswers[0]
        const validatedProfileId = bestAnswer.validatedProfileId;
        if (validatedProfileId) {
            resultsPostResult(validatedProfileId, history)
            return
        }

        const nextQuestionId = bestAnswer.nextQuestionId;
        const question = questions.find(x => x.id === nextQuestionId);
        if (question) {
            nextQuestion(question);
        }
    }
};

export const back = (setUserFeedback: (set: boolean) => void, currentQuestion: QuestionNode, navigate: (route: string) => void, previousQuestion: () => void ) => {
    setUserFeedback(false);
    if (!currentQuestion.isFirstQuestion) return previousQuestion();
    return navigate;
}
