import MainLayout from 'layouts/MainLayout/MainLayout';
import React from 'react';

const StyrManual = () => {
    return (
        <MainLayout><>
            Manual
        </></MainLayout>
    );
};

export default StyrManual;
