import { connect } from 'react-redux';
import {
    setSelectedAnswers,
    nextQuestion,
    previousQuestion,
} from 'store/allocator/allocator.actions';
import ReduxStore from 'store/store.type';
import { resultsPostResult } from 'store/results/results.actions';
import AllocatorQuestions from './AllocatorQuestions';

const mapStateToProps = (state: ReduxStore) => {
    return {
        questions: state.allocator.questions.questions,
        currentQuestion: state.allocator.questions.currentQuestion,
        selectedAnswers: state.allocator.questions.selectedAnswers,
        currentLanguage: state.language.currentLanguage,
        allocatorHistory: state.allocator.questions.history,
    };
};
const mapDispatchToProps = {
    setSelectedAnswers,
    previousQuestion,
    nextQuestion,
    resultsPostResult
};
const ConnectedAllocatorQuestions = connect(
    mapStateToProps,
    mapDispatchToProps
)(AllocatorQuestions);

export default ConnectedAllocatorQuestions;
