import MainLayout from 'layouts/MainLayout/MainLayout';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ConnectedJobFamilyMatrix } from 'sections/JobFamilyMatrix/JobFamilyMatrix';

const JobFamilyMatrixScreen: React.FC = () => {
    const { id } = useParams<{id: string}>();

    return (
        <MainLayout>
            <ConnectedJobFamilyMatrix id={id} />
        </MainLayout>
    )
};

export default JobFamilyMatrixScreen;
