import { connect } from 'react-redux';
import {
    resultChangeResultDetails,
    resultsChangeResult
} from 'store/results/results.actions';
import ReduxStore from 'store/store.type';
import ResultSummary from './ResultSummary';;

const mapStateToProps = (state: ReduxStore) => ({
    currentLanguage: state.language.currentLanguage,
    permissions: state.user.permissions,
    currentOrganisation: state.user.currentOrganisation,
});
const mapDispatchToProps = { resultsChangeResult, resultChangeResultDetails };
const ConnectedResultSummary = connect(
    mapStateToProps,
    mapDispatchToProps
)(ResultSummary);

export default ConnectedResultSummary;
