import styled from "styled-components";

export const ValidatedProfileContainer = styled.div`
    background-color: white;
    border-radius: 8px 8px 0 0;
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 200px);
    overflow-x: auto;

    box-sizing: border-box;
    position: relative;
`

export const SingleValidatedProfileContainer = styled.div`
    display: grid;
    grid-gap: 1em;
    grid-template-columns: 1fr 100px 100px 200px;
    align-items: center;
    cursor: pointer;
    height: 35px;
    border-bottom: 1px solid #E5E5E5;
    padding-left: 4em;
    padding-bottom: 0.5em;
    
    &:hover {
        background-color: #F5F5F5;
    }
`

export const AdminAllocatorValidatedProfilesListContainer = styled.div`
    margin-top: 50px;
`