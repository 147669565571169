import {
    ResultChangeResultDetails,
    ResultsChangeResult
} from 'store/results/results.actions';
import { Permissions } from 'store/user/user.types';
import { Language } from 'types/MultiLingualString';
import { Organisation } from 'types/Organisation';
import { Result } from 'types/Result';

export default interface ResultSummaryProps {
    permissions: Permissions;
    result?: Result;
    id: string;
    currentLanguage: Language;
    resultsChangeResult: ResultsChangeResult;
    resultChangeResultDetails: ResultChangeResultDetails;
    canEdit?: boolean
    currentOrganisation?: Organisation;
}

export type Status = "active" | "inactive" | "concept";
export const statusOptionList: Status[] = ["active", "inactive", "concept"];

export const statusOptions = (status?: string) => {
    return [
        { name: 'Active', value: 'active' },
        { name: 'Inactive', value: 'inactive' },
        { name: 'Concept', value: 'concept' }].sort((a, b) => {
            if (a.name.toLowerCase() === status?.toLowerCase()) {
                return -1;
            }
            return 1;
        });
}

export interface SummaryCardProps {
    title: string;
    text: string
}

export interface LevelCardProps {
    text: string;
    color: string
}
