import styled from 'styled-components';

export const LoginFormContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: space-between;

    & input {
        border-radius: 0;
    }
`;

export const LoginRememberCheckbox = styled.input`
    box-sizing: border-box;
    background-color: initial;
    cursor: default;
    margin: 3px 3px 3px 4px;
    padding: initial;
    border: initial;
`;

export const Link = styled.a`
    padding: 0.5em 0.5em 0.5em 0.5em;
    border: 2px solid #000;
    border-radius: 8px;
    text-align: center;
    color: #000;
    font-size: 14px;
    font-weight: 900;
    
    line-height: 30px;
    text-decoration: none;
`;

export const Row = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    grid-gap: 10px;
`

export const Form = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: 100%;
`