import VersionNumbers from "utils/versionNumbers";
import { LocalJobMatrixData } from "./JobMatrixResults.types";
import { DifferentiatingFactors } from 'types/Organisation';
import { TagTypes } from 'store/results/results.constants';

export const LOCAL_STORAGE_KEY = "styr-job-matrix-" + VersionNumbers.JobMatrix;
export const jobMatrixDefaultColumnWidth = 240;
export const jobMatrixDefaultRowHeight = 240;
export const lang = 200;

// The below data is a local representation of the job matrix, which describes the columns and rows and their initial state.
// Note that later on, some rows/columns did not have changeable widths/sticky-ness anymore. Thus this should be refactored
// So that only the relevant data is stored here.
const initialJobMatrixData: LocalJobMatrixData = {
    rows: [],
    tags: [
        {
            uuid: TagTypes.tagsFutureState,
            shown: true
        },
        {
            uuid: TagTypes.tagsCurrentState,
            shown: true
        },
        {
            uuid: "untagged",
            shown: true
        }
    ],
    columns: [
        {
            index: 0,
            uuid: "freeInputfield",
            shown: true,
            sticky: false,
            width: 300,
        },
        {
            index: 1,
            uuid: "freeInputfield2",
            shown: true,
            sticky: false,
            width: 300,
        },
        {
            index: 2,
            uuid: "freeInputfield3",
            shown: true,
            sticky: false,
            width: 300,
        },
        {
            index: 3,
            uuid: "styrLevel",
            width: 110,
            shown: true,
            sticky: false,
        },
        {
            index: 4,
            uuid: "styrGroup",
            width: 100,
            shown: true,
            sticky: false,
        },
        {
            index: 5,
            uuid: "educationLevel",
            width: 300,
            shown: true,
            sticky: false,
        },
        {
            index: 6,
            uuid: "problemSolvingCapability",
            width: 550,
            shown: true,
            sticky: false,
        },
        {
            index: 7,
            uuid: "planningHorizon",
            width: 180,
            shown: true,
            sticky: false,
        }
    ],
    talentpath: [
        {
            uuid: "talentpathAdministrative",
            shown: true
        },
        {
            uuid: "talentpathAdvisory",
            shown: true
        },
        {
            uuid: "talentpathCommercial",
            shown: true
        },
        {
            uuid: "talentpathExpert",
            shown: true
        },
        {
            uuid: "talentpathManagement",
            shown: true
        },
        {
            uuid: "talentpathProjectManagement",
            shown: true
        },
        {
            uuid: "talentpathSupportCoordination",
            shown: true
        },
        {
            uuid: "talentpathProcessManagement",
            shown: true
        },
        {
            uuid: "talentpathOperationalTechnical",
            shown: true
        }
    ]
}

export const constructInitialJobMatrix = (columns_blacklist: number[], differentiatingFactors: DifferentiatingFactors) => {
    const uuid_dict: { [key: number]: string } = {
        1: "freeInputfield",
        2: "freeInputfield2",
        3: "freeInputfield3"
    }
    const columns_blacklist_uuid = columns_blacklist.map(n => uuid_dict[n])
    let columns = initialJobMatrixData.columns.filter(c => !columns_blacklist_uuid.includes(c.uuid));

    if (!differentiatingFactors.planningHorizon)
        columns = columns.filter(c => c.uuid !== "planningHorizon");
    if (!differentiatingFactors.educationLevel)
        columns = columns.filter(c => c.uuid !== "educationLevel");

    return {
        ...initialJobMatrixData,
        columns: columns,
    }
}



export default initialJobMatrixData;
