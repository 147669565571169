import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs';
import ConnectedBottomNavigation from 'containers/BottomNavigation/BottomNavigation';
import getLanguageObject from 'helpers/language';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Routes from 'routes/Routes.types';
import FilterInputDropdown from 'sections/ResultsFilters/ResultsFilters.components';
import { options } from 'sections/ResultsFilters/ResultsFilters.data';
import { updateJobFamilyByField } from 'store/families/jobfamily.actions';
import { resultsGetResults } from 'store/results/results.actions';
import ReduxStore from 'store/store.type';
import { UpdateFamType, updateOrganisationRequest, updateResultInFamily } from 'store/user/user.actions';
import { Family, Result, Status } from 'types/Result';
import { getLevelIndex } from 'utils/levels';
import { Card, Columns, ConnectedChangeName, ConnectedDeleteItem, Container, FilterForm, Header, ListHeader, ManageButtons, ResultsList } from './JobFamilyEdit.components';
import JobFamilyEditProps, { BaseFilterValues, FilterValues } from './JobFamilyEdit.types';
import { statusOptions } from 'sections/ValidatedProfiles/ResultSummary/ResultSummary.types';

export const JobFamilyEdit: React.FC<JobFamilyEditProps> = ({
    currentLanguage,
    currentOrganisation,
    results,
    resultsGetResults,
    updateResultInFamily,
    updateJobFamilyByField,
    updateOrganisationRequest,
    id
}) => {
    const [filterValues, setFilterValues] = useState<FilterValues>(
        BaseFilterValues
    );

    const lang = getLanguageObject(currentLanguage)
    const jobfamily = currentOrganisation?.jobFamilies?.find(((item: Family) => item.id === id));

    const [activeFamStatus, setActiveFamStatus] = useState(jobfamily?.status || "concept");

    useEffect(() => resultsGetResults(), [
        resultsGetResults,
        currentOrganisation,
    ]);

    useEffect(() => {
        updateOrganisationRequest()
    }, [updateOrganisationRequest]);

    const handleChange = (key: string, value: string | number) => {
        setFilterValues({
            ...filterValues,
            [key]: value,
        });
    };

    if (!currentOrganisation) {
        return (
            <Container>
                <Header>
                    <BreadCrumbs title={lang.manageJobFamilyNotEnabled} paths={
                        [{ title: lang.manage, path: Routes.ManageJobFamily }]
                    } />
                </Header>
            </Container>
        );
    }

    if (!results || !jobfamily) {
        return null; //TODO: show loading
    }

    jobfamily.name = jobfamily.name.replace(/<[^>]*>?/gm, '').replace("&amp;", "&")

    const allResultsInAFamily = currentOrganisation.jobFamilies.flatMap((family) => family.jobs)

    const filteredResults = results.filter(result => {
        return (
            (result.status?.toLowerCase() === filterValues.status.toLowerCase() || filterValues.status === 'Show All') && // Filter on status filter
            (result.level?.toLowerCase() === filterValues.level?.toLowerCase() || filterValues.level === 'Show All') && // Filter on level filter
            (result.organisationUnitId === filterValues.organisationUnit || filterValues.organisationUnit === 'Show All') && // Filter on organisationUnit filter
            (result.validatedProfile.talentPath.key === filterValues.talentPath || filterValues.talentPath === 'Show All') && // Filter on talent path filter
            (result.unitId === filterValues.unit || filterValues.unit === 'Show All') && // Filter on unit filter
            (result.name?.toLowerCase().includes(filterValues.name?.toLowerCase() || "") || filterValues.name?.replace(/\s/g, '') === '') // Filter on name filter
        );
    });

    const jobfamilyResults = jobfamily.jobs || [];
    const resultsWithoutFamily = filteredResults.filter((x) => !allResultsInAFamily.find(y => y?.id === x.id)).sort((a, b) => getLevelIndex(b.level) - getLevelIndex(a.level))
    const resultsInFamily = jobfamilyResults.map((x) => results.find(y => y.id === x.id)).filter(x => Boolean(x)) as Result[];
    const resultsInCurrentFamily = resultsInFamily.sort((a, b) => getLevelIndex(b.level) - getLevelIndex(a.level))


    const handleAddToJobFamily = (resultID: string, action: UpdateFamType) => {
        updateResultInFamily(resultID, jobfamily.id, action)
    }

    const saveResultFamStatus = (status: string) => {
        updateJobFamilyByField(jobfamily.id, "status", status as Status, true, true)
        setActiveFamStatus(status as Status)
    }

    const getLevelDropdownOptions = () => {
        const result = options(currentLanguage, []).level;
        if (currentOrganisation?.enabledStyrLevelSubs) {
            return result.map(level => {
                return {
                    ...level,
                    name: currentOrganisation.alternativeLevels[level.value] || level.name
                }
            })
        }
        return result;
    }

    return (
        <Container>
            <Header>
                <BreadCrumbs title={jobfamily.name} paths={
                    [{ title: lang.manage, path: Routes.ManageJobFamily }, { title: `${lang.edit} ${lang.jobFamily}`, path: Routes.ManageJobFamily }]
                } />
            </Header>
            <Columns>
                <Card>
                    <>
                        <ListHeader>{lang.jobNRole}</ListHeader>
                        <ResultsList canEdit={true} results={resultsWithoutFamily} currentLanguage={currentLanguage} action={handleAddToJobFamily} actionType="add" />
                    </>
                </Card>
                <Card>
                    <>
                        <ListHeader>{jobfamily?.name}</ListHeader>
                        <ResultsList canEdit={true} results={resultsInCurrentFamily} currentLanguage={currentLanguage} action={handleAddToJobFamily} actionType="remove" />
                    </>
                </Card>
            </Columns>
            <ConnectedBottomNavigation>
                <ManageButtons>
                    <FilterInputDropdown
                        label="Status"
                        onChange={(e) => saveResultFamStatus(e.target.value)}
                        options={statusOptions(activeFamStatus)}
                        selected={activeFamStatus.toLowerCase()}
                    />
                    <ConnectedChangeName item={jobfamily} />
                    <ConnectedDeleteItem item={jobfamily} />
                </ManageButtons>
                <FilterForm>
                    <FilterInputDropdown
                        label={
                            // If alternative level is enabled and available
                            (currentOrganisation?.enabledStyrLevelSubs && currentOrganisation.alternativeTitle) ?
                            // Use that title
                            currentOrganisation.alternativeTitle :
                            // Fallback to STYR levels if unavailable
                            "STYR level"
                        }
                        onChange={e => {
                            handleChange('level', e.target.value);
                        }}
                        options={getLevelDropdownOptions()}
                        selected={filterValues.level}
                    />
                    <FilterInputDropdown
                        label={lang.organisationUnit}
                        onChange={e => {
                            handleChange('organisationUnit', e.target.value);
                        }}
                        options={options(currentLanguage, currentOrganisation.organisationUnits).organisationUnit}
                        selected={filterValues.organisationUnit}
                    />
                    <FilterInputDropdown
                        label={lang.unit}
                        onChange={e => {
                            handleChange('unit', e.target.value);
                        }}
                        options={options(currentLanguage, currentOrganisation.organisationUnits).unit}
                        selected={filterValues.unit}
                    />
                    <FilterInputDropdown
                        label={lang.talentpath}
                        onChange={e => {
                            handleChange('talentPath', e.target.value);
                        }}
                        options={options(currentLanguage, currentOrganisation.organisationUnits).talentPath}
                        selected={filterValues.talentPath}
                    />
                </FilterForm>
            </ConnectedBottomNavigation>
        </Container>
    )
}

const mapStateToProps = (state: ReduxStore) => (
    {
        currentLanguage: state.language.currentLanguage,
        currentOrganisation: state.user.currentOrganisation,
        results: state.results.results,
    }
)

const mapDispatchToProps = { resultsGetResults, updateResultInFamily, updateJobFamilyByField, updateOrganisationRequest };

const ConnectedJobFamilyEdit = connect(
    mapStateToProps,
    mapDispatchToProps
)(JobFamilyEdit);


export default ConnectedJobFamilyEdit


