import React from 'react';

const BellIcon: React.FC = () => {
    return (
        <svg 
            aria-hidden="true" 
            focusable="false" 
            data-prefix="fas" 
            data-icon="bell" 
            className="svg-inline--fa fa-bell fa-1x"
            role="img" 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 448 512"
            width="32"
            height="20"
        >
            <path 
                fill="currentColor" 
                d="M439.39 362.29c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.16 133.53-55.47 154.29-6.78 7.25-10.66 17.24-10.61 27.71.11 23.65 19.77 42.5 43.31 42.5H416.8c23.53 0 43.19-18.85 43.3-42.5.04-10.47-3.84-20.46-10.71-27.71zm-175.44 82.2c-16.38 0-31.19 8.62-40.57 21.96-2.1 2.99-2.38 7.11-.7 10.42 1.67 3.31 5.34 5.37 9.27 5.37h83.99c3.92 0 7.6-2.06 9.27-5.37 1.68-3.31 1.4-7.43-.7-10.42-9.38-13.33-24.19-21.96-40.56-21.96z"
            />
        </svg>
    );
}

export default BellIcon;