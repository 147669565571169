import { Dispatch } from 'redux';
import apiRequest from 'helpers/api';
import Update, { ChangedUpdate, NewUpdate } from 'types/Update';
import UpdatesActions from './updates.constants';
import store from 'store/store';
import {handleApiError} from "../../helpers/errorHandler";
import {Domain} from "../../types/Domain";
import {UpdatesErrorTypes} from "./updates.errors";

export const updatesAddError = (error: string) => (dispatch: Dispatch) => {
    return dispatch({
        type: UpdatesActions.AddError,
        payload: {
            error,
        },
    });
};

export const updatesCreateUpdate = (newUpdate: NewUpdate) => (dispatch: Dispatch) => {
    const endpoint = 'updates';
    const state = store.getState();
    apiRequest(endpoint, 'POST', state.user.jwt, newUpdate, {
        currentOrganisation: state.user.currentOrganisation?.id
    }).then(
        (data: Update[]) => {
            return dispatch({
                type: UpdatesActions.GetUpdates,
                payload: {
                    updates: data,
                },
            });
        }
    ).catch((error) => {
        handleApiError(Domain.Updates, UpdatesErrorTypes.updatesCreateUpdate, state.language.currentLanguage)
    });
};

export const updatesGetUpdates = () => (dispatch: Dispatch) => {
    const endpoint = 'updates';
    const state = store.getState();

    if(!state.user.jwt) {
        return
    }

    apiRequest(endpoint, 'GET', state.user.jwt, undefined, {
        currentOrganisation: state.user.currentOrganisation?.id
    }).then(
        (data: Update[]) => {
            return dispatch({
                type: UpdatesActions.GetUpdates,
                payload: {
                    updates: data,
                },
            });
        }
    ).catch((error) => {
        handleApiError(Domain.Updates, UpdatesErrorTypes.updatesGetUpdates, state.language.currentLanguage)
    });
};

export const updatesChangeUpdate = (updatedUpdate: ChangedUpdate) => (dispatch: Dispatch) => {
    const endpoint = 'updates';
    const state = store.getState();
    apiRequest(endpoint, 'PUT', store.getState().user.jwt, updatedUpdate, {
        currentOrganisation: state.user.currentOrganisation?.id
    }).then(
        (data: Update[]) => {
            return dispatch({
                type: UpdatesActions.GetUpdates,
                payload: {
                    updates: data,
                },
            });
        }
    )
        .catch((error) => {
            handleApiError(Domain.Updates, UpdatesErrorTypes.updatesChangeUpdate, state.language.currentLanguage)
        });
};

export const updatesDeleteUpdate = (id: string) => (dispatch: Dispatch) => {
    const endpoint = `updates/${id}`;
    const state = store.getState();
    return apiRequest(endpoint, 'DELETE', store.getState().user.jwt, undefined, {
        currentOrganisation: state.user.currentOrganisation?.id
    }).finally(
        () => {
            //@ts-ignore
            dispatch(updatesGetUpdates());
        }
    ).catch((error) => {
        handleApiError(Domain.Updates, UpdatesErrorTypes.updatesDeleteUpdate, state.language.currentLanguage)
    });
};
