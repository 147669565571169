import { connect } from 'react-redux';
import { loadValidatedProfiles } from 'store/allocator/allocator.actions';
import {
    resultsGetResults
} from 'store/results/results.actions';
import ReduxStore from 'store/store.type';
import { updateOrganisationRequest } from 'store/user/user.actions';
import { getLevels } from 'utils/levels';
import JobMatrixResults from './JobMatrixResults';
import { MatrixViewTabs } from './JobMatrixResults.components';
import { getRemoteLanguages } from 'store/language/language.actions';

const mapStateToProps = (state: ReduxStore) => ({
    currentLanguage: state.language.currentLanguage,
    currentOrganisation: state.user.currentOrganisation,
    remoteData: {
        organisationUnits: state.user.currentOrganisation?.organisationUnits || [],
        results: state.results.results.filter(x => x.status === "active"),
        extraColumns: {
            column1: state.user.currentOrganisation?.matrixLevelNotes,
            column2: state.user.currentOrganisation?.matrixLevelNotes2,
            column3: state.user.currentOrganisation?.matrixLevelNotes3,
        },
        levelOptions: state.user.options.levels
    },
    sidemenuIsOpen: state.navbar.sidebarOpen,
    permissions: state.user.permissions,
    levels: getLevels(state.user.permissions.canNotAccessLevels || []),
    isShareLink: state.user.isShareLink
});
const mapDispatchToProps = { resultsGetResults, updateOrganisationRequest, loadValidatedProfiles, getRemoteLanguages };
const ConnectedJobMatrixResults = connect(
    mapStateToProps, 
    mapDispatchToProps
)(JobMatrixResults);

export default ConnectedJobMatrixResults;

const mapMatrixViewTabsStateToProps = (state: ReduxStore) => ({
    currentLanguage: state.language.currentLanguage,
});
const mapMatrixViewTabsDispatchToProps = {  };
export const ConnectedJobMatrixTabs = connect(
    mapMatrixViewTabsStateToProps, 
    mapMatrixViewTabsDispatchToProps
)(MatrixViewTabs);
