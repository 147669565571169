import NumberTag from 'components/NumberTag/NumberTag';
import { H2 } from 'components/Typography/Typography';
import ConnectedEditJobForm from 'containers/EditJobForm/EditJobForm';
import { InnerMatrixBox, MatrixBox } from 'containers/MatrixContent/MatrixContent.components';
import { JobMatrixCellWithJobsList, MatrixDataContext } from 'containers/MatrixContextProvider/MatrixContextProvider';
import useOnClickOutside from 'helpers/hooks/useOnClick';
import getLanguageObject from 'helpers/language';
import React, { useContext, useRef } from 'react';
import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';
import { Language } from 'types/MultiLingualString';
import { Family, JobTag, Result } from 'types/Result';
import { useScrollChild } from 'utils/scrollToJobByID';
import ConnectedRenderJobItem from './JobItem';
import { EditJobPopup, Job, JobContainer, JobList, JobListBody, JobListBox, JobListHead, JobsList, PopupWithJobs } from './MatrixBoxWithJobs.components';
import MatrixBoxWithJobsProps from './MatrixBoxWithJobs.types';
import { TagTypes } from 'store/results/results.constants';
import FutureIcon from 'components/Icons/FutureIcon';

interface JobListBoxProps extends JobMatrixCellWithJobsList {
    currentLanguage: Language;
    jobFamilies?: Family[];
    results: Result[];
}

export const JobMatrixCellWithJobList: React.FC<JobListBoxProps> = ({ level, results, jobIDs, handleEditJob, currentLanguage, jobFamilies }) => {
    const lang = getLanguageObject(currentLanguage)
    const { editJob, setCurrentCellData } = useContext(MatrixDataContext)
    const jobs = results.filter((item) => jobIDs.includes(item.id)) || []
    const jobData = jobs.find(x => x.id === editJob.id)
    const ref = useRef(null);
    useOnClickOutside(ref, () => setCurrentCellData(null));

    if (jobs.length === 0) return null

    const getFamilyID = (jobId: string) => {
        if (!jobFamilies) return null
        return jobFamilies.filter(f => f.status?.toLowerCase() === "active").filter(f => f.jobs?.find(y => y?.id === jobId)).map(fm => fm.id)[0]
    }

    return (
        <JobListBox>
            <PopupWithJobs ref={ref}>
                <JobsList showEdit={editJob.popup}>
                    <JobListHead>
                        <NumberTag level={level} />
                        <H2>{lang.jobNRole}</H2>
                    </JobListHead>
                    <JobListBody countItems={jobs.length}>
                        {jobs.map((item) => <ConnectedRenderJobItem key={item.id} data={item} familyID={getFamilyID(item.id)} language={currentLanguage} />)}
                    </JobListBody>
                </JobsList>
                <EditJobPopup visible={editJob.popup}>
                    <JobListHead>
                        <H2 style={{padding: '3px'}}>{lang.editJob}</H2>
                    </JobListHead>
                    <JobListBody>
                        {jobData && <ConnectedEditJobForm job={jobData} externalHandler={handleEditJob} />}
                    </JobListBody>
                </EditJobPopup>
            </PopupWithJobs>
        </JobListBox >)
}

const mapJobMatrixCellWithJobListStateToProps = (state: ReduxStore) => ({
    currentLanguage: state.language.currentLanguage,
    jobFamilies: state.user.currentOrganisation?.jobFamilies,
    results: state.results.results
});
const mapJobMatrixCellWithJobListDispatchToProps = {  };
export const ConnectedJobMatrixCellWithJobList = connect(
    mapJobMatrixCellWithJobListStateToProps,
    mapJobMatrixCellWithJobListDispatchToProps
)(JobMatrixCellWithJobList);

export const MatrixBoxWithJobs: React.FC<MatrixBoxWithJobsProps> = ({ level, jobs }) => {
    const { childRef, scrollToId } = useScrollChild();
    const { setEditJob, setCurrentCellData } = useContext(MatrixDataContext)
    const findJobById = jobs.find((x) => x.id === scrollToId)?.id
    const handlePop = () => setEditJob({ id: "", popup: false })
    const handleEditJob = (id: string, state: boolean) => {
        setEditJob({ id, popup: state })
    }

    const cellData = {
        level,
        handleEditJob,
        jobIDs: jobs.map(x => x.id)
    }

    const handleView = () => {
        return setCurrentCellData(cellData);
    }

    const refIfIdMatches = () => {
        if (scrollToId === findJobById) {
            return childRef;
        }
        return null
    }

    return (
        <MatrixBox focus={scrollToId === findJobById} onClick={handleView}>
            <InnerMatrixBox>
                <JobList onClick={handlePop}>
                    {jobs?.map((item, index) => <div ref={refIfIdMatches()} key={(item.id, index)}>
                        <JobContainerWithPrefix hasTag={Boolean(item.tags?.some((x: JobTag) => x.id === TagTypes.tagsFutureState))}>
                            {item.name}
                        </JobContainerWithPrefix>
                    </div>)}
                </JobList>
            </InnerMatrixBox>
        </MatrixBox>
    )
}

const JobContainerWithPrefix = ({ hasTag, children }: { hasTag: boolean, children: React.ReactNode }) => {
    return (
        <JobContainer>
            <Job style={{
                // put the two children side by side on same vertical align
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '5px',
                marginTop: '5px'
            }}>
                <div>{hasTag && <FutureIcon />}</div>
                <div style={{
                    marginTop: '-2px',
                }}>
                    {children}
                </div>
            </Job>
        </JobContainer>
    )
}

export default MatrixBoxWithJobs;
