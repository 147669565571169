import styled from 'styled-components';
import ConnectedQuestion from 'containers/Question/Question';

export const CellQuestion = styled(ConnectedQuestion)`
    height: 100%;
`;

export const Container = styled.div`
    padding-top: 50px;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    max-height: calc(100vh - 130px);
`;

export const ButtonBundles = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
`;

export const Header = styled.div`
    margin: 0 auto;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 960px;
    box-sizing: border-box;
`

export const Content = styled.div<{hasInformation: boolean;}>`
    margin: 0 auto;
    padding: 0 40px;
    width: 100%;
    max-width: 960px;
    height: 100%;
    box-sizing: border-box;
    height: auto;
    overflow: auto;
`

export const ErrorContainer = styled.div`
    margin: 10px auto;
    width: 100%;
    max-width: 960px;
`
