import React from 'react';

const MatrixIcon: React.FC = () => {
    return (
        <svg 
            width="32"
            height="20"
            aria-hidden="true" focusable="false" data-prefix="fas" data-icon="table" className="svg-inline--fa fa-table fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M64 256V160H224v96H64zm0 64H224v96H64V320zm224 96V320H448v96H288zM448 256H288V160H448v96zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z"></path></svg>
    );
}

export default MatrixIcon;