import React from 'react';
import MainLayout from 'layouts/MainLayout/MainLayout';
import { Container } from './Dashboard.components';
import DashboardProps from './Dashboard.types';
import ConnectedDashboardBlocksSection from 'sections/DashboardBlocksSection/DashboardBlocksSection.connector';

const Dashboard: React.FC<DashboardProps> = () => {
    return (
        <MainLayout>
            <Container>
                <ConnectedDashboardBlocksSection />
            </Container>
        </MainLayout>
    );
};

export default Dashboard;