
import Button from 'components/Button/Button';
import getLanguageObject from 'helpers/language';
import React, { useRef, useState } from 'react';
import {
    Container,
} from './InviteNewUser.components';
import InviteNewUserProps, { InviteUserPopups } from './InviteNewUser.types';
import { ConnectedInviteUser } from './InviteNewUser.connector';

const InviteNewUser: React.FC<InviteNewUserProps> = ({
    currentLanguage,
}) => {
    const [popup, setPopup] = useState<InviteUserPopups | null>(null);
    const lang = getLanguageObject(currentLanguage);
    const ref = useRef(null);


    return (
        <Container>
            {popup && <ConnectedInviteUser popup={popup} setPopup={setPopup} />}
            <Button text={lang.inviteExistingUser} onClick={() => setPopup('existing-user')} priority="secondary" />
            <Button text={lang.createUser} onClick={() => setPopup('new-user')} priority="primary" />
        </Container>
    );
};


export default InviteNewUser;