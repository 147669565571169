import styled from "styled-components"
import theme from "utils/theme"

export const Main = styled.section`
    padding: 20px 0 20px 40px;
    display: grid;
    grid-template-columns: minmax(320px, 500px) 1fr;
    grid-gap: 40px;

    @media screen and (max-width: 1500px) {
        grid-template-rows: auto 1fr;
        grid-template-columns: 1fr;
    }
`

export const Row = styled.div`
    display: grid;
    grid-template-columns: 200px minmax(150px, 1fr) 32px 32px;
    grid-gap: 15px;
    align-items: center;
    cursor: pointer;
`;

export const Header = styled.div`
    margin-bottom: 1em;
`;

export const Overlay = styled.div`
    width: 100vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;

    background-color: ${theme.background.overlay};
`

export const Card = styled.div`
    padding: 20px 20px;

    width: 500px;
    max-height: 80vh;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5px;

    background-color: rgba(255,255,255,1);
    box-shadow: 0 0 20px 10px rgba(0,0,0, 0.02);
    border-radius: 5px;
    box-sizing: border-box;
    overflow: scroll;
`
