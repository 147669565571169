import React from 'react';
import styled from 'styled-components';
import theme from 'utils/theme';

export const CompareWithLevelPopUpContainer = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.84);
    opacity: 1 !important;

    animation: fadeIn linear .2s;
    -webkit-animation: fadeIn linear .2s;
    -moz-animation: fadeIn linear .2s;
    -o-animation: fadeIn linear .2s;
    -ms-animation: fadeIn linear .2s;

  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  @-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  @-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  @-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
`;

export const InnerPopup = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    height: fit-content;
    width: 500px;
    overflow: hidden;
    padding: 1rem;
    border-radius: 8px;
    color: ${theme.colors.dark};
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    background-color: white;
    opacity: 1 !important;
`;

export const ListWithLevels = styled.div`
    width: 500px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
`;

export const Navigation = styled.div`
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const LevelOptionsContainer = styled.div<{ selected: boolean; disabled: boolean }>`
    height: 44px;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    border: 1px solid rgba(0,0,0, .16);
    border-radius: 4px;
    background-color: ${props => props.selected ? props.disabled ? '#cfdeed' : theme.colors.green
        : '#e3e8f142'};
    color: ${props => props.selected ? theme.colors.light : theme.colors.dark};
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
`

export const LevelOption: React.FC<{
    isSelected: boolean;
    isCurrentLevel: boolean;
    label: string
}> = ({ label, isSelected, isCurrentLevel }) => {

    return (
        <LevelOptionsContainer selected={isSelected} disabled={isCurrentLevel}>
            {label}
        </LevelOptionsContainer>
    );
};

export const ErrorText = styled.div`
margin: 8px auto;

    color: red;
`;
