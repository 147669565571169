import Button from 'components/AllocatorButton/AllocatorButton';
import React from 'react';
import { getStringFromCurrentLanguage } from 'store/language/language.actions';
import styled from 'styled-components';
import Answer, { Prefix } from 'types/Answer';
import { Language } from 'types/MultiLingualString';
import QuestionNode from 'types/QuestionNode';
import theme from '../utils/theme';
import { getValidatedProfileIcon } from './getTalentpathIcon';
// @ts-ignore react/jsx-filename-extension

export const selectAnswer = async (
    setSelectedAnswers: Function,
    answer: Answer,
) => {
    const newSelectedAnswers = [answer];
    await setSelectedAnswers(newSelectedAnswers);
};

export const deSelectAnswer = async (
    setSelectedAnswers: Function,
    currentSelectedAnswers: Answer[],
    answer: Answer
) => {
    const newSelectedAnswers = currentSelectedAnswers.filter(
        ans => ans.id !== answer.id
    );
    await setSelectedAnswers(newSelectedAnswers);
};

export const renderAnswers = (
    currentQuestion: QuestionNode,
    selectedAnswers: Answer[],
    buttonClick: Function,
    currentLanguage: Language
) => {
    const sortedAnswers = currentQuestion.answers.sort((a, b) => {
        if (a.isGoBack)
            return 1;
        if (b.isGoBack)
            return -1;

        const levelA = a.prefix.text;
        const levelB = b.prefix.text;
        if (!levelA && !levelB) {
            const colorA = a.prefix.color;
            const colorB = b.prefix.color;
            const colorOrder = ['purple', 'yellow', 'orange', 'blue', 'green'];
            if (colorA && colorB) {
                return colorOrder.indexOf(colorA) - colorOrder.indexOf(colorB);
            } else {
                if (a.text[currentLanguage] > b.text[currentLanguage]) return 1;
                if (a.text[currentLanguage] < b.text[currentLanguage]) return -1;
            }
        }

        if (!levelA) return 1;
        if (!levelB) return -1;

        const levelANum = parseInt(levelA.replace(/[^0-9]/g, ''));
        const levelBNum = parseInt(levelB.replace(/[^0-9]/g, ''));
        if (levelANum > levelBNum) return -1;
        if (levelANum < levelBNum) return 1;

        if (a.text[currentLanguage] > b.text[currentLanguage]) return 1;
        if (a.text[currentLanguage] < b.text[currentLanguage]) return -1;

        return 0;
    });

    return sortedAnswers.map(ans => {
        const { color, text }: Prefix = ans.prefix;
        const isSelected = Boolean(selectedAnswers.find(a => a.id === ans.id));
        const isSelectable = true

        const handleClick = () => async () => {
            return buttonClick(ans, currentQuestion)();
        };

        //@ts-ignore
        const talentpath = ans.prefix.icon || '';
        const level = '';
        // check if answer is a go back button then hide icons
        const goBack = ans.isGoBack || false;
        const prefix: boolean = color !== '' || text !== '' || goBack;

        const maybeRenderIcon = () => {
            if (goBack) return;
            if (prefix && color) return <ColorContainer
                background={theme.colorCodePicker(color)}
            >
                <ValidatedProfileIconImage>
                    <img src={getValidatedProfileIcon(talentpath, level)} alt={`${talentpath} ${level}`} />
                </ValidatedProfileIconImage>
                <strong>{text}</strong>
            </ColorContainer>

            return (
                <ValidatedProfileIconImage>
                    <img src={getValidatedProfileIcon(talentpath, level)} alt={`${talentpath} ${level}`} />
                </ValidatedProfileIconImage>
            )
        }

        return (
            <Button
                onClick={handleClick()}
                key={`answer-${ans.id}-${ans.text}`}
                selectable={isSelectable}
                selected={isSelected}
            >
                <>
                    {maybeRenderIcon()}
                    {/* eslint-disable react/no-danger */}
                    <TextContainer
                        dangerouslySetInnerHTML={{
                            __html: getStringFromCurrentLanguage(
                                ans.text,
                                currentLanguage
                            ),
                        }}
                    />
                    {/* eslint-enable react/no-danger */}
                </>
            </Button>
        );
    });
};

export const ValidatedProfileIconImage = styled.figure`
    margin: 10px;
    padding: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #fff;
    img{
        width: 100%;
        aspect-ratio: 1/1;
    }
`;

const TextContainer = styled.div`
    padding: 10px;
`;

const ColorContainer = styled.div<{ background?: string }>`
    background: ${props => props.background};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 120px;
    height: 100%;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    color: #333;
`;
