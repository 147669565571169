import React, { useEffect, useContext, useState } from 'react';

interface ScrollContextType {
    scrollToId: string | null
    setScrollToId: (scrollToId: string | null) => void,
    containerRef: React.RefObject<HTMLDivElement>;
    childRef: React.RefObject<HTMLDivElement>;
}

// Context for storing the containerref and childref
export const ScrollContext = React.createContext<ScrollContextType>({
    scrollToId: null,
    setScrollToId: () => { },
    containerRef: { current: null },
    childRef: { current: null },
});

const useScrollToElement = () => {
    const { containerRef, childRef, setScrollToId } = useContext(ScrollContext);
    const [hasScrolled, setHasScrolled] = useState(false)
    
    const position = childRef.current?.getBoundingClientRect();

    useEffect(() => {
        if (position && position.x !== 0 && position.y !== 0 && !hasScrolled) {
            const node = containerRef.current;
            const container = containerRef.current?.getBoundingClientRect();
            if (node && node instanceof Element && container) {
                setHasScrolled(true)
                const correctionX = container.width;
                node.scrollTo(position.x - correctionX, position.bottom);
            }
        }
    }, [containerRef, hasScrolled, position]);

    return { containerRef, childRef, setScrollToId };
}

export default useScrollToElement;


export const useScrollChild = () => {
    const { childRef, scrollToId } = useContext(ScrollContext);
    return { childRef, scrollToId };
}