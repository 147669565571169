import Button from 'components/Button/Button';
import getLanguageObject from 'helpers/language';
import React, { useCallback, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { connect } from 'react-redux';
import { TextInput } from 'sections/JobFamilyEdit/JobFamilyEdit.components';
import { UpdateJobFamilyByField, updateJobFamilyByField } from 'store/families/jobfamily.actions';
import { resultsChangeResultExtraCompetence, ResultsChangeResultExtraCompetence, resultsChangeResultExtraCompetenceOrder, ResultsChangeResultExtraCompetenceOrder, resultsSaveExtraCompetence, ResultsSaveExtraCompetence, ResultsUpdateResultByField, resultsUpdateResultByField, ResultsUpdateResultExtraCompetence, resultsUpdateResultExtraCompetence } from 'store/results/results.actions';
import ReduxStore from 'store/store.type';
import { Language } from 'types/MultiLingualString';
import { DeleteButton, Description, InnerMatrixBox, MatrixBox, TextArea } from "./JobFamilyMatrix.styling";
import { BoxEditableProps, MatrixBoxEditableProps } from './jobFamilyMatrix.types';
import { IconButton } from 'components/Button/IconButton/IconButton';
import debounce from 'lodash/debounce';
import TrashIcon from 'components/Icons/trash';
import SortArrowUpIcon from 'components/Icons/arrowUp';
import { SortArrowDownIcon } from 'components/Icons/arrowDown';
import "quill-paste-smart";

const EditableFunctionOrRoleTitleComponent: React.FC<{ id: string; title?: string; updateJobFamilyByField: UpdateJobFamilyByField }> = ({ id, title, updateJobFamilyByField }) => {
    const [value, setValue] = useState(title);
    const [saved, setSaved] = useState(true);

    useEffect(() => {
        if (value === title) {
            setSaved(true);
            return;
        }
        const debounce = setTimeout(() => onSave(), 500);
        return () => clearTimeout(debounce);
    }, [value]);

    const onSave = () => {
        updateJobFamilyByField(id, "functionOrRoleTitle", value || '', false, true).finally(() => {
            setSaved(true);
        });
    };

    return (
        <TextArea
            value={value}
            onChange={(e) => {
                setValue(e.target.value);
                setSaved(false);
            }}
            style={{ width: 100, height: 50, minHeight: 50, background: saved ? '' : '#ECE1CB' }}
        />
    );
}
export const EditableFunctionOrRoleTitle = connect(() => ({}), { updateJobFamilyByField })(EditableFunctionOrRoleTitleComponent);

const EditableStyrLevelTitleComponent: React.FC<{ id: string; title?: string; updateJobFamilyByField: UpdateJobFamilyByField }> = ({ id, title, updateJobFamilyByField }) => {
    const [value, setValue] = useState(title);
    const [saved, setSaved] = useState(true);

    useEffect(() => {
        if (value === title) {
            setSaved(true);
            return;
        }
        const debounce = setTimeout(() => onSave(), 500);
        return () => clearTimeout(debounce);
    }, [value]);

    const onSave = () => {
        updateJobFamilyByField(id, "alternativeLevelTitle", value || '', true, true).finally(() => {
            setSaved(true);
        });
    };

    return (
        <TextArea
            value={value}
            onChange={(e) => {
                setValue(e.target.value);
                setSaved(false);
            }}
            style={{ width: 100, height: 50, minHeight: 50, background: saved ? '' : '#ECE1CB' }}
        />
    );
}
export const EditableStyrLevelTitle = connect(() => ({}), { updateJobFamilyByField })(EditableStyrLevelTitleComponent);


const EditableStyrLevelComponent: React.FC<{ id: string; alternativeLevel?: string; resultsUpdateResultByField: ResultsUpdateResultByField }> = ({ id, alternativeLevel, resultsUpdateResultByField }) => {
    const [value, setValue] = useState(alternativeLevel);
    const [saved, setSaved] = useState(true);

    useEffect(() => {
        if (value === alternativeLevel) return;
        const debounce = setTimeout(() => onSave(), 500);
        return () => clearTimeout(debounce);
    }, [value]);

    const onSave = () => {
        resultsUpdateResultByField(id, "alternativeLevel", value || "", true, false).finally(() => {
            setSaved(true);
        })
    }

    const changeHandler = (e: string) => {
        setValue(e);
        setSaved(false);
    };

    return (
        <TextArea
            value={value}
            onChange={(e) => changeHandler(e.target.value)}
            style={{ width: 100, height: 50, minHeight: 50, background: saved ? '' : '#ECE1CB' }}
        />
    )
}
export const EditableStyrLevel = connect(() => ({}), { resultsUpdateResultByField })(EditableStyrLevelComponent)

export const EditableCell: React.FC<MatrixBoxEditableProps> = ({ wysiwyg, id, field, text, whenEmpty, type, placeholder, resultsUpdateResultByField, updateJobFamilyByField, put, bold, currentLanguage, styling }) => {
    const [value, setValue] = useState(text);
    const [saved, setSaved] = useState(true);

    useEffect(() => {
        setValue(text);
    }, [currentLanguage, text]);

    useEffect(() => {
        if (value === text) {
            setSaved(true);
            return;
        }
        const debounce = setTimeout(() => onSave(), 500);
        return () => clearTimeout(debounce);
    }, [value]);

    const onSave = () => {
        const updateFunction = (put === 'family') ? updateJobFamilyByField : resultsUpdateResultByField;
        updateFunction(typeof id === "string" ? id : id.toString(), field, value, undefined, undefined).then(() => {
            setSaved(true);
        })
    }

    const changeHandler = (newValue: string) => {
        setValue(newValue);
        setSaved(false);
    }

    const BoxElement = (type === 'description') ? DescriptionBoxEditable : MatrixBoxEditable;
    return <BoxElement wysiwyg={wysiwyg} whenEmpty={whenEmpty} value={value} placeholder={placeholder} changeHandler={changeHandler} bold={bold} styling={styling} saved={saved} />
}

export const MatrixBoxEditable: React.FC<BoxEditableProps> = ({ multilang, wysiwyg, whenEmpty, value, changeHandler, placeholder, bold, styling, saved }) => {
    const cssStyling = {
        width: '100%',
        display: 'flex',
        'flex-direction': 'column',
        height: 'auto',
        minHeight: '100%',
        marginTop: multilang ? 40 : 0,
        background: saved ? 'none' : '#ECE1CB'
    }

    if (styling.isSticky && !styling.stickyOffset) {
        styling.stickyOffset = 0;
    }

    if (styling.isEditMode) {
        if (wysiwyg) {
            return (
                <MatrixBox styling={styling}>
                    <InnerMatrixBox>
                        <ReactQuill
                            theme='snow'
                            value={value || ""}
                            onChange={(newValue: string) => changeHandler(newValue)}
                            placeholder={placeholder}
                            style={cssStyling}
                            modules={{
                                clipboard: {
                                    matchVisual: false
                                }
                            }}
                        />
                    </InnerMatrixBox>
                </MatrixBox>
            )
        }

        return (
            <MatrixBox styling={styling}>
                <InnerMatrixBox>
                    <TextArea
                        value={value}
                        onChange={(e) => changeHandler(e.target.value)}
                        placeholder={placeholder}
                        style={cssStyling}
                    />
                </InnerMatrixBox>
            </MatrixBox>
        )
    }

    const MaybeRenderHTML = () => {
        if (value) return <InnerMatrixBox bold={bold} dangerouslySetInnerHTML={{ __html: value }} />
        if (whenEmpty) return <InnerMatrixBox bold={bold} dangerouslySetInnerHTML={{ __html: whenEmpty }} />
        return <InnerMatrixBox />
    }

    return (<MatrixBox styling={styling}><MaybeRenderHTML /></MatrixBox>)
}

const DescriptionBoxEditable: React.FC<BoxEditableProps> = ({ whenEmpty, value, changeHandler, placeholder, styling, saved }) => {
    const css = { width: '100%', marginBottom: 0, display: 'flex', 'flex-direction': 'column', background: saved ? 'none' : '#ECE1CB' };

    if (styling.isEditMode) return (
        <Description>
            <ReactQuill
                theme='snow'
                preserveWhitespace
                defaultValue={value || ""}
                onChange={(newValue: string) => changeHandler(newValue)}
                placeholder={placeholder}
                style={css}
                modules={{
                    clipboard: {
                        matchVisual: true
                    }
                }}
            />
        </Description>
    )

    if (value) return <Description dangerouslySetInnerHTML={{ __html: value }} />
    if (whenEmpty) return <Description dangerouslySetInnerHTML={{ __html: whenEmpty }} />
    return <Description />

}

const mapMatrixBoxEditableStateToProps = (state: ReduxStore) => ({
    currentLanguage: state.language.currentLanguage,
    currentOrganisation: state.user.currentOrganisation,
});

const mapMatrixBoxEditableDispatchToProps = { resultsUpdateResultByField, updateJobFamilyByField, resultsUpdateResultExtraCompetence };

export const ConnectedMatrixBoxEditable = connect(
    mapMatrixBoxEditableStateToProps,
    mapMatrixBoxEditableDispatchToProps
)(EditableCell);



interface NewMatrixBoxFormProps {
    id: number | string;
    currentLanguage: Language
    resultsUpdateResultExtraCompetence: ResultsUpdateResultExtraCompetence
}

const NewMatrixBoxForm: React.FC<NewMatrixBoxFormProps> = ({ id, currentLanguage, resultsUpdateResultExtraCompetence }) => {
    const lang = getLanguageObject(currentLanguage)

    const onSave = () => {
        resultsUpdateResultExtraCompetence(typeof id === "string" ? id : id.toString(), "", "")
        return
    }

    return (
        <MatrixBox>
            <InnerMatrixBox>
                <Button text={lang.addExtraCompetence} onClick={onSave} style={{ marginTop: 10 }} />
            </InnerMatrixBox>
        </MatrixBox>

    )
}


const mapNewMatrixBoxFormStateToProps = (state: ReduxStore) => ({
    currentLanguage: state.language.currentLanguage,
    currentOrganisation: state.user.currentOrganisation,
});

const mapNewMatrixBoxFormDispatchToProps = { resultsUpdateResultExtraCompetence };

export const ConnectedNewMatrixBoxForm = connect(
    mapNewMatrixBoxFormStateToProps,
    mapNewMatrixBoxFormDispatchToProps
)(NewMatrixBoxForm);


interface MatrixBoxFormProps {
    id: number | string;
    title: string
    text: string
    editMode: boolean
    position?: number
    totalItemCount?: number
    canDelete?: boolean
    currentLanguage: Language
    resultsChangeResultExtraCompetence: ResultsChangeResultExtraCompetence
    resultsSaveExtraCompetence: ResultsSaveExtraCompetence
    resultsChangeResultExtraCompetenceOrder: ResultsChangeResultExtraCompetenceOrder
}

const MatrixBoxForm: React.FC<MatrixBoxFormProps> = ({ id, title, text, currentLanguage, editMode, position, totalItemCount, canDelete, resultsChangeResultExtraCompetence, resultsSaveExtraCompetence, resultsChangeResultExtraCompetenceOrder }) => {
    const lang = getLanguageObject(currentLanguage)
    const values = {
        title,
        text,
        position,
        totalItemCount,
    }
    const [saved, setSaved] = useState(true);

    const onDelete = () => {
        if (typeof position === "undefined") {
            console.error("Trying to delete to remote but position is undefined")
            return
        }
        resultsSaveExtraCompetence(typeof id === "string" ? id : id.toString(), position, true)
        return
    }

    const saveReOrder = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, oldIndex: number, newIndex: number) => {
        if (typeof totalItemCount === "undefined") {
            return;
        }

        if (newIndex < 0 || newIndex >= totalItemCount || newIndex === oldIndex)
            return;

        if (e.shiftKey) {
            if (newIndex > oldIndex) {
                newIndex = totalItemCount - 1;
            }
            if (newIndex < oldIndex) {
                newIndex = 0;
            }
        }

        resultsChangeResultExtraCompetenceOrder(typeof id === "string" ? id : id.toString(), oldIndex, newIndex);

        return;
    }

    const save = (newValues: {
        title: string,
        text: string
    }) => {
        if (typeof position === "undefined") {
            setSaved(true);
            return
        }

        if (newValues.title === title && newValues.text === text) {
            setSaved(true);
            return
        }

        resultsChangeResultExtraCompetence(typeof id === "string" ? id : id.toString(), newValues.title, newValues.text, position);
        return
    }

    const saveToRemote = () => {
        if (typeof position === "undefined") {
            console.error("Trying to save to remote but position is undefined")
            return;
        }
        resultsSaveExtraCompetence(typeof id === "string" ? id : id.toString(), position, false).finally(() => {
            setSaved(true);
        })
    }
    const debouncedSave = useCallback(debounce(saveToRemote, 250), []); // Delay of 250ms

    const changeHandler = (key: string, e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const { value } = e.target;
        setSaved(false);

        save({
            ...values,
            [key]: value
        })
        debouncedSave()
    }

    const changeEditorHandler = (key: string, value: string) => {
        setSaved(false);
        save({
            ...values,
            [key]: value
        })
        debouncedSave()
    }

    const RenderDeleteButton = () => {
        return (<DeleteButton>
            <IconButton IconSVG={TrashIcon} color={'danger'} onClick={onDelete} />
        </DeleteButton>)
    }

    const RenderSortUpButton = ({ position }: { position: number }) => {
        if (typeof position === "undefined" && typeof totalItemCount === "undefined")
            return;

        return position > 0 ?
            <IconButton IconSVG={SortArrowUpIcon} color={'primary'} onClick={(e) => saveReOrder(e, position, position - 1)} />
            : null;
    };

    const RenderSortDownButton = ({ position }: { position: number }) => {
        if (typeof position === "undefined" && typeof totalItemCount === "undefined")
            return;

        return position < (totalItemCount ?? 0) - 1 ?
            <IconButton IconSVG={SortArrowDownIcon} color={'primary'} onClick={(e) => saveReOrder(e, position, position + 1)} />
            : null
    };

    if (!editMode) return (
        <MatrixBox>
            <InnerMatrixBox>
                <strong>{title}</strong>
                <span dangerouslySetInnerHTML={{
                    __html: text
                }}></span>
            </InnerMatrixBox>
        </MatrixBox>
    );

    const css = {
        width: '100%',
        minHeight: 180,
        marginTop: 5,
        display: 'flex', 'flex-direction': 'column',
        background: saved ? 'none' : '#ECE1CB'
    };

    return (
        <MatrixBox>
            <InnerMatrixBox>
                {canDelete && <RenderDeleteButton />}
                <div style={{
                    display: 'flex'
                }}>
                    {values.position != null && <RenderSortUpButton position={values.position} />}
                    {values.position != null && <RenderSortDownButton position={values.position} />}
                </div>
                <TextInput
                    placeholder={lang.title}
                    value={values.title}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => changeHandler('title', e)}
                    style={{
                        borderColor: "#eee",
                        background: saved ? 'none' : '#ECE1CB'
                    }}
                />
                <ReactQuill
                    theme='snow'
                    placeholder={lang.description}
                    value={values.text}
                    onChange={(e: string, _delta, source) => {
                        if (source != 'user') return
                        changeEditorHandler('text', e)
                    }}
                    style={css}
                    modules={{
                        clipboard: {
                            matchVisual: false
                        }
                    }}
                />
            </InnerMatrixBox>
        </MatrixBox >
    )
}

const mapMatrixBoxFormStateToProps = (state: ReduxStore) => ({
    currentLanguage: state.language.currentLanguage,
});

const mapMatrixBoxFormDispatchToProps = { resultsChangeResultExtraCompetence, resultsSaveExtraCompetence, resultsChangeResultExtraCompetenceOrder };

export const ConnectedMatrixBoxForm = connect(
    mapMatrixBoxFormStateToProps,
    mapMatrixBoxFormDispatchToProps
)(MatrixBoxForm);
