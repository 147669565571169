import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';
import { inviteUser } from 'store/user/user.actions';
import InviteNewUser from './InviteNewUser';
import InviteUser from './InviteUser.components';

const removeDuplicates = (originalArray: any[]) => {
    let newArray = [];
    let lookupObject : any = {};

    for(var i in originalArray) {
       lookupObject[originalArray[i]["id"]] = originalArray[i];
    }

    for(i in lookupObject) {
        newArray.push(lookupObject[i]);
    }
     return newArray;
}

const getPotentialUsersFromState = (state: ReduxStore) => {
    const result = state.user.organisations.flatMap((x) => {
        if(x.id === state.user.currentOrganisation?.id) {
            return [];
        }
        return x.users?.filter(y => y.emailAdress) // Email must be valid.
            .filter(y => !state.user.currentOrganisation?.users.find(z => z.id === y.id)) || []; // Should not be in current organisation
    })
    return removeDuplicates(result);
}

const mapStateToProps = (state: ReduxStore) => ({
    currentLanguage: state.language.currentLanguage
});
const mapDispatchToProps = { inviteUser };
const ConnectedInviteNewUser = connect(
    mapStateToProps,
    mapDispatchToProps
)(InviteNewUser);

export default ConnectedInviteNewUser;

const mapInviteUserStateToProps = (state: ReduxStore) => ({
    users: state.user.currentOrganisation?.users,
    error: state.user.manageOrganisationError,
    currentLanguage: state.language.currentLanguage,
    potentialUsers: getPotentialUsersFromState(state),
    permissions: state.user.permissions
});
const mapInviteUserDispatchToProps = { inviteUser };

export const ConnectedInviteUser = connect(
    mapInviteUserStateToProps,
    mapInviteUserDispatchToProps
)(InviteUser);

