import React from "react";
import styled from "styled-components";

const ProcessingOverlayContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99999;
`

const ProcessingOverlayContentContainer = styled.div`
`

const ProcessingOverlayContent = () => {
    return (
        <ProcessingOverlayContentContainer>
        </ProcessingOverlayContentContainer>
    )
} 

export const ProcessingOverlay = () => {
    return (
        <ProcessingOverlayContainer>
            <ProcessingOverlayContent />
        </ProcessingOverlayContainer>
    )
}