
import { blueLevels, greenLevels, orangeLevels, purpleLevels, turquesLevels, yellowLevels } from 'utils/levels';

const typeNameToImageSrc = (typeName: string) => {
    switch (typeName) {
      case 'talentpathAdministrative':
      case 'talentpathAdvisory':
      case 'talentpathCommercial':
      case 'talentpathExpert':
      case 'talentpathManagement':
      case 'talentpathProjectManagement':
      case 'talentpathSupportCoordination':
      case 'talentpathProcessManagement':
      case 'talentpathOperationalTechnical':
        return typeName.replace('talentpath', '');
      default:
        return '';
    }
  };

export const getValidatedProfileIcon = (typeName?: string, level?: string) => {
    if(!typeName) return require(`../assets/images/icons/talentpath-levels/Default.png`); 

    const type = typeNameToImageSrc(typeName);
    if(!type) return require(`../assets/images/icons/talentpath-levels/Default.png`); 
    if(!level) return require(`../assets/images/icons/talentpath-levels/${type}.png`); 

    if (turquesLevels.includes(level)) return require(`../assets/images/icons/talentpath-levels/${type}Turques.png`);
    if (purpleLevels.includes(level)) return require(`../assets/images/icons/talentpath-levels/${type}Purple.png`);
    if (yellowLevels.includes(level)) return require(`../assets/images/icons/talentpath-levels/${type}Yellow.png`);
    if (orangeLevels.includes(level)) return require(`../assets/images/icons/talentpath-levels/${type}Orange.png`);
    if (blueLevels.includes(level)) return require(`../assets/images/icons/talentpath-levels/${type}Blue.png`);
    if (greenLevels.includes(level)) return require(`../assets/images/icons/talentpath-levels/${type}Green.png`);
    
    return require(`../assets/images/icons/talentpath-levels/${type}.png`);
};
