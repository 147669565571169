import { H2 } from 'components/Typography/Typography';
import getLanguageObject from 'helpers/language';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Routes from 'routes/Routes.types';
import useLocalStorageState from 'use-local-storage-state/dist';
import {
    HeaderIcon,
    LatestUpdatesTableContainer,
    TitleContainer,
    UpdateItem,
    UpdateListContainer
} from './LatestUpdatesTable.components';
import LatestUpdatesTableProps from './LatestUpdatesTable.types';
import Button from 'components/Button/Button';

const LatestUpdatesTable: React.FC<LatestUpdatesTableProps> = ({
    currentLanguage,
    updates,
    updatesGetUpdates,
    canEdit
}) => {
    const lang = getLanguageObject(currentLanguage);
    const history = useHistory();

    const [notification, setNotifications] = useLocalStorageState<string[]>('update-notification', []);

    const navigate = (path: string) => {
        history.push(path);
    };

    const createNewUpdate = () => {
        navigate(Routes.CreateUpdateRoute);
    }

    useEffect(() => {
        updatesGetUpdates();
    }, [updatesGetUpdates]);

    const onClickUpdate = (id: string) => {
        const alreadySaved = notification.includes(id)

        if(alreadySaved) return navigate(Routes.UpdateRoute + id)

        setNotifications([...notification, id])
        return navigate(Routes.UpdateRoute + id)
    }

    const updatelist = updates.map(update => (
        <UpdateItem
            key={update.id}
            date={new Date(update.date)}
            name={update.title}
            onClick={() => update.id && onClickUpdate(update.id)}
        />
    ));
    return (
        <LatestUpdatesTableContainer>
            <TitleContainer>
                <HeaderIcon src={require('../../assets/images/icons/LatestUpdates.svg').default}/><H2>{lang.updates}</H2>
                {canEdit && <Button text={lang.createUpdate} onClick={createNewUpdate} />}
            </TitleContainer>
            <UpdateListContainer>{updatelist}</UpdateListContainer>
        </LatestUpdatesTableContainer>
    );
};

export default LatestUpdatesTable;
