
import MatrixBoxWithJobs from 'containers/MatrixBoxWithJobs/MatrixBoxWithJobs';
import { Header } from 'containers/MatrixContent/MatrixContent.components';
import getLanguageObject from 'helpers/language';
import React from 'react';
import { LocalJobMatrixData } from 'sections/JobMatrix/JobMatrixResults/JobMatrixResults.types';
import { Language } from 'types/MultiLingualString';
import { Result } from 'types/Result';
import theme from 'utils/theme';
import TalentPathDataColumnProps from './TalentPathDataColumn.types';
import { getAvailableLevels } from 'containers/MatrixContent/matrixUtil';


const TalentPathDataColumn: React.FC<TalentPathDataColumnProps> = ({ title, localData, currentLanguage, results, controllerHandlers, levels }) => {
    const localItem = localData.talentpath.find(localDataitem => localDataitem.uuid === title);

    const lang = getLanguageObject(currentLanguage);

    if (localItem && !localItem.shown) {
        return null;
    }

    //@ts-ignore
    const titleColumn = lang[title]
    return (
        <>
            <Header isTalentPath title={titleColumn} uuid={title} controllerHandlers={controllerHandlers} color={theme.colors.darkGrey} />
            <TalentPathColumns levels={levels} results={results} localData={localData} currentLanguage={currentLanguage} />
        </>
    );
};

export default TalentPathDataColumn;

interface TalentPathColumnsProps {
    results: Result[];
    currentLanguage: Language;
    localData: LocalJobMatrixData;
    levels: string[];
}

const TalentPathColumns: React.FC<TalentPathColumnsProps> = ({localData, results, levels }) => {
    const availableLevels = getAvailableLevels(levels, localData);
    return (
        <>
            {availableLevels.map(level => {
                const jobs = results.filter(x => x.level.toLowerCase() === level.toLowerCase());
                return (
                    <MatrixBoxWithJobs key={level} level={level} jobs={jobs} />
                )
            })}
        </>
    )
}
