import { connect } from 'react-redux';
import { resultsChangeResult } from 'store/results/results.actions';
import ReduxStore from 'store/store.type';
import ResultValidatedProfile from './ResultValidatedProfile';
import { getRemoteLanguages } from 'store/language/language.actions';

const mapStateToProps = (state: ReduxStore) => ({
    currentLanguage: state.language.currentLanguage,
    permissions: state.user.permissions,
    currentOrganisation: state.user.currentOrganisation,
});
const mapDispatchToProps = { resultsChangeResult, getRemoteLanguages };
const ConnectedResultValidatedProfile = connect(
    mapStateToProps,
    mapDispatchToProps
)(ResultValidatedProfile);

export default ConnectedResultValidatedProfile;
