import React, { useRef, useState } from 'react';
import { ScrollContext } from 'utils/scrollToJobByID';

const ScrollContextProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const childRef = useRef<HTMLDivElement>(null);
    const [scrollToId, setScrollToId] = useState <string | null>(null);
    return  (
        <ScrollContext.Provider value={{ containerRef, childRef, scrollToId, setScrollToId }}>
            {children}
        </ScrollContext.Provider>
    );
};


export default ScrollContextProvider