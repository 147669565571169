import React from 'react';
import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import Card from 'components/Card/Card';
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs';
import styled from 'styled-components';

const StyledCard = styled.div`
    margin: 1em !important;
    padding: 1em !important;
`;

const Admin: React.FC = () => {
    return (
        <AdminLayout>
            <br />
            <br />
            <BreadCrumbs title="Styr Administrator Dashboard" />
            <StyledCard>
                <Card>
                    <>
                        <p>
                            Welcome to the Styr administrator dashboard.
                        </p>
                        <p>
                            Use the sidebar to navigate to the different admin pages.
                        </p>
                    </>
                </Card>
            </StyledCard>
        </AdminLayout>
    );
};

export default Admin;