import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import GlobalStyle from './assets/globalStyle';
import { msalConfig } from './msal.config';
import store from './store/store';
import Styr from './Styr';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

export const msalInstance = new PublicClientApplication(msalConfig);

const ReduxApplication = () => {
    useEffect(() => {
        const expireStored = localStorage.getItem('styr-expire');
        if (expireStored) {
            const expire = new Date(expireStored);
            if (expire < new Date()) {
                window.addEventListener('beforeunload', () => {
                    localStorage.removeItem('styr-state-2')
                    localStorage.removeItem('styr-state-3')
                })
            }
        }
    }, []);
    return (
        <Provider store={store}>
            <MsalProvider instance={msalInstance}>
                    <GlobalStyle />
                    <Styr />
                    <ToastContainer />
            </MsalProvider>
        </Provider>
    );
};

const container = document.getElementById('root');
if(container) {
    const root = createRoot(container);
    root.render(<ReduxApplication />);
}

