import React, { useState } from 'react';
import styled from 'styled-components';
import { H2 } from 'components/Typography/Typography';
import getLanguageObject, { LangObject } from 'helpers/language';
import { Language } from 'types/MultiLingualString';
import { getValidatedProfileIcon } from 'helpers/getTalentpathIcon';
import Button from 'components/Button/Button';
import ConnectedTranslationTabs from 'containers/TranslationTabs/TranslationTabs.connector';
import XmarkIcon from 'components/Icons/xmark';
import InfoCircleIcon from 'components/Icons/infoCircle';

const Popup: React.FC<PopupProps> = ({ languagesTabs, children, currentLanguage, title, text, icon, buttonText, textEditor, popupState, popupHandler }) => {
    const [state, changeState] = useState(false);
    const switchState = popupHandler ? popupState : state;
    const lang: LangObject = getLanguageObject(currentLanguage);

    const MaybeRenderButton = () => {
        if (popupHandler) return null;
        if (buttonText) return <Button text={buttonText} onClick={handleState} priority="primary" center />

        return (<AlignRight onClick={handleState}>
            <InfoCircleIcon />
        </AlignRight>)
    }

    const handleState = () => {
        if (popupHandler) return popupHandler(!popupState)
        return changeState(!state)
    }

    const Header = () => {
        if (!icon) return (
            <StyledTitle>
                <H2>{title}</H2>
                <CloseButton onClick={handleState}>
                    <XmarkIcon /> {lang.close}
                </CloseButton>
            </StyledTitle>
        )

        return (
            <StyledIconTitle>
                <StyledIcon src={getValidatedProfileIcon(icon)} />
                <H2>{title}</H2>
                <CloseButton onClick={handleState}>
                    <XmarkIcon /> {lang.close}
                </CloseButton>
            </StyledIconTitle>
        )
    }

    const MaybeRenderLanguagesTabs = () => {
        if (languagesTabs) return <ConnectedTranslationTabs positionTop={-44} />
        return null;
    }

    return (
        <>
            <PopUp state={switchState}>
                <InnerPopUp textEditor={textEditor} languagesTabs={languagesTabs}>
                    <Header />
                    <MaybeRenderLanguagesTabs />
                    <OrangeLine />
                    <StyledText
                        dangerouslySetInnerHTML={{ __html: text || '' }}
                    />
                    {children}
                </InnerPopUp>
            </PopUp>
            <MaybeRenderButton />
        </>
    );
};

const PopUp = styled.div<{ state?: boolean; }>`
    display: ${props => props.state ? 'flex' : 'none'};
    position: fixed;
    width: 100%;
    max-width: 100% !important;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 1 !important;
`;

const StyledIconTitle = styled.div`
    display: grid;
    grid-template-columns: 38px 1fr 80px;
    grid-gap: .5em;
`;

const StyledTitle = styled.div`
    display: grid;
    grid-template-columns: 1fr 80px;
    grid-gap: .5em;
`;

const StyledIcon = styled.img`
    width: 40px;
    height: 40px;
`;

const StyledText = styled.p`
    margin: 1em 0;
`;

const CloseButton = styled.button`
    border: none;
    display: grid;
    grid-template-columns: 16px 1fr;
    align-items: center;
    width: 60px;
    margin-top: 8px;
    margin-left: auto;
    margin-bottom: auto;
    background-color: white;
    outline: none;
    font-size: 14px;
    font-weight: 900;
    line-height: 22px;
    &:hover {
        cursor: pointer;
    }
    float: right;
`;

const AlignRight = styled.div`
    display: inline-block;
    width: 5%;
    height: auto;
    text-align: right;
    :hover {
        cursor: pointer;
    }
`;

const OrangeLine = styled.hr`
    height: 1%;
    width: 30%;
    background-color: #f06e64;
    display: block;
    border: 0;
    border-top: 1px solid #f06e64;
    margin: 0 0;
    padding: 0;
`;

const InnerPopUp = styled.div<{ textEditor?: boolean; languagesTabs?: boolean; }>`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    min-height: 350px;
    max-width: 650px;
    width: fit-content;
    padding: 1rem;
    border-radius: ${props => props.languagesTabs ? "0 8px 8px 8px" : "8px"};
    color: #333333;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    background-color: white;
    opacity: 1 !important;

    ${props => props.textEditor ? `
        height: 550px;
        display: grid;
        grid-template-rows: 1fr auto;

    ` : null}
`;

export const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0, .32);
    display: flex;
    justify-content: center;
    align-items: center;
`

interface PopupProps {
    currentLanguage: Language;
    title?: string;
    text?: string;
    icon?: string;
    children?: React.ReactNode;
    buttonText?: string;
    textEditor?: boolean;
    languagesTabs?: boolean;
    popupState?: boolean;
    popupHandler?: (state: boolean) => void;
}

export default Popup;
