import React from 'react';
import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import { useParams } from 'react-router-dom';
import AdminAllocatorQuestionsSingle from 'sections/Admin/AdminAllocatorQuestionsSingle/AdminAllocatorQuestionsSingle.connected';

const AdminAllocatorQuestion: React.FC = () => {
    const { id } = useParams<{id: string}>();
    return (
        <AdminLayout>
            <AdminAllocatorQuestionsSingle id={id} />
        </AdminLayout>
    );
};

export default AdminAllocatorQuestion;