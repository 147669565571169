import { connect } from 'react-redux';
import {
    createValidatedProfile,
    loadValidatedProfiles,
} from 'store/allocator/allocator.actions';
import ReduxStore from 'store/store.type';
import { AdminAllocatorValidatedProfilesList } from './AdminAllocatorValidatedProfilesList';

const mapStateToProps = (state: ReduxStore) => {
    return {
        validatedProfiles: state.allocator.validatedProfiles,
        currentLanguage: state.language.currentLanguage,
    };
};
const mapDispatchToProps = {
    loadValidatedProfiles,
    createValidatedProfile
};
const ConnectedAdminAllocatorValidatedProfilesList = connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminAllocatorValidatedProfilesList);

export default ConnectedAdminAllocatorValidatedProfilesList;
