import React, { useEffect } from 'react';
import { useMsal, useMsalAuthentication } from '@azure/msal-react';
import { InteractionRequiredAuthError, InteractionType } from '@azure/msal-browser';
import ReduxStore from 'store/store.type';
import { userLoginMicrosoft } from 'store/user/user.actions';
import { connect } from 'react-redux';
import { Permissions } from 'store/user/user.types';
import AwaitLoading from 'containers/AwaitLoading/AwaitLoading';
import MainLayoutNoSidebar from 'layouts/MainLayoutNoSidebar/MainLayoutNoSidebar';

const RequireAuth: React.FC<{ children: React.ReactNode, userLoginMicrosoft: Function, isShareLink?: boolean }> = ({ children, userLoginMicrosoft, isShareLink }) => {
    if(isShareLink) return children;
    
    return (
        <RequireAuthAD userLoginMicrosoft={userLoginMicrosoft} isShareLink={isShareLink}>
            {children}
        </RequireAuthAD>
    )
}

const RequireAuthAD: React.FC<{ children: React.ReactNode, userLoginMicrosoft: Function, isShareLink?: boolean }> = ({ children, userLoginMicrosoft, isShareLink }) => {
    const { accounts } = useMsal();

    const tokenRequest = {
        account: accounts[0],
        scopes: [],
        forceRefresh: true,
    }

    const {
        login,
        acquireToken,
        result,
        error
    } = useMsalAuthentication(InteractionType.Redirect, tokenRequest);

    useEffect(() => {
        if(accounts.length === 0) {
            login();
            return
        };
        const refreshToken = () => {
            // Acquire an access token
            acquireToken().then((response) => {
                if(!response) return;
                userLoginMicrosoft(response.idToken)
            }).catch(async (e) => {
                // Catch interaction_required errors and call interactive method to resolve
                if (e instanceof InteractionRequiredAuthError) {
                    login();
                }
                throw e;
            });
        }

        const refreshTokenInterval = setInterval(() => {
            refreshToken();
        }, 60000);

        refreshToken();

        return () => {
            clearInterval(refreshTokenInterval);
        };
    }, [userLoginMicrosoft]);

    const isAuthenticated = error == null && result != null;

    if(!isAuthenticated) return (
        <MainLayoutNoSidebar>
            <AwaitLoading />
        </MainLayoutNoSidebar>
    );

    return <>{children}</>
};

const mapStateToProps = (state: ReduxStore) => ({
    isShareLink: state.user.isShareLink
});

const mapDispatchToProps = { userLoginMicrosoft };
const ConnectedRequireAuth = connect(
    mapStateToProps,
    mapDispatchToProps
)(RequireAuth);

export default ConnectedRequireAuth;


const RequireAdminComponent : React.FC<{children: React.ReactElement, permissions: Permissions}> = ({ children, permissions }) => {
    if(!permissions.canDuplicateOrganisation) return null;

    return children;
}

export const RequireAdmin = connect(
    (state: ReduxStore) => ({permissions: state.user.permissions}),
    {}
)(RequireAdminComponent);