import React from 'react';
import Header from 'containers/Header/Header.connector';
import Sidebar from 'containers/Sidebar/Sidebar.connector';
import { Wrapper, Main, Pages } from './MainLayout.components';
import MainLayoutProps from './MainLayout.types';

const MainLayout: React.FC<MainLayoutProps> = ({
    children,
    scrollPage
}) => {
    return (
        <Wrapper>
            <Sidebar />
            <Main>
                <Header />
                <Pages scrollPage>
                    {children}
                </Pages>
            </Main>
        </Wrapper>
    );
};

export default MainLayout;
