import getLanguageObject from 'helpers/language';
import React from 'react';
import Routes from 'routes/Routes.types';
import { BlocksGrid, DashboardBlocks } from './DashboardBlocksSection.components';
import DashboardBlockSectionProps, { DashboardBlockProps } from './DashboardBlocksSection.types';

interface Block extends DashboardBlockProps {
    show: boolean;
}

const DashboardBlocksSection: React.FC<DashboardBlockSectionProps> = ({isSharedLink, options, currentLanguage}) => {
    const lang = getLanguageObject(currentLanguage);

    const blocks : Block[] = [{
        title: lang.jobTalentMatrix,
        image: require('../../assets/images/JobMatrix.png'),
        navigateToPage: Routes.JobMatrixRoute,
        show: true
    },
    {
        title: lang.startAllocator,
        image: require('../../assets/images/icons/StartAllocator.png'),
        navigateToPage: Routes.AllocatorRoute,
        show: !isSharedLink
    },
    {
        title: lang.searchJobsnRoles,
        image: require('../../assets/images/SearchJobsRoles.png'),
        navigateToPage: Routes.ResultsRoute,
        show: true
    },
    {
        title: lang.manageJobFamily,
        image: require('../../assets/images/icons/JobProfiler.png'),
        navigateToPage: Routes.ManageJobFamily,
        show: Boolean(options?.jobFamilies) && !isSharedLink
    },
    {
        title: lang.elearningStyrModel,
        image: require('../../assets/images/ElearningStyrModel.png'),
        navigateToSite: 'https://styr.nl/e-learning/',
        show: !isSharedLink
    },
    {
        title: lang.talentManagementTool,
        image: require('../../assets/images/TalentManagementTool.png'),
        navigateToSite: options?.tmaLink || undefined,
        show: Boolean(options?.tmaLink) && !isSharedLink
    },
    ]

    return (
        <BlocksGrid>
            {blocks.map((item) => {
                if(!item.show) return null;
                return (<DashboardBlocks
                    key={item.title}
                    title={item.title}
                    image={item.image}
                    navigateToSite={item.navigateToSite}
                    navigateToPage={item.navigateToPage}
                />)
            })}
        </BlocksGrid>
    );
};

export default DashboardBlocksSection;
