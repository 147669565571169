import AllocatorBreadCrumbs from 'components/BreadCrumbs/AllocatorBreadCrumbs';
import Button from 'components/Button/Button';
import { IconButton } from 'components/Button/IconButton/IconButton';
import { H2 } from 'components/Typography/Typography';
import ConnectedBottomNavigation from 'containers/BottomNavigation/BottomNavigation';
import { ErrorMessagesContainer } from 'containers/InputField/InputField.components';
import ConnectedInformation from 'containers/Question/Information';
import getLanguageObject, { LangObject } from 'helpers/language';
import { useNavigate } from 'helpers/useNavigation';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Routes from 'routes/Routes.types';
import { getStringFromCurrentLanguage } from 'store/language/language.actions';
import Answer from 'types/Answer';
import {
    ButtonBundles, CellQuestion, Container, Content, ErrorContainer, Header
} from './AllocatorQuestions.components';
import { AllocatorScreenProps } from './AllocatorQuestions.types';
import { back, next } from './AllocatorQuestions.utils';
import RedoIcon from 'components/Icons/redo';

export const AllocatorQuestions: React.FC<AllocatorScreenProps> = ({
    questions,
    selectedAnswers,
    previousQuestion,
    nextQuestion,
    currentQuestion,
    currentLanguage,
    resultsPostResult,
    allocatorHistory
}) => {
    const { navigate } = useNavigate();
    const history = useHistory();
    const lang: LangObject = getLanguageObject(currentLanguage);
    const [userFeedback, setUserFeedback] = useState<boolean>(false);
    const [breadCrumbs, setBreadcrumb] = useState<string[]>([]);

    const saveAnwsers = () => {
        if (selectedAnswers.length > 0) {
            setUserFeedback(false);
            const getInformationFromAnswer = selectedAnswers.find((x: Answer) => x.info);
            const title =
                getInformationFromAnswer?.info?.title &&
                getStringFromCurrentLanguage(
                    getInformationFromAnswer.info?.title,
                    currentLanguage
                );
            setBreadcrumb([...breadCrumbs, title || ""]);
            next(
                questions,
                selectedAnswers,
                nextQuestion,
                history,
                resultsPostResult
            )();
        } else {
            setUserFeedback(true);
        }
    };

    const previous = () => {
        setBreadcrumb(breadCrumbs.slice(0, breadCrumbs.length - 1));
        back(setUserFeedback, currentQuestion, navigate, previousQuestion)
    }

    const error = () => (
        <ErrorContainer>
            {userFeedback && selectedAnswers.length === 0 && (<ErrorMessagesContainer>{lang.pleaseSelectAnAnswer}</ErrorMessagesContainer>)}
        </ErrorContainer>
    )

    const hasInformation = () => {
        if (!selectedAnswers || selectedAnswers.length === 0)
            return false;

        const getInformationFromAnswer = selectedAnswers.find((x: Answer) => x.info);
        const title =
            getInformationFromAnswer?.info?.title &&
            getStringFromCurrentLanguage(
                getInformationFromAnswer.info?.title,
                currentLanguage
            );

        return title !== '';

    };

    return (
        <Container>
            <AllocatorBreadCrumbs path={breadCrumbs} />
            <Header>
                <H2>
                    {getStringFromCurrentLanguage(
                        currentQuestion.question,
                        currentLanguage
                    )?.replace(/<[^>]*>/g, '')}
                </H2>
            </Header>
            <Content hasInformation={hasInformation()}>
                {questions.length > 0 && <CellQuestion />}
                {error()}
            </Content>
            {selectedAnswers && <ConnectedInformation />}
            <ConnectedBottomNavigation>
                <ButtonBundles>
                    <IconButton color={'warning'} IconSVG={RedoIcon} title="Redo allocator" onClick={() => navigate(Routes.AllocatorRoute)} />
                </ButtonBundles>
                <ButtonBundles>
                    {
                        allocatorHistory.length > 0 && <Button
                            onClick={previous}
                            text={lang.back}
                            priority="secondary"
                        />
                    }
                    <Button
                        onClick={saveAnwsers}
                        text={lang.next}
                        priority="primary"
                    />
                </ButtonBundles>
            </ConnectedBottomNavigation>
        </Container >
    );
};

export default AllocatorQuestions
