import Button, { BackButton } from 'components/Button/Button';
import { H2 } from 'components/Typography/Typography';
import useOnClickOutside from 'helpers/hooks/useOnClick';
import getLanguageObject from 'helpers/language';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';
import { CompareWithLevelPopUpContainer, ErrorText, InnerPopup, LevelOption, ListWithLevels, Navigation } from './CompareWithLevelPopUp.components';
import CompareWithLevelPopUpProps from './CompareWithLevelPopUp.types';
import { getLevelIndex } from 'utils/levels';

const CompareWithLevelPopUp: React.FC<CompareWithLevelPopUpProps> = ({
    results,
    selectedLevels,
    handleSelectedLevel,
    currentLanguage,
    InnerContent,
    goBack,
    currentLevel
}) => {
    const [visible, setVisible] = useState(true);
    const [error, setError] = useState(false);
    const lang = getLanguageObject(currentLanguage);
    const ref = useRef(null);
    useOnClickOutside(ref, () => close());

    const sortedResults = results?.sort((a, b) => {
        const levelA = a.styrLevel.level;
        const levelB = b.styrLevel.level;
        const levelIndexA = getLevelIndex(levelA)
        const levelIndexB = getLevelIndex(levelB)
        return levelIndexB - levelIndexA;
    })

    const close = () => {
        if (selectedLevels.length < 2) return setError(true);
        setVisible(false);
        setError(false);
    };

    const open = () => {
        setVisible(true);
    };

    return (
        <>
            {visible ? (
                <>
                    <div onClick={open}>
                        {InnerContent && <InnerContent />}
                    </div>
                    <CompareWithLevelPopUpContainer>
                        <InnerPopup ref={ref}>
                            <H2>{lang.chooseStyrLevels}</H2>
                            <p>{lang.selectStyrLevelsToCompare}</p>
                            <ListWithLevels>
                                {sortedResults?.map((item) => {
                                    const currentSelectedLevels = selectedLevels.includes(item.styrLevel.level);
                                    return <div key={item.styrLevel.level} onClick={() => handleSelectedLevel(item.styrLevel.level)}>
                                        <LevelOption label={item.styrLevel.level} isSelected={currentSelectedLevels} isCurrentLevel={item.styrLevel.level === currentLevel} />
                                    </div>
                                }
                                )}
                            </ListWithLevels>
                            {error && <ErrorText>{lang.pleaseSelectAtleastTwo}</ErrorText>}
                            <Navigation>
                                <BackButton text={`${lang.backToValidatedProfile}`} onClick={goBack} />
                                <Button
                                    text={lang.changeStyrLevel}
                                    priority="tertiary"
                                    onClick={close}
                                />
                            </Navigation>
                        </InnerPopup>

                    </CompareWithLevelPopUpContainer>
                </>
            ) : (
                <div onClick={open}>
                    {InnerContent && <InnerContent />}
                </div>
            )}
        </>
    );
};


const mapStateToProps = (state: ReduxStore) => ({
    currentLanguage: state.language.currentLanguage,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(CompareWithLevelPopUp);
