import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';
import MatrixBar from './MatrixBar';

const mapStateToProps = (state: ReduxStore) => ({
    cLanguage: state.language.currentLanguage,
    currentOrganisation: state.user.currentOrganisation,
    permissions: state.user.permissions,
    jobs: state.results.results,
});
const mapDispatchToProps = { };
const ConnectedMatrixBar = connect(mapStateToProps, mapDispatchToProps)(MatrixBar);

export default ConnectedMatrixBar;
