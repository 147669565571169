import getLanguageObject from 'helpers/language';
import React, { useCallback, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import initialJobMatrixData from 'sections/JobMatrix/JobMatrixResults/initialJobMatrixData';
import { ColumnSettings } from 'sections/JobMatrix/JobMatrixResults/JobMatrixResults.components';
import { LocalJobMatrixData } from 'sections/JobMatrix/JobMatrixResults/JobMatrixResults.types';
import { getCurrentLanguageId, getObjectFromCurrentLanguageStrict, getStringFromCurrentLanguage } from 'store/language/language.actions';
import { levelNotesFallback, LevelsOptionsArray } from 'store/user/user.types';
import { constructGroupsFromLevels, groupToColor } from 'utils/levels';
import { Header, HeaderEditable, InnerMatrixBox, MatrixBox, RowHandlerBox, StyrGroupInnerMatrixBox, StyrGroupMatrixBox } from './MatrixContent.components';
import { ControllerHandlers, ExtraColumnProps, RowHandlerProps, StyrDataColumnProps, StyrLevelColumnProps } from './MatrixContent.types';
import { getAvailableLevels } from './matrixUtil';
import { debounce } from 'lodash';
import { MatrixLevelNotesObject } from 'types/Organisation';

export const getLocalItem = (localData: LocalJobMatrixData, uuid: string) => {
    return localData.columns.find(x => x.uuid === uuid);
}

export const getStickyOffset = (localData: LocalJobMatrixData, uuid: string) => {
    // to keep in mind the first column is always sticky
    let returnOffset = 60;
    const stickyVisibleColumns = localData.columns
        .filter(x => x.sticky && x.shown)
        .sort((a, b) => a.index - b.index);

    for (let index = 0; index < stickyVisibleColumns.length; index++) {
        const column = stickyVisibleColumns[index];
        if (column.uuid === uuid) {
            break;
        }

        const columnWidth = column.width || 0;
        if (typeof columnWidth === 'number') {
            returnOffset += (columnWidth + 10);
        }
    }
    return returnOffset;
}

const renameKeys = (obj: LevelsOptionsArray) => {
    const keyValues = Object.keys(obj).map(key => {
        const newKey = key.replace("level_", "");
        return { [newKey]: (obj as any)[key] };
    });
    return Object.assign({}, ...keyValues);
}

export const StyrDataColumn: React.FC<StyrDataColumnProps> = ({ textCenter, data, localData, index, title, currentLanguage, uuid, controllerHandlers, levels }) => {
    const withRenamedKeys = renameKeys(data);
    const availableLevels = getAvailableLevels(levels, localData);
    const localItem = getLocalItem(localData, uuid);
    if (localItem && !localItem.shown) {
        return null;
    }

    return (
        <>
            <Header title={title} uuid={uuid} controllerHandlers={controllerHandlers} innerHTML />
            {
                availableLevels.map((level: string) => {
                    //@ts-ignore
                    const resultingData = withRenamedKeys[level][index];
                    return (
                        <MatrixBox key={level}>
                            <InnerMatrixBox
                                textCenter={textCenter}
                                dangerouslySetInnerHTML={{ __html: getStringFromCurrentLanguage(resultingData, currentLanguage) }}
                            />
                        </MatrixBox>
                    )
                })
            }
        </>
    )
}

export const StyrLevelColumn: React.FC<StyrLevelColumnProps> = ({ localData, currentLanguage, controllerHandlers, uuid, levels }) => {
    const lang = getLanguageObject(currentLanguage);
    const availableLevels = getAvailableLevels(levels, localData);
    const localItem = getLocalItem(localData, uuid);
    const stickyOffset = getStickyOffset(localData, uuid);
    const cssStickyHeader = localItem?.sticky ? `position: sticky; left: ${stickyOffset}px; z-index: 6; box-shadow: rgb(242, 243, 247) -10px 0px 0px, rgb(242, 243, 247) 10px 0px 0px;` : '';
    const cssStickyBody = localItem?.sticky ? `position: sticky; left: ${stickyOffset}px; z-index: 5; box-shadow: rgb(242, 243, 247) -10px -10px 0px, rgb(242, 243, 247) 10px -10px 0px, rgb(242, 243, 247) 10px 10px 0px, rgb(242, 243, 247) -10px 10px 0px;` : '';

    if (localItem && !localItem.shown) {
        return null;
    }

    return (
        <>
            <Header title={lang.styrLevel} uuid={uuid} controllerHandlers={controllerHandlers} canStick stickyState={localItem?.sticky} cssSticky={cssStickyHeader} />
            {
                availableLevels.map((level: string) => {
                    return (
                        <MatrixBox key={level} cssSticky={cssStickyBody}>
                            <InnerMatrixBox textCenter><span>{level.toUpperCase()}</span></InnerMatrixBox>
                        </MatrixBox>
                    )
                })
            }
        </>
    )
}

export const StyrGroupColumn: React.FC<StyrDataColumnProps> = ({ title, data, localData, index, currentLanguage, controllerHandlers, uuid, levels }) => {
    const availableLevels = getAvailableLevels(levels, localData);

    const localItem = getLocalItem(localData, uuid);

    const stickyOffset = getStickyOffset(localData, uuid);
    const cssStickyHeader = localItem?.sticky ? `position: sticky; left: ${stickyOffset}px; z-index: 6; box-shadow: rgb(242, 243, 247) -10px 0px 0px, rgb(242, 243, 247) 10px 0px 0px;` : '';
    const cssStickyBody = localItem?.sticky ? `position: sticky; left: ${stickyOffset}px; z-index: 5; box-shadow: rgb(242, 243, 247) -10px -10px 0px, rgb(242, 243, 247) 10px -10px 0px, rgb(242, 243, 247) 10px 10px 0px, rgb(242, 243, 247) -10px 10px 0px;` : '';

    if (localItem && !localItem.shown) {
        return null;
    }
    const groups = constructGroupsFromLevels(levels);
    return (
        <>
            <Header title={title} uuid={uuid} controllerHandlers={controllerHandlers} canStick stickyState={localItem?.sticky} cssSticky={cssStickyHeader} />
            {
                groups.map((levels_i, i) => {
                    const levels = levels_i.filter(s => availableLevels.includes(s))
                    const rowSpan = levels.filter(x => availableLevels.includes(x)).length;
                    if (rowSpan === 0) {
                        return null;
                    }

                    //@ts-ignore
                    const title = getStringFromCurrentLanguage(data['level_' + levels[0]][index], currentLanguage)
                    const color = groupToColor(i)
                    return (
                        <StyrGroupMatrixBox key={levels[0]} isSticky={localItem?.sticky} color={color} rowSpan={rowSpan} sticky={cssStickyBody}>
                            <StyrGroupInnerMatrixBox
                                rowSpan={rowSpan}
                                dangerouslySetInnerHTML={{ __html: title }}
                            />
                        </StyrGroupMatrixBox>
                    )
                })
            }
        </>
    )
}

export const ExtraColumn: React.FC<ExtraColumnProps> = ({ languageState, data, suffix, currentOrganisation, currentLanguage, editMode, localData, controllerHandlers, uuid, levels, updateLevelNotes }) => {
    const indexOfMatrixLevelNotes = "matrixLevelNotes" + (suffix || "");

    const availableLevels = getAvailableLevels(levels, localData);
    const localItem = getLocalItem(localData, uuid);

    const [levelNotes, setLevelNotes] = useState<MatrixLevelNotesObject>(data || levelNotesFallback);
    const dataWithFallback = levelNotes || levelNotesFallback;

    const stickyOffset = getStickyOffset(localData, uuid);
    const cssStickyHeader = localItem?.sticky ? `position: sticky; left: ${stickyOffset}px; z-index: 6; box-shadow: rgb(242, 243, 247) -10px 0px 0px, rgb(242, 243, 247) 10px 0px 0px;` : '';
    const cssStickyBody = localItem?.sticky ? `position: sticky; left: ${stickyOffset}px; z-index: 5; box-shadow: rgb(242, 243, 247) -10px -10px 0px, rgb(242, 243, 247) 10px -10px 0px, rgb(242, 243, 247) 10px 10px 0px, rgb(242, 243, 247) -10px 10px 0px;` : '';

    const currentLanguageId = getCurrentLanguageId(languageState!);

    const cssStyling = {
        width: '100%',
        height: 'calc(100% - 50px)',
        marginBottom: 0,
    }

    const submit = (newValues?: MatrixLevelNotesObject) => {
        if (newValues) {
            updateLevelNotes(newValues, suffix);
            return
        }
        updateLevelNotes(levelNotes, suffix);
    }

    const debouncedSave = useCallback(debounce(submit, 500), []); // Delay of 500ms

    useEffect(() => {
        //@ts-ignore
        if (!currentOrganisation || !currentOrganisation[indexOfMatrixLevelNotes]) return;

        //@ts-ignore
        const levelNotes: MatrixLevelNotesObject = getObjectFromCurrentLanguageStrict(currentOrganisation[indexOfMatrixLevelNotes], currentLanguageId);

        if (!levelNotes) {
            setLevelNotes(levelNotesFallback);
        } else {
            const levelNotesTransformed = Object.keys(levelNotes).reduce((acc, key) => {
                if (key.includes('level')) {
                    const newKey = key.replace('level', '').toLowerCase();
                    //@ts-ignore
                    acc[newKey] = levelNotes[key];
                } else {
                    //@ts-ignore
                    acc[key] = levelNotes[key];
                }
                return acc;
            }, {} as MatrixLevelNotesObject);
            setLevelNotes(levelNotesTransformed);
        }
    }, [indexOfMatrixLevelNotes, currentOrganisation?.id, currentLanguage, languageState?.remoteLanguages]);

    const onChange = (value: string, key?: string) => {
        if (!key) return null

        const newVal = {
            ...levelNotes,
            [key]: value
        }

        setLevelNotes(newVal);
        debouncedSave(newVal);
    }


    const toolbarOptions = [
        ['bold', 'italic'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'align': [] }],
    ];


    if (localItem && !localItem.shown) {
        return null;
    }

    return (
        <>
            <HeaderEditable canChangeWidth={true} editMode={editMode} onSubmit={submit} canStick stickyState={localItem?.sticky} cssSticky={cssStickyHeader} onChange={onChange} title={dataWithFallback["title"]} uuid={uuid} controllerHandlers={controllerHandlers} />
            {
                availableLevels.map((level) => {
                    //@ts-ignore
                    const title = levelNotes[level]
                    if (editMode) return (
                        <MatrixBox key={level} cssSticky={cssStickyBody}>
                            <InnerMatrixBox withEditor>
                                <ReactQuill
                                    theme="snow"
                                    defaultValue={title}
                                    onChange={(e: string) => onChange(e, level)}
                                    style={cssStyling}
                                    modules={
                                        {
                                            toolbar: toolbarOptions,
                                            clipboard: {
                                                matchVisual: false
                                            }
                                        }
                                    }
                                />
                            </InnerMatrixBox>
                        </MatrixBox>
                    )

                    return (
                        <MatrixBox key={level} cssSticky={cssStickyBody}>
                            <div style={{
                                margin: 0,
                                width: '100%',
                                height: '100%',
                            }}>
                                <InnerMatrixBox style={{ display: 'block' }} dangerouslySetInnerHTML={{ __html: title }} />
                            </div>
                        </MatrixBox>
                    )
                })
            }
        </>
    )
}

export const RowHandler: React.FC<RowHandlerProps> = ({ localData, controllerHandlers, levels, printing  }) => {
    const availableLevels = getAvailableLevels(levels, localData);

    return (
        <>
            <RowHandlerBox style={{zIndex: 7}} printing={printing}/>
            {
                availableLevels.map((level: string) => (
                    <RowControllersInnerBox key={level} level={level} controllerHandlers={controllerHandlers} printing={printing} />
                ))}
        </>
    )
}

const RowControllersInnerBox: React.FC<{
    level: string;
    controllerHandlers: ControllerHandlers;
    printing?: boolean;
}> = ({ level, controllerHandlers, printing }) => {
    const [showController, setShowController] = useState(false);

    return (<RowHandlerBox
        printing={printing}
        color={"#fff"}
        key={level}
        onMouseEnter={() => setShowController(true)}
        onMouseLeave={() => setShowController(false)}>
        <InnerMatrixBox removePadding>
            {showController && <ColumnSettings
                onClickToggleVisibility={() => controllerHandlers.changeVisibilityRow(level)}
                vertical
            />}
        </InnerMatrixBox>
    </RowHandlerBox>)
}

export { getAvailableLevels };
