import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';
import {
    userAddError,
    userClearErrors, userSharedLinkLogin
} from 'store/user/user.actions';
import LoginFormSL from './LoginFormSL';

const mapStateToProps = (state: ReduxStore) => ({
    errors: state.user.errors,
    currentLanguage: state.language.currentLanguage});
const mapDispatchToProps = { userSharedLinkLogin, userAddError, userClearErrors };
const ConnectedLoginForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginFormSL);

export default ConnectedLoginForm;