import { IconButton } from 'components/Button/IconButton/IconButton';
import ConnectedBottomNavigation from 'containers/BottomNavigation/BottomNavigation';
import FormDropdown from 'containers/FormDropdown/FormDropdown';
import { MatrixViews } from 'containers/MatrixContent/MatrixContent.types';
import { MatrixDataContext } from 'containers/MatrixContextProvider/MatrixContextProvider';
import getLanguageObject from 'helpers/language';
import moment from 'moment';
import React, { useContext } from "react";
import Routes from 'routes/Routes.types';
import { ConnectedTableZoomController } from 'screens/Matrix/Matrix.components';
import { constructInitialJobMatrix } from 'sections/JobMatrix/JobMatrixResults/initialJobMatrixData';
import { getObjectFromCurrentLanguage, getObjectFromCurrentLanguageStrict, getStringFromCurrentLanguage } from 'store/language/language.actions';
import styled from "styled-components";
import { talentPaths } from 'utils/talentPaths';
import { Filters, MatrixDropUp, MatrixDropUpContent } from './MatrixBar.components';
import MatrixHeaderProps, { DownloadTypes, RenderUnitsOrTalentpathProps } from './MatrixBar.types';
import PrintIcon from 'components/Icons/print';
import EditIcon from 'components/Icons/edit';
import ExpandIcon from 'components/Icons/expandIcon';

const PrintingHeaderContainer = styled.div`
    text-align: right;
    font-weight: 600;
    font-size: 18px;
    width: 100%;
    padding-right: 20px;
`

const Controls = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
`;

export const PrintingHeader = () => {
    var today = moment().format('DD / MM / YYYY');
    return (
        <PrintingHeaderContainer>
            {today}
        </PrintingHeaderContainer>
    )
}

const RenderUnitsOrTalentpath: React.FC<RenderUnitsOrTalentpathProps> = ({
    currentLanguage,
    currentView,
    changeVisibilityTalentpath,
    changeVisibilityOrganisationUnit,
    changeVisibilityColumn,
    getTalentPathOptions,
    getAvailableOrganisation,
    getAvailableOrganisationUnits }) => {
    const lang = getLanguageObject(currentLanguage);

    if (currentView === MatrixViews.talentmatrix) return <FormDropdown
        label="Talentpath"
        onCheckBox={changeVisibilityTalentpath}
        options={getTalentPathOptions()}
        currentLanguage={currentLanguage}
    />

    if (currentView === MatrixViews.jobmatrix && getAvailableOrganisation()) return (
        <>
            <FormDropdown
                label={lang.organisationUnit}
                onCheckBox={changeVisibilityOrganisationUnit}
                options={getAvailableOrganisationUnits()}
                currentLanguage={currentLanguage}
                customWidth={230}
            />
            <FormDropdown
                label={lang.unit}
                onCheckBox={changeVisibilityColumn}
                options={getAvailableOrganisation()}
                currentLanguage={currentLanguage}
                customWidth={230}
            />
        </>)
    return null;
}

const MatrixBar: React.FC<MatrixHeaderProps> = ({
    isFullscreen,
    currentOrganisation,
    language,
    cLanguage,
    downloadMatrix,
    currentView,
    localData,
    remoteData,
    levels,
    changeVisibilityLevel,
    changeVisibilityColumn,
    changeVisibilityTalentpath,
    changeFilterTag,
    permissions,
    jobs,
    isShareLink
}) => {
    const { editMode, setEditMode } = useContext(MatrixDataContext)


    let currentLanguage = language || cLanguage;
    const lang = getLanguageObject(currentLanguage);
    const navigate = (route: Routes) => {
        return window.open(route + currentLanguage, "_blank");
    };
    const changeDisplayMode = () => {
        setEditMode(!editMode);
        return;
    }

    const getColumnOptions = () => {
        // This blacklist is used in the shared links to check if you have access to these particular extra columns. If not, they are not displayed in the grid.
        const blacklist_free_columns: number[] = [];

        if (!permissions.canViewExtraFields?.extraField1) blacklist_free_columns.push(1);
        if (!permissions.canViewExtraFields?.extraField2) blacklist_free_columns.push(2);
        if (!permissions.canViewExtraFields?.extraField3) blacklist_free_columns.push(3);

        if (currentOrganisation === undefined) return [];
        const filteredInitialJobMatrixData = constructInitialJobMatrix(blacklist_free_columns, currentOrganisation?.differentiatingFactors);

        const languageId = lang.id;

        return filteredInitialJobMatrixData.columns.map(level => {
            const localColumns = localData.columns.find(x => x.uuid === level.uuid);
            const tempName = level.uuid;
            //@ts-ignore
            let name = lang[tempName];

            const isFreeInput1 = level.uuid === "freeInputfield"
            const isFreeInput2 = level.uuid === "freeInputfield2"
            const isFreeInput3 = level.uuid === "freeInputfield3"

            if (isFreeInput1) {
                const specificName = remoteData.extraColumns.column1 && getObjectFromCurrentLanguageStrict(remoteData.extraColumns.column1, languageId);
                if (specificName) {
                    name = specificName.title.replace(/<[^>]*>?/gm, '');
                }
            }

            if (isFreeInput2) {
                const specificName = remoteData.extraColumns.column2 && getObjectFromCurrentLanguageStrict(remoteData.extraColumns.column2, languageId);
                if (specificName) {
                    name = specificName.title.replace(/<[^>]*>?/gm, '');
                }
            }

            if (isFreeInput3) {
                const specificName = remoteData.extraColumns.column2 && getObjectFromCurrentLanguageStrict(remoteData.extraColumns.column3, languageId);
                if (specificName) {
                    name = specificName.title.replace(/<[^>]*>?/gm, '');
                }
            }

            return ({
                index: level.index,
                uuid: level.uuid,
                name: name,
                shown: localColumns ? localColumns.shown : true,
            })
        })
    }

    const getTalentPathOptions = () => {
        return talentPaths.map(talentpath => {
            const localColumns = localData.talentpath.find(x => x.uuid === talentpath);
            return ({
                uuid: talentpath,
                //@ts-ignore
                name: lang[talentpath],
                shown: localColumns ? localColumns.shown : true,
            })
        })
    }

    const getRowOptions = () => {
        return levels.map(level => {
            const localRow = localData.rows.find(x => x.uuid === level);
            return ({
                uuid: level,
                name: level,
                shown: localRow ? localRow.shown : true
            })
        })
    }

    const getAvailableOrganisation = () => {
        return currentOrganisation?.organisationUnits?.flatMap(x => x.units).map(unit => {
            const localColumns = localData.columns.find(x => x.uuid === unit.id);
            return ({
                index: localColumns ? localColumns.index : 0,
                uuid: unit.id,
                name: getStringFromCurrentLanguage(unit.name, currentLanguage).replace(/<[^>]*>?/gm, '').replace("&amp;", "&"),
                shown: localColumns ? localColumns.shown : true
            })
        }) || []
    }

    const getAvailableOrganisationUnits = () => {
        return currentOrganisation?.organisationUnits?.map(organisationUnit => {
            let shown = false

            organisationUnit.units.forEach(unit => {
                const localColumn = localData.columns.find(x => x.uuid === unit.id);
                if (localColumn) {
                    if (localColumn.shown) {
                        // Found a unit in this organisationUnit that has stored in localData that it is shown
                        shown = true;
                    }
                } else {
                    // Found a unit in this organisationUnit that is not in localData. Since default is shown, the unit is shown.
                    shown = true;
                }
            });

            return ({
                uuid: organisationUnit.id,
                name: getStringFromCurrentLanguage(organisationUnit.name, currentLanguage).replace(/<[^>]*>?/gm, '').replace("&amp;", "&"),
                shown: shown
            })
        }) || []
    }

    const changeVisibilityOrganisationUnit = (organisationUnitUUID: string) => {
        const organisationUnit = currentOrganisation?.organisationUnits?.find(x => x.id === organisationUnitUUID);
        const organisationUnitShown = getAvailableOrganisationUnits().find(x => x.uuid === organisationUnitUUID)?.shown;

        if (!organisationUnit) {
            return;
        }

        organisationUnit.units.forEach((unit) => {
            changeVisibilityColumn(unit.id, !organisationUnitShown);
        })
    }
    const canEditJobMatrix = permissions.canEditJobMatrix

    const getTags = () => {
        const seen = new Set();
        const localTags = localData.tags ?? [];
        const untagged = localTags.find(x => x.uuid === "untagged")?.shown ?? true;

        const filteredTags = jobs.flatMap(job => job.tags)
            .reduce((acc, tag) => {
                if (tag && !seen.has(tag.id)) {
                    seen.add(tag.id);
                    const localTag = localTags.find(x => x.uuid === tag.id);
                    const shown = localTag ? localTag.shown : true;
                    acc.push({
                        uuid: tag.id,
                        //@ts-ignore
                        name: lang["tags" + tag.name],
                        shown
                    });
                }
                return acc;
            }, [] as { uuid: string, name: string, shown: boolean }[]);

        // temporarly removed untagged, weel need to add it back in the future when more tags are added
        // if (!seen.has("untagged") && jobs.some(job => job?.tags?.length === 0)) {
        //     filteredTags.push({
        //         uuid: "untagged",
        //         name: lang.tagsUntagged,
        //         shown: untagged
        //     });
        // }

        return filteredTags;
    }

    return (
        <ConnectedBottomNavigation isFullscreen={isFullscreen}>
            <Controls>
                <ConnectedTableZoomController />
                {canEditJobMatrix && <IconButton IconSVG={EditIcon} onClick={changeDisplayMode} color={!editMode ? "warning" : "warningSticky"} />}
                {!isShareLink && <MatrixDropUp>
                    <IconButton IconSVG={PrintIcon} color="warning" onClick={() => ""} />
                    <MatrixDropUpContent>
                        <div onClick={() => downloadMatrix(DownloadTypes.Image)}>Image</div>
                        <div onClick={() => downloadMatrix(DownloadTypes.PDF)}>PDF</div>
                    </MatrixDropUpContent>
                </MatrixDropUp>}
                {!isShareLink && <IconButton
                    IconSVG={ExpandIcon}
                    color="success"
                    onClick={() => {
                        if (currentView === MatrixViews.jobmatrix) {
                            navigate(Routes.JobMatrixPrint)
                        } else {
                            navigate(Routes.TalentMatrixPrint)
                        }
                    }
                    }
                />}
            </Controls>
            <Filters>
                <FormDropdown
                    label={lang.filterTags}
                    onCheckBox={changeFilterTag}
                    options={getTags()}
                    currentLanguage={currentLanguage}
                    customWidth={130}
                />
                <FormDropdown
                    label={lang.styrLevel}
                    onCheckBox={changeVisibilityLevel}
                    options={getRowOptions()}
                    currentLanguage={currentLanguage}
                    customWidth={130}
                    levels
                />
                <FormDropdown
                    label={lang.columns}
                    onCheckBox={changeVisibilityColumn}
                    options={getColumnOptions()}
                    currentLanguage={currentLanguage}
                    customWidth={240}
                />
                <RenderUnitsOrTalentpath
                    currentView={currentView}
                    changeVisibilityTalentpath={changeVisibilityTalentpath}
                    changeVisibilityOrganisationUnit={changeVisibilityOrganisationUnit}
                    changeVisibilityColumn={changeVisibilityColumn}
                    getTalentPathOptions={getTalentPathOptions}
                    getAvailableOrganisation={getAvailableOrganisation}
                    currentLanguage={currentLanguage}
                    getAvailableOrganisationUnits={getAvailableOrganisationUnits}
                />
            </Filters>
        </ConnectedBottomNavigation>
    );
};

export default MatrixBar;
