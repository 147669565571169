import styled from 'styled-components';
import theme from 'utils/theme';

export const Icon = styled.div`
    color: #000;
    position: absolute;
    top: 42%;
    right: 26px;
    transform: translateX(-50%);
`;

export const SelectImage = styled.input`
    overflow: hidden;
    width: 0;
`;

export const SelectImageButton = styled.label`
    padding: 6px 14px;
    background-color: transparent;
    color: rgb(69, 71, 92);
    border: 2px solid rgb(69, 71, 92);
    border-radius: 4px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    &:hover {
        opacity: 0.64;
    }
`;

export const FormContainer = styled.div`
    margin-top: 20px;
    display: grid;
    grid-template-columns: 180px 1fr;
    grid-gap: 1em;
    align-items: center;
`

export const Content = styled.div`
    display: grid;
    grid-template-rows: auto 35px 60px auto auto;
`;
