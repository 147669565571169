import getLanguageObject from "helpers/language";
import React, { FC, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import ReduxStore from "store/store.type";
import { searchInCurOrganisation, SearchResult } from "utils/searchResults";
import ConnectedSearchResults from "./SearchResultsList.component";
import { Container, TextField, Wrapper } from "./styling";
import { SearchbarProps } from "./types";
import useOnClickOutside from "helpers/hooks/useOnClick";
import SearchIcon from "components/Icons/search";
import { loadValidatedProfiles } from "store/allocator/allocator.actions";

const Searchbar: FC<SearchbarProps> = ({ currentOrganisation, currentLanguage, validatedProfiles, loadValidatedProfiles }) => {
    const lang = getLanguageObject(currentLanguage);
    const [value, setValue] = useState('');
    const [searchResult, setSearchResult] = useState<SearchResult>()
    
    const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    }

    const onClickSearchResult = () => {
        setValue('');
        setSearchResult(undefined);
    }

    useEffect(() => {
        loadValidatedProfiles()
    }, [])

    // wait until user is finished typing and cancel previous searches
    useEffect(() => {
      const delayDebounceFn = setTimeout(() => {
        if(value && currentOrganisation) {
            searchInCurOrganisation(value, currentOrganisation).then(searchResult => {
                setSearchResult({
                    ...searchResult,
                    jobs: searchResult.jobs.map(job => {
                        const newValidatedProfileId = job.validatedProfileId;
                        const newValidatedProfile = validatedProfiles.find(profile => profile.id === newValidatedProfileId)!;
                        return ({ ...job, validatedProfile: newValidatedProfile })
                    }),
                })
            })
        }
      }, 500)
  
      return () => clearTimeout(delayDebounceFn)
    }, [value, currentOrganisation])

    const ref = useRef(null);
    useOnClickOutside(ref, () => setValue(""));
  
    const showResults = value && searchResult && (searchResult.jobFamilies.length > 0 || searchResult.jobs.length > 0);

    if (!currentOrganisation) return null;

    return (
        <Wrapper ref={ref}>
            <Container>
                <SearchIcon />
                <TextField
                    name={'general_search'}
                    type={'text'}
                    value={value}
                    placeholder={lang.headerSearchbarPlaceholder}
                    onChange={onChange}
                />
            </Container>
            {showResults && validatedProfiles && validatedProfiles.length > 0 && <ConnectedSearchResults onClickSearchResult={onClickSearchResult} found={searchResult} />}
        </Wrapper>
    )
}

const mapStateToProps = (state: ReduxStore) => ({
    currentLanguage: state.language.currentLanguage,
    currentOrganisation: state.user.currentOrganisation,
    validatedProfiles: state.allocator.validatedProfiles
});

const mapDispatchToProps = { loadValidatedProfiles };
const ConnectedSearchbar = connect(mapStateToProps, mapDispatchToProps)(Searchbar);

export default ConnectedSearchbar;

