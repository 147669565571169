import { Dispatch } from 'redux';
import NavbarActions from './navbar.constants';
import { allocatorRetake } from '../allocator/allocator.actions';

export const navbarAddError = (error: string) => (dispatch: Dispatch) => {
    return dispatch({
        type: NavbarActions.AddError,
        payload: {
            error,
        },
    });
};

export const navbarToggle = () => (dispatch: Dispatch) => {
    return dispatch({
        type: NavbarActions.ToggleNavbar,
    });
};

export const setActiveNavbarItem = (activeNavbarItem: string) => (
    dispatch: Dispatch
) => {
    // HACK: When navigating to page, reset the allocator data
    allocatorRetake()(dispatch);
    return dispatch({
        type: NavbarActions.SetActiveNavbarItem,
        payload: {
            activeNavbarItem,
        },
    });
};
