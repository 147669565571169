import styled from 'styled-components';

export const Header = styled.div`
    margin: 0 auto 20px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    box-sizing: border-box;
`

export const Container = styled.div` 
    padding-top: 50px;
    display: grid;
    grid-template-rows: auto 1fr;

    &:-webkit-scrollbar {
        display: none;
    }
`

export const Main = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`