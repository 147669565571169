import styled from 'styled-components';
import React from 'react';

export const LatestUpdatesTableContainer = styled.div`
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px 0 rgba(167, 178, 195, 0.32);
    padding: 1em;
    overflow: hidden;
`;
export const TitleContainer = styled.div`
    margin-top: 0;
    margin-left: -16px;
    margin-right: -16px;
    padding: 0 1em 1em 1em;
    font-weight: bold;
    box-shadow: 0 2px 4px 0 rgba(167, 178, 195, 0.32);
    display: grid;
    grid-template-columns: 50px 1fr auto;
    align-items: center;
    grid-gap: 1em;
`;
export const UpdateListContainer = styled.div`
    margin-left: -16px;
    margin-right: -16px;
    padding: 0 1em;
    height: 500px;
    overflow-y: scroll;
`;

export const UpdateItemContainer = styled.div`
    margin-left: -16px;
    margin-right: -16px;
    padding: 0.5em 1em;
    color: #333;
    :hover {
        cursor: pointer;
        background-color: #f5f5f5;
    }
`;

export const HeaderIcon = styled.img`
    height: 65px;
    width: auto;
`;

const DateContainer = styled.div`
    font-weight: bold;
    font-size: 0.875em;
    color: #333333;
    height: 20px;
    line-height: 20px;
`;
const TextContainer = styled.div`
    font-weight: normal;
    font-size: 1em;
    color: #333333;
    line-height: 24px;
`;
interface UpdateItemProps {
    date: Date;
    name: string;
    onClick: () => void;
}
export const UpdateItem: React.FC<UpdateItemProps> = ({
    date,
    name,
    onClick,
}) => {
    const month = date.toLocaleString('default', { month: 'long' });
    const day = date.getDate().toLocaleString();
    const year = date.getFullYear();
    return (
        <UpdateItemContainer onClick={onClick}>
            <DateContainer>
                {month} {day}, {year}
            </DateContainer>
            <TextContainer>{name}</TextContainer>
        </UpdateItemContainer>
    );
};
