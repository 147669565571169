import MainLayout from 'layouts/MainLayout/MainLayout';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import ConnectedResultSection from 'sections/ValidatedProfiles/ResultSection/ResultSection.connector';
import ResultProps from './Result.types';

const Result: React.FC<ResultProps> = () => {
    const { id } = useParams<{id: string}>();
    const [popupState, setPopup] = useState(false);
    const popupHandler = () => setPopup(!popupState);

    return (
        <MainLayout scrollPage>
            <ConnectedResultSection popupState={popupState} popupHandler={popupHandler} id={id} />
        </MainLayout>
    );
};

export default Result;
