import styled from "styled-components";

export const QuestionEditContainer = styled.div`
    margin-top: 30px;
    padding: 30px;
    background: white;
    overflow: auto;
    max-height: calc(100vh - 280px);
    padding-left: 4em;
`

export const QuestionSingleContainer = styled.div`
    margin-top: 50px;
`

export const CreateNewContainer = styled.div`
    margin-bottom: 50px;
`

export const DeleteAnswerContainer = styled.div`
    display: grid;
    grid-template-columns: 50px 1fr auto;
    align-items: center;
`

export const QuestionActionsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 450px;
    margin: 25px 0;
`

export const AnswerEditCollapsed = styled.div`
    display: grid;
    grid-template-columns: 50px 1fr;
    align-items: center;
    height: 50px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-top: 1px solid #ccc;
`

export const AnswerEditOpened = styled.div`
    display: grid;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    border-top: 1px solid #ccc;
`