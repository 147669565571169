import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';
import { addOrganisationUnit, addUnit, deleteOrganisationUnit, deleteUnit, updateOrganisationUnit, updateUnit, updateUnitsAndOrganisationUnitsOrder, updateUnitOrder, updateOrganisationRequest, updateOrganisationUnitOrder } from 'store/user/user.actions';
import ManageOrganisationUnits from './ManageOrganisationUnits';
import { OrganisationUnitForm, UnitForm, ChangeOrganisationUnitForm } from './ManageOrganisationUnits.components';
import { getRemoteLanguages } from 'store/language/language.actions';

const mapStateToProps = (state: ReduxStore) => {
    return ({
        currentLanguage: state.language.currentLanguage,
        currentOrganisation: state.user.currentOrganisation,
        canEditOrganisationUnits: state.user.permissions.canEditOrganisationUnits,
    })
};
const mapDispatchToProps = { deleteOrganisationUnit, deleteUnit, updateUnit, updateUnitsAndOrganisationUnitsOrder, updateUnitOrder, updateOrganisationUnitOrder, updateOrganisationRequest };

const ConnectedManageOrganisationUnits = connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageOrganisationUnits);

export default ConnectedManageOrganisationUnits;

const mapOrganisationUnitDispatchToProps = { addOrganisationUnit, getRemoteLanguages };

export const ConnectedOrganisationUnitForm = connect(
    mapStateToProps,
    mapOrganisationUnitDispatchToProps,
)(OrganisationUnitForm);

const mapUnitDispatchToProps = { addUnit };

export const ConnectedUnitForm = connect(
    mapStateToProps,
    mapUnitDispatchToProps
)(UnitForm);

const mapChangeOrganisationUnitFormDispatchToProps = { updateOrganisationUnit };

export const ConnectedChangeOrganisationUnitForm = connect(
    mapStateToProps,
    mapChangeOrganisationUnitFormDispatchToProps
)(ChangeOrganisationUnitForm);
