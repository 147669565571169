import requireAuth, { RequireAdmin } from 'helpers/auth';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Allocator from 'screens/Allocator/Allocator';
import Dashboard from 'screens/Dashboard/Dashboard';
import JobFamilyEditScreen from 'screens/JobFamilyEditScreen/JobFamilyEditScreen';
import JobFamilyMatrixScreen from 'screens/JobFamilyMatrixScreen/JobFamilyMatrixScreen';
import LatestUpdates from 'screens/LatestUpdates/LatestUpdates';
import Login from 'screens/Login/Login';
import LoginSharedLink from 'screens/Login/LoginSL';
import DifferentiatingFactorsConnected from 'screens/ManageJobFamily/DifferentiatingFactors';
import ManageJobFamilyConnected from 'screens/ManageJobFamily/ManageJobFamily';
import Valuation from 'screens/ManageOrganisationValuation/ManageOrganisationValuation';
import ManageShareLinks from 'screens/ManageShareLinks/ManageShareLinks';
import JobMatrix from 'screens/Matrix/JobMatrix';
import JobMatrixPrint from 'screens/Matrix/JobMatrixPrint';
import TalentMatrix from 'screens/Matrix/TalentMatrix';
import TalentMatrixPrint from 'screens/Matrix/TalentMatrixPrint';
import Pdf from 'screens/Pdf/Pdf';
import Result from 'screens/Result/Result';
import Results from 'screens/Results/Results';
import StyrManual from 'screens/StyrManual/StyrManual';
import ManageJobMatrix from '../screens/ManageJobMatrix/ManageJobMatrix';
import ManageOrganisationDetails from '../screens/ManageOrganisationDetails/ManageOrganisationDetails';
import ManageOrganisationUsers from '../screens/ManageOrganisationUsers/ManageOrganisationUsers';
import Update from '../screens/Update/Update';
import Routes from './Routes.types';
import Admin from 'screens/Admin/Admin';
import AdminAllocatorQuestions from 'screens/Admin/AdminAllocatorQuestions/AdminAllocatorQuestions';
import AdminAllocatorValidatedProfiles from 'screens/Admin/AdminAllocatorValidatedProfiles/AdminAllocatorValidatedProfiles';
import AdminAllocatorQuestion from 'screens/Admin/AdminAllocatorQuestion/AdminAllocatorQuestion';
import AdminAllocatorValidatedProfile from 'screens/Admin/AdminAllocatorValidatedProfile/AdminAllocatorValidatedProfile';
import RequireAuth from '../helpers/auth';

const MainRouter = () => (
<Router>
    <Switch>
        <Route path={Routes.DashboardRoute}>
            <RequireAuth>
                <Dashboard />
            </RequireAuth>
        </Route>
        <Route exact path="/">
            <RequireAuth>
                <Dashboard />
            </RequireAuth>
        </Route>
        <Route path={Routes.LoginRoute}>
            <Login />
        </Route>
        {/* Admin Routes */}
        <Route path={Routes.AdminAllocatorQuestionsRoute}>
            <RequireAuth>
                <RequireAdmin>
                    <AdminAllocatorQuestions />
                </RequireAdmin>
            </RequireAuth>
        </Route>
        <Route path={`${Routes.AdminAllocatorQuestionRoute}:id`}>
            <RequireAuth>
                <RequireAdmin>
                    <AdminAllocatorQuestion />
                </RequireAdmin>
            </RequireAuth>
        </Route>
        <Route path={Routes.AdminAllocatorValidatedProfilesRoute}>
            <RequireAuth>
                <RequireAdmin>
                    <AdminAllocatorValidatedProfiles />
                </RequireAdmin>
            </RequireAuth>
        </Route>
        <Route path={`${Routes.AdminAllocatorValidatedProfileRoute}:id`}>
            <RequireAuth>
                <RequireAdmin>
                    <AdminAllocatorValidatedProfile />
                </RequireAdmin>
            </RequireAuth>
        </Route>
        <Route path={Routes.AdminRoute}>
            <RequireAuth>
                <RequireAdmin>
                    <Admin />
                </RequireAdmin>
            </RequireAuth>
        </Route>
        {/* Allocator Routes */}
        <Route path={Routes.AllocatorRoute}>
            <RequireAuth>
                <Allocator />
            </RequireAuth>
        </Route>
        <Route path={`${Routes.ResultRoute}:id`}>
            <RequireAuth>
                <Result />
            </RequireAuth>
        </Route>
        <Route path={Routes.ResultsRoute}>
            <RequireAuth>
                <Results />
            </RequireAuth>
        </Route>
        <Route path={`${Routes.PdfRoute}:id/:language`}>
            <RequireAuth>
                <Pdf />
            </RequireAuth>
        </Route>
        <Route path={`${Routes.PdfRoute}:id`}>
            <RequireAuth>
                <Pdf />
            </RequireAuth>
        </Route>
        <Route path={`${Routes.ResultPreviewRoute}:id`} />
        <Route path={`${Routes.JobMatrixPrint}:language`}>
            <RequireAuth>
                <JobMatrixPrint />
            </RequireAuth>
        </Route>
        <Route path={`${Routes.TalentMatrixPrint}:language`}>
            <RequireAuth>
                <TalentMatrixPrint />
            </RequireAuth>
        </Route>
        <Route path={Routes.ValuationRoute}>
            <Valuation />
        </Route>
        <Route path="/update/:id">
            <RequireAuth>
                <Update />
            </RequireAuth>
        </Route>
        <Route path={Routes.CreateUpdateRoute}>
            <RequireAuth>
                <Update />
            </RequireAuth>
        </Route>
        <Route path={Routes.LatestUpdates}>
            <RequireAuth>
                <LatestUpdates />
            </RequireAuth>
        </Route>
        <Route path={Routes.StyrManual}>
            <RequireAuth>
                <StyrManual />
            </RequireAuth>
        </Route>

        {/* Manage organisation */}
        <Route path={Routes.ManageOrganisationUsers}>
            <RequireAuth>
                <ManageOrganisationUsers />
            </RequireAuth>
        </Route>
        <Route path={Routes.ManageOrganisationDetails}>
            <RequireAuth>
                <ManageOrganisationDetails />
            </RequireAuth>
        </Route>

        <Route path={`${Routes.ShardRoute}:id`}>
            <LoginSharedLink />
        </Route>
        <Route path={Routes.ManageShareLinks}>
            <RequireAuth>
                <ManageShareLinks />
            </RequireAuth>
        </Route>

        {/* Matrix */}
        <Route path={Routes.ManageJobMatrix}>
            <RequireAuth>
                <ManageJobMatrix />
            </RequireAuth>
        </Route>
        <Route path={Routes.JobMatrixRoute}>
            <RequireAuth>
                <JobMatrix />
            </RequireAuth>
        </Route>
        <Route path={Routes.TalentMatrix}>
            <RequireAuth>
                <TalentMatrix />
            </RequireAuth>
        </Route>

        <Route path={Routes.ManageJobFamily}>
            <RequireAuth>
                <ManageJobFamilyConnected />
            </RequireAuth>
        </Route>
        <Route path={`${Routes.JobFamilyEdit}:id`}>
            <RequireAuth>
                <JobFamilyEditScreen />
            </RequireAuth>
        </Route>
        <Route path={`${Routes.JobFamilyView}:id`}>
            <RequireAuth>
                <JobFamilyMatrixScreen />
            </RequireAuth>
        </Route>
        <Route path="*">
            <Login />
        </Route>
    </Switch>
</Router>
);

export default MainRouter;
