import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { loadQuestions } from 'store/allocator/allocator.actions';
import { updateOrganisationRequest } from 'store/user/user.actions';
import MainLayout from 'layouts/MainLayout/MainLayout';
import AllocatorSetup from 'sections/Allocator/AllocatorSetup/AllocatorSetup';
import ReduxStore from 'store/store.type';
import AllocatorProps from './Allocator.types';
import ConnectedAllocatorQuestions from 'sections/Allocator/AllocatorQuestions/AllocatorQuestions.connected';
import AwaitLoading from 'containers/AwaitLoading/AwaitLoading';
import Profile from 'types/Profile';
import QuestionNode from 'types/QuestionNode';

const MaybeRenderInitAllocator : React.FC<{
    profile?: Profile;
    questions: QuestionNode[];
    currentQuestion?: QuestionNode;
}>= ({profile, questions, currentQuestion}) => {
    if (!questions || !currentQuestion) return <AwaitLoading />
    if (profile) return <ConnectedAllocatorQuestions />

    return <AllocatorSetup />
}

const Allocator: React.FC<AllocatorProps> = ({
    profile,
    loadQuestions,
    questions,
    updateOrganisationRequest,
    currentQuestion
}) => {

    useEffect(() => {
        if(!questions || questions.length === 0) {
            loadQuestions();
        }
    }, [loadQuestions]);

    useEffect(() => {
        updateOrganisationRequest();
    }, [updateOrganisationRequest])

    return (
        <MainLayout>
            <MaybeRenderInitAllocator profile={profile} questions={questions} currentQuestion={currentQuestion} />
        </MainLayout>
    );
};

const mapStateToProps = (state: ReduxStore) => ({
    profile: state.allocator.profile,
    currentOrganisation: state.user.currentOrganisation,
    questions: state.allocator.questions.questions,
    currentQuestion: state.allocator.questions.currentQuestion
});

const mapDispatchToProps = {
    loadQuestions,
    updateOrganisationRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(Allocator);
