import React from 'react';
import QuestionNode from 'types/QuestionNode';
import Answer from 'types/Answer';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
    setSelectedAnswers,
} from 'store/allocator/allocator.actions';
import ReduxStore from 'store/store.type';
import {
    selectAnswer,
    deSelectAnswer,
    renderAnswers,
} from 'helpers/questionsButtonHelper';
import { Language } from 'types/MultiLingualString';

export const Question: React.FC<QuestionProps> = ({
    currentQuestion,
    selectedAnswers,
    currentLanguage,
    setSelectedAnswers,
}) => {
    const buttonClick = (
        answer: Answer,
        currentQuestion: QuestionNode
    ) => async () => {
        if (!selectedAnswers.includes(answer)) {
            await selectAnswer(
                setSelectedAnswers,
                answer,
            );
        } else {
            await deSelectAnswer(
                setSelectedAnswers,
                selectedAnswers,
                answer
            );
        }
    };

    const answers = renderAnswers(
        currentQuestion,
        selectedAnswers,
        buttonClick,
        currentLanguage
    );

    return <Container countOptions={answers.length >= 6}>{answers}</Container>
};

export const Container = styled.div<{ countOptions: boolean }>`
    display: grid;
    grid-template-columns: repeat(${props => props.countOptions ? 2 : 0}, 1fr);
    gap: 10px;
`;

interface QuestionProps {
    currentQuestion: QuestionNode;
    currentLanguage: Language;
    selectedAnswers: Answer[];
    setSelectedAnswers: Function;
}

const mapStateToProps = (state: ReduxStore) => ({
    currentQuestion: state.allocator.questions.currentQuestion,
    currentLanguage: state.language.currentLanguage,
    selectedAnswers: state.allocator.questions.selectedAnswers,
});
const mapDispatchToProps = {
    setSelectedAnswers,
};
const ConnectedQuestion = connect(
    mapStateToProps,
    mapDispatchToProps
)(Question);

export default ConnectedQuestion;
