import { OrganisationLogo } from 'containers/MatrixBar/MatrixBar.components';
import ConnectedMatrixBar from 'containers/MatrixBar/MatrixBar.connector';
import { ConnectedJobMatrixCellWithJobList } from 'containers/MatrixBoxWithJobs/MatrixBoxWithJobs';
import { Matrix, MatrixHeader, PrintingContainer, PrintingInnerContainer } from 'containers/MatrixContainer/MatrixContainer';
import ConnectedMatrixContent from 'containers/MatrixContent/MatrixContent.connector';
import { MatrixDataContext } from 'containers/MatrixContextProvider/MatrixContextProvider';
import ScrollContextProvider from 'containers/ScrollToJobContainer/ScrollToJobContainer';
import { getCurrentDateTime } from 'helpers/image';
import { toBlob } from 'html-to-image';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { StyrLogo } from 'sections/JobFamilyMatrix/JobFamilyMatrix.styling';
import { AwaitIsPrinting } from './JobMatrixResults.components';
import { useJobMatrixHelper } from './JobMatrixResults.helpers';
import { Container, Cover, Footer, MatrixContainer } from './JobMatrixResults.styles';
import JobMatrixResultsProps from "./JobMatrixResults.types";
import { API_URL } from 'config';
import { DownloadTypes } from 'containers/MatrixBar/MatrixBar.types';
import jsPDF from 'jspdf';

const JobMatrixResults: React.FC<JobMatrixResultsProps> = ({
    resultsGetResults,
    currentOrganisation,
    fullScreen,
    remoteData,
    language,
    updateOrganisationRequest,
    loadValidatedProfiles,
    getRemoteLanguages,
    levels,
    isShareLink,
    matrixView,
}) => {
    useEffect(() => {
        loadValidatedProfiles();
    }, [loadValidatedProfiles])

    useEffect(() => {
        getRemoteLanguages()
    }, [])

    useEffect(() => {
        updateOrganisationRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateOrganisationRequest, currentOrganisation?.id])

    const { localData, controllerHandlers } = useJobMatrixHelper();

    const [printing, setPrinting] = useState<boolean>(false);

    const { currentCellData, editMode } = useContext(MatrixDataContext)

    const ref = useRef<HTMLDivElement>(null)

    const downloadMatrix = useCallback((downloadType: DownloadTypes) => {
        if (ref.current === null) {
            return
        }
        setPrinting(true);

        setTimeout(() => {
            if (ref.current === null) {
                return
            }
            toBlob(ref.current, { cacheBust: true })
                .then((blob) => {
                    if (blob !== null) {
                        const url = URL.createObjectURL(blob);
                        const fileName = `job-matrix-D${getCurrentDateTime()}.${downloadType}`;

                        if (downloadType === DownloadTypes.Image) {
                            const a = document.createElement('a');
                            a.href = url;
                            a.download = fileName;
                            a.click();
                            URL.revokeObjectURL(url);
                            return;
                        }
                        else if (downloadType === DownloadTypes.PDF) {
                            let img = new Image();
                            img.onload = () => {
                                const imgWidth = img.width;
                                const imgHeight = img.height;
                                const pdf = new jsPDF({
                                    orientation: imgWidth > imgHeight ? 'l' : 'p',
                                    unit: 'px',
                                    format: [imgWidth, imgHeight]
                                });
                                pdf.addImage({
                                    imageData: url,
                                    format: 'PNG',
                                    compression: 'FAST',
                                    x: 0,
                                    y: 0,
                                    width: imgWidth,
                                    height: imgHeight,
                                });
                                pdf.save(fileName);
                                URL.revokeObjectURL(url);
                            };
                            img.src = url;
                        }
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    setPrinting(false)
                })
        }, 5000)

    }, [ref])

    // Load the latest results from the server.
    useEffect(() => {
        resultsGetResults();
    }, [resultsGetResults, currentOrganisation]);

    if (!currentOrganisation) {
        return null;
    }

    const logo = currentOrganisation?.imageUrl ? API_URL + currentOrganisation?.imageUrl : require('assets/images/logo.png');

    const renderPrintMatrix = () => (
        <Cover>
            <PrintingContainer ref={ref} isPrinting={printing}>
                <PrintingInnerContainer isPrinting={printing}>
                    <Matrix isPrinting={printing}>
                        <MatrixHeader>
                            <OrganisationLogo src={logo} alt="Organisation logo" />
                        </MatrixHeader>
                        <ConnectedMatrixContent
                            levels={levels}
                            editMode={editMode}
                            language={language}
                            printing={printing}
                            fullScreen={fullScreen}
                            matrixView={matrixView}
                            localData={localData}
                            remoteData={remoteData}
                            controllerHandlers={controllerHandlers} />
                        {printing && <Footer>
                            Powered by <StyrLogo src={require('assets/images/logo.png')} />
                        </Footer>}
                    </Matrix>
                </PrintingInnerContainer>
            </PrintingContainer>
        </Cover>
    )

    return (
        <>
            {printing && <AwaitIsPrinting />}
            {renderPrintMatrix()}
            <Container isFullscreen={fullScreen}>
                <MatrixContainer isFullscreen={fullScreen}>
                    <MatrixHeader>
                        <OrganisationLogo src={currentOrganisation?.imageUrl ? API_URL + currentOrganisation?.imageUrl : require('assets/images/logo.png')} alt="Organisation logo" />
                    </MatrixHeader>
                    <ScrollContextProvider>
                        <ConnectedMatrixContent levels={levels} editMode={editMode} language={language} fullScreen={fullScreen} matrixView={matrixView} localData={localData} remoteData={remoteData} controllerHandlers={controllerHandlers} />
                    </ScrollContextProvider>
                </MatrixContainer>
                {<ConnectedMatrixBar
                    isFullscreen={fullScreen}
                    isShareLink={Boolean(isShareLink)}
                    currentView={matrixView}
                    downloadMatrix={downloadMatrix}
                    levels={levels}
                    language={language}
                    remoteData={remoteData}
                    localData={localData}
                    changeVisibilityTalentpath={controllerHandlers.changeVisibilityTalentpath}
                    changeVisibilityLevel={controllerHandlers.changeVisibilityRow}
                    changeVisibilityColumn={controllerHandlers.changeVisibilityColumn}
                    changeFilterTag={controllerHandlers.changeFilterTag}
                />}
            </Container>
            {currentCellData &&
                <ConnectedJobMatrixCellWithJobList
                    level={currentCellData.level}
                    handleEditJob={currentCellData.handleEditJob}
                    jobIDs={currentCellData.jobIDs}
                />}
        </>
    );

};

export default JobMatrixResults;
