import React from 'react';
import {
    HeaderContainer,
} from './Header.components';
import HeaderProps from './Header.types';
import LanguageButton from '../LanguageButton/LanguageButton.connector';
import ConnectedSearchResults from 'components/Searchbar/Searchbar';
import { ConnectedCollapseNavbarButton } from 'containers/Sidebar/ToggleSidebar.component';

const Header: React.FC<HeaderProps> = () => (
    <HeaderContainer>
            <ConnectedCollapseNavbarButton />
            <ConnectedSearchResults />
            <LanguageButton />
    </HeaderContainer>
);

export default Header;
