import React from 'react';
import { useParams } from 'react-router-dom';
import ConnectedPdfResult from 'sections/ValidatedProfiles/PdfResult/PdfResult.connector';
import { isLanguage, Language } from 'types/MultiLingualString';
import PdfProps from './Pdf.types';

const Pdf: React.FC<PdfProps> = () => {
    const { id, language } = useParams<{id: string, language: string}>();
    const lang = isLanguage(language) ? language : 'en-EN';
    return (
        <>
            <ConnectedPdfResult id={id} currentLanguage={lang as Language} />
        </>
    );
};

export default Pdf;
