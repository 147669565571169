import styled from 'styled-components';

const gridRowSizes = "20px minmax(100px,1fr) 45px 200px 50px 250px;"

export const Container = styled.div<{ sidebarOpen?: boolean }>`    
    display: grid;
    grid-template-rows: 120px 1fr 80px;
    height: 100%;

    &:-webkit-scrollbar {
        display: none;
    }
`

export const Controls = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
    justify-content: flex-end;
    align-items: center;
`;

export const TableBody = styled.div`
    display: grid;
    grid-gap: 1em;
`;

export const TableHeaderItem = styled.div<{ activeItem?: boolean }>`
    font-weight: bold;
    color: ${props => (props.activeItem ? '#333333' : '#A9A9A9')};
    :hover {
        cursor: pointer;
    }
`;

export const Table = styled.div`
    background-color: white;
    border-radius: 8px 8px 0 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    box-sizing: border-box;
    position: relative;
    max-height: calc(100vh - 270px);
`;

export const TableItemContainer = styled.div`
    padding: 0 20px;
    display: grid;
    min-width: 100%;
    grid-template-columns: ${gridRowSizes};
    grid-gap: 1em;

    align-items: center;
    min-width: 100%;
    box-sizing: border-box;
    & > div {
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1em;
`;

export const Header = styled.div`
    margin: 0 auto 20px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    box-sizing: border-box;
`
