import { LocalJobMatrixData } from "sections/JobMatrix/JobMatrixResults/JobMatrixResults.types";

export const getAvailableLevels = (levels: string[], localData: LocalJobMatrixData) => {
    return levels.filter(x => {
        const local = localData.rows.find(y => y.uuid === x);
        if (!local) {
            return true;
        }

        return local.shown;
    })
}