import MainLayout from 'layouts/MainLayout/MainLayout';
import React from 'react';
import { useParams } from 'react-router-dom';
import UpdateSection from 'sections/UpdateSection/UpdateSection.connector';
import { UpdateContainer } from './Update.components';
import UpdateProps from './Update.types';

const Update: React.FC<UpdateProps> = () => {
    const { id } = useParams<{id: string}>();
    return (
        <MainLayout>
            <UpdateContainer>
                <UpdateSection id={id} />
            </UpdateContainer>
        </MainLayout>
    );
};

export default Update;
