import styled from "styled-components"

export const Container = styled.div`
        display: grid;
        grid-template-columns: repeat(6, 60px);
        grid-gap: 20px;
        align-items: center;
`

export const Level = styled.div<{ bgColor: string }>(({ bgColor }) => (
    `
        height: auto;
        min-height: 30px;
        background-color: ${bgColor};
        border-radius: 5px;
        display:flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        cursor: pointer;
        overflow: hidden;
        white-space: pre-wrap;
        text-overflow: ellipsis;
        padding: 5px;
    `
))

export const ExtraFieldContainer = styled.div`
        display: grid;
        grid-template-columns: repeat(3, auto);
        grid-gap: 20px;
        align-items: center;
`