import {
    Text, View
} from '@react-pdf/renderer';
import React from 'react';
import { processNodes } from 'react-html-parser';
import { styles } from 'sections/ValidatedProfiles/PdfResult/styling';

//eslint-disable-next-line
export const transform = (node: any, index: number) => {
    if (node.type === "tag" && node.name === "h1") {
        return (
            <Text style={styles.h1} key={index}>
                {processNodes(node.children, transform)}
            </Text>
        );
    }

    if (node.type === "tag" && node.name === "h2") {
        return (
            <Text style={styles.h2} key={index}>
                {processNodes(node.children, transform)}
            </Text>
        );
    }

    if (node.type === "tag" && node.name === "h3") {
        return (
            <Text style={styles.h3} key={index}>
                {processNodes(node.children, transform)}
            </Text>
        );
    }

    if (node.type === "tag" && node.name === "strong") {
        return (
            <Text style={styles.strong} key={index}>
                {processNodes(node.children, transform)}
            </Text>
        );
    }

    if (node.type === "tag" && node.name === "em") {
        return (
            <Text style={styles.em} key={index}>
                {processNodes(node.children, transform)}
            </Text>
        );
    }

    if (node.type === "tag" && node.name === "u") {
        return (
            <Text style={styles.underline} key={index}>
                {processNodes(node.children, transform)}
            </Text>
        );
    }

    if (node.type === "tag" && node.name === "p") {
        return (
            <Text style={styles.p} key={index}>
                {processNodes(node.children, transform)}
            </Text>
        );
    }

    if (node.type === "tag" && node.name === "span") {
        return (
            <Text style={styles.span} key={index}>
                {processNodes(node.children, transform)}
            </Text>
        );
    }

    if (node.type === "tag" && node.name === "br") {
        return (
            <View style={{ marginTop: 4, marginBottom: 4 }} />
        );
    }

    if (node.type === "tag" && node.attribs.class === "ql-indent-1") {
        return (
            <View style={styles.liIndent1}>
                <Text key={index}>
                    - {processNodes(node.children, transform)}
                </Text>
            </View>
        );
    }

    if (node.type === "tag" && node.name === "li") {
        return (
            <View style={styles.li}>
                <Text style={styles.p} key={index}>
                    • {processNodes(node.children, transform)}
                </Text>
            </View>
        );
    }

    if (node.type === "tag" && node.name === "li" && node.class === "ql-indent-1") {
        return (
            <View style={styles.li}>
                <Text style={styles.p} key={index}>
                    - {processNodes(node.children, transform)}
                </Text>
            </View>
        );
    }

    if (node.type === "tag" && node.name === "ul") {
        return (
            <View style={styles.ul} key={index}>
                {processNodes(node.children, transform)}
            </View>
        );
    }

    if (node.class === 'ql-indent-1') {
        return (
            <View style={{ backgroundColor: 'green' }}>
                <Text style={styles.p} key={index}>
                    - {processNodes(node.children, transform)}
                </Text>
            </View>
        );
    }

}

export const htmlToPDFoptions = {
    decodeOrganisationUnits: true,
    transform
};
