import styled from 'styled-components';
import theme from 'utils/theme';

export const MatrixContainer = styled.div<{ isFullscreen?: boolean }>`
    ${props => !props.isFullscreen && `
        padding: 20px 20px 40px 20px;
    `}
    position: relative;
    box-sizing: border-box;

`;

export const DivTestScroller = styled.div`
    height: 2000px;
    width: 2000px;
`;

export const TableResponsive = styled.div`
    width: 100%;
    overflow: auto;
    position: relative;
    background-color: #eff4f9;
`

export const Table = styled.table<{ zoom: number; printing?: boolean; }>`
    margin-top: ${props => props.printing ? "-10px" : "0"};
    border-spacing: 10px;
    border-collapse: separate;
    color: #212529;
    table-layout:fixed;
    width: ${(props) => props.zoom <= 40 ? "100%" : "unset"};
    zoom: ${(props) => props.zoom}%;
`;

export const TableHeadRow = styled.tr`
    height: 60px;
`;

export const TD = styled.td`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const TR = styled.tr<{ rowHeight?: number }>`
    background: ${theme.jobMatrix.cellColor};
    height: ${(props) => props.rowHeight}px;
    overflow: hidden;

    td {
        background-color: red;
    }

    td > div {
        margin: 8px;
        height: ${(props) => props.rowHeight}px;
    }
`

export const CellItems = styled.ul`
    margin: 0;
    padding: 1rem;
    list-style-type: none;
`;

export const CellItem = styled.li`

`;

export const IconContainer = styled.div`
    margin: 0 auto;
    width: 20px;
    height: 20px;
    text-align: center;
    cursor: pointer;
`;

export const ButtonEye = styled.button`
`;

export const Cell = styled.div`
    margin: 0 auto;
    overflow-x: scroll;
    text-overflow: ellipsis;
    white-space: normal;
`;

export const Button = styled.button`
    all: unset;
    margin: 0 auto;
    display: block;
`;

export const ButtonScale = styled.button`
    all: unset;
    margin: 1px 4px;
    padding: 2px;
    height: 24px;
    width: 24px;
    color: #fff;
    background-color: rgba(255,255,255,.10);

`;

export const ColumnSettingsContainer = styled.div<{ vertical?: boolean; }>`
    display: flex;
    flex-direction: ${props => props.vertical ? "column" : "row"};
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #6c788a;
    height: 100%;
    width: 100%;
    ${props => props.vertical && "width: calc(100% - 2px);"};
`;

export const TableHead = styled.th<{ width?: number; theadColor: string; show?: boolean; removeFromPrint?: boolean; organisationUnit?: boolean; filterable?: boolean }> `
    height: 60px;
    padding: 0 8px;
    color: #fff;
    min-width: ${(props) => props.width}px;
    ${(props) => props.organisationUnit && "font-size: 1.25rem;"};
    position: relative;
    ${(props) => props.show ? `` : `display: none;`};
    line-height: normal;
    background-color: ${(props) => props.theadColor};

    @media print {
       ${(props) => props.removeFromPrint ? 'display: none' : ''};
        background: ${(props) => props.theadColor};
        -webkit-print-color-adjust: exact;
    }

    &:hover ${ColumnSettingsContainer} {
        display: ${(props) => props.filterable ? 'flex' : 'none'};
    }
`;

export const BaseMatrix = styled.div`
    background-color: ${theme.background.app};
    padding-bottom: 50px;
`;

export const PrintLogoContainer = styled.div<{ visible?: boolean; }>`
    display: ${(props) => props.visible ? 'flex' : 'none'};
    padding: .25em 1rem;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    border-radius: 8px;
    background-color: #fff;

    img{
        overflow: visible;
    max-height: 68px;
    }
`;

export const AwaitIsPrintingContainer = styled.div`
    height: 100%;
    width: 100%;

    background-color: rgba(0,0,0,.64);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;

    display: block;
`;

export const AwaitIsPrintingIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 170px;
    height: 60px;
    background-color: white;
    border-radius: 10px;
    border: 2px solid #000;
`;


export const MatrixViewTabsContainer = styled.div`
    margin-bottom: 1em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 100%;
`;

export const TabsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 1em;
    width: 240px;
`;

export const Tab = styled.button<{ active?: boolean }>`
    all: unset;
    padding: .5em;
    font-weight: ${props => props.active ? 'bold' : 'normal'};
    border-bottom: ${props => props.active ? '2px solid #000' : '0'};
    cursor: pointer;
`;

export const Container = styled.div<{ isFullscreen?: boolean }>`
    display: grid;
    grid-template-rows: ${props => props.isFullscreen ? "calc(100vh - 70px) 70px" : "calc(100vh - 140px) 70px"};

    &:-webkit-scrollbar {
        display: none;
    }
`

export const Header = styled.div`
    margin: 0 auto 20px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    box-sizing: border-box;
`


export const Footer = styled.div`
    margin-top: 10px;
    padding: 1em 2em;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #fff;
    border-radius: 0 0 1em 1em;
    font-weight: bold;
`

export const Cover = styled.div`
    position: fixed;
    z-index: 0;

    &:before{
            content: '';
            position: absolute;
            top: 0px;
            left: 0;
            z-index: 20;
            width: 100%;
            height: 100%;
            background-color: #eff4f9;
    }
`
