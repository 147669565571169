import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';
import { resultsGetResult } from 'store/results/results.actions';
import PdfResult from './PdfResult';

const mapStateToProps = (state: ReduxStore) => ({
    results: state.results.results,
    currentOrganisation: state.user.currentOrganisation,
});
const mapDispatchToProps = { resultsGetResult };
const ConnectedPdfResult = connect(
    mapStateToProps,
    mapDispatchToProps
)(PdfResult);

export default ConnectedPdfResult;
