import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';
import { updatesGetUpdates, updatesChangeUpdate, updatesDeleteUpdate, updatesCreateUpdate } from 'store/updates/updates.actions';
import UpdateSection from './UpdateSection';

const mapStateToProps = (state: ReduxStore) => ({
    updates: state.updates.updates,
    currentLanguage: state.language.currentLanguage,
    canEdit: state.user.permissions.canDeleteOrganisation
});
const mapDispatchToProps = { updatesGetUpdates, updatesChangeUpdate, updatesDeleteUpdate, updatesCreateUpdate };
const ConnectedUpdateSection = connect(
    mapStateToProps,
    mapDispatchToProps
)(UpdateSection);

export default ConnectedUpdateSection;
