import { connect } from 'react-redux';
import {
    createQuestion,
    loadQuestions,
} from 'store/allocator/allocator.actions';
import ReduxStore from 'store/store.type';
import { AdminAllocatorQuestionsList } from './AdminAllocatorQuestionsList';

const mapStateToProps = (state: ReduxStore) => {
    return {
        questions: state.allocator.questions.questions,
        currentLanguage: state.language.currentLanguage,
    };
};
const mapDispatchToProps = {
    loadQuestions,
    createQuestion
};
const ConnectedAdminAllocatorQuestionsList = connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminAllocatorQuestionsList);

export default ConnectedAdminAllocatorQuestionsList;
