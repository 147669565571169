import React from 'react';
import { ButtonContainer, BackButtonContainer } from './Button.components';
import ButtonProps, { ButtonPriority } from './Button.types';
import ArrowLeftIcon from 'components/Icons/arrowLeft';

const bgColorRecord: Record<ButtonPriority, string> = {
    primary: '#3b4b63',
    secondary: 'transparent',
    tertiary: '#f06e64',
};

const txtColorRecord: Record<ButtonPriority, string> = {
    primary: '#fff',
    secondary: '#000',
    tertiary: '#fff',
};

const Button: React.FC<ButtonProps> = ({ text, onClick, priority, disabled, style, size }) => {
    const handleOnClick = disabled ? () => { } : onClick

    return (
        <ButtonContainer
            bgColor={bgColorRecord[priority || 'primary']}
            txtColor={txtColorRecord[priority || 'primary']}
            onClick={handleOnClick}
            disabled={disabled}
            center
            style={style}
            size={size}
        >
            <span>{text}</span>
        </ButtonContainer>
    );
};

export default Button;

export const BackButton: React.FC<ButtonProps> = ({ text, onClick }) => (
    <BackButtonContainer onClick={onClick} >
        <ArrowLeftIcon />
        <span>{text}</span>
    </BackButtonContainer>
)
