import apiRequest from "helpers/api";
import store from "store/store";
import { Organisation } from "types/Organisation";
import { Family, Result } from "types/Result";

type SearchInCurOrganisation = (
    query: string,
    currentOrganisation: Organisation
) => Promise<SearchResult>;

export interface SearchResult {
    jobs: Result[];
    jobFamilies: Family[];
};

export const searchInCurOrganisation: SearchInCurOrganisation = (query, currentOrganisation) => {
    const state = store.getState();
    const endpoint = `organisations/${currentOrganisation?.id}/search?searchTerm=${query}`;

    return apiRequest(endpoint, 'GET', state.user.jwt, undefined, {
        currentOrganisation: state.user.currentOrganisation?.id
    })
};
