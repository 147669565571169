import React from 'react';

const SortIcon: React.FC = () => {
    return (
        <svg style={{
            width: '24px',
        }}xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <path
                style={{
                    textIndent: 0,
                    textAlign: 'start',
                    lineHeight: 'normal',
                    textTransform: 'none'
                }}
                d="M 16 4 C 9.3845336 4 4 9.3845336 4 16 C 4 22.615466 9.3845336 28 16 28 C 22.615466 28 28 22.615466 28 16 L 26 16 C 26 21.534534 21.534534 26 16 26 C 10.465466 26 6 21.534534 6 16 C 6 10.465466 10.465466 6 16 6 C 19.702296 6 22.896862 8.0195513 24.625 11 L 21 11 L 21 13 L 27 13 L 28 13 L 28 12 L 28 6 L 26 6 L 26 9.34375 C 23.846712 6.11172 20.16707 4 16 4 z M 15 8 L 15 16 L 15 17 L 16 17 L 22 17 L 22 15 L 17 15 L 17 8 L 15 8 z"
                overflow="visible"
                fontFamily="Sans"
            />
        </svg>
    );
}

export default SortIcon;