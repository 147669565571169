import React, { useRef, useState } from 'react';

import LanguageDropdown from 'components/LanguageDropdown/LanguageDropdown';
import useOnClickOutside from 'helpers/hooks/useOnClick';
import LanguageButtonProps from './LanguageButton.types';
import {
    Container,
    Icon,
    LangButton,
} from './LanguageButton.components';
import { CountryFlag } from 'components/CountryFlag/CountryFlag';
import ArrowDownIcon from 'components/Icons/arrowDown';

const LanguageButton: React.FC<LanguageButtonProps> = ({ currentLanguage }) => {
    const ref = useRef(null);

    const [dropdown, setDropdown] = useState(false);
    useOnClickOutside(ref, () => setDropdown(false));

    return (
        <Container ref={ref}>
            <LangButton
                onClick={() => {
                    setDropdown((dropdown: boolean) => !dropdown);
                }}
            >
                <CountryFlag language={currentLanguage} />
                <Icon>
                    <ArrowDownIcon color="#000" />
                </Icon>
            </LangButton>
            {dropdown && <LanguageDropdown />}
        </Container>
    );
};

export default LanguageButton;
