import { connect } from 'react-redux';
import {
    resultsGetResult,
} from 'store/results/results.actions';
import ReduxStore from 'store/store.type';
import ComparingLevels from './ComparingLevels';

const mapStateToProps = (state: ReduxStore) => ({
    results: state.results.results,
    currentLanguage: state.language.currentLanguage,
    validatedProfiles: state.allocator.validatedProfiles
});
const mapDispatchToProps = { resultsGetResult};
const ConnectedComparingLevels = connect(mapStateToProps, mapDispatchToProps)(ComparingLevels);

export default ConnectedComparingLevels;
