import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';
import { AdminAllocatorQuestionsSingle } from './AdminAllocatorQuestionsSingle';
import { createAnswer, deleteAnswer, deleteQuestion, loadQuestions, loadTalentpaths, loadValidatedProfiles, updateAnswer, updateQuestion } from 'store/allocator/allocator.actions';
import { getRemoteLanguages } from 'store/language/language.actions';

const mapStateToProps = (state: ReduxStore) => {
    return {
        questions: state.allocator.questions.questions,
        currentLanguage: state.language.currentLanguage,
        validatedProfiles: state.allocator.validatedProfiles,
        talentPaths: state.allocator.talentPaths,
    };
};

const mapDispatchToProps = { deleteQuestion, loadQuestions, loadValidatedProfiles, getRemoteLanguages, updateQuestion, createAnswer, deleteAnswer, updateAnswer, loadTalentpaths};

const ConnectedAdminAllocatorQuestionsSingle = connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminAllocatorQuestionsSingle);

export default ConnectedAdminAllocatorQuestionsSingle;
