import { MatrixDataContext } from 'containers/MatrixContextProvider/MatrixContextProvider';
import getLanguageObject from 'helpers/language';
import { getIdFromUrl } from 'helpers/url';
import React, { useContext, useEffect } from 'react';
import ScrollContainer from "react-indiana-drag-scroll";
import { constructInitialJobMatrix } from 'sections/JobMatrix/JobMatrixResults/initialJobMatrixData';
import TalentMatrixWithJobs from 'sections/TalentMatrixWithJobs/TalentMatrixWithJobs';
import { getObjectFromCurrentLanguage } from 'store/language/language.actions';
import useScrollToElement from 'utils/scrollToJobByID';
import { OrganisationUnitDataColumn } from './OrganisationUnitDataColumn';
import { RowHandler, StyrDataColumn, StyrGroupColumn, StyrLevelColumn } from './MatrixColumns';
import { MatrixContentContainer, ZoomContainer } from './MatrixContent.components';
import { ConnectedExtraColumn } from './MatrixContent.connector';
import MatrixContentProps, { ColumnSetProps, MatrixViews, RenderOrangeColumnsProps } from './MatrixContent.types';
import { JobTag } from 'types/Result';
import { LocalFilterTagData } from 'sections/JobMatrix/JobMatrixResults/JobMatrixResults.types';

const MatrixContent: React.FC<MatrixContentProps> = ({
    cLanguage,
    language,
    controllerHandlers,
    localData,
    remoteData,
    matrixView,
    permissions,
    isSidemenuOpen,
    fullScreen,
    printing,
    editMode,
    levels,
    currentOrganisation
}) => {
    let currentLanguage = language || cLanguage;
    const { containerRef, setScrollToId } = useScrollToElement();
    const { zoomTable } = useContext(MatrixDataContext)
    // param.id is the id of the result we need to scroll to, get it from the url params.
    const param = getIdFromUrl(window.location.href);

    useEffect(() => {
        if (param) return setScrollToId(param)

        return;
    }, [param, setScrollToId])

    const getContainerStyle = () => {
        if (fullScreen) return `
            width: calc(100vw - 20px);
            height: calc(100vh - 200px);
        `;

        return `
            width: calc(100vw - ${isSidemenuOpen && !fullScreen ? `350px` : `140px`});
            height: calc(100vh - 280px);
            @media screen and (max-height: 868px) {
                height: calc(100vh - 300px);
            }
        `
    }

    // This blacklist is used in the shared links to check if you have access to these particular extra columns. If not, they are not displayed in the grid.
    const blacklist_free_columns: number[] = [];

    if (!permissions.canViewExtraFields.extraField1) blacklist_free_columns.push(1);
    if (!permissions.canViewExtraFields.extraField2) blacklist_free_columns.push(2);
    if (!permissions.canViewExtraFields.extraField3) blacklist_free_columns.push(3);

    if (currentOrganisation === undefined) return null;
    const filteredInitialJobMatrixData = constructInitialJobMatrix(blacklist_free_columns, currentOrganisation?.differentiatingFactors)

    if (currentOrganisation === undefined) return null;

    if (printing) {
        return (
            <MatrixContentContainer initialData={filteredInitialJobMatrixData} levels={levels} currentMatrixView={matrixView} zoom={zoomTable} remoteData={remoteData} localData={localData} differentiatingFactors={currentOrganisation.differentiatingFactors}  >
                <GreyColumnsWithInformation permissions={permissions} levels={levels} editMode={editMode} localData={localData} remoteData={remoteData} currentLanguage={currentLanguage} controllerHandlers={controllerHandlers} currentOrganisation={currentOrganisation} printing={printing} />
                <RenderOrangeColumns levels={levels} matrixView={matrixView} localData={localData} remoteData={remoteData} currentLanguage={currentLanguage} controllerHandlers={controllerHandlers} editMode={editMode} />
            </MatrixContentContainer>
        )
    }

    return (
        <ScrollContainer hideScrollbars={false} ignoreElements=".quill, textarea, select, input" innerRef={containerRef}>
            <ZoomContainer containerSize={getContainerStyle()}>
                <div style={{ width: "fit-content" }}> {/* Necessary for position: sticky to stick when scrolling. */}
                    <MatrixContentContainer initialData={filteredInitialJobMatrixData} levels={levels} currentMatrixView={matrixView} zoom={zoomTable} remoteData={remoteData} localData={localData} differentiatingFactors={currentOrganisation.differentiatingFactors}  >
                        <GreyColumnsWithInformation permissions={permissions} levels={levels} editMode={editMode} localData={localData} remoteData={remoteData} currentLanguage={currentLanguage} controllerHandlers={controllerHandlers} currentOrganisation={currentOrganisation} />
                        <RenderOrangeColumns levels={levels} editMode={editMode} matrixView={matrixView} localData={localData} remoteData={remoteData} currentLanguage={currentLanguage} controllerHandlers={controllerHandlers} />
                    </MatrixContentContainer>
                </div>
            </ZoomContainer>
        </ScrollContainer>
    );
};


const GreyColumnsWithInformation: React.FC<ColumnSetProps> = ({ editMode, currentLanguage, remoteData, localData, controllerHandlers, levels, permissions, currentOrganisation, printing }) => {
    const lang = getLanguageObject(currentLanguage);
    return (
        <>
            <RowHandler
                printing={printing}
                levels={levels}
                localData={localData}
                controllerHandlers={controllerHandlers} />

            {remoteData.extraColumns.column1 && permissions?.canViewExtraFields.extraField1 && <ConnectedExtraColumn
                levels={levels}
                editMode={editMode}
                data={getObjectFromCurrentLanguage(remoteData.extraColumns.column1, currentLanguage)}
                localData={localData}
                controllerHandlers={controllerHandlers}
                uuid="freeInputfield"
                currentLanguage={currentLanguage}
            />}
            {remoteData.extraColumns.column2 && permissions?.canViewExtraFields.extraField2 && <ConnectedExtraColumn
                levels={levels}
                editMode={editMode}
                data={getObjectFromCurrentLanguage(remoteData.extraColumns.column2, currentLanguage)}
                localData={localData}
                controllerHandlers={controllerHandlers}
                uuid="freeInputfield2"
                suffix="2"
                currentLanguage={currentLanguage}
            />}
            {remoteData.extraColumns.column3 && permissions?.canViewExtraFields.extraField3 && <ConnectedExtraColumn
                levels={levels}
                editMode={editMode}
                data={getObjectFromCurrentLanguage(remoteData.extraColumns.column3, currentLanguage)}
                localData={localData}
                controllerHandlers={controllerHandlers}
                uuid="freeInputfield3"
                suffix="3"
                currentLanguage={currentLanguage}
            />}

            <StyrLevelColumn
                levels={levels}
                currentLanguage={currentLanguage}
                localData={localData}
                controllerHandlers={controllerHandlers}
                uuid="styrLevel"
            />
            <StyrGroupColumn
                levels={levels}
                data={remoteData.levelOptions}
                title={lang.styrGroups}
                controllerHandlers={controllerHandlers}
                currentLanguage={currentLanguage}
                index="problem_solving_focus"
                localData={localData}
                uuid="styrGroup"
            />
            {currentOrganisation?.differentiatingFactors.educationLevel &&
                <StyrDataColumn
                    levels={levels}
                    data={remoteData.levelOptions}
                    index="education"
                    title={lang.educationLevel}
                    controllerHandlers={controllerHandlers}
                    currentLanguage={currentLanguage}
                    localData={localData}
                    uuid="educationLevel"
                />}
            <StyrDataColumn
                levels={levels}
                data={remoteData.levelOptions}
                index="problem_solving_capability"
                title={lang.problemSolvingCapability}
                controllerHandlers={controllerHandlers}
                currentLanguage={currentLanguage}
                localData={localData}
                uuid="problemSolvingCapability"
            />

            {currentOrganisation?.differentiatingFactors.planningHorizon &&
                <StyrDataColumn
                    levels={levels}
                    data={remoteData.levelOptions}
                    index="planning_horizon"
                    title={lang.planningHorizon}
                    controllerHandlers={controllerHandlers}
                    currentLanguage={currentLanguage}
                    localData={localData}
                    uuid="planningHorizon"
                    textCenter
                />}
        </>
    )
}

// The orange columns contain the jobs
const RenderOrangeColumns: React.FC<RenderOrangeColumnsProps> = ({ levels, matrixView, localData, remoteData, currentLanguage, controllerHandlers, editMode }) => {

    if (localData && localData.tags?.length > 0 && localData.tags.some(x => !x.shown)) {
        remoteData = {
            ...remoteData, results: remoteData.results.filter((item) => {
                // Check if "untagged" is selected
                const untaggedSelected = localData.tags.some((localTag: LocalFilterTagData) => localTag.uuid === "untagged" && localTag.shown);

                // Check if the item is untagged
                const isUntagged = item.tags === undefined || item.tags.length === 0;

                // Check if any of the item's tags are selected
                const tagSelected = item.tags?.some((tag: JobTag) =>
                    localData.tags.some((localTag: LocalFilterTagData) => localTag.uuid === tag.id && localTag.shown)
                );

                // Show the item if "untagged" is selected and the item is untagged, or if any of the item's tags are selected
                return (untaggedSelected && isUntagged) || tagSelected;
            })
        };
    }

    // Select either the talent matrix or the job matrix. This decides the columns used.
    if (matrixView === MatrixViews.talentmatrix) {
        return <TalentMatrixWithJobs
            levels={levels}
            localData={localData}
            remoteData={remoteData}
            currentLanguage={currentLanguage}
            controllerHandlers={controllerHandlers} />
    }
    return <OrangeColumnsWithJobs
        levels={levels}
        localData={localData}
        remoteData={remoteData}
        currentLanguage={currentLanguage}
        controllerHandlers={controllerHandlers}
        editMode={editMode} />
}

// The orange columns with jobs are used in the 'job matrix'
const OrangeColumnsWithJobs: React.FC<ColumnSetProps> = ({ editMode, localData, remoteData, currentLanguage, controllerHandlers, levels }) => {
    return (
        <>
            {remoteData.organisationUnits.map(organisationUnit => {
                let data = remoteData.results.filter(x => x.organisationUnitId === organisationUnit.id);
                return (
                    <OrganisationUnitDataColumn levels={levels} key={organisationUnit.id} localData={localData} currentLanguage={currentLanguage} organisationUnit={organisationUnit} results={data} controllerHandlers={controllerHandlers} editMode={editMode} />
                )
            })}
        </>
    );
}

export default MatrixContent
