import React from 'react';
import styled from 'styled-components';
import theme from 'utils/theme';
import { ValidatedProfileItemProps } from './ResultValidatedProfile.types';

export const ResultValidatedProfileContainer = styled.section`
    background-color: ${theme.colors.light};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    user-select: none;
`;

export const ResultValidatedProfileMain = styled.div`
    overflow: hidden;
    padding: 10px;
`;
export const ResultValidatedProfileTMACompetencies = styled.div`
    overflow: hidden;
    padding: 2.5em 0 0;
`;

export const ResultValidatedProfileTMACompetenciesHeader = styled.div`
    display: flex;
    padding: 0 1em .5em;
    font-weight: bold;
    font-size: 1.1em;
    background-color: white;
    width: 100%;
    height: 5%;
    padding: 30px 20px;
`;

export const ResultValidatedProfileTMACompetenciesHeaderTitle = styled.span`
    margin-right: 5px;
`;
export const ResultValidatedProfileTMACompetenciesColumnContainer = styled.div`
    padding: 10px;
`;

export const ResultValidatedProfileTMACompetenciesColumn = styled.div`
    width: 100%;
    max-width: 50%;
    background-color: ${theme.colors.light};
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: column;
    overflow: hidden;
    margin-top: 10px;
`;

export const ResultValidatedProfileColumn = styled.div`
    padding: 2.5em 0 0;
    width: 100%;
    max-width: 50%;
    background-color: ${theme.colors.light};
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: column;
    overflow: hidden;
`;

export const ResultValidatedProfileBottom = styled.div`
    padding: 20px;
`;

export const TextEditor = styled.div`
    display: grid;
    grid-template-rows: auto auto;
    grid-gap: 50px;
`

const ValidatedProfileItemContainer = styled.div`
    min-height: 48px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: flex-start;
`;

export const ValidatedProfileItemTitle = styled.div`
    width: 95%;
    padding: 0 1em .5em;
    font-size: 1em;
    font-weight: 600;
`;

export const ValidatedProfileItemText = styled.div`
    flex: 1 1;
    padding: 0 1em 1em;
    font-size: 1em;
`;

export const ValidatedProfileItem: React.FC<ValidatedProfileItemProps> = ({ title, text, color }) => {
    if (!text || text === "nvt" || text === "n / a") {
        return null;
    }

    return (
        <ValidatedProfileItemContainer>
            <ValidatedProfileItemTitle
                dangerouslySetInnerHTML={{ __html: title || '' }} />
            <ValidatedProfileItemText
                color={color}
                dangerouslySetInnerHTML={{ __html: text || '' }}
            />
        </ValidatedProfileItemContainer>
    );
};
