import React from 'react';
import styled from 'styled-components';
import { FilterInputDropdownProps } from './ResultsFilters.types';
import ArrowDownIcon from 'components/Icons/arrowDown';

export const ResultsFiltersContainer = styled.div`
width: 100%;
`;

export const FilterForm = styled.div`
    display:  flex;
    gap: 10px;
    align-items: center;
`;

const Container = styled.div`
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;

    background-color: #EFEDEE;
    border-radius: 8px;
`;

const DropdownSelect = styled.select`
    all: unset;
    padding: 0 24px 0 10px;

    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 40px;
    border: 0;
`;


export const Icon = styled.div`
    color: #000;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
`;

const FilterInputDropdown: React.FC<FilterInputDropdownProps> = ({
    selected,
    options,
    onChange,
    label,
    showAll = true
}) => {
    const processHTML = (input: string) => {
        if (!input) {
            return input;
        }
        return input.replace("<Br/>", ", ").replace("<br/>", ", ");
    }

    return (
        <Container title={label}>
            <DropdownSelect value={selected} onChange={onChange}>
                {showAll && <option value="Show All">{label}</option>}
                {options.map(option => {
                    return (
                        <option key={option.value} value={option.value}>
                            {processHTML(option.name)}
                        </option>
                    );
                })}
            </DropdownSelect>
            <Icon>
                <ArrowDownIcon color="#000" />
            </Icon>
        </Container>
    );
};

export default FilterInputDropdown

export const BulkActions = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    `;
