import { Container } from 'containers/ContentBox/ContentBox';
import styled from 'styled-components';
import theme from 'utils/theme';

export const Main = styled.div`
display: flex;
flex-direction: column;
`

export const Title = styled.div`
    padding: 0 20px;
`;

export const Table = styled(Container)`
    padding: 20px 0;
    grid-template-columns: 1fr 1fr;
`;

export const Footer = styled(Container)`
    grid-template-columns: 1fr 1fr 1fr;
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
`;

const Draggable = styled.div<{selected?: boolean;}>`
    width: 100%;
    padding: 5px 0;
    margin: 5px 0;
    height: 44px;
    display: grid;
    grid-template-columns: 1fr 44px 10px;
    grid-gap: .25em;
    align-items: center;
    background-color: #fff;
    border: 0;
    border-bottom-width: 2px;
    border-style: solid;
    border-color: ${theme.colors.green};
    cursor: pointer;
`;

export const OrganisationUnitContainer = styled(Draggable)`
    background-color: ${props => props.selected ? '#eff4f9' : '#fff'};
    grid-template-columns: 1fr 44px 10px;
    padding-left: 10px;
`;

export const Icon = styled.div`
    width: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: move;
`;

export const Units = styled.div`
    width: 100%;
    margin-left: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
`;

export const OrganisationUnitH3 = styled.div`
`;

export const UnitContainer = styled(Draggable)`
    background-color: ${props => props.selected ? '#eff4f9' : '#fff'};
    padding-left: 10px;
`;

export const AddItemContainer = styled.div`
    margin-top: auto;
    padding: 20px 20px;
`;

export const NoArchitectureSelected = styled.div`
    display: grid;
    justify-items: center;
    align-items: center;
    opacity: .2;
`;

export const Row = styled.div`
    display: grid;
    grid-gap: 1em;
    grid-template-columns: auto auto;
`;
