import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';
import { createNewOrganisation, updateOrganisationJobFamilies, duplicateOrganisation, updateOrganisationName, updateOrganisationTMALink, deleteOrganisation, updateOrganisationRequest } from 'store/user/user.actions';
import ManageOrganisation from './ManageOrganisation';

const mapStateToProps = (state: ReduxStore) => ({
    currentLanguage: state.language.currentLanguage,
    currentOrganisation: state.user.currentOrganisation,
    organisations: state.user.organisations,
    canDuplicateOrganisation: state.user.permissions.canDuplicateOrganisation,
    permissions: state.user.permissions,
});
const mapDispatchToProps = { duplicateOrganisation, updateOrganisationJobFamilies, createNewOrganisation, updateOrganisationTMALink, updateOrganisationName, updateOrganisationRequest, deleteOrganisation };
const ConnectedManageOrganisation = connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageOrganisation);

export default ConnectedManageOrganisation;
