import styled from "styled-components";

export const Container = styled.button<{ bgColor: string; }>(({ bgColor }) => (
    `
        all: unset;
        
        height: 24px;
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid rgba(0, 0, 0, 0.05);
        background-color: ${bgColor};
        border-radius: 5px;
        cursor: pointer;
    `
))
