import React, { useEffect, useState } from 'react';
import Update, { ChangedUpdate } from 'types/Update';
import { UnderlinedTitle } from 'components/Typography/Typography';
import { UpdateHeaderButtonsContainer, UpdateSectionContainer } from './UpdateSection.components';
import UpdateSectionProps from './UpdateSection.types';
import Routes from 'routes/Routes.types';
import getLanguageObject from 'helpers/language';
import Button from 'components/Button/Button';
import { useNavigate } from 'helpers/useNavigation';
import { IconButton } from 'components/Button/IconButton/IconButton';
import EditIcon from 'components/Icons/edit';
import TrashIcon from 'components/Icons/trash';
import { TextInput } from 'sections/JobFamilyEdit/JobFamilyEdit.components';
import ReactQuill from 'react-quill';
import { useHistory } from 'react-router-dom';

const UpdateSection: React.FC<UpdateSectionProps> = ({
    id,
    updates,
    updatesGetUpdates,
    updatesDeleteUpdate,
    updatesChangeUpdate,
    updatesCreateUpdate,
    currentLanguage,
    canEdit
}) => {
    const history = useHistory();
    const lang = getLanguageObject(currentLanguage);
    const { navigate } = useNavigate();

    const [isEditting, setEditting] = useState(typeof id === "undefined")

    const update: ChangedUpdate | undefined = updates.find(
        update => update.id === id
    ) || {
        title: "",
        content: "",
    };

    const [changedUpdate, setChangedUpdate] = useState<ChangedUpdate>(update);

    useEffect(() => {
        updatesGetUpdates();
    }, [updatesGetUpdates]);

    const editUpdateStart = () => {
        setEditting(!isEditting)
    }

    const deleteUpdate = () => {
        if(typeof id === "undefined") {
            return
        }
        updatesDeleteUpdate(id).finally(() => {
            history.push(Routes.LatestUpdates)
        })
    }

    const onChangeUpdateTitle = (value: string) => {
        if(!changedUpdate) {
            return
        }
        setChangedUpdate({
            ...changedUpdate,
            title: value,
        })
    }

    const onChangeUpdateContent = (value: string) => {
        if(!changedUpdate) {
            return
        }
        setChangedUpdate({
            ...changedUpdate,
            content: value,
        })
    }

    const save = () => {
        if(!changedUpdate) {
            return
        }
        if(!changedUpdate.id) {
            updatesCreateUpdate(changedUpdate);
            history.goBack();
            return
        }
        updatesChangeUpdate(changedUpdate);
        setEditting(false);
    }

    return (
        <>
            <UpdateHeaderButtonsContainer>
                <Button text={lang.back} priority="primary" onClick={() => navigate(Routes.LatestUpdates)} size="small" />
                {canEdit && (
                    <>
                        <IconButton IconSVG={EditIcon} color="primary" onClick={editUpdateStart} title={lang.edit} />
                        <IconButton IconSVG={TrashIcon} color="danger" onClick={deleteUpdate} title={lang.delete} />
                    </>
                )}
            </UpdateHeaderButtonsContainer>
            <UpdateSectionContainer>
                <UpdateTitle isEditting={isEditting} onChange={onChangeUpdateTitle} value={isEditting ? changedUpdate?.title : update.title} />
                <UpdateContent isEditting={isEditting} onChange={onChangeUpdateContent} value={isEditting ? changedUpdate?.content : update.content}/>
                {isEditting && <Button text={update.id ? lang.save : lang.createUpdate} onClick={save} />}
            </UpdateSectionContainer>
        </>
    );
};

export default UpdateSection;

const UpdateTitle : React.FC<{
    isEditting: boolean;
    onChange: (value: string) => void;
    value?: string;
}>= ({
    isEditting,
    onChange,
    value

}) => {
    if(isEditting) {
        return (
            <UnderlinedTitle>
                <TextInput onChange={(event) => {
                    onChange(event.target.value)
                }} value={value} />
            </UnderlinedTitle>
        )
    }
    return (
        <UnderlinedTitle>{value}</UnderlinedTitle>
    )
}

const UpdateContent : React.FC<{
    isEditting: boolean;
    onChange: (value: string) => void;
    value?: string;
}>= ({
    isEditting,
    onChange,
    value

}) => {
    if(isEditting) {
        return (
            <ReactQuill
                theme='snow'
                defaultValue={value}
                style={{
                    marginBottom: 15,
                }}
                onChange={(newText: string) => onChange(newText)}
                modules={{
                    clipboard: {
                        matchVisual: false
                    }
                }}
            />
        )
    }
    
    if(!value) {
        return <div />
    }

    return (
        <div dangerouslySetInnerHTML={{ __html: value }} />
    )
}