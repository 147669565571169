import React from "react";
import styled from "styled-components";
import { Language } from "types/MultiLingualString";

export const CountryFlag: React.FC<{ language: Language }> = ({ language }) => {
    if (language === 'en-EN') {
        return (
            <Image
                src={require('assets/images/languages/EN.png')}
            />
        );
    }
    if (language === 'nl-BE') {
        return <Image src={require('assets/images/languages/BE.png')} />
    }
    if (language === 'fr-BE') {
        return <Image src={require('assets/images/languages/BEFR.png')} />
    }
    if (language === 'de-DE') {
        return <Image src={require('assets/images/languages/DE.png')} />;
    }
    if (language === 'fr-FR') {
        return <Image src={require('assets/images/languages/FR.png')} />;
    }
    if (language === 'pl-PL') {
        return <Image src={require('assets/images/languages/PL.png')} />;
    }
    if (language === 'es-ES') {
        return <Image src={require('assets/images/languages/ES.png')} />;
    }
    return <Image src={require('assets/images/languages/NL.png')} />;
};

const Image = styled.img`
    display: inline;
    vertical-align: middle;
    width: 20;
    height: 15px;
`;
