import { ColumnSetProps } from 'containers/MatrixContent/MatrixContent.types';
import TalentPathDataColumn from 'containers/TalentPathDataColumn/TalentPathDataColumn';
import React from 'react';
import { talentPaths } from 'utils/talentPaths';

const TalentMatrixWithJobs: React.FC<ColumnSetProps> = ({ localData, remoteData, currentLanguage, controllerHandlers, levels }) => {

    return (
        <>
            {talentPaths.map(talentPath => {
                let results = remoteData.results.filter(x => x.validatedProfile.talentPath.key === talentPath)

                return <TalentPathDataColumn levels={levels} key={talentPath} title={talentPath} localData={localData} currentLanguage={currentLanguage} results={results} controllerHandlers={controllerHandlers} />
            })}
        </>
    );
};

export default TalentMatrixWithJobs;
