import { useMsal } from '@azure/msal-react';
import { B2C_URL, MICROSOFT_CLIENT_ID } from 'config';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';
import { GetRole, getRole } from 'store/user/user.actions';
import styled from 'styled-components';

export const SidebarContainer = styled.aside`

`;

export const SidebarNav = styled.nav<{ full: boolean }>`
    padding: 0 0 16px;
    min-height: 100%;
    background-color: #0a1e3c;
    width: ${props => (props.full ? '280px' : '56px')};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
`;

export const SidebarBottom = styled.div`
    position: absolute;
    bottom: 100px;
    width: 100%;
`;

export const Container = styled.div`
    padding: 8px;
    padding: 8px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    position: relative;

    color: #000;
    box-sizing: border-box;
    box-sizing: border-box;
`

export const ProfilePicturesShape = styled.div<{ color?: string }>`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #afc8e1;
    text-transform: uppercase;
`;

export const Details = styled.div`
    display: flex;
    flex-direction: column;
`

export const Name = styled.span`
    color: #fff;
    text-transform: capitalize;
`

export const Role = styled.small`
    color: #fff;
`

export interface CurrentUserSidebarItemProps {
    sidebarOpen: boolean,
    role?: string;
    getRole: GetRole;
}

export const CurrentUserSidebarItem: React.FC<CurrentUserSidebarItemProps> = ({
    sidebarOpen,
    role,
    getRole
}) => {
    const { accounts  } = useMsal();
    const user = accounts[0]?.name;

    // If the user changes, get the role
    useEffect(() => {
        getRole()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accounts[0]?.idToken])


    const MaybeRenderPictureBackgroundColor = () => {
        if (!user) return (
            <ProfilePicturesShape>
                <strong>G</strong>
            </ProfilePicturesShape>
        );

        return (
            <ProfilePicturesShape>
                <strong>{user.charAt(0)}</strong>
            </ProfilePicturesShape>
        );
    };

    const openAccountLinking = () => {
        // Build the URL for account linking
        const baseUrl = B2C_URL + "oauth2/v2.0/authorize";
        const queryParams = new URLSearchParams({
            p: "B2C_1A_ACCOUNTLINKANDUNLINK",
            client_id: MICROSOFT_CLIENT_ID,
            nonce: "defaultNonce",
            redirect_uri:  window.location.origin + "/login",
            scope: "openid",
            response_type: "id_token",
            prompt: "login"
        });
        const url = `${baseUrl}?${queryParams.toString()}`;

        // Open the URL in a new window
        window.open(url);
    };

    const isShareLink = role === "Guest";

    if(isShareLink) return (
        <Container>
            <MaybeRenderPictureBackgroundColor />
            {sidebarOpen &&
            <>
                <Details>
                    <Name>{role}</Name>
                </Details>
            </>
            }
        </Container>
    );

    return (
        <Container>
            <MaybeRenderPictureBackgroundColor />
            {sidebarOpen &&
            <>
                <Details>
                    <Name>{user}</Name>
                    <Role>{role}{" | "}<a style={{cursor: "pointer", textDecoration: "underline"}} onClick={openAccountLinking}>Link account</a></Role>
                </Details>
            </>
            }
        </Container>
    );
};



const mapStateToProps = (state: ReduxStore) => ({
    sidebarOpen: state.navbar.sidebarOpen,
    role: state.user.role
});

const mapDispatchToProps = { getRole };
const ConnectedCurrentUserSidebarItem = connect(
    mapStateToProps,
    mapDispatchToProps
)(CurrentUserSidebarItem);

export default ConnectedCurrentUserSidebarItem;
