import JobProfilerIcon from "components/Icons/jobProfiler";
import NumberTag from "components/NumberTag/NumberTag";
import getLanguageObject from "helpers/language";
import { useNavigate } from "helpers/useNavigation";
import React, { FC } from "react";
import { connect } from "react-redux";
import Routes from "routes/Routes.types";
import { getStringFromCurrentLanguage } from "store/language/language.actions";
import { resultsGetResults } from "store/results/results.actions";
import ReduxStore from "store/store.type";
import styled from "styled-components";
import { Language } from "types/MultiLingualString";
import { Family, Result } from "types/Result";
import { SearchResult } from "utils/searchResults";

interface SearchbarResultsListProps {
    currentLanguage: Language
    found?: SearchResult,
    onClickSearchResult: () => void;
}

export const SearchbarResultsList: FC<SearchbarResultsListProps> = ({ found, currentLanguage, onClickSearchResult }) => {
    const lang = getLanguageObject(currentLanguage);
    const { navigate } = useNavigate();

    if (!found) return <Container></Container> // Loading
    if (!found.jobs && !found.jobFamilies) return <Container>{lang.headerSearchbarNoResults}</Container> // No results

    const sortedResults: Result[] = found.jobs.sort((a, b) => {
        if (a.level !== b.level) {
            return a.level.localeCompare(b.level);
        }
        return a.name.localeCompare(b.name);
    });

    const sortedFamilies: Family[] = found.jobFamilies.sort((a, b) => a.name.localeCompare(b.name));

    const jobs = [...sortedResults, ...sortedFamilies];

    const renderResultItem = (result: Result | Family) => {
        if ('level' in result) {
            const { id, level, name } = result;
            return (
                <ListItem
                    key={id}
                    onClick={() => {
                        onClickSearchResult
                        navigate(Routes.ResultRoute + id);
                    }}
                    title={`${level} ${name}`}
                >
                    <NumberTag level={level} />
                    <Content>
                        {name}
                        <small dangerouslySetInnerHTML={{ __html: getStringFromCurrentLanguage(result.validatedProfile.talentPath.names, currentLanguage) }} />
                    </Content>
                </ListItem>
            );
        }

        const { id, name, jobs } = result;

        if(!jobs || jobs.length < 1) {
            return null;
        }
        
        return (
            <ListItem
                key={id}
                onClick={() => {
                    if(jobs?.length && jobs.length > 0) {
                        onClickSearchResult();
                        navigate(Routes.JobFamilyView + id);
                    }
                }}>
                <Icon>
                    <JobProfilerIcon />
                </Icon>
                <Content>
                    {name}
                    <small dangerouslySetInnerHTML={{ __html: `${lang.jobFamily}: ${jobs?.length} ${lang.jobNRole}` }} />
                </Content>
            </ListItem>
        );
    };

    return <Container>{jobs.map(renderResultItem)}</Container>
}

const mapStateToProps = (state: ReduxStore) => ({
    currentLanguage: state.language.currentLanguage,
    currentOrganisation: state.user.currentOrganisation,
});

const mapDispatchToProps = { resultsGetResults };
const ConnectedSearchResults = connect(mapStateToProps, mapDispatchToProps)(SearchbarResultsList);

export default ConnectedSearchResults;

export const Icon = styled.div`
    width: 48px;
    height: 48px;

    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Content = styled.div`  
    display: flex;
    flex-direction: column;
`;

export const Container = styled.div`
    padding: 10px;
    border-radius: 5px;
    background-color: #ffffff;
    border: 2px solid rgba(0, 0, 0, 0.04);
    display: flex;
    flex-direction: column;
    gap: 8px;
    box-sizing: border-box;

    position: absolute;
    top: 46px;
    left: 0;
    width: 280px;
    height: 400px;
    overflow-y: scroll;
    z-index: 99999;
`;

export const ListItem = styled.div`
    padding: 0 0 8px;
    width: 100%;
    background-color: #ffffff;
    border: 0;
    border-bottom-width: 2px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.04);

    display: grid;
    grid-gap: 10px;
    grid-template-columns: 70px 1fr;
    cursor: pointer;
`;
