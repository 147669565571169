import React, { useEffect, useState } from "react";
import { Language, emptyMultiLingualString } from "types/MultiLingualString";
import BreadCrumbs from "components/BreadCrumbs/BreadCrumbs";
import getLanguageObject from "helpers/language";
import { Header } from 'layouts/MainLayout/MainLayout.components';
import QuestionNode from "types/QuestionNode";
import { IconButton } from "components/Button/IconButton/IconButton";
import AreYouSurePopup from "components/AreYouSurePopup/AreYouSurePopup";
import { CreateAnswer, DeleteAnswer, DeleteQuestion, LoadQuestions, LoadValidatedProfiles, UpdateAnswer, UpdateQuestion } from "store/allocator/allocator.actions";
import { useHistory } from "react-router-dom";
import Routes from "routes/Routes.types";
import { QuestionEditContainer, QuestionSingleContainer, CreateNewContainer, QuestionActionsContainer, AnswerEditCollapsed, DeleteAnswerContainer, AnswerEditOpened } from "./AdminAllocatorQuestionsSingle.components";
import MultiLingualInputField from "containers/MultiLingualInputField/MultiLingualInputField";
import Answer from "types/Answer";
import ArrowDownIcon from "components/Icons/arrowDown";
import Button from "components/Button/Button";
import styled from "styled-components";
import InputField from "containers/InputField/InputField";
import { StyledSelect } from "../AdminAllocatorValidatedProfilesSingle/AdminAllocatorValidatedProfilesSingle.components";
import { ValidatedProfile } from "types/Result";
import { getLevelIndex } from "utils/levels";
import { InputFieldErrorMessages } from "containers/InputField/InputField.components";
import { RemoteTalentPath } from "types/TalentPath";

const StyledHeader = styled(Header)`
    display: grid;
    grid-template-columns: 1fr auto;
`

interface AdminAllocatorQuestionsSingleProps {
    id: string;
    questions: QuestionNode[];
    currentLanguage: Language;
    updateQuestion: UpdateQuestion;
    deleteQuestion: DeleteQuestion;
    loadQuestions: LoadQuestions;
    loadValidatedProfiles: LoadValidatedProfiles;
    getRemoteLanguages: () => void;
    createAnswer: CreateAnswer;
    updateAnswer: UpdateAnswer;
    deleteAnswer: DeleteAnswer;
    validatedProfiles: ValidatedProfile[];
    talentPaths: RemoteTalentPath[];
    loadTalentpaths: () => void;
}

export const AdminAllocatorQuestionsSingle: React.FC<AdminAllocatorQuestionsSingleProps> = ({
    id,
    questions,
    currentLanguage,
    updateQuestion,
    deleteQuestion,
    createAnswer,
    updateAnswer,
    deleteAnswer,
    loadQuestions,
    loadValidatedProfiles,
    loadTalentpaths,
    getRemoteLanguages,
    validatedProfiles,
    talentPaths
}) => {
    const history = useHistory();

    useEffect(() => {
        if (!questions || questions.length === 0) {
            loadQuestions();
        }
    }, [])

    useEffect(() => {
        loadValidatedProfiles();
    }, [])

    useEffect(() => {
        getRemoteLanguages()
    }, [])

    useEffect(() => {
        loadTalentpaths();
    }, [])

    const lang = getLanguageObject(currentLanguage);
    const question = questions.find((question) => question.id === id);
    if (!question) {
        return (
            <div>
                Loading
            </div>
        )
    };

    const onDelete = () => {
        deleteQuestion(id).finally(() => {
            history.push(Routes.AdminAllocatorQuestionsRoute);
        })
    }

    return (
        <QuestionSingleContainer>
            <StyledHeader>
                <BreadCrumbs title={question.title} paths={[{
                    title: lang.allocatorQuestions,
                    path: Routes.AdminAllocatorQuestionsRoute,
                }, {
                    title: lang.allocatorQuestion,
                    path: Routes.AdminAllocatorQuestionsRoute,
                }]} />
                <AreYouSurePopup
                    onClick={onDelete}
                    action={lang.deletePlural}
                    item={lang.allocatorQuestion}
                    InnerContent={() => <Button text="Delete Question" />}
                />
            </StyledHeader>
            <QuestionEdit talentPaths={talentPaths} question={question} updateQuestion={updateQuestion} updateAnswer={updateAnswer} createAnswer={createAnswer} deleteAnswer={deleteAnswer} questions={questions} validatedProfiles={validatedProfiles} />
        </QuestionSingleContainer>
    )
}

interface QuestionEditProps {
    question: QuestionNode
    updateQuestion: UpdateQuestion
    createAnswer: CreateAnswer
    updateAnswer: UpdateAnswer
    deleteAnswer: DeleteAnswer
    questions: QuestionNode[]
    validatedProfiles: ValidatedProfile[];
    talentPaths: RemoteTalentPath[];
}

const QuestionEdit: React.FC<QuestionEditProps> = ({ question, updateQuestion, updateAnswer, createAnswer, deleteAnswer, questions, validatedProfiles, talentPaths }) => {
    const [newQuestion, setNewQuestion] = useState<QuestionNode>(question);

    useEffect(() => {
        setNewQuestion(question);
    }, [question])

    const onChangeQuestionText = (field: keyof QuestionNode, value: string, language: Language) => {
        if (newQuestion[field] === value) {
            return
        }
        setNewQuestion({
            ...newQuestion,
            [field]: {
                ...newQuestion.question,
                [language]: value
            }
        })
    }

    return (
        <QuestionEditContainer>
            <InputField type="text" value={newQuestion.title} onChange={(v) => setNewQuestion({
                ...newQuestion,
                title: v.target.value
            })} errorMessage={null} />
            <h2>Question</h2>
            <MultiLingualInputField value={newQuestion.question} onChange={(value, language) => onChangeQuestionText("question", value, language)} />
            <h2>Description</h2>
            <MultiLingualInputField value={newQuestion.description} onChange={(value, language) => onChangeQuestionText("description", value, language)} />
            <QuestionActionsContainer>
                <Button text="Save Question" onClick={() => {
                    updateQuestion(newQuestion);
                }} />
            </QuestionActionsContainer>
            <hr style={{ marginBottom: 50 }} />
            <h2>Answers</h2>
            <AnswersEdit talentPaths={talentPaths} updateAnswer={updateAnswer} answers={newQuestion.answers} createAnswer={() => createAnswer(question.id)} deleteAnswer={deleteAnswer} questions={questions} validatedProfiles={validatedProfiles} />
        </QuestionEditContainer>
    )
}

interface AnswersEditProps {
    updateAnswer: UpdateAnswer;
    answers: Answer[];
    createAnswer: () => Promise<{id: string}>;
    deleteAnswer: DeleteAnswer;
    questions: QuestionNode[];
    validatedProfiles: ValidatedProfile[];
    talentPaths: RemoteTalentPath[];
}

const AnswersEdit: React.FC<AnswersEditProps> = ({ updateAnswer, answers, createAnswer, deleteAnswer, questions, validatedProfiles, talentPaths }) => {
    const [openedAnswer, setOpenedAnswer] = useState<number | null>(null);

    return (
        <div>
            <CreateNewContainer>
                <Button text="Create new answer" onClick={async () => {
                    const res = await createAnswer();
                    const newId = res?.id;
                    if(newId) {
                        const newAnswer = answers.find((a) => a.id === newId);
                        if(newAnswer) {
                            setOpenedAnswer(answers.indexOf(newAnswer));
                        }
                    }
                }}></Button>
            </CreateNewContainer>
            {answers.map((answer, index) => {
                if (openedAnswer === index) {
                    return (
                        <AnswerEditOpened>
                            <DeleteAnswerContainer>
                                <IconButton color="primary" onClick={() => setOpenedAnswer(null)} IconSVG={ArrowDownIcon} />
                                <h3>Answer {index + 1}</h3>

                                <AreYouSurePopup
                                    onClick={() => {
                                        setOpenedAnswer(null);
                                        deleteAnswer(answer.id)
                                    }}
                                    action={"Delete"}
                                    item={"answer"}
                                    InnerContent={() => <Button text={"Delete Answer " + (index + 1)} />}
                                />
                            </DeleteAnswerContainer>
                            <AnswerEdit updateAnswer={updateAnswer} answer={answer} questions={questions} validatedProfiles={validatedProfiles} talentPaths={talentPaths} />
                        </AnswerEditOpened>
                    )
                }

                const profile = validatedProfiles.find((profile) => profile.id === answer.validatedProfileId);
                const profileTitle = profile ? profile.color + " " + profile.styrLevel.level + " " + profile.talentPath.names["en-EN"] : "";

                const nextQuestion = questions.find((q) => q.id === answer.nextQuestionId);
                const nextQuestionTitle = nextQuestion ? nextQuestion.title : "";

                const nextQOrProfileOrNoneTitle = nextQuestionTitle || profileTitle || "None";


                return (
                    <AnswerEditCollapsed>
                        <div>
                            <IconButton color="primary" onClick={() => setOpenedAnswer(index)} IconSVG={ArrowDownIcon} />
                        </div>
                        <h3>Answer {index + 1} ({nextQOrProfileOrNoneTitle})</h3>
                    </AnswerEditCollapsed>
                )
            })}
        </div>
    )
}

interface AnswerEditProps {
    answer: Answer;
    updateAnswer: UpdateAnswer;
    questions: QuestionNode[];
    validatedProfiles: ValidatedProfile[];
    talentPaths: RemoteTalentPath[];
}

const AnswerEdit: React.FC<AnswerEditProps> = ({ answer, questions, updateAnswer, validatedProfiles, talentPaths }) => {
    const [newAnswer, setNewAnswer] = useState<Answer>(answer);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        setNewAnswer(answer);
        setError(null)
    }, [answer])

    const onChangeAnswerText = (value: string, language: Language) => {
        setNewAnswer({
            ...newAnswer,
            text: {
                ...newAnswer.text,
                [language]: value
            }
        })
    }

    const onChangeAnswerTitle = (value: string, language: Language) => {
        setNewAnswer({
            ...newAnswer,
            info: {
                title: {
                    ...emptyMultiLingualString,
                    ...newAnswer.info?.title,
                    [language]: value
                },
                text: newAnswer.info?.text || emptyMultiLingualString, // Ensure text is always defined
                icon: newAnswer.info?.icon || "" // Ensure icon is always defined
            }
        })
    }

    const onChangeAnswerInfo = (value: string, language: Language) => {
        setNewAnswer({
            ...newAnswer,
            info: {
                title: newAnswer.info?.title || emptyMultiLingualString, // Ensure title is always defined
                text: {
                    ...emptyMultiLingualString,
                    ...newAnswer.info?.text,
                    [language]: value
                },
                icon: newAnswer.info?.icon || "" // Ensure icon is always defined
            }
        })
    }

    const onChangeNextQuestion = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setNewAnswer({
            ...newAnswer,
            nextQuestionId: e.target.value || undefined
        })
    }

    const onChangeValidatedProfile = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setNewAnswer({
            ...newAnswer,
            validatedProfileId: e.target.value || undefined
        })
    }

    const onChangeTalentPath = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setNewAnswer({
            ...newAnswer,
            talentPathId: e.target.value || undefined
        })
    }

    return (
        <div>
            {/* FIXME: what is the difference between title and text here? */}
            <h3>Text</h3>
            <MultiLingualInputField value={newAnswer.text} onChange={onChangeAnswerText} />
            <h3>Title</h3>
            <MultiLingualInputField value={newAnswer.info?.title} onChange={onChangeAnswerTitle} />
            <h3>Info</h3>
            <MultiLingualInputField value={newAnswer.info?.text} onChange={onChangeAnswerInfo} />
            <h3>Next Question</h3>
            <StyledSelect onChange={onChangeNextQuestion} value={newAnswer.nextQuestionId}>
                <option value="" selected={null === newAnswer.nextQuestionId}>None</option>
                {questions.map((question) => {
                    return (
                        <option value={question.id} selected={question.id === newAnswer.nextQuestionId}>{question.title}</option>
                    )
                })}
            </StyledSelect>
            <h3>Validated Profile</h3>
            <StyledSelect onChange={onChangeValidatedProfile} value={newAnswer.validatedProfileId}>
                <option value="" selected={null === newAnswer.nextQuestionId}>None</option>
                {validatedProfiles.sort((a, b) => {
                    const levelA = a.styrLevel.level.toLowerCase();
                    const levelAIndex = getLevelIndex(levelA);
                    const levelB = b.styrLevel.level.toLowerCase();
                    const levelBIndex = getLevelIndex(levelB);
                    if (levelA === levelB) {
                        return a.talentPath.names["en-EN"].localeCompare(b.talentPath.names["en-EN"]);
                    }
                    return levelAIndex - levelBIndex;
                }).map((validatedProfile) => {
                    return (
                        <option value={validatedProfile.id} selected={validatedProfile.id === newAnswer.validatedProfileId}>{validatedProfile.color + " " + validatedProfile.styrLevel.level + " " + validatedProfile.talentPath.names["en-EN"]?.toLowerCase()}</option>
                    )
                })}
            </StyledSelect>
            <h3>Talent path (icon)</h3>
            <StyledSelect onChange={onChangeTalentPath} value={newAnswer.talentPathId || undefined}>
                <option value="" selected={null === newAnswer.talentPathId}>None</option>
                {talentPaths.map((t) => {
                    return (
                        <option value={t.id}>{t.names["en-EN"]}</option>
                    )
                })}
            </StyledSelect>
            <hr />
            {error && <InputFieldErrorMessages text={error} />}
            <Button text="Save Answer" onClick={() => {
                updateAnswer(newAnswer).catch((e) => {
                    e.json().then((body: { detail: string }) => {
                        setError(body.detail);
                    })
                })
            }} />
        </div>
    )
}
