import React, { useState } from 'react';
import { cleanUpSanitizedInput } from "helpers/sanitization";
import { Organisation } from "types/Organisation";
import styled from "styled-components";
import ThumbTackIcon from 'components/Icons/thumbtack';

export const MenuItem: React.FC<MenuItemProps> = ({ organisation, onClick, isPinned, onSave }) => {
    const [hover, setHover] = useState(false)


    return <Container
        key={`organisation-${organisation.id}`}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}>
        <Content onClick={() => onClick(organisation)}>{cleanUpSanitizedInput(organisation.name)}</Content>
        {hover && <Icon onClick={() => onSave(organisation.id)}>
            <ThumbTackIcon />
        </Icon>}
    </Container>;
}

interface MenuItemProps {
    isPinned?: boolean
    onSave: (id: string) => void
    organisation: Organisation
    onClick: (organisation: Organisation) => void
}

const Container = styled.div`
    padding: 0 20px;
    display: grid;
    grid-template-columns: 1fr 44px;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;

    :hover {
        background-color: rgba(0,0,0, 8%);
        cursor: pointer;
    }
`;

const Content = styled.div`
    display: flex;
    align-items: center;
    height: 42px;
    width: 100%;
    color: #000;
`

const Icon = styled.div`
    padding-right: 20px;
    width: 44px;
    display: flex;
    align-items: center;
    justify-content:  flex-end;

`