import ConnectedOrganisationDropdownMenu from 'components/OrganisationDropdownMenu/OrganisationDropdownMenu.connector';
import SidebarItem from 'components/SidebarItem/SidebarItem';
import { SidebarDivider } from 'components/SidebarItem/SidebarItem.components';
import getLanguageObject from 'helpers/language';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Routes from 'routes/Routes.types';
import ConnectedCurrentUserSidebarItem, {
    SidebarContainer,
    SidebarNav
} from './Sidebar.components';
import { AdminSidebarProps } from './Sidebar.types';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import LogoutIcon from 'components/Icons/logout';
import AllocatorIcon from 'components/Icons/allocator';
import JobProfilerIcon from 'components/Icons/jobProfiler';

const AdminSidebar: React.FC<AdminSidebarProps> = ({
    sidebarOpen,
    currentLanguage,
    userLogout
}) => {
    const isUsingMSAL = useIsAuthenticated();
    const { instance } = useMsal();
    const lang = getLanguageObject(currentLanguage);
    const history = useHistory();

    return (
        <SidebarContainer>
            <SidebarNav full={sidebarOpen}>
                <div>
                    <ConnectedCurrentUserSidebarItem />
                    <SidebarItem
                        sidebarOpen={sidebarOpen}
                        IconSvg={AllocatorIcon}
                        text={lang.allocatorQuestions}
                        navigateToPage={Routes.AdminAllocatorQuestionsRoute}
                    />
                    <SidebarItem
                        sidebarOpen={sidebarOpen}
                        IconSvg={JobProfilerIcon}
                        text={lang.styrProfile}
                        navigateToPage={Routes.AdminAllocatorValidatedProfilesRoute}
                    />
                </div>
                <div>
                    <SidebarDivider />
                    <SidebarItem
                        sidebarOpen={sidebarOpen}
                        IconSvg={LogoutIcon}
                        text={lang.logout}
                        onClick={() => {
                            if (isUsingMSAL) {
                                instance.logoutPopup()
                            }
                            userLogout(history)
                        }}
                    />
                </div>
            </SidebarNav>
        </SidebarContainer>
    );
};

export default AdminSidebar;
