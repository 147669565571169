import styled from 'styled-components';
import theme from 'utils/theme';

export const Header = styled.div`
    margin-bottom: 1em;
`;

export const Main = styled.div`
    margin: 0 auto;
    background-color: white;
    border-radius: 8px;
    box-shadow: ${theme.boxShadow};
    padding: 1em;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: calc(100vh - 350px);
    width: calc(100% - 80px) !important;

    ::-webkit-scrollbar {
        width: 10px !important;
        display: inline-block !important;
    }

    ::-webkit-scrollbar-thumb {
        background-color: darkgrey !important;
        border-radius: 10px !important;
    }
`;

export const ActivateHeader = styled.div`
    display: grid;
    grid-template-columns: 1fr;
`;
