import styled from 'styled-components';
import theme from 'utils/theme';
import { ContainerProps } from './AllocatorButton.types';

export const Container = styled.button<ContainerProps>`
    all: unset;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: auto 1fr 40px;
    align-items: center;
    color: ${props =>
        props.selected
            ? 'white'
            : '#000'};
    background-color: ${props => (props.selected ? '#3b4b63' : '#fff')};
    border-radius: 8px;
    box-shadow: ${theme.boxShadow};
    min-height: 48px;
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
        background-color: #6c788a;
        color: #fff;
    }
`;
