import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Routes from 'routes/Routes.types';
import ReduxStore from 'store/store.type';
import {
    IconContainer,
    SidebarItemContainer, SidebarItemsDropdownContainer, TextContainer, UpdateFeedback
} from './SidebarItem.components';
import SidebarItemProps, { SidebarItemsDropwdownProps } from './SidebarItem.types';
import ArrowRightIcon from 'components/Icons/arrowRight';

const SidebarItem: React.FC<SidebarItemProps> = ({
    IconSvg,
    text,
    navigateToSite,
    navigateToPage,
    isActive,
    sidebarOpen,
    isSubitem,
    updateAvailable,
    onClick
}) => {
    const history = useHistory();
    const navigate = (route: Routes) => {
        history.push(route);
    };

    const Text = () => {
        if (sidebarOpen) {
            return <TextContainer>{text}</TextContainer>;
        }
        return null;
    };

    const checkIfActive = (route: Routes) => {
        const activePage = isActive.split('/')[1];
        const currentRoute = route.split('/')[1];
        
        return activePage === currentRoute;
    };

    return (
        <SidebarItemContainer
            active={navigateToPage ? checkIfActive(navigateToPage) : false}
            isSubitem={isSubitem}
            sidebarOpen={sidebarOpen}
            onClick={() => {
                if (navigateToSite) {
                    window.open(navigateToSite, '_blank');
                }
                if (navigateToPage) {
                    navigate(navigateToPage);
                }
                if (onClick) { 
                    onClick();
                }
            }}
        >
            <IconContainer>
                <IconSvg />
                {updateAvailable && <UpdateFeedback />}
            </IconContainer>
            <Text />
        </SidebarItemContainer>
    );
};

const mapeStateToProps = (state: ReduxStore) => ({
    isActive: state.navbar.activeNavbarItem, 
});
const mapDispatchToProps = {};

export default connect(mapeStateToProps, mapDispatchToProps)(SidebarItem);

export const SidebarItemsDropdown: React.FC<SidebarItemsDropwdownProps> = ({ sidebarOpen, text, children, collapse, setCollapse }) => {
    const Text = () => {
        if (sidebarOpen) {
            return <TextContainer>{text}</TextContainer>;
        }
        return null;
    };

    return (
        <>
            <SidebarItemsDropdownContainer sidebarOpen={sidebarOpen} onClick={() => setCollapse(!collapse)}>
                <IconContainer>
                    <ArrowRightIcon />
                </IconContainer>
                <Text />
            </SidebarItemsDropdownContainer>
            {collapse && children}
        </>
    )
}