import React from 'react';

const MinusIcon: React.FC = () => {
  return (
    <svg width="18" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="5" y="11" width="14" height="2" fill="currentColor"/>
    </svg>
  );
}

export default MinusIcon;