import styled from 'styled-components';
import theme from 'utils/theme';

export const CardContainer = styled.div<{removePadding?: boolean; width?: number}>`
    background-color: white;
    border-radius: 8px;
    box-shadow: ${theme.boxShadow};
    padding: ${props => props.removePadding ? '0' : '1em'};
    overflow: hidden;
    width: ${props => props.width ? `${props.width}px` : "unset"};
`;
