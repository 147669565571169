enum ResultsActions {
    AddError = 'AddError',
    ClearErrors = 'ClearErrors',
    UpdateResult = 'UpdateResult',
    UpdateResultExtraCompetence = 'UpdateResultExtraCompetence',
    RemoveExtraCompetence = 'RemoveExtraCompetence',
    UpdateResults = 'UpdateResults',
    UpdateResultValidatedProfile = "UpdateResultValidatedProfile",
    CreateJobFamily = "CreateJobFamily",
    UpdateJobFamily = "UpdateJobFamily",
    UpdateResultByField = "UpdateResultByField"
}

export default ResultsActions;

export enum TagTypes {
    tagsFutureState = "1e25ae00-b0ac-477e-b8c6-de9b74156225",
    tagsCurrentState = "964f631c-7261-4dc8-a34a-e9762e9b0334",
}
