import { Link } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'utils/theme';

export const StyledLink = styled(Link)`
    text-decoration: none;
    color: #000;
    cursor: pointer;
    position: relative;

    li{
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid rgba(0,0,0, .26);
        padding: 10px 0;

        strong{
            text-decoration: underline;
        }

        &:last-of-type{
            border: 0;
        }
    }
    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
        text-decoration: none;
    }
`;

export const JobListBox = styled.div`
    background: rgba(0,0,0, .8);
    width: 100% !important;
    height: 100% !important;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: ${theme.colors.dark};
        display: flex;
        position: fixed !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9999 !important;
`;

export const JobList = styled.div<{ open?: boolean }>`
    padding: 1rem;
    width: 400px;
    text-align: center;
    overflow: hidden;
    border-radius: 10px;
    background-color: ${props => props.open ? "#fff" : "transparent"};
`;

export const JobListLevel = styled.div<{ color: string }>`
    background: ${props => props.color};
    border: 2px solid #000;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    width: 30px;
    height: 30px;
    font-size: 1.125em;
`;



export const Job = styled.div`
    padding: 4px 0;

    cursor: pointer;
    position: relative;
    
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;

    &:after{
        content: '';
        position: absolute;
        bottom: -5px;
        left: 50%;
        transform: translateX(-50%);
        width: 20px;
        height: 2px;
        background-color: #3b4b63;
    }

    &:last-of-type{
        border-bottom: 0;
    }
`;

export const JobContainer = styled.div<{ hasTag?: boolean}>`
    margin-bottom: 5px;
    position: relative;
`;

export const JobWithView = styled.div<{ selected?: boolean }>`
    background-color: ${props => props.selected ? 'rgba(0,0,0, .12)' : 'transparent'};
    &:hover{
        background-color: rgba(0,0,0, .12);
    }
`;

export const PopupWithJobs = styled.div`
    position: relative;
`;
export const JobsList = styled.div<{ showEdit?: boolean }>`
    width: 378px;
    background-color: #fff;
    border-radius: ${props => props.showEdit ? "10px 0 0 10px" : "10px"};
`

export const JobListHead = styled.div`
    padding: 1em;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 10px;
    align-items: center;
`

export const JobListBody = styled.div<{ countItems?: number }>`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: ${props => props.countItems ? `repeat(${props.countItems}, 53px)` : 'auto'};
    box-shadow: inset 0px 4px 8px rgba(0,0,0,.12);
    min-height: 300px;
    `

export const Options = styled.div<{ active?: boolean }>`
    position: absolute;
    top: 0px;
    right: -200px;
    z-index: 0;
    width: 200px;
    display: ${props => props.active ? 'grid' : 'none'};
    grid-template-columns: 1fr;
    grid-gap: 10px;
    background-color: #fff;
    border-radius: 0 10px 10px 0;
    box-shadow: 0px 0px 8px 0 rgba(0,0,0,.12);
    overflow: hidden;
`

export const Container = styled.div`
    position: relative;
`

export const Title = styled.div`
    padding: 1em;
`

export const Option = styled(Link)`
    padding: 1em;
    color: #000;
    font-weight: bold;

    &:hover{
        background-color: rgba(0,0,0, .12);
    }

    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
        text-decoration: none;
    }
`

export const EditOption = styled.div`
    padding: 1em;
    color: #000;
    font-weight: bold;

    &:hover{
        background-color: rgba(0,0,0, .12);
    }

    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
        text-decoration: none;
    }
`

export const EditJobPopup = styled.div<{ visible?: boolean }>`
    position: absolute;
    top: 0px;
    right: -377px;
    width: 378px;
    background-color: #fff;
    display: ${props => props.visible ? 'block' : 'none'};
    border-radius: 0 10px 10px 10px;
    max-height: 75vh;
    overflow-y: auto;
`
