import { Configuration, RedirectRequest } from "@azure/msal-browser";
import { MICROSOFT_AUTHORITY_URL, MICROSOFT_CLIENT_ID, MICROSOFT_KNOWN_AUTHORITY } from "config";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: MICROSOFT_CLIENT_ID,
        redirectUri: window.location.origin + "/login",
        postLogoutRedirectUri: "/",
        authority: MICROSOFT_AUTHORITY_URL,
        knownAuthorities: [ MICROSOFT_KNOWN_AUTHORITY ]
    },
    cache: {
      cacheLocation: 'localStorage',
    },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
    scopes: [ MICROSOFT_CLIENT_ID ]
};
