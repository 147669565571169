import React from 'react';

const ThumbTackIcon: React.FC<{ color?: string }> = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 384 512" color={color || "black"}><path fill="currentColor" d="M32 32C32 14.3 46.3 0 64 0H320c17.7 0 32 14.3 32 32s-14.3 32-32 32H290.5l11.4 148.2c36.7 19.9 65.7 53.2 79.5 94.7l1 3c3.3 9.8 1.6 20.5-4.4 28.8s-15.7 13.3-26 13.3H32c-10.3 0-19.9-4.9-26-13.3s-7.7-19.1-4.4-28.8l1-3c13.8-41.5 42.8-74.8 79.5-94.7L93.5 64H64C46.3 64 32 49.7 32 32zM160 384h64v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V384z" /></svg>
    );
}

export default ThumbTackIcon;

export const ThumbTackLightIcon: React.FC<{ color?: string }> = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 384 512" color={color || "black"}><path fill="currentColor" d="M264.6 218.2l17.9 13c18.8 13.8 32.7 32.1 41.9 49.6c5.1 9.7 8 17.7 9.7 23.2H216V216 192H168v24 88H49.4c.2-1 .5-2.1 .9-3.2c1.9-6.5 4.9-14.4 9.4-22.9c9-17 22.6-34.5 41.2-47.6l18.6-13 1.7-22.6L132.4 48H251.6l11.4 148.2 1.7 22zM216 352H336h48V312c0-2.3-.2-4.9-.7-8c-4.1-25.8-26-77.6-72.5-111.5L299.7 48H328h24V0H328 296 88 56 32V48H56 84.3l-11 143C26.1 224.1 4.2 275.6 .6 304c-.4 2.9-.6 5.6-.6 8v40H48 168V488v24h48V488 352z" /></svg>
    );
}
