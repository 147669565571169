import { css } from 'styled-components';

const generateFontFace = ({
    fontFaceName,
    fileName,
    fontWeight,
    fontStyle,
}: Font) => `
    @font-face {
        font-family: "${fontFaceName}";
        src: url(${require(`../fonts/${fileName}.ttf`)}) format("truetype");
        font-style: ${fontStyle};
        font-weight: ${fontWeight};
    }
`;

const fonts: Font[] = [
    {
        fontFaceName: 'Avenir',
        fileName: 'Avenir',
        fontWeight: 200,
        fontStyle: 'normal',
    },
    {
        fontFaceName: 'Avenir',
        fileName: 'Avenir-Book',
        fontWeight: 400,
        fontStyle: 'normal',
    },
    {
        fontFaceName: 'Avenir',
        fileName: 'Avenir-Medium',
        fontWeight: 500,
        fontStyle: 'normal',
    },
    {
        fontFaceName: 'Avenir',
        fileName: 'Avenir-Heavy',
        fontWeight: 900,
        fontStyle: 'normal',
    },
    {
        fontFaceName: 'NunitoSans',
        fileName: 'NunitoSans-Regular',
        fontWeight: 500,
        fontStyle: 'normal',
    },
];

const globalFonts = fonts.map(font => generateFontFace(font)).join('\n');

export default css`
    ${globalFonts}
`;

interface Font {
    fontFaceName: string;
    fileName: string;
    fontWeight: number;
    fontStyle: string;
}
