import ConnectedLanguageButton from 'containers/LanguageButton/LanguageButton.connector';
import React from 'react';
import {
    LanguageSwitch, LoginFormContainer,
    LoginImage, LoginSectionContainer
} from './LoginSection.components';
import LoginSectionProps from './LoginSection.types';

const LoginSection: React.FC<LoginSectionProps> = ({
    children,
}) => {
    return (
        <LoginSectionContainer>
            <LoginImage style={{
                backgroundColor: '#fff',
                backgroundImage: `url(${require('assets/images/allocator_image.svg').default})`,
                backgroundSize: '350px',
                backgroundRepeat: 'no-repeat',
                position: 'relative',
            }}>
            </LoginImage>
            <LoginFormContainer>
                <LanguageSwitch>
                    <ConnectedLanguageButton />
                </LanguageSwitch>
                {children}
            </LoginFormContainer>
        </LoginSectionContainer>
    );
};

export default LoginSection;
