import { useNavigate } from 'helpers/useNavigation';
import React from 'react';
import styled from 'styled-components';
import { DashboardBlockProps } from './DashboardBlocksSection.types';

export const DashboardBlocksContainer = styled.article`
    height: auto;

    width: min(350px, 20vw);
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px 0 rgb(167 178 195 / 32%);
    overflow: hidden;

    cursor: pointer;

    &:nth-child(even) {background: #afc8e1}
    &:nth-child(odd) {background: #afc8e1}

    &:hover{
        &:nth-child(even) {background: #bfd3e7}
        &:nth-child(odd) {background: #bfd3e7}
    }
`

const DashboardBlocksHeader = styled.header`
    aspect-ratio: 16 / 9;
    display: flex;
    align-items: center;
    justify-content: center;
        overflow: hidden;
`

const Figure = styled.figure`
    margin: 0;
    padding: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
`;

const DashboardBlocksBody = styled.div`
    padding: 1.25em 1em;
    background-color: #fff;
    font-weight: bold;
`

const DashboardBlocksImg = styled.img<{ enlarge?: boolean; }>`
    width: auto;
    height: 160px;
`;


export const DashboardBlocks: React.FC<DashboardBlockProps> = ({ title, image, navigateToSite, navigateToPage }) => {
    const { navigate } = useNavigate();

    const goto = () => {
        if (navigateToSite) {
            window.open(navigateToSite, '_blank');
            return;
        } else if (navigateToPage) {
            navigate(navigateToPage);
        }
    }

    return (
        <DashboardBlocksContainer onClick={goto}>
            <DashboardBlocksHeader>
                <Figure>
                    <DashboardBlocksImg
                        src={image}
                    />
                </Figure>
            </DashboardBlocksHeader>
            <DashboardBlocksBody>
                {title}
            </DashboardBlocksBody>
        </DashboardBlocksContainer >
    )
}

const Container = styled.div<{ count: string }>`
    display: grid;
    grid-template-areas: ${props => props.count};
    grid-gap: 1em;
    align-items: center;
    justify-content: center;
    max-height: 100%;
    max-width: 100%;
`;

export const BlocksGrid: React.FC<{children: React.ReactNode}> = ({ children }) => {
    const itemsCount = React.Children.toArray(children).length;

    const columns = (itemsCount: number) => {
        if (itemsCount === 4) return ("'. .' '. .'");

        return ("'. . .' '. . .'");
    }

    return (<Container count={columns(itemsCount)}>
        {children}
    </Container>)
}
