import Button, { BackButton } from 'components/Button/Button';
import CompareWithLevelPopUp from 'components/CompareWithLevelPopUp/CompareWithLevelPopUp';
import { H2 } from 'components/Typography/Typography';
import ConnectedComparingLevelsTable from 'containers/ComparingLevelsTable/ComparingLevelsTable.connector';
import getLanguageObject from 'helpers/language';
import React, { useState } from 'react';
import { ComparingLevelsSectionContainer, Header } from './ComparingLevels.components';
import ComparingLevelsProps from './ComparingLevels.types';
import { ValidatedProfile } from 'types/Result';

const convertValidatedProfileIdsToValidatedProfiles = (ids: string[] | undefined, validatedProfiles: ValidatedProfile[]) => {
    if (!ids) { return [] }
    return ids.map(id => validatedProfiles.find(validatedProfile => validatedProfile.id === id) as ValidatedProfile);
}

const ComparingLevels: React.FC<ComparingLevelsProps> = ({ id, title, currentLevel, result, preview, currentLanguage, currentOrganisation, changePreviewValidatedProfile, changeResultValidatedProfile, goBack, validatedProfiles }) => {
    const lang = getLanguageObject(currentLanguage);
    const [selectedLevels, setSelectedLevels] = useState([`${currentLevel}`])

    const handleSelectedLevels = (level: string) => {
        if(level === currentLevel) return;

        const checkInSelectedLevels = selectedLevels.includes(level);
        const checkSelectedLevels = selectedLevels.filter(
            (item) => item !== level
        );
        if (!checkInSelectedLevels && selectedLevels.length < 3) return setSelectedLevels([...selectedLevels, level]);

        return (setSelectedLevels(checkSelectedLevels));
    };

    if (currentOrganisation === undefined) return null;

    const ComparingLevelTables = () => {
        if (result) return <ConnectedComparingLevelsTable talentpath={result.validatedProfile.talentPath.key} currentId={id} currentLevel={result.level.toString()} data={convertValidatedProfileIdsToValidatedProfiles(result.validatedProfile.talentPath.validatedProfileIds, validatedProfiles)} selectedLevels={selectedLevels} changeResultValidatedProfile={changeResultValidatedProfile} currentOrganisation={currentOrganisation} />
        if (preview) return <ConnectedComparingLevelsTable talentpath={preview.talentPath.key} currentLevel={currentLevel} data={convertValidatedProfileIdsToValidatedProfiles(preview.talentPath.validatedProfileIds, validatedProfiles)} selectedLevels={selectedLevels} changePreviewValidatedProfile={changePreviewValidatedProfile} currentOrganisation={currentOrganisation} />
        return null;
    }

    return (
        <ComparingLevelsSectionContainer>
            <Header>
                <BackButton text={`${lang.backToValidatedProfile}`} onClick={goBack} />
                <H2>{lang.talentpath}</H2>
                <CompareWithLevelPopUp
                    results={convertValidatedProfileIdsToValidatedProfiles(result?.validatedProfile.talentPath.validatedProfileIds, validatedProfiles)}
                    InnerContent={() => <Button text={lang.changeStyrLevel} />}
                    selectedLevels={selectedLevels}
                    handleSelectedLevel={handleSelectedLevels}
                    goBack={goBack}
                    currentLevel={currentLevel}
                />
            </Header>
            <ComparingLevelTables />
        </ComparingLevelsSectionContainer>
    );
};

export default ComparingLevels;
