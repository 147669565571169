import React from 'react';
import { AwaitLoadingContainer, AwaitIcon, AwaitLoadingContainerNoBackground } from './AwaitLoading.components';
import AwaitLoadingProps from './AwaitLoading.types';
import { ClipLoader } from 'react-spinners';

const AwaitLoading: React.FC<AwaitLoadingProps> = ({ message }) => {
    if (!message) {
        return (
            <AwaitLoadingContainerNoBackground>
                <ClipLoader
                    loading={true}
                    size={45}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </AwaitLoadingContainerNoBackground>
        )
    }
    return (
        <AwaitLoadingContainer><AwaitIcon>{message}</AwaitIcon></AwaitLoadingContainer>
    );
};

export default AwaitLoading;
