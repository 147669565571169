import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import * as Sentry from "@sentry/react";
import Button from 'components/Button/Button';
import getLanguageObject from 'helpers/language';
import { History } from 'history';
import { loginRequest } from 'msal.config';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Language } from 'types/MultiLingualString';

const MicrosoftLoginContainer = styled.div`
    cursor: pointer;
    margin-top: 12px;
    width: 214px;
`

export const MicrosoftLogin : React.FC<{
    userLoginMicrosoft: (
        token: string, history: History
    ) => void,
    userAddError: (error: string) => void
    currentLanguage: Language;
}> = ({currentLanguage, userLoginMicrosoft, userAddError}) => {
    const language = getLanguageObject(currentLanguage);
    const { instance, accounts, inProgress } = useMsal();
    const history = useHistory();

    const startLogin = () => {
        instance.loginRedirect(loginRequest).catch((e) => {
            if(e.errorCode === "interaction_in_progress") {
                // Do nothing, error is expected
                return;
            }
        })
    }

    useEffect(() => {
        const login = () => {
            instance.acquireTokenSilent({
                scopes: [],
                account: accounts[0],
            }).then(response => {
                userLoginMicrosoft(response.idToken, history)
            }).catch(e => {
                Sentry.captureException(e)
            })
        }

        const checkLogin = () => {
            if (accounts.length > 0) {
                login()
            } else {
                if(inProgress === InteractionStatus.None || inProgress === InteractionStatus.Logout) {
                    startLogin();
                }
            }
        }

        checkLogin();

        const interval = setInterval(checkLogin, 3000);
        const timeout = setTimeout(() => {
            startLogin();
        }, 6500);

        return () => {
            clearTimeout(timeout)
            clearInterval(interval)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accounts, instance]);

    return (
        <MicrosoftLoginContainer>
            <Button text={language.login} onClick={startLogin} priority="tertiary" />
        </MicrosoftLoginContainer>
    )
}
