import { useHistory } from "react-router-dom";
import Routes from "routes/Routes.types";

export const useNavigate = () => {
    const history = useHistory();
    const navigate = (route: Routes | string) => {
        history.push(route);
    };
    return { navigate }
}
