import { MatrixViews } from 'containers/MatrixContent/MatrixContent.types';
import ConnectedMatrixContextProvider from 'containers/MatrixContextProvider/MatrixContextProvider';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ContainerFullScreen } from 'screens/Matrix/Matrix.components';
import JobMatrixResults from 'sections/JobMatrix/JobMatrixResults/JobMatrixResults.connector';
import { isLanguage, Language } from 'types/MultiLingualString';

const JobMatrixPrint: React.FC = () => {
    const { language } = useParams<{language: string}>();
    const lang : Language = isLanguage(language) ? language as Language : 'en-EN';

    return (
        <ContainerFullScreen>
            <ConnectedMatrixContextProvider>
                <JobMatrixResults language={lang} fullScreen matrixView={MatrixViews.jobmatrix} />
            </ConnectedMatrixContextProvider>
        </ContainerFullScreen>
    )
};

export default JobMatrixPrint;
