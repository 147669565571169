import React, { useEffect } from "react";
import { CreateValidatedProfile, LoadValidatedProfiles } from "store/allocator/allocator.actions";
import { Language } from "types/MultiLingualString";
import { ValidatedProfile } from "types/Result";
import { AdminAllocatorValidatedProfilesListContainer, ValidatedProfileContainer, SingleValidatedProfileContainer } from "./AdminAllocatorValidatedProfilesList.components";
import { getLevelIndex } from "utils/levels";
import BreadCrumbs from "components/BreadCrumbs/BreadCrumbs";
import getLanguageObject from "helpers/language";
import { Header } from 'layouts/MainLayout/MainLayout.components';
import { useHistory } from "react-router-dom";
import Routes from "routes/Routes.types";
import { getStringFromCurrentLanguage } from "store/language/language.actions";
import Button from "components/Button/Button";
import { StyledHeader } from "../AdminAllocatorQuestionsList/AdminAllocatorQuestionsList.components";

interface AdminAllocatorValidatedProfilesListProps {
    validatedProfiles: ValidatedProfile[];
    currentLanguage: Language;
    loadValidatedProfiles: LoadValidatedProfiles;
    createValidatedProfile: CreateValidatedProfile;
}

export const AdminAllocatorValidatedProfilesList : React.FC<AdminAllocatorValidatedProfilesListProps> = ({
    validatedProfiles,
    currentLanguage,
    loadValidatedProfiles,
    createValidatedProfile
}) => {
    const history = useHistory();
    const lang = getLanguageObject(currentLanguage);

    useEffect(() => {
        loadValidatedProfiles()
    }, [])

    const sortedValidatedProfiles = validatedProfiles.sort((a, b) => {
        const levelA = a.styrLevel.level.toLowerCase();
        const levelAIndex = getLevelIndex(levelA);
        const levelB = b.styrLevel.level.toLowerCase();
        const levelBIndex = getLevelIndex(levelB);
        if(levelA === levelB) {
            return getStringFromCurrentLanguage(a.talentPath.names, currentLanguage).localeCompare(getStringFromCurrentLanguage(b.talentPath.names, currentLanguage));
        }
        return levelAIndex - levelBIndex;
    })

    const navigate = (id: string) => {
        history.push(Routes.AdminAllocatorValidatedProfileRoute + id);
    }

    if(!validatedProfiles || validatedProfiles.length === 0) {
        return (
            <AdminAllocatorValidatedProfilesListContainer>
                <StyledHeader>
                    <BreadCrumbs title={lang.styrProfile} paths={[]} />
                    <Button text="Create Validated Profile" onClick={() => {
                    createValidatedProfile().then((id) => {
                        history.push(Routes.AdminAllocatorValidatedProfileRoute + id);
                    })
                    }}/>
                </StyledHeader>
                <ValidatedProfileContainer>
                    <SingleValidatedProfileContainer>Loading...</SingleValidatedProfileContainer>
                </ValidatedProfileContainer>
            </AdminAllocatorValidatedProfilesListContainer>
        )
    }

    return (
        <AdminAllocatorValidatedProfilesListContainer>
            <StyledHeader>
                <BreadCrumbs title={lang.styrProfile} paths={[]} />
                <Button text="Create Validated Profile" onClick={() => {
                    createValidatedProfile().then((id) => {
                        history.push(Routes.AdminAllocatorValidatedProfileRoute + id);
                    })
                }}/>
            </StyledHeader>
            <ValidatedProfileContainer>
                {sortedValidatedProfiles.map((validatedProfile) => (
                    <SingleValidatedProfileContainer key={validatedProfile.id} onClick={() => {navigate(validatedProfile.id)}}>
                        <p>{validatedProfile.title}</p>
                        <p>{validatedProfile.styrLevel.level}</p>
                        <p>{validatedProfile.color}</p>
                        <p>{getStringFromCurrentLanguage(validatedProfile.talentPath.names, currentLanguage)}</p>
                    </SingleValidatedProfileContainer>
                ))}
            </ValidatedProfileContainer>
        </AdminAllocatorValidatedProfilesListContainer>
    )
}