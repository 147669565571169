import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';
import { setCurrentLanguage } from 'store/language/language.actions';
import TranslationTabs from './TranslationTabs';

const mapStateToProps = (state: ReduxStore) => ({
    currentLanguage: state.language.currentLanguage,
});
const mapDispatchToProps = { setCurrentLanguage };
const ConnectedTranslationTabs = connect(mapStateToProps, mapDispatchToProps)(TranslationTabs);

export default ConnectedTranslationTabs;

