import styled from 'styled-components';

export const AwaitLoadingContainerNoBackground = styled.div<{ isLoading?: boolean }>`
    padding: 1em;
    height: 100%;
    width: 100%;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const AwaitLoadingContainer = styled.div`
padding: 1em;
height: 100%;
width: 100%;

background-color: rgba(0,0,0,.64);
position: fixed;
top: 0;
left: 0;
z-index: 9999;

display: block;
`;

export const AwaitIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 170px;
    height: 60px;
    background-color: white;
    border-radius: 10px;
    border: 2px solid #000;
`;
