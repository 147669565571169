import {toast} from "react-toastify";
import {MultiLingualString} from "../types/MultiLingualString";
import {
    getResultsErrorMessage,
    ResultErrorTypeKeys
} from "../store/results/results.errors";
import {
    AllocatorErrorTypeKeys,
    getAllocatorErrorMessage
} from "../store/allocator/allocator.errors";
import {Domain} from "../types/Domain";
import {
    getJobFamilyErrorMessage,
    JobFamilyErrorTypeKeys
} from "../store/families/jobfamily.errors";
import {
    getLanguageErrorMessage,
    LanguageErrorTypeKeys
} from "../store/language/language.errors";
import {
    getUpdatesErrorMessage,
    UpdatesErrorTypeKeys
} from "../store/updates/updates.errors";
import {
    getUserErrorMessage,
    UserErrorTypeKeys
} from "../store/user/user.errors";

//Different types of ErrorKeys, when a new domain is created add the new ErrorKeys to this type
//Necessary so that inside of the [domain].errors.ts files the errorNames are enforced to also be
//in the ErrorKeys type
type ErrorKeys = ResultErrorTypeKeys |AllocatorErrorTypeKeys |
    JobFamilyErrorTypeKeys | UpdatesErrorTypeKeys |
    UserErrorTypeKeys | LanguageErrorTypeKeys

export function handleApiError(errorDomain: keyof typeof Domain, errorName: ErrorKeys, language: keyof MultiLingualString) {
    const translatedErrorMessage = getErrorMessage(errorDomain, errorName, language);
    if (translatedErrorMessage)
        toast.error(translatedErrorMessage, {
            pauseOnHover: true,
            toastId: errorName,
            style: {
                color: 'black'
            }
        });
}

//Mappping of the different domains to the function that returns the error message
const errorDomainToFunction = {
    [Domain.Results]: getResultsErrorMessage,
    [Domain.Allocator]: getAllocatorErrorMessage,
    [Domain.JobFamily]: getJobFamilyErrorMessage,
    [Domain.Language]: getLanguageErrorMessage,
    [Domain.Updates]: getUpdatesErrorMessage,
    [Domain.User]: getUserErrorMessage
};

//Function that returns the error message based on the domain and the errorName
function getErrorMessage(errorDomain: keyof typeof Domain, errorName: ErrorKeys, language: keyof MultiLingualString): string | undefined {
    const errorMessageFunction = errorDomainToFunction[errorDomain] as (errorName: ErrorKeys, language: keyof MultiLingualString) => string | undefined;
    if (errorMessageFunction) {
        return errorMessageFunction(errorName, language);
    } else {
        console.error(`No error message function found for domain: ${errorDomain}`);
        return undefined;
    }
}
