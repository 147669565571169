import React, { useEffect, useState } from 'react';
import {
    H2,
    H3
} from 'components/Typography/Typography';
import getLanguageObject, { LangObject } from 'helpers/language';
import { OrganisationUnitElement, UnitElement } from './ManageOrganisationUnits.components';
import { ConnectedOrganisationUnitForm, ConnectedUnitForm } from './ManageOrganisationUnits.connector';
import { AddItemContainer, Column, Main, Table, Title } from './ManageOrganisationUnits.styling';
import ManageOrganisationUnitsProps from './ManageOrganisationUnits.types';
import { OrganisationUnit, Unit } from 'types/Organisation';
import { Language } from 'types/MultiLingualString';
import { IconButton } from 'components/Button/IconButton/IconButton';
import { FullArrowUpIcon } from 'components/Icons/arrowUp';
import { FullArrowDownIcon } from 'components/Icons/arrowDown';

const OrganisationUnitItem = ({
    value,
    selectedOrganisationUnit,
    currentLanguage,
    deleteOrganisationUnit,
    lang,
    onMove,
    index,
    onClick
}: {
    value: OrganisationUnit,
    selectedOrganisationUnit: string,
    currentLanguage: Language,
    deleteOrganisationUnit: (id: string) => void,
    lang: LangObject,
    onMove: (index: number, direction: 'up' | 'down') => void,
    index: number,
    onClick: () => void
}) => {
    const organisationUnit = value;
    const selected = selectedOrganisationUnit === value.id;
    return (
        <div style={{
            display: "grid",
            gridTemplateColumns: "50px 1fr"
        }} onClick={onClick}>
            <div style={{
                // Under each other
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <IconButton color={"success"} IconSVG={FullArrowUpIcon} onClick={() => onMove(index, 'up')} />
                <IconButton color={"success"} IconSVG={FullArrowDownIcon} onClick={() => onMove(index, 'down')} />
            </div>
            <OrganisationUnitElement selected={selected} currentLanguage={currentLanguage} key={organisationUnit.id} organisationUnit={organisationUnit} deleteOrganisationUnit={deleteOrganisationUnit} lang={lang} />
        </div>
    );
};

export const ManageOrganisationUnits: React.FC<ManageOrganisationUnitsProps> = ({
    currentLanguage,
    currentOrganisation,
    deleteOrganisationUnit,
    deleteUnit,
    updateUnit,
    updateUnitOrder,
    updateOrganisationUnitOrder,
    updateOrganisationRequest
}) => {
    const lang = getLanguageObject(currentLanguage);
    const [selectedOrganisationUnit, setSelectedOrganisationUnit] = useState('');

    useEffect(() => {
        if (!selectedOrganisationUnit && currentOrganisation && currentOrganisation.organisationUnits[0]) setSelectedOrganisationUnit(currentOrganisation.organisationUnits[0].id);
    }, [setSelectedOrganisationUnit, currentOrganisation, selectedOrganisationUnit]);

    useEffect(() => {
        updateOrganisationRequest();
    }, []);

    const moveOrganisationUnit = (index: number, direction: 'up' | 'down') => {
        if (!currentOrganisation || !currentOrganisation.organisationUnits) return;
        if (index === 0 && direction === 'up') return;
        if (index === currentOrganisation.organisationUnits.length - 1 && direction === 'down') return;
        const oldIndex = index;
        const newIndex = direction === 'up' ? index - 1 : index + 1;
        updateOrganisationUnitOrder(oldIndex, newIndex)
    };

    const moveUnit = (index: number, direction: 'up' | 'down') => {
        if (!selectedOrganisationUnit || !currentOrganisation) return;
        const organisationUnit = currentOrganisation.organisationUnits.find(u => u.id === selectedOrganisationUnit);
        if (!organisationUnit) return;
        if (index === 0 && direction === 'up') return;
        if (index === organisationUnit.units.length - 1 && direction === 'down') return;
        const oldIndex = index;
        const newIndex = direction === 'up' ? index - 1 : index + 1;
        updateUnitOrder(selectedOrganisationUnit, oldIndex, newIndex)
    }

    if (!currentOrganisation) {
        return null;
    }

    const selectedOrganisationUnitObj = currentOrganisation.organisationUnits.find(u => u.id === selectedOrganisationUnit)

    return (
        <Main>
            <Table>
                <Column>
                    <Title><H2>{lang.organisationUnit}</H2></Title>
                    {currentOrganisation.organisationUnits.map((value, index) => (
                        <OrganisationUnitItem key={value.id} index={index} value={value} deleteOrganisationUnit={deleteOrganisationUnit} selectedOrganisationUnit={selectedOrganisationUnit} currentLanguage={currentLanguage} lang={lang} onMove={moveOrganisationUnit} onClick={() => {
                            setSelectedOrganisationUnit(value.id);
                        }}/>
                    ))}
                    <AddItemContainer><H3>{lang.addNewOrganisationUnit}</H3><ConnectedOrganisationUnitForm /></AddItemContainer>
                </Column>
                <Column>
                    {selectedOrganisationUnit && currentOrganisation.organisationUnits.find(u => u.id === selectedOrganisationUnit)?.units.map((unit, index) => {
                        const organisationUnit = currentOrganisation.organisationUnits.find(u => u.id === selectedOrganisationUnit);
                        if(!organisationUnit)
                            return null;

                        return (
                            <UnitItem 
                                key={unit.id}
                                index={index}
                                unit={unit}
                                deleteUnit={deleteUnit}
                                currentLanguage={currentLanguage}
                                onMove={moveUnit} 
                                updateUnit={updateUnit}
                                organisationUnit={organisationUnit}
                            />
                        )
                    })}
                    {selectedOrganisationUnitObj && <AddItemContainer><H3>{lang.addNewUnit}</H3><ConnectedUnitForm selectedOrganisationUnit={selectedOrganisationUnitObj} /></AddItemContainer>}
                </Column>
            </Table>
        </Main>
    );
};

const UnitItem = ({
    unit,
    deleteUnit,
    onMove,
    index,
    currentLanguage,
    updateUnit,
    organisationUnit
}: {
    unit: Unit,
    deleteUnit: (unit: string) => void,
    onMove: (index: number, direction: 'up' | 'down') => void,
    index: number,
    currentLanguage: Language,
    updateUnit: (name: string, unit: string) => void,
    organisationUnit: OrganisationUnit
}) => {
    return (
        <div style={{
            display: "grid",
            gridTemplateColumns: "50px 1fr"
        }}>
            <div style={{
                // Under each other
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <IconButton color={"success"} IconSVG={FullArrowUpIcon} onClick={() => onMove(index, 'up')} />
                <IconButton color={"success"} IconSVG={FullArrowDownIcon} onClick={() => onMove(index, 'down')} />
            </div>
            <UnitElement 
                value={unit}
                currentLanguage={currentLanguage}
                updateUnit={updateUnit}
                deleteUnit={deleteUnit}
                organisationUnit={organisationUnit}
            />
        </div>
    );
};

export default ManageOrganisationUnits;
