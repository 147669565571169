import React, { useEffect } from "react";
import { CreateQuestion, LoadQuestions } from "store/allocator/allocator.actions";
import { Language } from "types/MultiLingualString";
import { AdminAllocatorQuestionsListContainer, QuestionContainer, SingleQuestionContainer, StyledHeader } from "./AdminAllocatorQuestionsList.components";
import Button from 'components/Button/Button';
import BreadCrumbs from "components/BreadCrumbs/BreadCrumbs";
import getLanguageObject from "helpers/language";
import { useHistory } from "react-router-dom";
import Routes from "routes/Routes.types";
import QuestionNode from "types/QuestionNode";

interface AdminAllocatorQuestionsListProps {
    questions: QuestionNode[];
    currentLanguage: Language;
    loadQuestions: LoadQuestions;
    createQuestion: CreateQuestion;
}

export const AdminAllocatorQuestionsList : React.FC<AdminAllocatorQuestionsListProps> = ({
    questions,
    currentLanguage,
    loadQuestions,
    createQuestion
}) => {
    const history = useHistory();
    const lang = getLanguageObject(currentLanguage);

    useEffect(() => {
        loadQuestions()
    }, [])

    const navigate = (id: string) => {
        history.push(Routes.AdminAllocatorQuestionRoute + id);
    }

    if(!questions || questions.length === 0) {
        return (
            <AdminAllocatorQuestionsListContainer>
                <StyledHeader>
                    <BreadCrumbs title={lang.allocatorQuestions} paths={[]} />
                    <Button text="Create Question" onClick={() => {
                        createQuestion().then((id) => {
                            history.push(Routes.AdminAllocatorQuestionRoute + id);
                        })
                    }}/>
                </StyledHeader>
                <QuestionContainer>
                    <SingleQuestionContainer>Loading...</SingleQuestionContainer>
                </QuestionContainer>
            </AdminAllocatorQuestionsListContainer>
        )
    }

    return (
        <AdminAllocatorQuestionsListContainer>
            <StyledHeader>
                <BreadCrumbs title={lang.allocatorQuestions} paths={[]} />
                <Button text="Create Question" onClick={() => {
                    createQuestion().then((id) => {
                        history.push(Routes.AdminAllocatorQuestionRoute + id);
                    })
                }}/>
            </StyledHeader>
            <QuestionContainer>
                {questions.sort((a, b) => a.title < b.title ? -1 : 1).sort((a, b) => a.isFirstQuestion ? -1 : 1).map((question) => (
                    <SingleQuestionContainer key={question.id} onClick={() => {navigate(question.id)}}>
                        <p>{question.title}</p>
                    </SingleQuestionContainer>
                ))}
            </QuestionContainer>
        </AdminAllocatorQuestionsListContainer>
    )
}