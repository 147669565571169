import React from 'react';

import { Label } from 'containers/InputField/InputField.components';
import { getLevelColorByString, getLevels } from 'utils/levels';
import { Container, ExtraFieldContainer, Level } from './SelectLevels.styling';
import { ShareLink } from 'types/Organisation';

const SelectLevels: React.FC<{ label: string; setShareLink: (shareLink: ShareLink) => void, shareLink: ShareLink }> = ({ label, setShareLink, shareLink }) => {
    const getBgColorIfItemSelected = (level: string) => {
        const isSelected = shareLink.visibleLevels && shareLink.visibleLevels.includes(level.toUpperCase())
        if (isSelected) return getLevelColorByString(level)
        return '#ced2d8'
    }

    const levels = getLevels([]) // show all levels

    const onClick = (level: string) => {
        const visibleLevels = shareLink.visibleLevels || []
        const newVisibleLevels = visibleLevels.includes(level) ? visibleLevels.filter((x) => x !== level) : [...visibleLevels, level]
        setShareLink({ ...shareLink, visibleLevels: newVisibleLevels })
    }

    return (
        <div>
            <Label>{label}</Label>
            <Container>
                {levels.map(x => x.toUpperCase()).map((x) => <Level key={x} onClick={() => onClick(x)} bgColor={getBgColorIfItemSelected(x)}>{x}</Level>).reverse()}
            </Container>
        </div>
    )
}

export default SelectLevels


export const SelectExtraFields: React.FC<{ label: string; fieldLabels: string[]; setShareLink: (shareLink: ShareLink) => void, shareLink: ShareLink}> = ({ label, fieldLabels, setShareLink, shareLink }) => {

    const getBgColorIfItemSelected = (isSelected: boolean) => {
        if (isSelected) return "#FCE162"
        return '#ced2d8'
    }

    const sanitizeLabel = (label: string) => {
        return label.replace("<p>","").replace("</p>", "")
    }

    return (
        <div>
            <Label>{label}</Label>
                <ExtraFieldContainer>
                    <Level key={"c1"} onClick={() => setShareLink({...shareLink, showExtraColumn1: !shareLink.showExtraColumn1})} bgColor={getBgColorIfItemSelected(shareLink.showExtraColumn1)}>{sanitizeLabel(fieldLabels[0])}</Level>
                    <Level key={"c2"} onClick={() => setShareLink({...shareLink, showExtraColumn2: !shareLink.showExtraColumn2})} bgColor={getBgColorIfItemSelected(shareLink.showExtraColumn2)}>{sanitizeLabel(fieldLabels[1])}</Level>
                    <Level key={"c3"} onClick={() => setShareLink({...shareLink, showExtraColumn3: !shareLink.showExtraColumn3})} bgColor={getBgColorIfItemSelected(shareLink.showExtraColumn3)}>{sanitizeLabel(fieldLabels[2])}</Level>
                </ExtraFieldContainer>
        </div>
    )
}
