import styled from "styled-components";

export const Wrapper = styled.div`
    position: relative;
`

export const Container = styled.div`
    margin: 0;
    width: 100%;
    padding: 6px 8px;
    border-radius: 5px;
    background-color: #ffffff;
    border: 2px solid rgba(0, 0, 0, 0.04);
    display: flex;
    align-items: center;
    gap: 8px;
    box-sizing: border-box;
`;

export const TextField = styled.input`
    all: unset;

    box-sizing: border-box;
    font-size: 16px;
    line-height: 1.5;

    color: #333333;

    display: block;
    font-weight: 500;
    text-indent: 8px;
`;