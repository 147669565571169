import React from 'react';
import styled from "styled-components";
import { HeaderProps, TableHeaderProps } from "./TableElements.types";
import { XmarkRound } from 'components/Icons/xmark';
import PauseIcon from 'components/Icons/pause';
import CheckIcon from 'components/Icons/check';
import SortArrowUpIcon from 'components/Icons/arrowUp';
import { SortArrowDownIcon } from 'components/Icons/arrowDown';

const Status = styled.div`
    margin: 0 auto;
    color: ${props => props.color};
    overflow: visible;
    width: 1em;
    vertical-align: -0.225em;
    font-size: 1.33333em;
    line-height: 0.75em;
`;

export const State: React.FC<{ status: string }> = ({ status }) => {
    if(!status) {
        return null
    }

    if (status.toLowerCase() === 'concept')
        return (
            <Status color="#ed8b37">
                <PauseIcon />
            </Status>
        );
    if (
        status.toLowerCase() === 'active' ||
        status.toLowerCase() === 'activated'
    )
        return (
            <Status color="#00a78c">
                <CheckIcon />
            </Status>
        );
    if (
        status.toLowerCase() === 'inactive' ||
        status.toLowerCase() === 'deactivated'
    )
        return (
            <Status color="#f06e64">
                <XmarkRound />
            </Status>
        );
    return null;
};

const TableHeaderItem = styled.div<TableHeaderProps>`
    padding: 1em 0;
    font-weight: bold;
    color: #333;

    height: 54px;
    background-color: #fff;
    box-sizing: border-box;

    :hover {
        cursor:  ${props => (props.inactive ? 'arrow' : 'pointer')};
    }
`;

const IconContainer = styled.div`
    overflow: visible;
    width: 0.625em;
    vertical-align: middle;
    padding-left: 8px;
    margin-top: -8px;
    display: inline-block;
    font-size: inherit;
    height: 1em;
`;

export const HeaderItem: React.FC<HeaderProps> = ({
    onClick,
    text,
    activeItem,
    sortingOrder,
    inactive
}) => {
    return (
        <TableHeaderItem onClick={onClick} inactive={inactive}>
            {text}
            {activeItem && <IconContainer>
                {sortingOrder ? <SortArrowUpIcon color="#000" /> : <SortArrowDownIcon />}
            </IconContainer>}
        </TableHeaderItem>
    );
};

export const HeaderCheckBox: React.FC<HeaderProps> = ({
    activeItem,
    onClick
}) => {
    return (
        <TableHeaderItem>
            <input type='checkbox' checked={activeItem} onChange={onClick} />
        </TableHeaderItem>
    );
};
