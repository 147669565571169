import { Language } from "types/MultiLingualString";
import { Organisation } from "types/Organisation";
import { Result } from "types/Result";

export default interface ResultsProps {
    currentLanguage: Language
    results: Result[];
    resultsGetResults: () => void;
    currentOrganisation?: Organisation;
    role?: string;
}

export interface FilterValues {
    name: string;
    status: string;
    level: string;
    organisationUnit: string;
    unit: string;
    talentPath: string;
}

export const BaseFilterValues: FilterValues = {
    name: '',
    unit: 'Show All',
    organisationUnit: 'Show All',
    level: 'Show All',
    status: 'Show All',
    talentPath: 'Show All'
};

export type SetFilterValues = (filterValues: FilterValues) => void;
