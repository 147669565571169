import LatestUpdatesTable from 'containers/LatestUpdatesTable/LatestUpdatesTable.connector';
import MainLayout from 'layouts/MainLayout/MainLayout';
import React from 'react';

const LatestUpdates = () => {
    return (
        <MainLayout>
            <LatestUpdatesTable />
        </MainLayout>
    );
};

export default LatestUpdates;
