export const styrCompetenceOptions = [
    { key: 'accountability', value: 'Accountability' },
    { key: 'attention_to_detail', value: 'Attention To Detail' },
    { key: 'business_orientation', value: 'Business Orientation' },
    { key: 'commercial_power', value: 'Commercial Power' },
    { key: 'conduct', value: 'Conduct' },
    { key: 'controlling_progress', value: 'Controlling Progress' },
    { key: 'cooperation', value: 'Cooperation' },
    { key: 'creativity', value: 'Creativity' },
    { key: 'customer_orientation', value: 'Customer Orientation' },
    { key: 'decisiveness', value: 'Decisiveness' },
    { key: 'delegating', value: 'Delegating' },
    { key: 'developing_employees', value: 'Developing Employees' },
    { key: 'discipline_competence', value: 'Discipline' },
    { key: 'flexible_behavior', value: 'Flexible Behavior' },
    { key: 'focus_on_quality', value: 'Focus on Quality' },
    { key: 'forming_judgment', value: 'Forming Judgment' },
    { key: 'identification_with_management', value: 'Identification With Management' },
    { key: 'independence', value: 'Independence' },
    { key: 'innovative_power', value: 'Innovative Power' },
    { key: 'insight', value: 'Insight' },
    { key: 'leadership_of_groups', value: 'Leadership of Groups' },
    { key: 'managing', value: 'Managing' },
    { key: 'need_to_achieve', value: 'Need to Achieve' },
    { key: 'networking', value: 'Networking' },
    { key: 'perseverance', value: 'Perseverance' },
    { key: 'persuasiveness', value: 'Persuasiveness' },
    { key: 'planning_and_organizing', value: 'Planning and Organizing' },
    { key: 'result_orientedness', value: 'Result-Orientedness' },
    { key: 'sociability', value: 'Sociability' },
    { key: 'social_awareness', value: 'Social Awareness' },
    { key: 'verbal_expression', value: 'Verbal Expression' },
    { key: 'vision', value: 'Vision' },
    { key: 'workmanship', value: 'Workmanship' },
    { key: 'written_expression', value: 'Written Expression' },
    { key: 'courage', value: 'Courage' },
    { key: 'integrity', value: 'Integrity' }
  ];
  