import styled from 'styled-components';
import theme from 'utils/theme';

export const UpdateSectionContainer = styled.div`
    margin-top: 1em;
    background-color: white;
    border-radius: 8px;
    box-shadow: ${theme.boxShadow};
    padding: 15px 20px;    
`;

export const UpdateHeaderButtonsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 30px 30px
    grid-gap: 0.5em;
    margin-right: 1em;
    margin-top: 1em;
`