import styled from 'styled-components';

export const ComparingLevelsSectionContainer = styled.div`
    margin: 10px;
`


export const Header = styled.div`
    display: grid;
    grid-template-rows: 1fr;
`;

export const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    height: 48px;
    padding: 1em;
    background-color: white;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
`;