import { Language } from "types/MultiLingualString";
import { OrganisationUnit } from "types/Organisation";
import { getLevels } from "utils/levels";
import { talentPaths, transformTalentPathKey } from "utils/talentPaths";
import { getStringFromCurrentLanguage } from 'store/language/language.actions';

export const options = (lang: Language, organisationUnits: OrganisationUnit[]) => {
    const levels = getLevels([])

    return {
        status: [
            {
                name: 'Concept',
                value: 'concept',
            },
            {
                name: 'Active',
                value: 'active',
            },
            {
                name: 'Inactive',
                value: 'inactive',
            },
        ],
        level: levels.map((x) => ({
            name: x.toUpperCase(),
            value: x
        })),
        organisationUnit: organisationUnits.map(x => {
            return {
                name: getStringFromCurrentLanguage(x.name,lang),
                value: x.id
            }
        }),
        unit: organisationUnits.flatMap(x => x.units).map(x => {
            return {
                name: getStringFromCurrentLanguage(x.name,lang),
                value: x.id
            }
        }),
        talentPath: talentPaths.map(x => {
            return {
                name: transformTalentPathKey(x, lang) || x,
                value: x
            }
        })
    };
}
