import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';
import { updateUnit, updateLevelNotes } from 'store/user/user.actions';
import { HeaderEditableUnit } from './OrganisationUnitDataColumn';
import { ExtraColumn } from './MatrixColumns';
import MatrixContent from './MatrixContent';

const mapStateToProps = (state: ReduxStore) => ({
    cLanguage: state.language.currentLanguage,
    isSidemenuOpen: state.navbar.sidebarOpen,
    currentOrganisation: state.user.currentOrganisation,
    permissions: state.user.permissions,
});
const mapDispatchToProps = { };
const ConnectedMatrixContent = connect(mapStateToProps, mapDispatchToProps)(MatrixContent);

export default ConnectedMatrixContent;

const mapStateToPropsForExtraColumn = (state: ReduxStore) => ({
    cLanguage: state.language.currentLanguage,
    isSidemenuOpen: state.navbar.sidebarOpen,
    currentOrganisation: state.user.currentOrganisation,
    languageState: state.language
});
const mapDispatchToPropsForExtraColumn = { updateLevelNotes };
export const ConnectedExtraColumn = connect(mapStateToPropsForExtraColumn, mapDispatchToPropsForExtraColumn)(ExtraColumn);

const mapStateToPropsForHeaderEditableUnit = (state: ReduxStore) => ({
    cLanguage: state.language.currentLanguage
});
const mapDispatchToPropsForHeaderEditableUnit = { updateUnit };
export const ConnectedHeaderEditableUnit = connect(mapStateToPropsForHeaderEditableUnit, mapDispatchToPropsForHeaderEditableUnit)(HeaderEditableUnit);
