import React from 'react';
import MainLayout from 'layouts/MainLayout/MainLayout';
import ManageOrganisationUnits from 'sections/ManageOrganisation/ManageOrganisationUnits/ManageOrganisationUnits.connector';
import getLanguageObject from 'helpers/language';
import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';
import ManageOrganisationProps from '../ManageOrganisationUsers/ManageOrganisationUsers.types';
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs';
import Routes from 'routes/Routes.types';
import { Container, Header } from 'layouts/MainLayout/MainLayout.components';
import { deleteShareLink } from 'store/user/user.actions';

const ManageJobMatrix: React.FC<ManageOrganisationProps> = ({ currentLanguage }) => {
    const lang = getLanguageObject(currentLanguage);

    return (
        <MainLayout>
            <Container>
                <Header>
                    <BreadCrumbs title={lang.jobMatrixArchitecture} paths={[
                        { title: lang.dashboard, path: Routes.DashboardRoute },
                        { title: lang.jobMatrixArchitecture, path: Routes.JobMatrixRoute },
                    ]} />
                </Header>
                <ManageOrganisationUnits />
            </Container>
        </MainLayout>
    );
};

const mapStateToProps = (state: ReduxStore) => ({
    shareLinks: state.user.currentOrganisation?.shareLinks || [],
    currentLanguage: state.language.currentLanguage
});

const mapDispatchToProps = { deleteShareLink };

const ManageOrganisationMatrix = connect(mapStateToProps, mapDispatchToProps)(ManageJobMatrix);

export default ManageOrganisationMatrix;
