import styled from 'styled-components';

export const Container = styled.div<{visible?: boolean;}>`

`

export const Filters = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;

    @media print {
        visibility: hidden;
    }
`
export const MatrixTableHeader = styled.div`
    position: fixed;
    top: 78px;
    left: 0;
    z-index: 3;
    width: 100%;
    height: 120px;
    border: 0px solid #fff;
    border-top-width: 1px;
    border-bottom-width: 1px;
    display: flex;
    flex-direction: row;
`;

export const StickyTH = styled.th<{width?: number}>`
    width: ${(props) => props.width}px;
    height: 120px;
    color: #fff;
    border: 0px solid #fff;
    border-left-width: 1px;
    border-right-width: 1px;
    background: #786D64;
    position: relative;
`;

export const Filler = styled.th<{width?: number}>`
    width: ${(props) => props.width}px;
`;

export const OrganisationLogo = styled.img`
    overflow: visible;
    max-height: 68px;
`

export const StyrLogo = styled.img`
margin-top: 6px;
margin-bottom: 6px;
    max-height: 68px;
    display: none;
    float: right;
    @media print {
        display: block;
    }
`
export const MatrixDropUp = styled.div`

    &:hover div{
        display: block;
    }
  `;

export const MatrixDropUpContent = styled.div`
    display: none;
    position: absolute;
    background-color: #EFEDEE;
    border-radius: 8px;
    min-width: 150px;
    bottom: 45px;
    z-index: 3;
    border: 8px solid rgb(242, 243, 247);
    &:hover{
        display: block;
    }
    & div {
        cursor: pointer;
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;

        &:hover {

            background-color: #EFEDEE;
            filter: brightness(150%);
            color: black;
        }
    }
  `;

export const MatrixDropUpButton = styled.div`
    &:hover {
        background-color: #f1f1f1;
    }
`;
