import React, { useState } from "react";
import { ButtonRecords } from "./IconButton.colors";
import { Container } from "./IconButton.styling";
import { IconButtonProps, States } from "./IconButton.types";

export const IconButton: React.FC<IconButtonProps> = ({ color, IconSVG, title, onClick, disabled }) => {
    const [state, setState] = useState<States>('default');
    const handleOnClick = disabled ? (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => { } : onClick

    return (
        <Container
            title={title}
            bgColor={ButtonRecords[color || 'primary'][state].background}
            onMouseEnter={() => setState('hover')}
            onMouseLeave={() => setState('default')}
            onMouseDown={() => setState('focus')}
            onMouseUp={() => setState('hover')}
            onClick={handleOnClick}
        >
            <IconSVG color={color && ButtonRecords[color][state].text} />
        </Container>
    );
};
