import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';
import ListItemWithToggle from 'containers/ListItemWithToggle/ListItemWithToggle';
import { UpdateOrganisationRequest, updateOrganisationRequest } from 'store/user/user.actions';
import { Language } from 'types/MultiLingualString';
import { diffKey, UpdateDifferentiatingFactor, updateDifferentiatingFactor } from 'store/families/jobfamily.actions';
import getLanguageObject from 'helpers/language';
import { H2 } from 'components/Typography/Typography';
import { Permissions } from 'store/user/user.types';
import { DifferentiatingFactors, Organisation } from 'types/Organisation';

interface ToggleFactorsProps {
    permissions: Permissions;
    currentLanguage: Language;
    currentOrganisation?: Organisation;
    factors: DifferentiatingFactors;
    updateOrganisationRequest: UpdateOrganisationRequest;
    updateDifferentiatingFactor: UpdateDifferentiatingFactor;
}

const ToggleFactors: React.FC<ToggleFactorsProps> = ({ permissions, currentLanguage, currentOrganisation, factors, updateDifferentiatingFactor }) => {
    const lang = getLanguageObject(currentLanguage);

    if (!currentOrganisation?.id) return null;


    const changeState = (key: diffKey) => {
        // TODO: Show toast message when the request fails
        // TODO: Show disabled during loading?
        factors[key] = !factors[key];

        if (key === "styrCompetencies") {
            const currentStateStyrCompetencies = factors.styrCompetencies;

            factors.communicationSkills = !currentStateStyrCompetencies;
            factors.projectManagementSkills = !currentStateStyrCompetencies;
        }

        updateDifferentiatingFactor(currentOrganisation.id, factors)
    }

    const styrCompetencies = Object.entries(factors).find((x) => x[0] !== "styrCompetencies");

    return (<div>
        {Object.entries(factors).filter((x) => x[0] !== "styrCompetencies" && x[0] !== "id").map(([key, value]: any, index) => {
            //@ts-ignore
            const label = lang[key] || key;

            if (key === "problemSolvingCapability") {
                return null;
            }
            return (
                <div key={key} onClick={() => changeState(key)}>
                    <ListItemWithToggle label={label} state={value} />
                </div>
            )
        })}
        {permissions.canToggleStyrTmaNormedCompetencies && styrCompetencies && (
            <>
                <H2>{lang.extraCompetencies}</H2>
                <div key={"styrCompetencies"} onClick={() => {
                    changeState("styrCompetencies");
                }}>
                    <ListItemWithToggle label={lang["styr_competencies"]} state={factors.styrCompetencies} />
                </div>

            </>
        )}
    </div>
    )
};

const mapStateToProps = (state: ReduxStore) => ({
    permissions: state.user.permissions,
    currentLanguage: state.language.currentLanguage,
    currentOrganisation: state.user.currentOrganisation,
});

const mapDispatchToProps = { updateOrganisationRequest, updateDifferentiatingFactor };

const ToggleFactorsConnected = connect(mapStateToProps, mapDispatchToProps)(ToggleFactors);

export default ToggleFactorsConnected;
