import { constructGroupsFromLevels, getLevels } from "utils/levels";
import theme from "utils/theme";

export const levelToColor = (level: string) => {
    const groups = constructGroupsFromLevels(getLevels([]))
    if (groups[0].includes(level)) {
        return theme.colorCodePicker('purple');
    }
    if (groups[1].includes(level)) {
        return theme.colorCodePicker('yellow');
    }
    if (groups[2].includes(level)) {
        return theme.colorCodePicker('orange');
    }
    if (groups[3].includes(level)) {
        return theme.colorCodePicker('blue');
    }
    if (groups[4].includes(level)) {
        return theme.colorCodePicker('green');
    }
    return '#000';
};