import Button from 'components/Button/Button';
import { H2 } from 'components/Typography/Typography';
import InputField, { SelectField } from 'containers/InputField/InputField';
import SelectLevels, { SelectExtraFields } from 'containers/SelectLevels/SelectLevels';
import getLanguageObject from 'helpers/language';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getObjectFromCurrentLanguage, getStringFromCurrentLanguage } from 'store/language/language.actions';
import ReduxStore from 'store/store.type';
import { createShareLink, updateShareLink } from 'store/user/user.actions';
import { Card, Overlay } from './ManageShareLinks.styling';
import { PopUpCreateShareLinkProps } from './ManageShareLinks.types';
import Toggle from 'components/Toggle/Toggle';
import { ErrorMessagesContainer, Label } from 'containers/InputField/InputField.components';
import { ShareLink } from 'types/Organisation';

export const PopUpCreateShareLink: React.FC<PopUpCreateShareLinkProps> = ({ currentLanguage, currentOrganisation, uuid, extraFieldLabels, handleState, createShareLink, updateShareLink }) => {
    const findByUUID = currentOrganisation?.shareLinks?.find((sl) => sl.id === uuid)
    const lang = getLanguageObject(currentLanguage)
    const emptyShareLink: ShareLink = {
        id: "",
        name: "",
        password: "",
        visibleLevels: [],
        showStyrProfile: true,
        organisationUnitId: undefined,
        showExtraColumn1: false,
        showExtraColumn2: false,
        showExtraColumn3: false,
    }
    const [error, setError] = useState(false)
    const [errorMessageAccessCode, setErrorMessageAccessCode] = useState("")
    const [data, setData] = useState<ShareLink>(findByUUID || emptyShareLink)

    useEffect(() => {
        if (data.name === "" || data.password === "") return setError(false)

        return setError(true)
    }, [data, setError])


    useEffect(() => {
        setData(findByUUID || emptyShareLink)
    }, [findByUUID])

    const handleInput = (key: string, value: string) => {
        return setData({ ...data, [key]: value })
    }

    const cancelSubmit = () => {
        setData(emptyShareLink)
        return handleState()
    }

    const handleSubmit = () => {
        if (findByUUID === undefined) {
            createShareLink(data)
        } else {
            updateShareLink(data)
        }
        handleState()
    }

    const MaybeRenderSelectUnit = () => {
        if (!currentOrganisation?.organisationUnits?.length) return null
        if (currentOrganisation.organisationUnits.length <= 1) return null
        

        return <SelectField
            fullWidth
            label={lang.organisationUnit}
            onChange={e => handleInput('organisationUnitId', e.target.value)}
            options={
                [<option key={""} value={""}>-- {getLanguageObject(currentLanguage).allOrganisationUnitsTitle} --</option>].concat(
                    currentOrganisation.organisationUnits.map((e) => (
                        <option key={e.id} value={e.id}>{getStringFromCurrentLanguage(e.name, currentLanguage)}</option>
                    )))
            }
            value={data.organisationUnitId || ""}
        />
    }

    return (
        <Overlay>
            <Card>
                {/* The below is necessary to have Chrome not autofill our data */}
                <input style={{display: "none"}} type="text" name="username" />
                <input style={{display: "none"}} type="password" name="password" />
                <div>
                    <H2>{lang.createNewShareLinkTitle}</H2>
                    <p dangerouslySetInnerHTML={{ __html: lang.createNewShareLinkDescription }} />
                </div>
                <InputField fullWidth label={lang.description} value={data.name} type={'text'} onChange={(e) => handleInput('name', e.target.value)} errorMessage={null} />
                <MaybeRenderSelectUnit />
                <SelectLevels label={'Select visible levels'} setShareLink={setData} shareLink={data} />
                <hr/>
                <SelectExtraFields fieldLabels={extraFieldLabels} label={lang.freeInputfieldColumn} setShareLink={setData} shareLink={data} />
                <hr/>
                <div>
                    <Label>{lang.showStyrProfile}</Label>
                    <div onClick={() => {setData({...data, showStyrProfile: !data.showStyrProfile})}}>
                        <Toggle state={data.showStyrProfile}/>
                    </div>
                </div>
                <hr/>
                <InputField fullWidth label={lang.accessCode} value={data.password} type={'text'} disableAutocomplete onChange={(e) => {
                    const isAlphaNum = /^[a-z0-9]+$/i.test(e.target.value)
                    if (!isAlphaNum) {
                        setError(true)
                        setErrorMessageAccessCode(lang.accessCodeError)
                        return
                    }

                    setError(false)
                    setErrorMessageAccessCode("")

                    handleInput('password', e.target.value)
                }} errorMessage={errorMessageAccessCode ? <ErrorMessagesContainer>{errorMessageAccessCode}</ErrorMessagesContainer>: null} />
                <i style={{fontSize: 16, paddingBottom: 15}}>{lang.accessCodeDescription}</i>
                <div>
                    <Button priority='secondary' text={lang.cancel} onClick={cancelSubmit} />
                    <Button priority='primary' text={lang.save} onClick={handleSubmit} disabled={!error} />
                </div>
            </Card>
        </Overlay>
    )
}

const extractExtraFieldLabels = (state: ReduxStore) => {
    const currentLanguage = state.language.currentLanguage
    const lang = getLanguageObject(currentLanguage);
    const labels = [
        getObjectFromCurrentLanguage(state.user.currentOrganisation?.matrixLevelNotes, currentLanguage)?.title || lang.freeInputfield,
        getObjectFromCurrentLanguage(state.user.currentOrganisation?.matrixLevelNotes2, currentLanguage)?.title || lang.freeInputfield2,
        getObjectFromCurrentLanguage(state.user.currentOrganisation?.matrixLevelNotes3, currentLanguage)?.title || lang.freeInputfield3
    ]
    return labels
}

const mapStateToProps = (state: ReduxStore) => ({
    currentLanguage: state.language.currentLanguage,
    currentOrganisation: state.user.currentOrganisation,
    extraFieldLabels: extractExtraFieldLabels(state)
});

const mapDispatchToProps = {
    createShareLink: createShareLink,
    updateShareLink: updateShareLink
};

const ConnectedPopUpCreateShareLink = connect(mapStateToProps, mapDispatchToProps)(PopUpCreateShareLink);

export default ConnectedPopUpCreateShareLink;