import MainLayout from 'layouts/MainLayout/MainLayout';
import React from 'react';
import { useParams } from 'react-router-dom';
import ConnectedJobFamilyEdit from 'sections/JobFamilyEdit/JobFamilyEdit';

const JobFamilyEditScreen: React.FC = () => {
    const { id } = useParams<{id: string}>();

    return (
        <MainLayout>
            <ConnectedJobFamilyEdit id={id} />
        </MainLayout>
    );
};

export default JobFamilyEditScreen;