import { CountryFlag } from "components/CountryFlag/CountryFlag";
import React, { useEffect } from "react"
import { useState } from "react";
import ReactQuill from "react-quill";
import { connect } from "react-redux";
import ReduxStore from "store/store.type";
import styled from "styled-components";
import { Language, MultiLingualString } from "types/MultiLingualString";

interface MultiLingualInputFieldProps {
    value?: MultiLingualString;
    onChange: (value: string, language: Language) => void;
    languages: Language[];
    currentLanguage: Language;
}

const LanguageSelectorContainer = styled.div`
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    margin-bottom: 5px;
`

const LanguageSelectorSingle = styled.div<{active?: boolean}>`
    background: ${props => props.active ? "#ccc" : "#fff"};
    padding: 10px;
    margin: 5px;
    border: 1px solid #ccc;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
`

const Container = styled.div`
    width: 750px;
`

const MultiLingualInputField: React.FC<MultiLingualInputFieldProps> = ({languages, currentLanguage, value, onChange}) => {
    const [selectedLanguage, setSelectedLanguage] = useState<Language>(currentLanguage);
    const [editorContent, setEditorContent] = useState<string>("");

    useEffect(() => {
        setSelectedLanguage(currentLanguage);
    }, [currentLanguage]);

    // Update the editor content when the language changes
    useEffect(() => {
        setEditorContent(value?.[selectedLanguage] || "");
    }, [selectedLanguage, value]);

    const changeLanguage = (l: Language) => {
        setSelectedLanguage(l);
    };

    const onChangeValue = (v: string) => {
        setEditorContent(v); // Update local state
        onChange(v, selectedLanguage); // Notify parent component
    };

    return (
        <Container>
            <LanguageSelectorContainer>
                {languages.map((l) => (
                    <LanguageSelectorSingle
                        onClick={() => changeLanguage(l)}
                        active={selectedLanguage === l}>
                            <CountryFlag language={l} />
                    </LanguageSelectorSingle>
                ))}
            </LanguageSelectorContainer>
            <ReactQuill
                theme="snow"
                preserveWhitespace={true}
                value={editorContent} // Use local state
                modules={{
                    clipboard: {
                        matchVisual: false
                    }
                }}
                onChange={onChangeValue} />
        </Container>
    );
};

const mapStateToProps = (state: ReduxStore) => {
    return {
        languages: state.language.remoteLanguages.map(x => x.code as Language),
        currentLanguage: state.language.currentLanguage
    };
};

const mapDispatchToProps = { };

const ConnectedMultiLingualInputField = connect(
    mapStateToProps,
    mapDispatchToProps
)(MultiLingualInputField);

export default ConnectedMultiLingualInputField;
