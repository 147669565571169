import React, { useEffect } from 'react';
import MainLayout from 'layouts/MainLayout/MainLayout';
import ManageOrganisationDetailsProps from './ManageOrganisationDetails.types';
import { Main } from './ManageOrganisationDetails.components';
import getLanguageObject from 'helpers/language';
import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';
import { Container, Header } from 'layouts/MainLayout/MainLayout.components';
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs';
import ConnectedManageOrganisation from 'sections/ManageOrganisation/ManageOrganisation/ManageOrganisation.connector';
import Routes from 'routes/Routes.types';
import { getRemoteLanguages } from 'store/language/language.actions';

const OrganisationDetails: React.FC<ManageOrganisationDetailsProps> = ({ currentLanguage, getRemoteLanguages }) => {
    const lang = getLanguageObject(currentLanguage);

    useEffect(() => {
        getRemoteLanguages()
    }, [])

    return (
        <MainLayout>
            <Container>
                <Header>
                    <BreadCrumbs title={lang.manageOrganisation} paths={[
                        { title: lang.dashboard, path: Routes.DashboardRoute },
                        { title: lang.management, path: Routes.ManageOrganisationDetails },
                    ]} />
                </Header>
                <Main>
                    <ConnectedManageOrganisation />
                </Main>
            </Container>
        </MainLayout>
    );
};

const mapStateToProps = (state: ReduxStore) => ({
    currentLanguage: state.language.currentLanguage
});

const mapDispatchToProps = { getRemoteLanguages };

const ManageOrganisationDetails = connect(mapStateToProps, mapDispatchToProps)(OrganisationDetails);

export default ManageOrganisationDetails;
