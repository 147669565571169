import React from 'react';
import MainLayoutNoSidebar from 'layouts/MainLayoutNoSidebar/MainLayoutNoSidebar';
import LoginForm from 'containers/LoginForm/LoginForm.connector';
import LoginProps from './Login.types';
import AwaitLoading from 'containers/AwaitLoading/AwaitLoading';

const Login: React.FC<LoginProps> = () => {
        return (
            <MainLayoutNoSidebar>
                <AwaitLoading />
                <div style={{display: "none"}}>
                    <LoginForm/>
                </div>
            </MainLayoutNoSidebar>
        )
};

export default Login