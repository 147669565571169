import { H2 } from 'components/Typography/Typography';
import { InputSearch } from 'containers/InputField/InputField';
import useOnClickOutside from 'helpers/hooks/useOnClick';
import getLanguageObject from 'helpers/language';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Routes from 'routes/Routes.types';
import { Organisation } from 'types/Organisation';
import useLocalStorageState from 'use-local-storage-state/dist';
import { MenuItem } from './MenuItem.component';
import {
    AvatarImage,
    Container,
    Content,
    Image,
    OrganisationsList,
    SearchContainer, 
    Small, 
    SwitchContainer, 
    Wrapper
} from './OrganisationDropdownMenu.components';
import OrganisationDropdownMenuProps from './OrganisationDropdownMenu.types';
import { API_URL } from 'config';

const OrganisationDropdownMenu: React.FC<OrganisationDropdownMenuProps> = ({
    sidebarOpen,
    organisations,
    setCurrentOrganisation,
    currentLanguage,
    currentOrganisation,
    updateOrganisationsRequest,
    updateOrganisationRequest
}) => {
    const language = getLanguageObject(currentLanguage);
    const [pickerVisible, setPickerVisible] = useState(false);
    const [search, setSearch] = useState('');
    const [pinnedOrganisation, setPinnedOrganisation] = useLocalStorageState<string[]>(`pinned-organsiation-ids`, []);
    const ref = useRef(null);
    useOnClickOutside(ref, () => setPickerVisible((current) => !current));

    const history = useHistory();
    const handleClick = (organisation: Organisation) => {
        setCurrentOrganisation(organisation.id);
        setPickerVisible((current) => !current)

        history.push(Routes.DashboardRoute);
    };
    const organisationsSorted = organisations.sort((a, b) => a.name.localeCompare(b.name))
    const filteredOrganisations = organisationsSorted.filter((project) => project.name.toLowerCase().includes(search.toLowerCase()))
    const pinnedOrganisations = pinnedOrganisation.filter((organisations) => organisations)

    useEffect(() => {
        updateOrganisationsRequest()
        updateOrganisationRequest()
    }, []);

    const pinOrganisation = (id: string) => {
        const pinned = pinnedOrganisation.includes(id)

        if (pinned) return setPinnedOrganisation((current) =>
            current.filter((x) => x !== id)
        );

        setPinnedOrganisation([...pinnedOrganisation, id]);
    };

    const PinnedOrganisations = () => {
        if (pinnedOrganisation.length === 0) return null

        return (
            <>
                {pinnedOrganisations.map((id) => {
                    const organisation = organisations.find(x => x.id === id)
                    if (!organisation) return null
                    return <MenuItem key={id} onClick={handleClick} organisation={organisation} isPinned onSave={pinOrganisation} />
                })}
                <hr />
            </>
        )
    }

    const renderSwitcher = () => (
        <Wrapper>
            <SwitchContainer ref={ref}>
                <SearchContainer>
                    <div>
                        <H2>{language.switchOrganisation}</H2>
                        <span>{language.viewOtherOrganisations}</span>
                    </div>
                    <InputSearch
                        type={'text'}
                        value={search}
                        onChange={event => setSearch(event.target.value)}
                        placeholder={language.search}
                        errorMessage={null}
                        fullWidth
                    />
                </SearchContainer>
                <OrganisationsList>
                    <PinnedOrganisations />
                    {filteredOrganisations.map((organisation) => {
                        const pinned = pinnedOrganisation.includes(organisation.id)

                        if (pinned) return null

                        return <MenuItem key={organisation.id} onClick={handleClick} organisation={organisation} isPinned={pinned} onSave={pinOrganisation} />
                    })}
                </OrganisationsList>
            </SwitchContainer>
        </Wrapper>
    )
    return (
        <>
            <Container onClick={() => setPickerVisible((pickerVisible: boolean) => !pickerVisible)}>
                <AvatarImage>
                    <Image src={currentOrganisation?.imageUrl ? API_URL + currentOrganisation?.imageUrl + "&current_time=" + "-" + new Date().getTime() : require('assets/images/logo.png')} />
                </AvatarImage>
                {sidebarOpen &&
                    <Content>
                        <span>{currentOrganisation?.name}</span>
                        <Small>Switch organisation</Small>
                    </Content>
                }
            </Container>
            {pickerVisible && renderSwitcher()}
        </>
    )
};

export default OrganisationDropdownMenu;
