import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';
import LanguageButton from './LanguageButton';

const mapStateToProps = (state: ReduxStore) => ({
    // Map state to props
    currentLanguage: state.language.currentLanguage,
});
const mapDispatchToProps = {};
const ConnectedLanguageButton = connect(
    mapStateToProps,
    mapDispatchToProps
)(LanguageButton);

export default ConnectedLanguageButton;
