import React from 'react';
import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import AdminAllocatorQuestionsList from 'sections/Admin/AdminAllocatorQuestionsList/AdminAllocatorQuestionsList.connected';

const AdminAllocatorQuestions: React.FC = () => {
    return (
        <AdminLayout>
            <AdminAllocatorQuestionsList />
        </AdminLayout>
    );
};

export default AdminAllocatorQuestions;