import useLocalStorageState from 'use-local-storage-state/dist';

const useZoomMatrix = (currentOrganisation?: string) => {
    const [zoomTable, setZoomTable] = useLocalStorageState<number>(`jobmatrix-zoom-level-` + currentOrganisation, 80);

    const handleZoom = (value: number) => {
        setZoomTable(value)
    }
    return { zoomTable, handleZoom };
}

export default useZoomMatrix;
