import Button from 'components/Button/Button';
import { transform } from 'helpers/htmlToPdf';
import getLanguageObject from 'helpers/language';
import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { useHistory } from 'react-router';
import { getStringFromCurrentLanguage } from 'store/language/language.actions';
import {
    ValidatedProfileItem, ResultValidatedProfileBottom, ResultValidatedProfileColumn, ResultValidatedProfileContainer,
    ResultValidatedProfileMain, TextEditor,
    ResultValidatedProfileTMACompetencies,
    ResultValidatedProfileTMACompetenciesHeader,
    ResultValidatedProfileTMACompetenciesColumn,
    ResultValidatedProfileTMACompetenciesHeaderTitle,
    ResultValidatedProfileTMACompetenciesColumnContainer
} from './ResultValidatedProfile.components';
import ResultValidatedProfileProps from './ResultValidatedProfile.types';
import { IconButton } from 'components/Button/IconButton/IconButton';
import ArrowDownIcon from 'components/Icons/arrowDown';
import { ArrowUpIcon } from 'components/Icons/arrowUp';

const ResultValidatedProfile: React.FC<ResultValidatedProfileProps> = ({
    result, currentLanguage, note, resultsChangeResult, canEdit, setCanEdit, getRemoteLanguages, currentOrganisation
}) => {
    const lang = getLanguageObject(currentLanguage)
    const [newNote, setNewNote] = useState(note || '')
    const history = useHistory()
    const [isCollapsed, setIsCollapsed] = useState(true);


    useEffect(() => {
        getRemoteLanguages()
    }, [])

    if (!result) return null;

    const onChangeNote = () => {
        const emptyLine = "<p><br></p>"
        const filteredActiveNote = (newNote !== "" && newNote !== emptyLine) ? newNote.replace(emptyLine, "") : emptyLine
        resultsChangeResult(result.status, filteredActiveNote, result?.id, history);
        setCanEdit(false)
    }

    const toggleTMACompetences = () => {
        setIsCollapsed(!isCollapsed);
    }

    if (!currentOrganisation) return null;

    const competences = result.validatedProfile.competences ?? [];
    const firstHalf = competences.filter((_, index) => index % 2 === 0);
    const secondHalf = competences.filter((_, index) => index % 2 === 1);

    return (
        <>
            <ResultValidatedProfileContainer>
                <ResultValidatedProfileMain>
                    <ResultValidatedProfileColumn>
                        <ValidatedProfileItem
                            title={lang.styrGroups}
                            text={getStringFromCurrentLanguage(
                                result.validatedProfile.styrGroup,
                                currentLanguage
                            )}
                            color={result.validatedProfile.color}
                        />
                            <ValidatedProfileItem
                                title={lang.problemSolvingCapability}
                                text={getStringFromCurrentLanguage(
                                    result.validatedProfile.problemSolvingCapability,
                                    currentLanguage
                                )}
                            />
                        {
                            <ValidatedProfileItem
                                title={lang.expertiseLevel}
                                text={getStringFromCurrentLanguage(
                                    result.validatedProfile.expertiseLevel,
                                    currentLanguage
                                )}
                            />
                        }
                        {
                            <ValidatedProfileItem
                                title={lang.planningHorizon}
                                text={getStringFromCurrentLanguage(
                                    result.validatedProfile.planningHorizon,
                                    currentLanguage
                                )}
                            />
                        }
                    </ResultValidatedProfileColumn>
                    <ResultValidatedProfileColumn>
                        {
                            <ValidatedProfileItem
                                title={lang.continuousImprovement}
                                text={getStringFromCurrentLanguage(
                                    result.validatedProfile.continuousImprovement,
                                    currentLanguage
                                )}
                            />
                        }
                        {
                            <ValidatedProfileItem
                                title={lang.autonomy}
                                text={getStringFromCurrentLanguage(
                                    result.validatedProfile.autonomy,
                                    currentLanguage
                                )}
                            />}
                        {
                            <ValidatedProfileItem
                                title={lang.communicationSkills}
                                text={getStringFromCurrentLanguage(
                                    result.validatedProfile.communicationSkills,
                                    currentLanguage
                                )}
                            />
                        }
                        {
                            <ValidatedProfileItem
                                title={lang.managementSkill}
                                text={getStringFromCurrentLanguage(
                                    result.validatedProfile.managementSkills,
                                    currentLanguage
                                )}
                            />
                        }
                        {
                            <ValidatedProfileItem
                                title={lang.projectManagementSkills}
                                text={getStringFromCurrentLanguage(
                                    result.validatedProfile.projectManagementSkills,
                                    currentLanguage
                                )}
                            />
                        }
                        {
                            <ValidatedProfileItem
                                title={lang.expertiseLevel + "<br/>" + lang.educationLevelValidatedProfile}
                                text={getStringFromCurrentLanguage(
                                    result.validatedProfile.educationLevel,
                                    currentLanguage
                                )}
                            />
                        }
                    </ResultValidatedProfileColumn>
                </ResultValidatedProfileMain>
                {(currentOrganisation.differentiatingFactors.styrCompetencies && competences.length > 0) && <ResultValidatedProfileTMACompetencies>
                    <ResultValidatedProfileTMACompetenciesHeader>
                        <ResultValidatedProfileTMACompetenciesHeaderTitle>{lang.styr_competencies}</ResultValidatedProfileTMACompetenciesHeaderTitle>
                        <IconButton IconSVG={isCollapsed ? ArrowDownIcon: ArrowUpIcon} color="success" onClick={toggleTMACompetences} />
                    </ResultValidatedProfileTMACompetenciesHeader>
                    {!isCollapsed && <ResultValidatedProfileTMACompetenciesColumnContainer>
                        <ResultValidatedProfileTMACompetenciesColumn>
                            {firstHalf.map((competence) =>
                                <ValidatedProfileItem
                                    //@ts-ignore
                                    title={lang[competence.key] || competence.key}
                                    text={getStringFromCurrentLanguage(competence.translations, currentLanguage)} />)}
                        </ResultValidatedProfileTMACompetenciesColumn>
                        <ResultValidatedProfileTMACompetenciesColumn>
                            {secondHalf.map((competence) =>
                                <ValidatedProfileItem
                                    //@ts-ignore
                                    title={lang[competence.key] || competence.key}
                                    text={getStringFromCurrentLanguage(competence.translations, currentLanguage)} />)}
                        </ResultValidatedProfileTMACompetenciesColumn>
                    </ResultValidatedProfileTMACompetenciesColumnContainer>
                    }
                </ResultValidatedProfileTMACompetencies>}
                <ResultValidatedProfileBottom>
                    <strong>{lang.note}</strong>
                    {canEdit ? <TextEditor><ReactQuill
                        theme='snow'
                        defaultValue={newNote}
                        onChange={(newNote: string) => setNewNote(newNote)}
                        modules={{
                            clipboard: {
                                matchVisual: false
                            }
                        }}
                    />
                        <Button text={lang.save} priority="primary" onClick={onChangeNote} />
                    </TextEditor> : <div dangerouslySetInnerHTML={{ __html: getStringFromCurrentLanguage(result.jobInformations?.extraField, currentLanguage) }} />}
                </ResultValidatedProfileBottom>
            </ResultValidatedProfileContainer>

        </>
    );
};

export default ResultValidatedProfile;
