import apiRequest from 'helpers/api';
import { History } from 'history';
import { Dispatch } from 'redux';
import Routes from 'routes/Routes.types';
import { getCurrentLanguageId } from 'store/language/language.actions';
import ResultsActions from 'store/results/results.constants';
import store from 'store/store';
import { getToken } from 'store/tokenHelper';
import { updateOrganisationRequest } from 'store/user/user.actions';
import UserActions from 'store/user/user.constants';
import { ApiError } from 'types/Api';
import { DifferentiatingFactors } from 'types/Organisation';
import {handleApiError} from "../../helpers/errorHandler";
import {JobFamilyErrorTypes} from "./jobfamily.errors";
import {Domain} from "../../types/Domain";

export type CreateJobfamily = (
    organisation: string,
    name: string
) => void;

export const createJobfamily: CreateJobfamily = (
    organisation,
    name
) => (dispatch: Dispatch) => {
    const state = store.getState();
    const endpoint = `organisations/family`;

    const details = {
        organisationId: organisation,
        name,
        status: 'concept'
    }

    apiRequest(endpoint, 'POST', state.user.jwt, details, {
        currentOrganisation: state.user.currentOrganisation?.id
    })
        .then(() => {
            //@ts-ignore
            updateOrganisationRequest()(dispatch)
        })
        .catch((error: ApiError) => {
            handleApiError(Domain.JobFamily, JobFamilyErrorTypes.createJobfamily, state.language.currentLanguage)
            return dispatch({
                type: UserActions.AddError,
                payload: {
                    error: 'There was a problem creating the job family',
                },
            });
        });
}

export type UpdateJobfamily = (organisation: string, family: string, name: string, results: string[] | null) => void;
export const updateJobfamily: UpdateJobfamily = (organisation, family, name, results) => async (
    dispatch: Dispatch
) => {
    const state = store.getState();
    const endpoint = `organisations/family`;

    const currentFamily = state.user.currentOrganisation?.jobFamilies.find((f) => f.id === family)
    const currentStatus = currentFamily?.status || 'active'

    const details = {
        id: family,
        organisationId: organisation,
        name,
        results,
        status: currentStatus
    }
    apiRequest(endpoint, 'PUT', state.user.jwt, details, {
        currentOrganisation: state.user.currentOrganisation?.id
    })
        .then(() => {
            //@ts-ignore
            updateOrganisationRequest()(dispatch)
        })
        .catch(() => {
            handleApiError(Domain.JobFamily, JobFamilyErrorTypes.updateJobfamily, state.language.currentLanguage)
            return dispatch({
                type: UserActions.AddError,
                payload: {
                    error: 'There was a problem updating the job family',
                },
            });
        });
};

export type DeleteJobfamily = (id: string, history: History) => void;
export const deleteJobfamily: DeleteJobfamily = (id, history) => async (
    dispatch: Dispatch
) => {
    const state = store.getState();
    const endpoint = `organisations/family/${id}`;

    await apiRequest(endpoint, 'DELETE', state.user.jwt, undefined, {
        currentOrganisation: state.user.currentOrganisation?.id
    }).then(
        () => {
            //@ts-ignore
            updateOrganisationRequest()(dispatch)
            history.push(Routes.ManageJobFamily);
        }).catch(() => {
            handleApiError(Domain.JobFamily, JobFamilyErrorTypes.deleteJobfamily, state.language.currentLanguage)
            return dispatch({
                type: UserActions.AddError,
                payload: {
                    error: 'There was a problem updating the job family',
                },
            });
        })
};


export type diffKey = "levelOfExpertise" |
    "planningHorizon" |
    "continuousImprovement" |
    "autonomy" |
    "communicationSkills" |
    "coordinationSkills" |
    "projectManagementSkills" |
    "educationLevel" |
    "styrCompetencies";

export type UpdateDifferentiatingFactor = (organisation: string, differentiatingFactors: DifferentiatingFactors) => void;
export const updateDifferentiatingFactor: UpdateDifferentiatingFactor = (organisation, differentiatingFactors) => async (
    dispatch: Dispatch
) => {
    const state = store.getState();
    const endpoint = `organisations`;
    const details = {
        id: organisation,
        differentiatingFactors
    }

    dispatch({
        type: UserActions.UpdateOrganisation,
        payload: {
            organisation: {
                ...state.user.currentOrganisation,
                differentiatingFactors
            },
        },
    });

    apiRequest(endpoint, 'PUT', state.user.jwt, details, {
        currentOrganisation: state.user.currentOrganisation?.id
    })
        .catch(() => {
            handleApiError(Domain.JobFamily, JobFamilyErrorTypes.updateDifferentiatingFactor, state.language.currentLanguage)
            return dispatch({
                type: UserActions.AddError,
                payload: {
                    error: 'There was a problem updating the job family',
                },
            });
        });
};

export type UpdateJobFamilyByField = (
    id: string,
    field: string,
    data: string,
    noTranslation?: boolean,
    updateState?: boolean
) => Promise<any>;

export const updateJobFamilyByField = (
    id: string,
    field: string,
    data: string,
    noTranslation?: boolean,
    updateState?: boolean
) => async (dispatch: Dispatch) => {
    const state = store.getState();

    const token = await getToken(state, dispatch);

    const endpoint = 'organisations/family';
    const currentLanguageCode = getCurrentLanguageId(state.language)

    const currentFamily = state.user.currentOrganisation?.jobFamilies.find((family) => family.id === id);

    let message : any = {
        organisationId: state.user.currentOrganisation?.id,
        id: id,
        status: currentFamily?.status || 'active',
        [field]:
            [{
                languageId: currentLanguageCode,
                translation: data
            }],
    };

    if(noTranslation) {
        message[field] = data
    }

    return apiRequest(endpoint, 'PUT', token, message, {
        currentOrganisation: state.user.currentOrganisation?.id
    })
        .then(() => {
            if(updateState) {
                //@ts-ignore
                updateOrganisationRequest()(dispatch)
            } else {
                let updatedJobFamily = currentFamily;

                if(noTranslation && currentFamily) {
                    updatedJobFamily = {
                        ...currentFamily,
                        [field]: data
                    }
                } else if(currentFamily && currentLanguageCode) {
                    updatedJobFamily = {
                        ...currentFamily,
                        [field]: {
                            //@ts-ignore
                            ...currentFamily[field],
                            [currentLanguageCode]: data
                        }
                    }
                }

                dispatch({
                    type: UserActions.UpdateOrganisation,
                    payload: {
                        organisation: {
                            ...state.user.currentOrganisation,
                            jobFamilies: state.user.currentOrganisation?.jobFamilies.map((family) => {
                                if(family.id === id) {
                                    return updatedJobFamily
                                }
                                return family
                            })
                        },
                    },
                })
            }
        })
        .catch((error: ApiError) => {
            handleApiError(Domain.JobFamily, JobFamilyErrorTypes.updateJobFamilyByField, state.language.currentLanguage)
            return dispatch({
                type: ResultsActions.AddError,
                payload: {
                    error: error.message,
                },
            });
        });
};
