import InputField from 'containers/InputField/InputField';
import React from 'react';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';;
import styled from 'styled-components';
import { OrganisationUser } from 'types/Organisation';
import theme from 'utils/theme';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
`;

export const InviteNewUserContainer = styled.div`
    background-color: white;
    border-radius: 8px;
    box-shadow: ${theme.boxShadow};
    margin: 1em 0;
    padding: 1em;
    display: flex;
    flex-direction: column;
    width: 500px;

`;

export const Label = styled.label`
    color: #333333;
    font-size: 14px;
    font-weight: 900;
    line-height: 22px;
    position: relative;
    display: flex;
    flex-direction: column;
`;

export const DropdownSelect = styled.select`
    border: 2px solid #3b4b63;
    border-radius: 4px;
    height: 2.5rem;
    padding-left: 0.5rem;
    outline: none;
    background-color: white;
    font-size: 14px;
    box-sizing: border-box;
    height: 46px !important;
    margin-bottom: 5px;
    width: 100%;
    -webkit-appearance: none;
    &active, &:focus {
        border: 2px solid #66caba;
        outline: none;
    }
`;

export const Icon = styled.div`
    color: #000;
    position: absolute;
    top: 48%;
    right: 20px;
    transform: translateY(-50%);
`;

const InviteExistingUserDropdownContainer = styled.div`
    margin-bottom: 1em;
    z-index: 4;
`;

export const InviteExistingUserDropdown: React.FC<{ data: OrganisationUser[]; selected: string; select: (email: string) => void }> = ({ data, selected, select }) => {
    // const handleOnSelect = (item: OrganisationUser) => {
    //     select(item.emailAdress);
    // }

    // const formatResult = (item: any) => {
    //     return (
    //         <>
    //             <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
    //             <span style={{ display: 'block', textAlign: 'left' }}>{item.emailAdress}</span>
    //         </>
    //     )
    // }
    return (
        <InviteExistingUserDropdownContainer>
            {/* <ReactSearchAutocomplete
                items={data}
                onSelect={handleOnSelect}
                inputSearchString={selected}
                formatResult={formatResult}
                placeholder="Email"
                fuseOptions={{ keys: ["email", "name"] }}
                styling={{
                    border: '2px solid',
                    borderRadius: '8px'
                }}
                showIcon={false}

            /> */}
            <InputField placeholder="Email" type="text" onChange={(e) => select(e.target.value)} value={selected} errorMessage={null} />
        </InviteExistingUserDropdownContainer>
    )
}
