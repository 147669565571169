import React from 'react';
import styled from 'styled-components';
import { JobTag } from 'types/Result';
import theme from 'utils/theme';
import { Profile } from './AllocatorSetup';
import { FieldLabel } from 'containers/InputField/InputField.components';
import { TagTypes } from 'store/results/results.constants';

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`;

export const SetupContainer = styled.div`

    border-radius: 10px;
    box-shadow: ${theme.boxShadow};
    display: grid;
    grid-template-columns: 280px 1fr;
    gap: 20px;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
`;

export const SetupImage = styled.div`
    display: grid;
    align-items: end;
    justify-content: end;
    background-size: cover;
    background-repeat: no-repeat;

    img{
        padding: 10px;
    }
`;

export const StyledOption = styled.option`
    background-color: white;
    outline: none;
    font-size: 14px;
    padding: 0.25rem;
`;

export const OrangeLine = styled.hr`
    height: 2%;
    width: 30%;
    background-color: #f06e64;
    display: block;
    border: 0;
    border-top: 2px solid #f06e64;
    margin: 0 0;
    padding: 0;
`;

export const SubTypography = styled.div`
    color: #333333;
    font-size: 18px;
    font-weight: 900;
    line-height: 27px;
    margin: 1rem 0;
    max-width: 35rem;
    width: auto;
`;

export const StyledForm = styled.form`
    display: flex;
    flex-direction: column;

    input,
    select,
    fieldset{
        width: 100%;
        max-width: unset;
    }
`;

export const LabelText = styled.label`
    margin-bottom: 8px;
    color: #333333;
    font-size: 14px;
    font-weight: 900;
    line-height: 22px;
    position: relative;
`;

export const FirstButtonText = styled.span`
    display: inline-block;
    height: 2.15%;
    width: 7.64%;
    color: #ffffff;
    font-size: 14px;
    font-weight: 900;
    line-height: 22px;
    text-align: left;
`;
export const SecButtonText = styled.div`
    display: inline-block;
    height: 1.76%;
    width: 90%;
    color: white !important;
    text-align: right;
    font-family: 'Font Awesome 5 Pro';
    font-size: 16px;
    letter-spacing: -0.17px;
    line-height: 16px;
`;

export const StyledInput = styled.input`
    padding: 0px;
    padding-right: 15px;
    border: 2px solid #3b4b63;
    border-radius: 5px;
    color: #333333;
    display: block;
    font-family: Avenir;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    min-height: 48px;
    text-indent: 8px;
    width: 90%;
    &:focus,
    &:active {
        border: 2px solid #66caba;
        outline-offset: 0;
        outline: 0;
    }
`;

export const Header = styled.div`
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
`;

export const FormContainer = styled.div`
    background: white;
    padding: 40px 30px;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 380px;
`;

interface TagsCheckboxesProps {
  profile: Profile;
  setProfile: React.Dispatch<React.SetStateAction<Profile>>;
  getAllTags: () => JobTag[];
  lang: { tagsHeader: string };
}

const TagsCheckboxes: React.FC<TagsCheckboxesProps> = ({ profile, setProfile, getAllTags, lang }) => {
  const tags = profile.tags || [];
  const onCheckBox = (tag: string) => {
    // Only select one tag at most. If the tag is already selected, deselect it and select the other one. There are only two tagTypes.
    const allTags = getAllTags();
    const isDeselect = tags.includes(tag);
    if(isDeselect) {
      setProfile({
        ...profile,
        tags: allTags.filter(x => x.id === tag).map((tag) => tag.id),
      });
      return
    }

    // If the tag is not already selected, then we need to deselect the other tag and select the current one.
    setProfile({
      ...profile,
      tags: [tag]
    });
};

  return (
    <>
      <FieldLabel label={lang.tagsHeader} />
      <div style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '10px',
        marginBottom: '20px',
      }}>
        {getAllTags().map((option) => (
          <div key={option.id} style={{
            display: 'grid',
            gridTemplateColumns: 'auto 1fr',
            gap: '10px',
            padding: '7px 0',
          }}>
            <input
              type="checkbox"
              value={option.id}
              onChange={() => onCheckBox(option.id)}
              checked={tags.includes(option.id)}
            />
            {option.name}
          </div>
        ))}
      </div>
    </>
  );
};

export default TagsCheckboxes;