import React from "react";
import styled from "styled-components";
import { LabelProps } from "./CopyLink.types";

export const Hover = () => <Container><Label bgColor='#E6E6E6'>Copy text to clipboard</Label></Container>
export const Copied = () => <Container><Label bgColor='#F0FDE1'><Checked />Copied text to clipboard</Label></Container>

export const EnvelopeForward: React.FC = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" height={24} width={24}><path d="M24 24.9 7 13.75V37h19.5v3H7q-1.2 0-2.1-.9Q4 38.2 4 37V11q0-1.2.9-2.1Q5.8 8 7 8h34q1.2 0 2.1.9.9.9.9 2.1v15.5h-3V13.75Zm0-3L41 11H7Zm14.7 23.9-2.1-2.1 3.65-3.7H29.5v-3h10.75l-3.7-3.7 2.15-2.1 7.3 7.3ZM7 13.75V38.7 26.5v.2V11Z" /></svg>
const Checked: React.FC = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" height={24} width={24}><path d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" /></svg>

const Container = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    cursor: pointer;
`

const Label = styled.div<LabelProps>`
    padding: 4px 12px;
    height: 24px;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 34px;
    background-color: ${props => props.bgColor};
`