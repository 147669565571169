import React from 'react';
import styled from 'styled-components';
import theme from 'utils/theme';

const Paper: React.FC<any> = props => {
    return <PaperContainer>{props.children}</PaperContainer>;
};

const PaperContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
    height: auto;
    width: auto;
    max-width: 400px;
    margin: auto;
    border-radius: 8px;
    box-shadow: ${theme.boxShadow};
    padding: 1em;
    min-width: 25rem;
    background-color: white;
`;

export default Paper;
