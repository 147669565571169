import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Copied, EnvelopeForward, Hover } from "./CopyLink.components";
import CopyLinkProps from "./CopyLink.types";

const CopyLink: React.FC<CopyLinkProps> = ({ link }) => {
    const [hover, setHover] = useState(false);
    const [clicked, setClicked] = useState(false);

    const hasCopied = useCallback(() => {
        setHover(false)
        setTimeout(() => setClicked(false), 1000);
        navigator.clipboard.writeText(link)
        return;
    },[link, setHover])

    useEffect(() => {
        if (clicked) return hasCopied();
        return;
    },[clicked, hasCopied])

    return (
        <Container onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onMouseDown={() => setClicked(true)}>
            <EnvelopeForward />
            {link}
            {hover && <Hover />}
            {clicked && <Copied />}
        </Container>
    )
}

export default CopyLink

const Container = styled.div`
    padding: 4px;
    display: grid;
    grid-template-columns: 24px 1fr;
    grid-gap: 10px;
    align-items: center;
    
    position: relative;
`
