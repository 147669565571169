import { connect } from 'react-redux';
import ReduxStore from 'store/store.type';
import {
    userAddError,
    userClearErrors, userLoginMicrosoft
} from 'store/user/user.actions';
import LoginForm from './LoginForm';

const mapStateToProps = (state: ReduxStore) => ({
    errors: state.user.errors,
    currentLanguage: state.language.currentLanguage
});
const mapDispatchToProps = { userLoginMicrosoft, userAddError, userClearErrors };
const ConnectedLoginForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginForm);

export default ConnectedLoginForm;