
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs';
import Button from 'components/Button/Button';
import Popup from 'components/Popup/Popup';
import { HeaderCheckBox, HeaderItem, State } from 'components/TableElements/TableElements';
import ConnectedBottomNavigation from 'containers/BottomNavigation/BottomNavigation';
import InputField from 'containers/InputField/InputField';
import { InputFieldErrorMessages } from 'containers/InputField/InputField.components';
import { compareFamily } from 'helpers/CompareFunctions';
import { onKeyDown } from 'helpers/form';
import getLanguageObject from 'helpers/language';
import { useNavigate } from 'helpers/useNavigation';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Routes from 'routes/Routes.types';
import FilterInputDropdown, { BulkActions } from 'sections/ResultsFilters/ResultsFilters.components';
import { CreateJobfamily, createJobfamily, deleteJobfamily, DeleteJobfamily, updateJobfamily, UpdateJobfamily, UpdateJobFamilyByField, updateJobFamilyByField } from 'store/families/jobfamily.actions';
import { resultsGetResults, ResultsGetResults } from 'store/results/results.actions';
import ReduxStore from 'store/store.type';
import { updateOrganisationRequest, UpdateOrganisationRequest } from 'store/user/user.actions';
import { Permissions } from 'store/user/user.types';
import { Language } from 'types/MultiLingualString';
import { Family, Result, Status } from 'types/Result';
import useLocalStorageState from 'use-local-storage-state/dist';
import { Container, Controls, Header, Row, Table, TableBody, TableItemContainer } from './ListOfJobFamilies.components';
import { statusOptionList, statusOptions } from 'sections/ValidatedProfiles/ResultSummary/ResultSummary.types';
import { Organisation } from 'types/Organisation';

const ListJobFamilies: React.FC<ListJobFamiliesProps> = ({ isShareLink, currentLanguage, currentOrganisation, updateOrganisationRequest, createJobfamily, resultsGetResults, updateJobFamilyByField, permissions }) => {
    const lang = getLanguageObject(currentLanguage);
    const { navigate } = useNavigate();

    const [popupState, setPopup] = useState(false);
    const [selectedItems, setSelectedItems] = useState<string[]>([])

    const [newJobFamily, setNewJobFamily] = useState("")
    const [state, setState] = useLocalStorageState<{
        sorting: keyof Family;
        sortingOrder: boolean;
    }>("jobProfilerSortOnLastModified" + currentOrganisation?.id, { sorting: 'lastModified', sortingOrder: false });

    const [statusFilter, setStatusFilter] = useState<string | null>(null);

    const changeSorting = (item: keyof Family) => {
        if (state.sorting === item) {
            setState({
                ...state,
                sortingOrder: !state.sortingOrder,
            });
        } else {
            setState({
                ...state,
                sorting: item,
                sortingOrder: false,
            });
        }
    };

    const [error, setError] = useState({
        exist: false,
        value: false,
    });

    useEffect(() => {
        resultsGetResults();
    }, [
        resultsGetResults,
        currentOrganisation,
    ]);

    useEffect(() => updateOrganisationRequest(), [
        updateOrganisationRequest,
    ]);

    if (!currentOrganisation?.id) return null;

    const submit = () => {
        const checkIfFamilyExist = currentOrganisation?.jobFamilies.find((x: Family) => x.name === newJobFamily)
        if (!newJobFamily) return setError({ ...error, value: true })
        if (checkIfFamilyExist) return setError({ ...error, exist: true })
        createJobfamily(currentOrganisation.id, newJobFamily);
        setNewJobFamily("")
        setPopup(false)
    };

    const MaybeRenderError = () => {
        if (error.exist) return <InputFieldErrorMessages text={lang.jobFamilyAlreadyExist} />
        if (error.value) return <InputFieldErrorMessages text={lang.pleaseEnterFamilyName} />

        return null;
    }

    const countItems = (value: any[] | null): number => {
        if (!value || value === null) return 0

        return value.length
    }

    const jobFamilies = currentOrganisation?.jobFamilies || [];

    const onSelectAll = () => {
        if (selectedItems.length === jobFamilies.length) {
            setSelectedItems([]);
            return
        }
        const ids = jobFamilies.map(x => x.id)
        setSelectedItems(ids)
    }

    const onBulkChangeStatus = (newStatus: string) => {
        const statusOptionsListAsStrings : string[] = statusOptionList;
        if(!statusOptionsListAsStrings.includes(newStatus.toLowerCase())) {
            return
        }
        selectedItems.forEach(id => {
            updateJobFamilyByField(id, "status", newStatus as Status, true, true)
        })
        setSelectedItems([])
    }

    const RenderJobFamilies = () => {
        if (!currentOrganisation?.jobFamilies) return null;

        const filteredFamilies = jobFamilies.filter(x => {
            if(!statusFilter) {
                return true
            }
            return x.status?.toLowerCase() === statusFilter
        }).filter((x) => {
            if(!isShareLink) {
                return true
            }
            return x.status?.toLowerCase() === "active"
        })

        const sortedFamilies = filteredFamilies.sort((a, b) => {
            return compareFamily(a, b, state.sorting);
        });

        if (state.sortingOrder) {
            sortedFamilies.reverse();
        }

        return (<TableBody>
            {sortedFamilies.map((family: Family) => {
                const allowToView = countItems(family.jobs) === 0 ? true : false
                const checkStatus = family.status === null ? 'Concept' : family.status
                return (
                    <TableItemContainer key={family.id}>
                        {permissions.canManageOrganisation ? <HeaderCheckBox activeItem={selectedItems.includes(family.id)} onClick={() => {
                            if (!selectedItems.includes(family.id)) {
                                setSelectedItems([...selectedItems, family.id])
                                return
                            }
                            setSelectedItems(selectedItems.filter(x => x != family.id))
                        }} /> : <div />}
                        <div>
                            {family.name.replace(/<[^>]*>?/gm, '').replace("&amp;", "&")}
                        </div>
                        <div>
                            {countItems(family.jobs)}
                        </div>
                        <div>
                            {moment(family.lastModified).format('DD MMM YYYY HH:mm')}
                        </div>
                        <State status={checkStatus} />
                        <Row>
                            {(permissions.canManageOrganisation || (family.status?.toLowerCase() === "concept")) && <Button text={lang.manage} priority="primary" onClick={() => navigate(Routes.JobFamilyEdit + family.id)} size="small" />}
                            <Button text={lang.view} priority="tertiary" onClick={() => navigate(Routes.JobFamilyView + family.id)} disabled={allowToView} size="small" />
                        </Row>
                    </TableItemContainer>
                )
            })}
        </TableBody>)
    }

    return (
        <>
            <Container>
                <Header>
                    <BreadCrumbs title={lang.manageJobFamily} paths={
                        [{ title: lang.manage, path: Routes.ManageJobFamily }]
                    } />
                </Header>
                <Table>
                    <TableItemContainer>
                        {permissions.canManageOrganisation ? <HeaderCheckBox activeItem={selectedItems.length === jobFamilies.length} onClick={onSelectAll} /> : <div />}
                        <HeaderItem
                            text={lang.jobFamily}
                            onClick={() => changeSorting('name')}
                            activeItem={state.sorting === 'name'}
                            sortingOrder={state.sortingOrder}
                        />

                        <HeaderItem
                            text={"#"}
                            inactive
                        />
                        <HeaderItem
                            onClick={() => changeSorting('lastModified')}
                            text={lang.lastModified}
                            activeItem={state.sorting === 'lastModified'}
                            sortingOrder={state.sortingOrder}
                        />
                        <HeaderItem
                            text={lang.status}
                            inactive
                        />
                        <div></div>
                    </TableItemContainer>
                    <RenderJobFamilies />
                </Table>
                <ConnectedBottomNavigation>
                    <BulkActions>
                        {permissions.canManageOrganisation && (
                            <FilterInputDropdown
                                label="Status"
                                onChange={e => {
                                    const newStatus = e.target.value;
                                    if(newStatus === "Show All") {
                                        setStatusFilter(null);
                                        return;
                                    }
                                    setStatusFilter(newStatus);
                                    onBulkChangeStatus(e.target.value);
                                }}
                                options={statusOptions()}
                                selected={statusFilter || "Status"}
                            />
                        )}
                    </BulkActions>
                    <Controls>
                        {permissions.canManageOrganisation && (
                            <>
                                <Button text={lang.newJobFamily} onClick={() => setPopup(true)} priority="primary" />
                                <Popup currentLanguage={currentLanguage} buttonText={lang.newJobFamily} title={lang.newJobFamily} popupHandler={setPopup} popupState={popupState}>
                                    <InputField
                                        width={280}
                                        placeholder={lang.nameJobFamily}
                                        type="text"
                                        value={newJobFamily}
                                        onChange={event => setNewJobFamily(event.target.value)}
                                        onKeyDown={event => onKeyDown(event, submit)}
                                        errorMessage={
                                            <MaybeRenderError />
                                        } />
                                    <Button text={lang.addJobFamily} onClick={submit} priority="tertiary" />

                                </Popup>
                            </>
                        )}
                    </Controls>
                </ConnectedBottomNavigation>
            </Container>
        </>
    );
};

const mapStateToProps = (state: ReduxStore) => ({
    currentLanguage: state.language.currentLanguage,
    currentOrganisation: state.user.currentOrganisation,
    results: state.results.results,
    permissions: state.user.permissions,
    isShareLink: state.user.isShareLink
});
const mapDispatchToProps = { createJobfamily, updateOrganisationRequest, deleteJobfamily, updateJobfamily, resultsGetResults, updateJobFamilyByField };
const ListJobFamiliesConnected = connect(
    mapStateToProps,
    mapDispatchToProps
)(ListJobFamilies);

export default ListJobFamiliesConnected;

interface ListJobFamiliesProps {
    isShareLink?: boolean;
    currentLanguage: Language
    currentOrganisation?: Organisation;
    createJobfamily: CreateJobfamily;
    updateOrganisationRequest: UpdateOrganisationRequest;
    updateJobFamilyByField: UpdateJobFamilyByField;
    deleteJobfamily: DeleteJobfamily
    updateJobfamily: UpdateJobfamily
    resultsGetResults: ResultsGetResults
    results: Result[];
    permissions: Permissions;
}
