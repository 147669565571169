import Toggle from 'components/Toggle/Toggle';
import React from 'react';
import styled from 'styled-components';

interface ListItemWithToggleProps {
    label: string;
    state: boolean;
    onChange?: (state: boolean) => void;
}

const Container = styled.div`
    margin: 10px 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    p{
        margin: 0;
    }
`

const ListItemWithToggle: React.FC<ListItemWithToggleProps> = ({ label, state, onChange }) => {


    return (<Container onClick={onChange ? () => onChange(!state) : undefined}>
        {label && <span dangerouslySetInnerHTML={{ __html: label }} />}
        <Toggle state={state} />
    </Container>)
}

export default ListItemWithToggle